import React, { PureComponent, useContext, useState } from 'react';
import { BarChart, LineChart, Bar, Line, XAxis, YAxis, CartesianGrid, Cell, Tooltip, Legend, LabelList, ResponsiveContainer, Label } from 'recharts';
import { numbersManager } from '../actions/userActions'
import { AppContext } from '../actions/contextApi';
import { centerlist } from '../actions/centerActions';
import { Box, Typography } from '@material-ui/core';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
var createReactClass = require('create-react-class');
const data = [
  {
    "year": "2021",
    "North": { 'IP': '', 'OP': '', 'Total': 130000000 },
    "South": { 'IP': '', 'OP': '', 'Total': 97927344 },
    "West": { 'IP': '', 'OP': '', 'Total': 69442337 },
    "East": { 'IP': '', 'OP': '', 'Total': 123456789 },
    "East-1": { 'IP': '', 'OP': '', 'Total': 98765432 },

  },
  {
    "year": "2022",
    "North": { 'IP': '', 'OP': '', 'Total': 130000000 },
    "South": { 'IP': '', 'OP': '', 'Total': 97927344 },
    "West": { 'IP': '', 'OP': '', 'Total': 69442337 },
    "East": { 'IP': '', 'OP': '', 'Total': 123456789 },
    "East-1": { 'IP': '', 'OP': '', 'Total': 98765432 },

  }
]

const colors = ['green', '#FFC658', '#FF847C', '#DE3959', '#E84A5F', '#ffc658', '#FECEAB', '#99B898', '#f77bef', '#003366', '#88eedd', '#a474dc', '#93831d', '#9ab762', '#391a21', '#20b2aa', '#95989d', '#ace1af', '#172e1e', '#b00e18', '#40ff00', '#ff8000', '#ff00bf']

const StackChartPayout = (props) => { 
  const {graphFilterByGroup, setGraphFilterByGroup} = useContext(AppContext)
  const {graphFilterByYear, setGraphFilterByYear} = useContext(AppContext)
  const {tableHeaderName, setTableHeaderName} = useContext(AppContext);
  const {graphFilterByLegend, setGraphFilterByLegend} = useContext(AppContext);
  const {tableShow, setTableShow} = useContext(AppContext)
  const [focusBar, setfocusBar] = useState(null);
  const [focusBar1, setfocusBar1] = useState(null);
  const [focusBar2, setfocusBar2] = useState(null);
  const [focusBar3, setfocusBar3] = useState(null);
  const ref = useContext(AppContext);
  // console.log(props.RevenueData,);
  const CustomizedLabel = createReactClass({
    render: function () {
      const { x, y, fill, value } = this.props;
  // console.log(value, "HDJDJDJDJJDJDJDJ")
      return (<text
        x={x}
        y={y}
        dy={-4}
        fontSize='10px'
        fontWeight='bold'
        fill={fill}
        textAnchor="start">{numbersManager(value)}</text>)
    }
  })
  const CustomTooltip = ({ active, payload, label }) => {
// console.log(payload, "arr--------")
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{ marginTop: '1px', maxHeight: '200px', overflow: 'scroll', pointerEvents: 'auto', fontSize: "10px"}}>
          <p  style={{color:'Black', fontSize: "12px", fontWeight: "bolder"}}>Payout:</p>
          {Object.keys(payload[0].payload).filter(elem => elem !== 'GroupName').filter(elem => elem !== 'YearName').map(item =>
            <div>
              {(`${item}`.includes("-p")) ? (
                <p className="label" style={{ display: 'flex', allignItems: 'left', fontSize: '10px' }}>{`${(`${item}`).slice(0, -1)} : ${numbersManager(payload[0].payload[item])}`}</p>
              ) : ""}
            </div>
          )}
          <p  style={{color:'Black', fontSize: "12px", fontWeight: "bolder"}}>Revenue:</p>
          {Object.keys(payload[0].payload).filter(elem => elem !== 'GroupName').filter(elem => elem !== 'YearName').map(item =>
            <div>
              {(`${item}`.includes("-r")) ? (
                <p className="label" style={{ display: 'flex', allignItems: 'left', fontSize: '10px' }}>{`${(`${item}`).slice(0, -1)} : ${numbersManager(payload[0].payload[item])}`}</p>
              ) : ""}
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  const graphOnClickPay = (e, GroupName) => {
    ref.current?.scrollIntoView({behavior: 'smooth'})
    setGraphFilterByGroup(GroupName)
    setGraphFilterByYear(e.YearName)
    setGraphFilterByLegend(e.tooltipPayload[0].name.slice(0, -2))
    setTableHeaderName('Payout-LOS Table')
    setTableShow(true)  
    // console.log(e.tooltipPayload[0].name.slice(0, -2), "abc----------->>>>>>>>123")
  }
  const graphOnClickRev = (e, GroupName) => {
    ref.current?.scrollIntoView({behavior: 'smooth'})
    setGraphFilterByGroup(GroupName)
    setGraphFilterByYear(e.YearName)
    setGraphFilterByLegend(e.tooltipPayload[0].name.slice(0, -2))
    setTableHeaderName('Revenue-LOS Table')
    setTableShow(true) 
    // console.log(e.tooltipPayload[0].name.slice(0, -2), "abc----------->>>>>>>>123")
  }
  return (
    <div width="100%" height="100%">
      <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
        <div style={{width: "120px", display: "flex", flexDirection: "row", justifyContent: "Right"}}>
          <span style={{ fontWeight: "bold", marginRight: "33px" }}>R</span>
          <span style={{ fontWeight: "bold", marginRight: "15px" }}>P</span>
        </div>
        <div style={{width: "120px", display: "flex", flexDirection: "row", justifyContent: "Left"}}>
          <span style={{ fontWeight: "bold", marginLeft: "65px" }}>R</span>
          <span style={{ fontWeight: "bold", marginLeft: "28px" }}>P</span>
        </div>
      </div>
      
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <p style={{ width: '60px', display: 'flex', alignItems: 'center', fontSize: '11px', fontWeight: '630' }}>Fertility:</p>
        <BarChart
          width={300}
          height={100}
          data={props.RevenueData.Fertility}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          onMouseMove={(state) => {
            if (state.isTooltipActive) {
              setfocusBar(state.activeTooltipIndex);
            }

          }}
          onMouseLeave={(state) => {
              setfocusBar(null);

          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="YearName" fontSize="10px" />
          {/* <YAxis /> */}
          <Tooltip cursor={{fill: 'transparent'}} wrapperStyle={{ zIndex: 10003 }} content={<CustomTooltip />} />
          {/* <Legend /> */}
          {props.CenterList.map((element, Index) =>
            <>
              <Bar dataKey={element.LegendName + '-r'} stackId="a" fill={element.ColorCode ? element.ColorCode : 'green'} barSize={35} cursor='pointer' onClick={(e) => graphOnClickRev(e, "Fertility")} radius={[3,3,3,3]}>{data.map((entry, index) => (
                <Cell
                  fillOpacity={focusBar === index ? ".5" : "1"}
                />
              ))}</Bar>
              <Bar dataKey={element.LegendName + '-p'} stackId="b" fill={element.ColorCode ? element.ColorCode : 'green'} barSize={35} cursor='pointer' onClick={(e) => graphOnClickPay(e, "Fertility")} radius={[3,3,3,3]}>{data.map((entry, index) => (
                <Cell
                  fillOpacity={focusBar === index ? ".5" : "1"}
                />
              ))}</Bar>
            </>
          )}
        </BarChart>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <p style={{ width: '60px', display: 'flex', alignItems: 'center', fontSize: '11px', fontWeight: '630' }}>Delivery:</p>
        <BarChart
          width={300}
          height={100}
          data={props.RevenueData.Delivery}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          onMouseMove={(state) => {
            if (state.isTooltipActive) {
              setfocusBar1(state.activeTooltipIndex);
            }

          }}
          onMouseLeave={(state) => {
              setfocusBar1(null);

          }}
        >
          {/* <CartesianGrid strokeDasharray='3 3' /> */}
          <XAxis dataKey="YearName" fontSize="10px" />
          {/* <YAxis /> */}
          <Tooltip cursor={{fill: 'transparent'}} wrapperStyle={{ zIndex: 10002 }} content={<CustomTooltip />} />
          {/* <Legend wrapperStyle={{fontSize: "8px"}} iconSize={5} iconType='circle' /> */}
          {props.CenterList.map((element, Index) =>
            <>
              <Bar dataKey={element.LegendName+'-r'} stackId="a" fill={element.ColorCode ? element.ColorCode : 'green'} barSize={35} cursor='pointer' onClick={(e) => graphOnClickRev(e, "Delivery")} radius={[3,3,3,3]}>{data.map((entry, index) => (
              <Cell
                fillOpacity={focusBar1 === index ? ".5": "1"}
              />
            ))}</Bar>
              <Bar dataKey={element.LegendName+'-p'} stackId="b" fill={element.ColorCode ? element.ColorCode : 'green'} barSize={35} cursor='pointer' onClick={(e) => graphOnClickPay(e, "Delivery")} radius={[3,3,3,3]}>{data.map((entry, index) => (
              <Cell
                fillOpacity={focusBar1 === index ? ".5": "1"}
              />
            ))}</Bar>
            </>
          )}
        </BarChart>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <p style={{ width: '60px', display: 'flex', alignItems: 'center', fontSize: '11px', fontWeight: '630' }}>Gynec:</p>
        <BarChart
          width={300}
          height={100}
          data={props.RevenueData.Gynec}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          onMouseMove={(state) => {
            if (state.isTooltipActive) {
              setfocusBar2(state.activeTooltipIndex);
            }

          }}
          onMouseLeave={(state) => {
              setfocusBar2(null);

          }}
        >
          {/* <CartesianGrid strokeDasharray='3 3' /> */}
          <XAxis dataKey="YearName" fontSize="10px" />
          {/* <YAxis /> */}
          <Tooltip cursor={{fill: 'transparent'}} wrapperStyle={{ zIndex: 10001 }} content={<CustomTooltip />} />
          {/* <Legend wrapperStyle={{fontSize: "8px"}} iconSize={5} iconType='circle' /> */}
          {props.CenterList.map((element, Index) =>
            <>
              <Bar dataKey={element.LegendName+'-r'} stackId="a" fill={element.ColorCode ? element.ColorCode : 'green'} barSize={35} cursor='pointer' onClick={(e) => graphOnClickRev(e, "Gynec")} radius={[3,3,3,3]}>{data.map((entry, index) => (
              <Cell
                fillOpacity={focusBar2 === index ? ".5": "1"}
              />
            ))}</Bar>
              <Bar dataKey={element.LegendName+'-p'} stackId="b" fill={element.ColorCode ? element.ColorCode : 'green'} barSize={35} cursor='pointer' onClick={(e) => graphOnClickPay(e, "Gynec")} radius={[3,3,3,3]}>{data.map((entry, index) => (
              <Cell
                fillOpacity={focusBar2 === index ? ".5": "1"}
              />
            ))}</Bar>
            </>
          )}
        </BarChart>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <p style={{ width: '60px', display: 'flex', alignItems: 'center', fontSize: '11px', fontWeight: '630' }}>Pead:</p>
        <BarChart
          width={300}
          height={100}
          data={props.RevenueData.Pead}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          onMouseMove={(state) => {
            if (state.isTooltipActive) {
              setfocusBar3(state.activeTooltipIndex);
            }

          }}
          onMouseLeave={(state) => {
              setfocusBar3(null);

          }}
        >
          {/* <CartesianGrid strokeDasharray='3 3' /> */}
          <XAxis dataKey="YearName" fontSize="10px" />
          {/* <YAxis /> */}
          <Tooltip cursor={{fill: 'transparent'}} wrapperStyle={{ zIndex: 10000 }} content={<CustomTooltip />} />
          {/* <Legend wrapperStyle={{ fontSize: "12px" }} iconSize={8} iconType='circle' /> */}
          {props.CenterList.map((element, Index) =>
            <>
              <Bar dataKey={element.LegendName+'-r'} stackId="a" fill={element.ColorCode ? element.ColorCode : 'green'} barSize={35} cursor='pointer' onClick={(e) => graphOnClickRev(e, "Pead")} radius={[3,3,3,3]}>{data.map((entry, index) => (
              <Cell
                fillOpacity={focusBar3 === index ? ".5": "1"}
              />
            ))}</Bar>
              <Bar dataKey={element.LegendName+'-p'} stackId="b" fill={element.ColorCode ? element.ColorCode : 'green'} barSize={35} cursor='pointer' onClick={(e) => graphOnClickPay(e, "Pead")} radius={[3,3,3,3]}>{data.map((entry, index) => (
              <Cell
                fillOpacity={focusBar3 === index ? ".5": "1"}
              />
            ))}</Bar>
            </>
          )}
        </BarChart>
      </div>
        <Box style={{display: 'flex',justifyContent: 'center'}}>
        {props.CenterList && props.CenterList.length > 0 && props.CenterList.map((item, index) =>
          <Box style={{ display: "flex", margin: "3px", justifyContent: "flex-start" }} >
            <Typography variant="subtitle2" style={{ color: item.ColorCode ? item.ColorCode : 'green', display: 'inline-block', fontSize: '10px', paddingTop: '5px', width: 'auto' }} > <FiberManualRecordIcon fontSize={'small'} style={{ color: item.ColorCode, paddingTop: '10px',marginTop:'-11px' }} /> {item.LegendName ? item.LegendName : ''}
            </Typography>
          </Box>
        )}
      </Box>
    </div>

  );
}
export default StackChartPayout