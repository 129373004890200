import React, { useEffect, useState } from "react";
import { Card, Table, Toast } from "react-bootstrap";
import Navbar from "../../Components/Navbar";
import { Modal } from "react-bootstrap";
//import ui components
import DoctorSidebar from "../../Components/DoctorSidebar";
import { agreementState } from "../../CommomMethods/States";
//import material-ui component
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import MaterialTable from "material-table";
import { Paper } from "@material-ui/core";
import { APIURL } from "../../CommomMethods/Fetch";
//import axios
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import AssignmentIcon from "@material-ui/icons/Assignment";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import { listusers } from "../../actions/userActions";
import Sidebar from "../../Components/Sidebar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { toast } from "react-toastify";
import FixedfullTime from "../Payout/NEW/FixedfullTime";
import FixedMG from "../Payout/NEW/fixedMG";
import VcChennai from "../Payout/NEW/VcChennai";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisitingCon from "../Payout/NEW/VisitingCon";
import TextField from "@mui/material/TextField";
import { numberWithCommas, numberToEnglish } from "../../actions/createPositionActions"
import useId from "@mui/material/utils/useId";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const myTableStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "20%",
    marginTop: theme.spacing(3),
    "& td": {
      fontFamili: "Roboto !important",
    },
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflow: "auto",
    marginBottom: "50px",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },

  buttonProgress: {
    // backgroundColor: "red",
    color: "white",
    position: "absolute",
    top: "50%",
    left: "20%",
    marginTop: -12,
    marginLeft: -12,
  },
}));
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "fixed",
    backgroundColor: "#810e36",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));
export default function CheckAgreement() {
  const tables = myTableStyle();
  const [show, setShow] = useState(false);
  const [showOldCommercial, setShowOldCommercial] = useState(false);
  const [rows, setRows] = useState([]);
  const [orow, setOrow] = useState({});
  const [openr, setOpenr] = useState(false);
  const [remark, setRemark] = useState("");
  const [viewAgreement, setViewAgreement] = useState(false);
  const [row, setRow] = useState({});
  const [categoty, setCategory] = useState("");
  const [docData, setDocData] = useState([]);
  const classes = useStyles();
  const dispatch = useDispatch();
  const userList = useSelector((state) => state.userList);
  const { users } = userList;
  const [docid, setDocid] = useState("");
  const [doctorDetail, setDoctorDetail] = useState([]);
  const [doctorOpDetail, setDoctorOpDetail] = useState([]);
  const [doctorIpCommercial, setDoctorIpCommercial] = useState([]);
  const [doctorOpDetailEdit, setDoctorOpDetailEdit] = useState([]);
  const [doctorIpDetail, setDoctorIpDetail] = useState([]);
  const [doctorMgDetails, setDoctorMgDetails] = useState([]);
  const [doctorFixedDetails, setDoctorFixedDetails] = useState([]);
  const [TotalPayOutValue, setTotalPayOutValue] = useState([]);
  const [doctorOpCommercial, setDoctorOpCommercial] = useState([]);
  const [ipgrossbillTot, setipgrossbillTot] = useState("");
  const [ipdeductiontot, setipdeductiontot] = useState(0);
  const [totaldeduction, setTotaldeduction] = useState(0);
  const [adhoc, setAdhoc] = useState("");
  const [opRevenue, setopRevenue] = useState(0);
  const [opNetPayout, setOpNetPayout] = useState(0);
  const [gross, setGross] = useState("");
  const [opNetAmount, setOpNetAmount] = useState(0);
  const [openOp, setOpenOp] = React.useState(false);
  const [netIp, setnetIp] = useState("");
  const [ipAmountTot, setIpAmountTot] = useState(0);
  const [ipPayoutTotal, setIpPayoutTotal] = useState(0);
  const [grossIp, setGrossIp] = useState("");
  const [ipdiscount, setIpDiscount] = useState("Not applicable");
  const [ipNetBillAmountTot, setipNetBillAmountTot] = useState(0);
  const [doctorOpCommercial_archived, setDoctorOpCommercial_archived] = useState([]);
  const [doctorIpCommercial_archived, setDoctorIpCommercial_archived] = useState([]);
  const [doctorIPParameter_archived, setDoctorIPParameter_archived] = useState([]);
  const [doctorOPParameter_archived, setDoctorOPParameter_archived] = useState([]);
  const [doctorMgDetails_archived, setDoctorMgDetails_archived] = useState([]);
  const [doctorFixedDetails_archived, setDoctorFixedDetails_archived] = useState([]);
  let userid = users[0] && users[0].id;

  useEffect(() => {
    dispatch(listusers());
  }, [dispatch]);

  useEffect(() => {
    axios
      .get(APIURL() + "onboard/currentAgreementStatus/")
      .then((res) => {
    // console.log(userid, res.data.data, "data for chhhh");
        // setRows(res.data.data);
        //setRows(res.data.data.filter((z) => z.currentActionBy == userid));
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, [userid]);

  useEffect(() => {
    axios
      .get(APIURL() + "onboard/currentstatus/")
      .then((res) => res.data)
      .then((rows) => {
    // console.log(rows, userid, "chk point for ch");
        setRows(rows.datas);
        // setRows(rows.data.filter((z) => z.currentActionBy == userid));
      });
  }, []);

  const formStyle = {
    borderRadius: "5px",
    border: "1px solid black",
  };
  const headerStyle = {
    backgroundColor: "#94248E",
    margins: "0px",
    padding: "10px",
    color: "white",
    borderRadius: "5px",
    borderBottom: "3px black solid",
  };
  const bodyStyle = {
    borderRadius: "5px",
  };
  const handleClose = () => setShow(false);
  const handleShow = (doctorid) => {
    setShow(true);
  };

  const handleInputChange = (rowop, event) => {
    const { name, value } = event.target;
    setDoctorOpDetail(rowop);
    if (name == "fixedamount") {
      doctorOpDetail.fixedamount = value;
    }
    if (name == "fixedpercentage") {
      doctorOpDetail.fixedpercentage = value;
    }
    if (name == "opcount") {
      doctorOpDetail.opcount = value;
    }
    if (name == "opperunitcharge") {
      doctorOpDetail.opperunitcharge = value;
    }
    if (name == "netamount") {
      doctorOpDetail.netamount = value;
    }
    if (name == "oprevenue") {
      doctorOpDetail.oprevenue = value;
    }
    if (name == "opgross") {
      doctorOpDetail.opgross = value;
    }
    if (name == "opnet") {
      doctorOpDetail.opnet = value;
    }
    if (name == "totalRevenue") {
      doctorOpDetail.totalRevenue = value;
    }
    if (name == "opadhoc") {
      doctorOpDetail.opadhoc = value;
    }
// console.log(name, "%%%%55");
    setOpservice({ ...rowop, [name]: value });
  };

  const initialFormtStateipservice = {
    itemGroup: "",
    ipconsultaion: "",
    iproom: "",
    ipmodeofpayment: "",
    ipfixedamount: "",
    ipsurgeonfeeperc: "",
    ippackageamountperc: "",
    ipsurgeonfeeamount: "",
    ipdiscountvalue: "",
    ipdiscountType: "",
    ipgrossperc: "",
    ipnetperc: "",
    ipmonth: "",
    netperc: "",
    amount: "",
    count: "",
    revenue: "",
    payout: "",
    ipgrossbill: "",
    ipgrossbillpercentage: "",
    ipdeductaionvalue: "",
    deductionCat: "",
    ipnetbillpercentage: "",
    iptotalamount: "",
    ipnetbillamount: "",
    ipTotalAmount: "",
    ipTotalNetBillAmount: "",
    ipgrossbillperunit: "",
    ipdeductionperunit: "",
  };
  const [iprow, setIpservice] = useState([
    {
      initialFormtStateipservice,
    },
  ]);


  const initialFormtState = {
    consultation: "",
    totalRevenue: "",
    opfixedpay: "",
    oppercpay: "",
    opadhoc: "",
    opcount: "",
    opmonth: "",
    fixedamount: "",
    fixedpercentage: "",
    opperunitcharge: "",
    netamount: "",
    opamount: "",
    oprevenue: "",
    opgross: "",
    opnet: "",
    oppayout: "",
    opdiscounttype: "",
    opdiscountvalue: "",
  };

  const [rowop, setOpservice] = useState({
    initialFormtState,
  });

  const ipCalculation = (rowop, event) => {
    setIpDiscount(rowop.ipdiscountType);
// console.log(rowop, "UUU");
    let packageamount = 5000;
    let surgeonfee = parseInt(rowop.ipsurgeonfeeamount);
    let count = parseInt(rowop.count);
    let disperu = parseInt(rowop.ipdeductionperunit);

    if (isNaN(disperu)) {
      disperu = 0;
    } else {
    }
    if (isNaN(packageamount)) {
      packageamount = 0;
    } else {
    }
    //count value checking
    if (isNaN(surgeonfee)) {
      surgeonfee = 0;
    } else {
    }
    if (isNaN(count)) {
      count = 0;
    } else {
    }

    // let GrossPercentage = packageamount
    //   ? packageamount * count
    //   : surgeonfee * count;

    // if (rowop.ipdiscountType === "No") {
    //   setGrossIp(GrossPercentage);
    // } else {
    //   setnetIp(GrossPercentage)
    // }

    let grossbilip = parseInt(rowop.ipgrossbillperunit);
    let deductaionvalip = parseInt(rowop.ipdeductaionvalue) * count;
    let discountvalip = parseInt(rowop.ipdiscountvalue) * count;
    let grossbilltot = parseInt(rowop.ipgrossbillperunit) * count;

    let netbillpercent = parseInt(rowop.ipnetbillpercentage);

    if (isNaN(grossbilltot)) {
      grossbilltot = 0;
    } else {
    }

    setipgrossbillTot(grossbilltot);

    let deductiontot = disperu * count;

    setipdeductiontot(deductiontot);

    if (isNaN(grossbilip)) {
      grossbilip = 0;
    } else {
    }
    if (isNaN(deductaionvalip)) {
      deductaionvalip = 0;
    } else {
    }
    if (isNaN(discountvalip)) {
      discountvalip = 0;
    } else {
    }

    let finalNetbillcount =
      netbillpercent ? grossbilltot - deductiontot :
        discountvalip === 0 && deductiontot === 0
          ? 0
          : discountvalip === 0
            ? grossbilltot - deductiontot
            : deductiontot === 0
              ? grossbilltot - discountvalip
              : grossbilltot - discountvalip - deductiontot;

    setipNetBillAmountTot(finalNetbillcount);

    // let grossipAmount = grossbilip * count;
    let surgoenipAmount = surgeonfee * count;
    let netAmountip = finalNetbillcount * count;
    let amountval = 0;
    // if (event.target.name === "ipfixedamount") {
    //   amountval = parseInt(event.target.value);
    // }
    amountval = parseInt(rowop.ipfixedamount);
    if (isNaN(amountval)) {
      amountval = 0;
    } else {
    }

    let fixedAmount = amountval * count;
// console.log(finalNetbillcount, rowop, deductiontot, "finalNetbillcount");

    let amountip = finalNetbillcount
      ? finalNetbillcount
      : // ? finalNetbillcount * count
      grossbilip
        ? grossbilltot
        : surgeonfee
          ? surgoenipAmount
          : ipNetBillAmountTot === 0 && packageamount === 0
            ? surgeonfee * count
            : ipNetBillAmountTot === 0 && surgeonfee === 0
              ? 5000 * count
              : 0;
// console.log(amountip, "amountip");
    setIpAmountTot(amountip);

    let surgeopnfeeperc = parseInt(rowop.ipsurgeonfeeperc);

    let packageamountpercnt = parseInt(rowop.ippackageamountperc);
    if (isNaN(surgeopnfeeperc)) {
      surgeopnfeeperc = 0;
    } else {
    }
    if (isNaN(packageamountpercnt)) {
      packageamountpercnt = 0;
    } else {
    }
    if (isNaN(netbillpercent)) {
      netbillpercent = 0;
    } else {
    }
    let grossbillperc = parseInt(rowop.ipgrossbillpercentage);
    let surgeopnfeeperccalculate = (surgoenipAmount * surgeopnfeeperc) / 100;
    if (isNaN(grossbillperc)) {
      grossbillperc = 0;
    } else {
    }
    // let surgeonpayoutip = surgeopnfeeperccalculate * amountip;
    let packageamountpercntcalculate =
      packageamount * count * (packageamountpercnt / 100);
    let grossbillcal = (grossbilltot - ipdeductiontot) * (grossbillperc / 100);
    let netbillpercntage =
      (grossbilltot - ipdeductiontot) * (netbillpercent / 100);
    // let netbillpercntage = netAmountip * (netbillpercent / 100);

    //netAmountip

    let ipPayouttot = fixedAmount
      ? fixedAmount
      : netbillpercntage
        ? netbillpercntage
        : surgeopnfeeperccalculate
          ? surgeopnfeeperccalculate
          : surgeopnfeeperc === 0 && packageamountpercnt === 0
            ? grossbillcal
            : grossbillperc === 0 && packageamountpercnt === 0
              ? netbillpercntage * amountip
              : surgeopnfeeperc === 0 && finalNetbillcount === 0
                ? packageamountpercntcalculate
                : packageamountpercnt === 0 && finalNetbillcount === 0;

    setIpPayoutTotal(ipPayouttot);

    let GrossPercentage = grossbillperc
      ? grossbilltot
      : netbillpercntage
        ? grossbilltot
        : surgeonfee * count
          ? surgeonfee * count
          : packageamount * count;

// console.log(ipdiscount, "rowop.ipdiscountType");
    if (ipdiscount === "Not applicable" || ipdiscount === "No") {
      setGrossIp(GrossPercentage);
      setnetIp("");
    } else {
      setGrossIp("");
      setnetIp(GrossPercentage);
    }
  };



  const opCalculation = (rowop) => {
    //deduction calculation
// console.log(rowop, "LLLL");
    let deduct = parseInt(rowop.opadhoc);
    if (isNaN(deduct)) {
      deduct = 0;
    } else {
    }

    setAdhoc(deduct);

    let unit = parseInt(rowop.opperunitcharge);
    let count = parseInt(rowop.opcount);
    //unit value checking
    if (isNaN(unit)) {
      unit = 0;
    } else {
    }
    //count value checking
    if (isNaN(count)) {
      count = 0;
    } else {
    }
    let gs = unit * count;
    let discountval = parseInt(rowop.opdiscountvalue) * count;
    //discount value checking
    if (isNaN(discountval) || rowop.opdiscountvalue.length === 0) {
      discountval = 0;
    } else {
    }

    let netVal = gs - discountval;
    let deduction = parseInt(rowop.opadhoc) * count;
    // let totalRevenue = parseInt(rowop.totalRevenue) * count;
    let totalRevenue = parseInt(rowop.totalRevenue);

    if (isNaN(deduction)) {
      deduction = 0;
    } else {
    }

    if (isNaN(totalRevenue)) {
      totalRevenue = 0;
    } else {
    }
    //net value checking
    deduction = totalRevenue ? deduct : deduction
    discountval = totalRevenue ? parseInt(rowop.opdiscountvalue) : discountval
    gs = totalRevenue ? totalRevenue : gs
    netVal = totalRevenue ? totalRevenue - deduction : netVal

    setTotaldeduction(deduction);
    if (isNaN(deduction)) {
      deduction = 0;
    } else {
    }
    let opRevenueval = netVal - deduction;
    let percnt = parseInt(rowop.fixedpercentage);
    let fixedamt = parseInt(rowop.fixedamount);

// console.log(percnt, fixedamt, "UUUUU");
    //payout value checking

    if (isNaN(percnt)) {
      percnt = 0;
    } else {
    }
    if (isNaN(fixedamt)) {
      fixedamt = 0;
    } else {
    }

    let fixedDeduction = totalRevenue
      ? totalRevenue * (percnt / 100)
      : fixedamt
        ? fixedamt * (count > 0 ? count : 1)
        : opRevenueval * (percnt / 100);
    fixedDeduction = fixedDeduction.toFixed(2);
    //set value
    setGross(gs);
    // setOpNetAmount(gs - discountval);
    setOpNetAmount(gs - deduction);

    if (rowop.totalRevenue) {
      // rowop.totalRevenue=opRevenueval
      setopRevenue(opRevenueval);
    } else {
      // rowop.totalRevenue=(netVal - deduction)
      setopRevenue(netVal - deduction);
    }
// console.log(opRevenueval, 'opRevenuevalopRevenueval')

    setOpNetPayout(fixedDeduction);
  };

  const TableTitle = ({ text }) => (
    <Typography
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: "black",
        textDecoration: "underline",
        fontWeight: "bold",
        fontSize: "15px"
      }}
    >
      {text}
    </Typography>
  );

  const handleInputChangeIp = (rowop, event) => {
    const { name, value } = event.target;
// console.log(name, "ip-name");
    setDoctorIpDetail(row);
    if (name == "ipTotalAmount") {
      doctorIpDetail.ipTotalAmount = value;
    }
    if (name == "count") {
      doctorIpDetail.count = value;
    }
    if (name == "ipsurgeonfeeperc") {
      doctorIpDetail.ipsurgeonfeeperc = value;
    }
    if (name == "ippackageamountperc") {
      doctorIpDetail.ippackageamountperc = value;
    }

    if (name == "ipgrossbillpercentage") {
      doctorIpDetail.ipgrossbillpercentage = value;
    }
    if (name == "ipfixedamount") {
      doctorIpDetail.ipfixedamount = value;
    }

    if (name == "ipdeductaionvalue") {
      doctorIpDetail.ipdeductaionvalue = value;
    }

    setIpservice({ ...iprow, [name]: value });

  }

  function getDocdetail(id) {
    axios
      .get(APIURL() + "onboard/previewOne/" + id)
      .then((res) => {
        setDoctorDetail(res.data);
        setDoctorIpDetail(res.data.doctorIPParameter);
        setDoctorOpDetail(res.data.doctorOPParameter);
        setDoctorOpDetailEdit(res.data.doctorOPParameter);
        setDoctorOpCommercial(res.data.doctorOpCommercial);
        setDoctorIpCommercial(res.data.doctorIpCommercial);
        setDoctorMgDetails(res.data.doctorMgDetails);
        setDoctorFixedDetails(res.data.doctorFixedDetails);
        setDoctorOpCommercial_archived(res.data.doctorOpCommercial_archived)
        setDoctorIpCommercial_archived(res.data.doctorIpCommercial_archived)
        setDoctorIPParameter_archived(res.data.doctorIPParameter_archived)
        setDoctorOPParameter_archived(res.data.doctorOPParameter_archived)
        setDoctorMgDetails_archived(res.data.doctorMgDetails_archived)
        setDoctorFixedDetails_archived(res.data.doctorFixedDetails_archived)
        let TotalRev = 0
        let TotalPayOutOP = 0
        let TotalPayOutIP = 0
        let TotalMGorFixedValue = 0
        let TotalPayout = 0
        if (res.data.doctorIPParameter && res.data.doctorIPParameter.length > 0) {
          res.data.doctorIPParameter.map((elem) => {
            TotalRev = TotalRev + parseFloat(elem.ipTotalAmount)
            TotalPayOutIP = TotalPayOutIP + parseFloat(elem.payout)
          })
        }
        if (res.data.doctorOPParameter && res.data.doctorOPParameter.length > 0) {
          res.data.doctorOPParameter.map((elem) => {
            TotalRev = TotalRev + parseFloat(elem.oprevenue)
            TotalPayOutOP = TotalPayOutOP + parseFloat(elem.oppayout)
          })
        }
        if (res.data.doctorMgDetails && res.data.doctorMgDetails.length > 0) {
          res.data.doctorMgDetails.map((elem) => {
            TotalMGorFixedValue = TotalMGorFixedValue + parseFloat(elem.minguranteepermonth)
          })
          if (TotalMGorFixedValue > (TotalPayOutIP + TotalPayOutOP)) {
            TotalPayout = TotalMGorFixedValue
          } else {
            TotalPayout = (TotalPayOutIP + TotalPayOutOP)
          }
        }
        if (res.data.doctorFixedDetails && res.data.doctorFixedDetails.length > 0) {
          res.data.doctorFixedDetails.map((elem) => {
            TotalMGorFixedValue = TotalMGorFixedValue + parseFloat(((elem.professionalfeepermonth).toString()).replaceAll(',', '')) + parseFloat(((elem.additionalfeevalue).toString()).replaceAll(',', ''))
          })
          TotalPayout = TotalMGorFixedValue + (TotalPayOutIP + TotalPayOutOP)
        }
        setTotalPayOutValue([{
          "TotalRevenue": TotalRev,
          "TotalIP": TotalPayOutIP,
          "TotalOP": TotalPayOutOP,
          "TotalMGorFixedValue": TotalMGorFixedValue,
          "Payout": TotalPayout
        }])
      })
      .catch((error) => { });
  }

  const handlerClickOpenOp = (rowop) => {
    if (rowop.doctorId) {
      setDocid(rowop.doctorId);
      getDocdetail(rowop.doctorId);
    }

    setOpenOp(true);
  };

  const handlerCloseOp = () => {
    setShowOldCommercial(false);
    setOpenOp(false);
  };


  const handleApprove = (row) => {
    const obj = {
      doctorId: row.doctorId,
      name: row.name,
      currentAction: agreementState.ch_approved.state,
      currentActionBy: users[0].id,
      nextAction: "COMPLETE",
      status: 1,
    };
    axios
      .post(APIURL() + "onboard/agreementAction/", obj)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handlerClickOpen = (row) => {
    setOrow(row);
    setOpenr(true);
  };
  const Remark = (e) => {
    setRemark(e.target.value);
  };

  const handlerClose = () => {
    setOpenr(false);
  };

  const handleSubmitReject = () => {
    const obj = {
      doctorId: orow.doctorId,
      name: orow.name,
      currentAction: agreementState.ch_approved.state,
      currentActionBy: users[0].id,
      nextAction: agreementState.hr_accepted.state,
      remark: remark,
      status: 1,
    };

    axios
      .post(APIURL() + "onboard/agreementAction/", obj)
      .then((res) => {
        toast.success("Rejection successfully submitted");
      })
      .catch((err) => {
        toast.error(err.message);
      });
    window.location.reload();
  };

  const closeAgreement = () => {
    setViewAgreement(false);
  };

  const handlerViewOldCommercial = () => {
    let val = !showOldCommercial
    setShowOldCommercial(val)
  };

  const viewAgreementDoc = (row) => {
    setRow(row);
    axios
      .get(APIURL() + "onboard/agreementView/" + row.doctorId)
      .then((res) => {
        if (res.data != null) {
          setCategory(res.data.data.vacancyData[0].category);
          setDocData(res.data.data);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
    setViewAgreement(true);
  };

  return (
    <>
      <Sidebar />

      <div className="main-content side-content pt-0 mg-b-30">
        <div className="container-fluid">
          <div className="inner-body">
            <Navbar />

            <form>
              <br />
              <Card style={formStyle}>
                <Card.Body style={bodyStyle}>
                  <MaterialTable
                    columns={[
                      { title: "Doctor Name", field: "name" },

                      // {
                      //   title: "Current Action",
                      //   field: "Action",
                      //   filtering: false,
                      //   render: (row) => (
                      //     <div style={{ width: "140px" }}>
                      //       <>{agreementState.hr_accepted.message}</>
                      //     </div>
                      //   ),
                      // },
                      // {
                      //   title: "Current Action",
                      //   field: "currentAction",
                      //   filtering: false,
                      //   render: (row) => (
                      //     <div style={{ width: "140px" }}>
                      //       {row.currentAction === agreementState.init.state ? (
                      //         <span style={{ color: "red" }}>
                      //           {" "}
                      //           {agreementState.init.message}
                      //         </span>
                      //       ) : row.currentAction ===
                      //         agreementState.rd_revision.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.rd_revision.message}
                      //         </span>
                      //       ) : row.currentAction ===
                      //         agreementState.hr_revision.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.hr_revision.message}
                      //         </span>
                      //       ) : row.currentAction ===
                      //         agreementState.dr_accepted.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.dr_accepted.message}
                      //         </span>
                      //       ) : row.currentAction ===
                      //         agreementState.hr_accepted.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.hr_accepted.message}
                      //         </span>
                      //       ) : row.currentAction ===
                      //         agreementState.ch_approved.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.ch_approved.message}
                      //         </span>
                      //       ) : row.currentAction ===
                      //         agreementState.ceo_approved.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.ceo_approved.message}
                      //         </span>
                      //       ) : row.currentAction ===
                      //         agreementState.rd_approved.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.rd_approved.message}
                      //         </span>
                      //       ) : row.currentAction ===
                      //         agreementState.dr_decline.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.dr_decline.message}
                      //         </span>
                      //       ) : row.currentAction ===
                      //         agreementState.ch_decline.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.ch_decline.message}
                      //         </span>
                      //       ) : row.currentAction ===
                      //         agreementState.rd_decline.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.rd_decline.message}
                      //         </span>
                      //       ) : row.currentAction ===
                      //         agreementState.hr_decline.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.hr_decline.message}
                      //         </span>
                      //       ) : row.currentAction ===
                      //         agreementState.completed ? (
                      //         <span style={{ color: "blue" }}>"COMPLETED"</span>
                      //       ) : (
                      //         ""
                      //       )}
                      //     </div>
                      //   ),
                      // },
                      // {
                      //   title: "Next Action",
                      //   field: "nextAction",
                      //   filtering: false,
                      //   render: (row) => (
                      //     <div style={{ width: "140px" }}>
                      //       {row.nextAction === agreementState.init.state ? (
                      //         <span style={{ color: "red" }}>
                      //           {" "}
                      //           {agreementState.init.message}
                      //         </span>
                      //       ) : row.nextAction ===
                      //         agreementState.rd_revision.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.rd_revision.message}
                      //         </span>
                      //       ) : row.nextAction ===
                      //         agreementState.hr_revision.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.hr_revision.message}
                      //         </span>
                      //       ) : row.nextAction ===
                      //         agreementState.dr_accepted.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.dr_accepted.message}
                      //         </span>
                      //       ) : row.nextAction ===
                      //         agreementState.hr_accepted.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.hr_accepted.message}
                      //         </span>
                      //       ) : row.nextAction ===
                      //         agreementState.ch_approved.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.ch_approved.message}
                      //         </span>
                      //       ) : row.nextAction ===
                      //         agreementState.ceo_approved.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.ceo_approved.message}
                      //         </span>
                      //       ) : row.nextAction ===
                      //         agreementState.rd_approved.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.rd_approved.message}
                      //         </span>
                      //       ) : row.nextAction ===
                      //         agreementState.dr_decline.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.dr_decline.message}
                      //         </span>
                      //       ) : row.nextAction ===
                      //         agreementState.ch_decline.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.ch_decline.message}
                      //         </span>
                      //       ) : row.nextAction ===
                      //         agreementState.rd_decline.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.rd_decline.message}
                      //         </span>
                      //       ) : row.nextAction ===
                      //         agreementState.hr_decline.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.hr_decline.message}
                      //         </span>
                      //       ) : row.nextAction === agreementState.completed ? (
                      //         <span style={{ color: "blue" }}>"COMPLETED"</span>
                      //       ) : (
                      //         ""
                      //       )}
                      //     </div>
                      //   ),
                      // },

                      // {
                      //   title: "View Agreement",
                      //   field: "Action",
                      //   filtering: false,
                      //   render: (rows) => (
                      //     <div style={{ width: "140px" }}>
                      //       {rows && rows.data1 && rows.data1.agreementcurrentAction === "DR_ACCEPTED" ? (
                      //         <InsertDriveFileIcon
                      //           style={{ color: "#810e36", cursor: "pointer" }}
                      //           aria-label="close"
                      //           onClick={() => {
                      //             viewAgreementDoc(row);
                      //           }}
                      //         >
                      //           <VisibilityIcon />
                      //         </InsertDriveFileIcon>
                      //       ) : (
                      //         <InsertDriveFileIcon
                      //           style={{ color: "gray" }}
                      //           aria-label="close"
                      //         >
                      //           <VisibilityIcon />
                      //         </InsertDriveFileIcon>
                      //       )}
                      //     </div>
                      //   ),
                      // },
                      {
                        title: "View Agreement",
                        field: "Action",
                        filtering: false,
                        render: (row) =>
                          row.agreementcurrentAction == "DR_ACCEPTED" ? (
                            <div style={{ width: "140px" }}>
                              <AssignmentIcon
                                style={{ color: "#810e36" }}
                                aria-label="close"
                                onClick={() => {
                                  viewAgreementDoc(row);
                                }}
                              >
                                <VisibilityIcon />
                              </AssignmentIcon>
                            </div>
                          ) : (
                            <div style={{ width: "140px" }}>
                              <AssignmentIcon
                                style={{ color: "gray" }}
                                aria-label="close"
                              >
                                <VisibilityIcon />
                              </AssignmentIcon>
                            </div>
                          ),
                      },
                      {
                        title: "Estimation Details",
                        field: "Action",
                        filtering: false,
                        render: (rowop) => (
                          <div style={{ width: "140px" }}>
                            <i
                              className="fas fa-address-card"
                              style={{ fontSize: "20px", color: "#810e36" }}
                              onClick={() => handlerClickOpenOp(rowop)}
                            />
                          </div>
                        ),
                      },
                      // {
                      //   title: "Approve/Reject",
                      //   field: "Action",
                      //   filtering: false,
                      //   render: (row) =>
                      //     row.nextAction ===
                      //     agreementState.ch_approved.state ? (
                      //       <div style={{ whiteSpace: "nowrap" }}>
                      //         <Button
                      //           size="small"
                      //           color="primary"
                      //           variant="contained"
                      //           onClick={() => {
                      //             handleApprove(row);
                      //           }}
                      //         >
                      //           Approve
                      //         </Button>

                      //         <Button
                      //           style={{ marginLeft: "5px" }}
                      //           size="small"
                      //           color="secondary"
                      //           variant="contained"
                      //           onClick={() => {
                      //             handlerClickOpen(row);
                      //           }}
                      //         >
                      //           Rectification Required
                      //         </Button>
                      //       </div>
                      //     ) : (
                      //       <Button
                      //         style={{ marginLeft: "5px" }}
                      //         size="small"
                      //         color="secondary"
                      //         variant="contained"
                      //         disabled
                      //       >
                      //         No Action Required
                      //       </Button>
                      //     ),
                      // },
                    ]}
                    data={rows.data1}
                    // data={
                    //   rows && rows.filter((z) => z.nextAction === "CH_APPROVED")
                    // }
                    options={{
                      // filtering: true,
                      sorting: true,
                      exportButton: true,
                      pageSize: 5,
                      pageSizeOptions: [5, 10, 50, 100, 200, 500],
                      search: true,
                    }}
                    title="Agreement Details"
                  />
                </Card.Body>
              </Card>
            </form>
          </div>
        </div>
      </div>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerClose}
        aria-labelledby="customized-dialog-title"
        open={openr}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClose}
          style={{ textAlign: "center" }}
        >
          Review
        </DialogTitle>
        <DialogContent dividers>
          <label>
            <b>Remark</b>
          </label>
          <textarea
            type="text"
            onChange={Remark}
            style={{ color: "black", border: "1px solid black" }}
            className="form-control"
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleSubmitReject}
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
          <Button
            autoFocus
            onClick={handlerClose}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen
        open={viewAgreement}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="end"
              style={{ marginLeft: "99%" }}
              color="inherit"
              onClick={closeAgreement}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <List>
          {categoty === "Full Time Consultant" || categoty === 'Retainer' ? (
            <FixedfullTime
              history={row}
              recData={docData}
              catagory={categoty}
            />
          ) : categoty === "Minimum Guarentee(MG)" ? (
            <FixedMG history={row} recData={docData} catagory={categoty} />
          ) : categoty === "Visiting Consultant" ? (
            <VisitingCon history={row} recData={docData} catagory={categoty} />
          ) : categoty === "Visiting Consultant Chennai" ? (
            <VcChennai history={row} recData={docData} catagory={categoty} />
          ) : (
            "hi"
          )}
        </List>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth="xl"
        onClose={handlerCloseOp}
        aria-labelledby="customized-dialog-title"
        open={openOp}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerCloseOp}
          style={{ textAlign: "center" }}
        >
          <h5
            style={{
              margin: ".4rem",
              textAlign: "center",
              padding: "7px",
              backgroundColor: "#810e36",
              color: "white",
            }}
          >
            <b>Commercials Summary</b>
          </h5>
        </DialogTitle>
        <DialogContent dividers>
          {showOldCommercial ?
            <>
              <Paper className={tables.root} style={{ border: "1px solid" }}>
                <h5 className="p-2" style={{ fontWeight: "bold", borderBottom: "1px solid" }}>Commercials Summary Old</h5>

                <MaterialTable
                  columns={[
                    { title: "Center Name", field: "center", render: (rowop) => (<div>{rowop.center ? rowop.center : "---"}</div>) },
                    { title: "Item Group", field: "itemgroup", render: (rowop) => (<div>{rowop.itemgroup ? rowop.itemgroup : "---"}</div>) },
                    { title: "Item Name", field: "itemName", render: (rowop) => (<div>{rowop.itemName ? rowop.itemName : "---"}</div>) },
                    { title: "Commercial Term", field: "commercialterm", render: (rowop) => (<div>{rowop.commercialterm ? rowop.commercialterm : "---"}</div>) },
                  ]}
                  data={doctorOpCommercial_archived}
                  options={{
                    // filtering: true,
                    sorting: true,
                    paging: false,
                    search: false,
                    rowopStyle: {
                      fontSize: 12,
                    },
                    headerStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px",
                      fontSize: 12,
                    },
                    cellStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px"
                    },
                  }}
                  title={<TableTitle text="Out-Patient Commercial" />}
                />
                <MaterialTable
                  columns={[
                    { title: "Center Name", field: "Center", render: (rowop) => (<div>{rowop.Center ? rowop.Center : "---"}</div>) },
                    { title: "Item Group", field: "itemGroup", render: (rowop) => (<div>{rowop.itemGroup ? rowop.itemGroup : "---"}</div>) },
                    { title: "Item Name", field: "itemName", render: (rowop) => (<div>{rowop.itemName ? rowop.itemName : "---"}</div>) },
                    { title: "Room Category", field: "roomCategory", render: (rowop) => (<div>{rowop.roomCategory ? rowop.roomCategory : "---"}</div>) },
                    { title: "MOP", field: "ipmodeofpayment", render: (rowop) => (<div>{rowop.ipmodeofpayment ? rowop.ipmodeofpayment : "---"}</div>) },
                    { title: "Commercial Term", field: "commercialTerm", render: (rowop) => (<div>{rowop.commercialTerm ? rowop.commercialTerm : "---"}</div>) },
                  ]}
                  // data={doctorDetail.doctorIPParameter}
                  data={doctorIPParameter_archived}
                  options={{
                    // filtering: true,
                    sorting: true,
                    paging: false,
                    search: false,
                    rowopStyle: {
                      fontSize: 12,
                    },
                    headerStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px",
                      fontSize: 12,
                    },
                    cellStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px"
                    },
                  }}
                  title={<TableTitle text="In-Patient Commercials" />}
                />
                {doctorMgDetails_archived && doctorMgDetails_archived.length > 0 ?
                  doctorMgDetails_archived.map((item) =>
                    <MaterialTable
                      columns={[
                        { title: "Minimum Guarantee Payment: The Company agrees to pay the Consultant an amount of Rs. " + numberWithCommas(item.minguranteepermonth) + " (Rs. " + numberToEnglish((item.minguranteepermonth).replaceAll(',', '')) + ") per month (“Minimum Guarantee Amount”) or the “Calculated Earnings” whichever is higher subject to statutory deductions. ", field: "mgCommercial" },
                      ]}
                      // data={doctorDetail.doctorIPParameter}
                      data={
                        [
                          {
                            mgCommercial: 'The Minimum Guarantee Amount will be paid for ' + item.mgperiod + ' months'
                          },
                          {
                            mgCommercial: 'The earnings shall be calculated on ' + item.settlementperiod + ' basis and difference amount (Calculated Earnings minus Minimum Guarantee), if any, shall be paid at the end of ' + item.settlementpaymentperiod + '.'
                          },
                        ]
                      }
                      options={{
                        // filtering: true,
                        sorting: true,
                        paging: false,
                        search: false,
                        headerStyle: {
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px 10px",
                          fontSize: 12,
                          fontWeight: 400
                        },
                        rowopStyle: {
                          fontSize: 12,
                        },
                        cellStyle: {
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px 10px"
                        },
                      }}
                      title={<TableTitle text={"Minimum Guarentee (MG) Commercial for " + item.Center} />}
                    />
                  )
                  : ''}
                {doctorFixedDetails_archived && doctorFixedDetails_archived.length > 0 ?
                  doctorFixedDetails_archived.map((item) =>
                    <MaterialTable
                      columns={[
                        { title: "Monthly Fixed Fee: The Company will pay an all-inclusive amount of Rs. " + numberWithCommas((item.professionalfeepermonth).replaceAll(',', '')) + " (Rs. " + numberToEnglish((item.professionalfeepermonth).replaceAll(',', '')) + ") per month. ", field: "fixedCommercial", width: "100%" },
                      ]}
                      // data={doctorDetail.doctorIPParameter}
                      data={
                        [
                          {
                            fixedCommercial: item.additionalfeevalue != '0' ? "Additional Fee for " + item.additionalfeename + ": The Company will pay an additional amount of Rs. " + numberWithCommas(((item.additionalfeevalue).toString()).replaceAll(',', '')) + " (Rs. " + numberToEnglish(((item.additionalfeevalue).toString()).replaceAll(',', '')) + ") per month. " : ""
                          },
                        ]
                      }
                      options={{
                        // filtering: true,
                        sorting: false,
                        paging: false,
                        search: false,
                        headerStyle: {
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px 10px",
                          fontSize: 12,
                          fontWeight: 400
                        },
                        rowopStyle: {
                          fontSize: 12,
                        },
                        cellStyle: {
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px 10px"
                        },
                      }}
                      title={<TableTitle text={"Full Time Consultant Commercial for " + item.Center} />}
                    />
                  )
                  : ''}
              </Paper>
              <br />
              <br />
            </>
            :
            <>
              <Paper className={tables.root} style={{ border: "1px solid" }}>
                <h5 className="p-2" style={{ fontWeight: "bold", borderBottom: "1px solid" }}>Commercials Summary</h5>

                <MaterialTable
                  columns={[
                    { title: "Center Name", field: "center", render: (rowop) => (<div>{rowop.center ? rowop.center : "---"}</div>) },
                    { title: "Item Group", field: "itemgroup", render: (rowop) => (<div>{rowop.itemgroup ? rowop.itemgroup : "---"}</div>) },
                    { title: "Item Name", field: "itemName", render: (rowop) => (<div>{rowop.itemName ? rowop.itemName : "---"}</div>) },
                    { title: "Commercial Term", field: "commercialterm", render: (rowop) => (<div>{rowop.commercialterm ? rowop.commercialterm : "---"}</div>) },
                  ]}
                  data={doctorOpCommercial}
                  options={{
                    // filtering: true,
                    sorting: true,
                    paging: false,
                    search: false,
                    rowopStyle: {
                      fontSize: 12,
                    },
                    headerStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px",
                      fontSize: 12,
                    },
                    cellStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px"
                    },
                  }}
                  title={<TableTitle text="Out-Patient Commercial" />}
                />
                <MaterialTable
                  columns={[
                    { title: "Center Name", field: "Center", render: (rowop) => (<div>{rowop.Center ? rowop.Center : "---"}</div>) },
                    { title: "Item Group", field: "itemGroup", render: (rowop) => (<div>{rowop.itemGroup ? rowop.itemGroup : "---"}</div>) },
                    { title: "Item Name", field: "itemName", render: (rowop) => (<div>{rowop.itemName ? rowop.itemName : "---"}</div>) },
                    { title: "Room Category", field: "roomCategory", render: (rowop) => (<div>{rowop.roomCategory ? rowop.roomCategory : "---"}</div>) },
                    { title: "MOP", field: "ipmodeofpayment", render: (rowop) => (<div>{rowop.ipmodeofpayment ? rowop.ipmodeofpayment : "---"}</div>) },
                    { title: "Commercial Term", field: "commercialTerm", render: (rowop) => (<div>{rowop.commercialTerm ? rowop.commercialTerm : "---"}</div>) },
                  ]}
                  // data={doctorDetail.doctorIPParameter}
                  data={doctorIpDetail}
                  options={{
                    // filtering: true,
                    sorting: true,
                    paging: false,
                    search: false,
                    rowopStyle: {
                      fontSize: 12,
                    },
                    headerStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px",
                      fontSize: 12,
                    },
                    cellStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px"
                    },
                  }}
                  title={<TableTitle text="In-Patient Commercials" />}
                />
                {doctorMgDetails && doctorMgDetails.length > 0 ?
                  doctorMgDetails.map((item) =>
                    <MaterialTable
                      columns={[
                        { title: "Minimum Guarantee Payment: The Company agrees to pay the Consultant an amount of Rs. " + numberWithCommas(item.minguranteepermonth) + " (Rs. " + numberToEnglish((item.minguranteepermonth).replaceAll(',', '')) + ") per month (“Minimum Guarantee Amount”) or the “Calculated Earnings” whichever is higher subject to statutory deductions. ", field: "mgCommercial" },
                      ]}
                      // data={doctorDetail.doctorIPParameter}
                      data={
                        [
                          {
                            mgCommercial: 'The Minimum Guarantee Amount will be paid for ' + item.mgperiod + ' months'
                          },
                          {
                            mgCommercial: 'The earnings shall be calculated on ' + item.settlementperiod + ' basis and difference amount (Calculated Earnings minus Minimum Guarantee), if any, shall be paid at the end of ' + item.settlementpaymentperiod + '.'
                          },
                        ]
                      }
                      options={{
                        // filtering: true,
                        sorting: true,
                        paging: false,
                        search: false,
                        headerStyle: {
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px 10px",
                          fontSize: 12,
                          fontWeight: 400
                        },
                        rowopStyle: {
                          fontSize: 12,
                        },
                        cellStyle: {
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px 10px"
                        },
                      }}
                      title={<TableTitle text={"Minimum Guarentee (MG) Commercial for " + item.Center} />}
                    />
                  )
                  : ''}
                {doctorFixedDetails && doctorFixedDetails.length > 0 ?
                  doctorFixedDetails.map((item) =>
                    <MaterialTable
                      columns={[
                        { title: "Monthly Fixed Fee: The Company will pay an all-inclusive amount of Rs. " + numberWithCommas((item.professionalfeepermonth).replaceAll(',', '')) + " (Rs. " + numberToEnglish((item.professionalfeepermonth).replaceAll(',', '')) + ") per month. ", field: "fixedCommercial", width: "100%" },
                      ]}
                      // data={doctorDetail.doctorIPParameter}
                      data={
                        [
                          {
                            fixedCommercial: item.additionalfeevalue != '0' ? "Additional Fee for " + item.additionalfeename + ": The Company will pay an additional amount of Rs. " + numberWithCommas(((item.additionalfeevalue).toString()).replaceAll(',', '')) + " (Rs. " + numberToEnglish(((item.additionalfeevalue).toString()).replaceAll(',', '')) + ") per month. " : ""
                          },
                        ]
                      }
                      options={{
                        // filtering: true,
                        sorting: false,
                        paging: false,
                        search: false,
                        headerStyle: {
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px 10px",
                          fontSize: 12,
                          fontWeight: 400
                        },
                        rowopStyle: {
                          fontSize: 12,
                        },
                        cellStyle: {
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px 10px"
                        },
                      }}
                      title={<TableTitle text={"Full Time Consultant Commercial for " + item.Center} />}
                    />
                  )
                  : ''}
              </Paper>
              <br />
              <br />
            </>
          }
        </DialogContent>
        <DialogActions>
          {/* <Button
            autoFocus
            onClick={updateRecord}
            color="primary"
            variant="contained"
            size="small"
          >
            Update Data
          </Button> */}
          {doctorOpCommercial_archived && doctorOpCommercial_archived.length > 0 || doctorIpCommercial_archived && doctorIpCommercial_archived.length > 0 || doctorIPParameter_archived && doctorIPParameter_archived.length > 0 || doctorOPParameter_archived && doctorOPParameter_archived.length > 0 || doctorMgDetails_archived && doctorMgDetails_archived.length > 0 || doctorFixedDetails_archived && doctorFixedDetails_archived.length > 0 ?
            <Button
              // autoFocus
              onClick={handlerViewOldCommercial}
              color="primary"
              variant="contained"
              size="small"
            >
              {showOldCommercial ? "Current Commercials" : "Old Commercials"}
            </Button>
            : null}
          <Button
            autoFocus
            onClick={handlerCloseOp}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
