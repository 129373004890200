import React, { useState, useEffect } from "react";
import { Modal, Button, Container, Row, Col, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import doctorLogo from "../images/doctor.png";
import RequestTable from "../Components/doctorRequestTable";
import RenewalRequest from "../Components/RenewalRequest";
import renewalLogo from "../images/migrate.png";
import positionLogo from "../images/shop.png";
import axios from "axios";
import { toast } from "react-toastify";
// import { login } from "../../../actions/authActions";
import { login } from "../actions/authActions";
import { APIURL } from "../CommomMethods/Fetch";


function StatsCard({ history, reqTableData, clickReturn }) {
  const [newVis, setNewVis] = useState("hiddenDiv");
  const [renewVis, setRenewVis] = useState("hiddenDiv");
  const [ongVis, setOngVis] = useState("hiddenDiv");
  const [compVis, setCompVis] = useState("hiddenDiv");
  const [dashBoardCount, setDashBoardCount] = useState([]);
  const [onboardeddocCount, setOnboardeddocCount] = useState([]);
  const [onboardingdocCount, setOnboardingdocCount] = useState([]);
  const [upcomingrenewCount, setUpcomingrenewCount] = useState([]);

  useEffect(() => {
    // async function getRecord() {
    //   // await axios.get(APIURL()+"doctor/").then((res) => {
    //     axios.get(APIURL() + "vacancies/findAllvacwithname/").then((res) => {
    // // console.log(res.data,"res.data");
    //     setDashBoardCount(res.data);
    //   });


    // }
    axios.get(APIURL() + "onboard/onboardedDocCountStat")
      .then((res) => {
        setDashBoardCount(res.data.data);
        setOnboardeddocCount(res.data.onboardeddoc);
        setOnboardingdocCount(res.data.onboardingdoc);
        setUpcomingrenewCount(res.data.upcomingrenew);
      })
      .catch((err) => {
        toast.error(err.message);
      });
    // getRecord();
  }, []);
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;
  const cardStyle = {
    background: "transparent",
    border: "0px",
  };
  const infoStyle = {
    // border: "2px solid #858585",
  };
  const subTitle = {
    fontSize: "13px",
  };
  function tabChange(stat) {
    if (stat == "new") {
      if (newVis == "hiddenDiv") {
        setNewVis("visibleDiv");
        setRenewVis("hiddenDiv");
        setOngVis("hiddenDiv");
        setCompVis("hiddenDiv");
      } else {
        setNewVis("hiddenDiv");
      }
    }
  }

  function tab1Change(stat) {
    if (stat == "rnew") {
      if (renewVis == "hiddenDiv") {
        setRenewVis("visibleDiv");
        setNewVis("hiddenDiv");
        setOngVis("hiddenDiv");
        setCompVis("hiddenDiv");
      } else {
        setRenewVis("hiddenDiv");
      }
    }
  }
  
  // console.log("doccount", onboardeddocCount[0].count, onboardingdocCount[0].count, upcomingrenewCount[0].count)

  return (
    <>
      <Card style={infoStyle}>
        <Card.Header
          style={{
            paddingTop: "5px",
            paddingLeft: "5px",
            paddingBottom: "5px",
            borderBottom: "1px solid #858585",
          }}
        >
          <h3 style={{textAlign: 'center'}}>Doctor Statistics</h3>
        </Card.Header>
        <Card.Body style={{ height: '108px' }}>
          <Row style={{display: "flex", justifyContent: "center"}}>
            <Col md={7} xs={7} className="pr-0">
              <Card style={cardStyle}>
                <Card.Body style={{ paddingTop: "10px" }}>
                  <Row>
                    <Col
                      md={3}
                      xs={3}
                      className="pl-2 pr-3"
                      style={{ textAlign: "center", paddingRight: "0px" }}
                    >
                      <div style={{}}>
                        <Link to={{
                          pathname: "/doctorList",
                          search: "?sort=new doctors"
                        }} >
                          <img
                            // onClick={() => tabChange("new")}
                            reqType="new"
                            src={doctorLogo}
                            style={{ cursor: "pointer" }}
                            className="img-responsive"
                            alt=""
                          />
                        </Link>
                      </div>
                    </Col>
                    <Col
                      md={4}
                      xs={4}
                      style={{ textAlign: "center", paddingLeft: "0px" }}
                    >
                      <div style={{marginTop: "5px"}}>
                        <h3>{onboardeddocCount && onboardeddocCount.length}</h3>
                        <h6 style={subTitle}>Onboarded</h6>
                      </div>
                    </Col>
                    <Col
                      md={4}
                      xs={4}
                      className="pr-0"
                      style={{ textAlign: "center", paddingLeft: "0px" }}
                    >
                      <div style={{marginTop: "5px"}}>
                        <h3>{onboardingdocCount && onboardingdocCount.length}</h3>
                        <h6 style={subTitle}>Onboarding In process</h6>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            {/* <Col md={3} xs={3}>
              <Card style={cardStyle}>
                <Card.Body style={{ paddingTop: "10px" }}>
                  <Row>
                    <Col
                      md={5}
                      xs={5}
                      style={{ textAlign: "center", paddingRight: "0px" }}
                    >
                      <div style={{}}>
                        <Link to={{
                          pathname: "/doctorList",
                          search: "?sort=new doctors"
                        }} >
                          <img
                            // onClick={() => tabChange("new")}
                            reqType="new"
                            src={doctorLogo}
                            style={{ cursor: "pointer" }}
                            className="img-responsive"
                            alt=""
                          />
                        </Link>
                      </div>
                    </Col>
                    <Col
                      md={6}
                      xs={6}
                      style={{ textAlign: "center", paddingLeft: "0px" }}
                    >
                      <div>
                        <h3>{onboardingdocCount && onboardingdocCount.length}</h3>
                        <h6 style={subTitle}>Onboarding In Process</h6>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col> */}
            <Col md={5} xs={5} className="pl-0" >
              <Card style={cardStyle}>
                <Card.Body style={{ paddingTop: "10px" }}>
                  <Row>
                    <Col md={5} xs={5} style={{paddingRight: '1px' }}>
                      <div>
                        <Link to={{
                          pathname: "/doctorList",
                          search: "?sort=upcoming renewals"
                        }} >
                          <img
                            // onClick={() => { tab1Change("rnew"); clickReturn(dashBoardCount.data1) }}
                            reqType="new"
                            style={{ cursor: "pointer", height: '64px', width: '64px' }}
                            className="img-responsive"
                            alt=""
                            src={renewalLogo}
                          />
                        </Link>
                      </div>
                    </Col>
                    <Col
                      md={6}
                      xs={6}
                      style={{ textAlign: "center", paddingLeft: "0px", paddingRight: "2px"}}
                    >
                      <div style={{marginTop: "5px"}}>
                        <h3>{upcomingrenewCount && upcomingrenewCount.length}</h3>
                        <h6 style={subTitle}>Upcoming Renewals</h6>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            {/* <Col md={3} xs={6}>
            <Card style={cardStyle}>
              <Card.Body style={{ paddingTop: "10px" }}>
                <Row>
                  <Col
                    md={6}
                    xs={6}
                    style={{ textAlign: "center", paddingRight: "0px" }}
                  >
                    <div style={{}}>
                      <img
                        src={positionLogo}
                        className="img-responsive"
                        alt=""
                      />
                    </div>
                  </Col>
                  <Col
                    md={6}
                    xs={6}
                    style={{ textAlign: "center", paddingLeft: "0px" }}
                  >
                    <div>
                      <h3>6</h3>
                      <h6>Open Positions</h6>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card> 
          </Col>*/}
            {/* <Col md={3} xs={6}>
            <Card style={cardStyle}>
              <Card.Body style={{ paddingTop: "10px" }}>
                <Row>
                  <Col
                    md={6}
                    xs={6}
                    style={{ textAlign: "center", paddingRight: "0px" }}
                  >
                    <div style={{}}>
                      <img src={doctorLogo} className="img-responsive" alt="" />
                    </div>
                  </Col>
                  <Col
                    md={6}
                    xs={6}
                    style={{ textAlign: "center", paddingLeft: "0px" }}
                  >
                    <div>
                      <h3>87%</h3>
                      <h6 style={subTitle}>Requests Finished</h6>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col> */}
          </Row>
        </Card.Body>
      </Card>
      <div className={`${newVis}`} id="newReqTable">
        <RequestTable
          newDoctorData={dashBoardCount}
          reqType="new"
          history={history}
        />
      </div>
      <div className={`${renewVis}`} id="newReqTable">
        <RenewalRequest
          reqTableData={dashBoardCount && dashBoardCount.data1}
          reqType="new"
          history={history}
        />
      </div>
    </>
  );
}
export default StatsCard;
