import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Card, Table, Toast } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import MaterialTable from "material-table";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { makeStyles } from "@material-ui/core/styles";

import { Paper } from "@material-ui/core";
//import ui
import SidebarRD from "../../Components/Rd-sidebar";
import Navmain from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { documentlist } from "../../actions/documentActions";
import { listusers } from "../../actions/userActions";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { onboardState } from "../../../src/CommomMethods/States";
import { APIURL } from "../../CommomMethods/Fetch";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import CloseIcon from "@material-ui/icons/Close";

import FixedfullTime from "../Payout/NEW/FixedfullTime";
import FixedMG from "../Payout/NEW/fixedMG";
import VcChennai from "../Payout/NEW/VcChennai";
import VisitingCon from "../Payout/NEW/VisitingCon";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "fixed",
    backgroundColor: "#810e36",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  buttonProgress: {
    // backgroundColor: "red",
    color: "white",
    position: "absolute",
    top: "50%",
    left: "20%",
    marginTop: -12,
    marginLeft: -12,
  },
}));
export default function RdStatus() {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [doctorid, setdoctorId] = useState(0);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [lists, setList] = useState([]);
  const [id, setId] = useState("");
  const [rid, setrId] = useState("");
  const [uploadData, setUploadData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [openr, setOpenr] = React.useState(false);
  const [openRemark, setOpenRemark] = React.useState(false);
  const [viewRemark, setViewRemark] = useState("");
  const [remark, setRemark] = useState("");
  const [orow, setOrow] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [agreementrow, setAgreementRow] = useState({});
  const [categoty, setCategory] = useState("");
  const [docData, setDocData] = useState([]);
  const [viewAgreement, setViewAgreement] = useState(false);
  const [datas, setDatas] = useState([]);

  const formStyle = {
    borderRadius: "5px",
    border: "1px solid black",
  };
  const headerStyle = {
    backgroundColor: "#94248E",
    margins: "0px",
    padding: "10px",
    color: "white",
    borderRadius: "5px",
    borderBottom: "3px black solid",
  };
  const bodyStyle = {
    borderRadius: "5px",
  };

  const handleClose = () => setShow(false);
  const handleShow = (doctorid) => {
    setShow(true);
    dispatch(documentlist(doctorid));
  };
  const listDoc = useSelector((state) => state.listDoc);
  const { list } = listDoc;

  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;

  const userList = useSelector((state) => state.userList);
  const { users } = userList;

  console.log(user, "rd login data");

  function getAllRecord() {
    axios
      .get(APIURL() + "onboard/currentOnboardStatusbyRd", {
        params: { obj: user.user.usermap },
      })
      .then((res) => {
    // console.log(res, "status  by rd");
        setRows(res.data);
      })
      .catch((error) => {});
  }

  useEffect(() => {
    getAllRecord();
  }, []);
  // useEffect(() => {
  //   dispatch(documentlist(125));
  // }, []);
  useEffect(() => {
    dispatch(listusers());
  }, [dispatch]);

  useEffect(() => {
    axios
      .get(APIURL() + "documents/list/" + 128)
      .then((res) => res.data)
      .then((rows) => {
        setList(rows.data);
        var k = rows.data;
        var l = [];
        k.forEach((element) => {
          l.push(element.docTitle);
        });
        setUploadData(l);
      });
  }, [refresh]);

  const handleApprove = (row) => {
    const onboardData = {
      doctorId: row.doctorId,
      name: row.name,
      currentAction: onboardState.completed,
      currentActionBy: user.user.id,
      nextAction: onboardState.final,
      status: 1,
    };
    axios.post(APIURL() + "onboard/action/", onboardData).then((res) => {
      getAllRecord();
    });
  };

  function viewDocuments(id) {
    setdoctorId(id);
  }
  const handleDownload = (url, filename) => {
    fetch(APIURL() + "documents/download/" + url, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
      });

      //window.location.href = response.url;
    });
  };
  const handlemClickOpen = (id) => {
    dispatch(documentlist(id));
    setId(id);
    setOpen(true);
  };

  const closeAgreement = () => {
    setViewAgreement(false);
  };
  const handlemClose = () => {
    setOpen(false);
  };

  const handlerClickOpen = (row) => {
    setOrow(row);
    setOpenr(true);
  };
  const handlerClose = () => {
    setOpenr(false);
  };
  const handleSubmitRejectDoc = () => {
    const obj = {
      doctorId: orow.doctorId,
      name: orow.name,
      currentAction: onboardState.rd.state,
      currentActionBy: user.user.id,
      nextAction: onboardState.ch.state,
      remark: remark,
      status: 1,
    };
    axios.post(APIURL() + "onboard/action/", obj).then((res) => {
      window.location.reload();
      getAllRecord();
      setRemark("");
      setOpenr(false);
    });
  };
  const handleSubmitReject = () => {
    const onboardData = {
      doctorId: orow.doctorId,
      name: orow.name,
      currentAction: onboardState.ch.state,
      currentActionBy: user.user.id,
      nextAction: onboardState.hr.state,
      remark: remark,
      status: 1,
    };
    axios.post(APIURL() + "onboard/action/", onboardData).then((res) => {
      getAllRecord();
      setRemark("");
      setOpenr(false);
    });
  };

  const Remark = (e) => {
    setRemark(e.target.value);
  };
  const handleApproveDoc = (row) => {
    const updateData = {
      isVerified: "APPROVED",
      verifiedBy: users[0] && users[0].users_role_id,
      verificationRemark: "2",
    };
    axios
      .put(APIURL() + "documents/documents/" + row.id, updateData)
      .then((res) => {});
    // setTimeout(() => {
    window.location.reload();
    // }, 1000);
  };

  const handlerClickOpenDoc = (id) => {
    setrId(id);
    setOpenr(true);
    // setOpenr(true);
    // var k = window.confirm("Are you sure want to delete this record ?");
    // if (k) {
    //   axios.delete("documents/" + id).then(res => {
    //   })
    // }
  };

  const handleRemarkOpen = (remark) => {
    setViewRemark(remark);
    setOpenRemark(true);
  };
  const handleCloseRemark = () => {
    setOpenRemark(false);
  };
  const viewAgreementDoc = (row) => {
// console.log(row, "view-agreement-ch");
    setAgreementRow(row);
    axios
      .get(APIURL() + "onboard/agreementView/" + row.doctorId)
      .then((res) => {
        if (res.data != null) {
      // console.log(res.data.data, "category-view");
          setCategory(res.data.data.vacancyData[0].category);
          setDocData(res.data.data);
        }
      });
    setViewAgreement(true);
  };

  useEffect(() => {
    axios
      .get(APIURL() + "onboard/getdataforAll/")
      .then((res) => res.data)
      .then((rows) => {
        setDatas(rows.data);
      });
  }, []);

  return (
    <>
      {/* {user && user.id === 3 ? ( */}
      <>
        <SidebarRD />
        <div className="main-content side-content pt-0">
          <div className="container-fluid">
            <div className="inner-body">
              <Navmain />

              <div className="page-header">
                <div>
                  <h2 className="main-content-title tx-24 mg-b-5">
                    Welcome {users && users[0] && users[0].name}
                  </h2>
                  <br />
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="dashboard">Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      viewstatus
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <Card.Body style={bodyStyle}>
            {/* <MaterialTable
              columns={[
                { title: "Doctor Name", field: "name" },

                {
                  title: "Current Action",
                  field: "Action",
                  filtering: false,
                  render: (row) => onboardState.rd.message,
                },
                {
                  title: " View Documents",
                  field: "Action",
                  filtering: false,
                  render: (row) => (
                    <InsertDriveFileIcon
                      className="fas fa-eye"
                      style={{ color: "#810e36" }}
                      onClick={() => {
                        handlemClickOpen(row.doctorId);
                      }}
                    ></InsertDriveFileIcon>
                  ),
                },

                {
                  title: "Action",
                  field: "Action",
                  filtering: false,
                  render: (row) =>
                    row.nextAction === onboardState.rd.state ? (
                      <div style={{ whiteSpace: "nowrap" }}>
                        <Button
                          size="small"
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            handleApprove(row);
                          }}
                        >
                          Approve
                        </Button>

                        <Button
                          style={{ marginLeft: "5px" }}
                          size="small"
                          color="secondary"
                          variant="contained"
                          onClick={() => {
                            handlerClickOpen(row);
                          }}
                        >
                          Rectification Required
                        </Button>
                      </div>
                    ) : (
                      " "
                    ),
                },
              ]}
              data={rows.data}
              // data={
              //   rows.data &&
              //   rows.data.filter((z) => z.nextAction === onboardState.rd.state)
              // }
              options={{
                // filtering: true,
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
              }}
              title="Details"
            /> */}

            <div>
              <MaterialTable
                columns={[
                  { title: "Doctor Name", field: "name" },

                  {
                    title: "Current Action",
                    field: "currentAction",
                    filtering: false,
                    render: (row) => (
                      <div style={{ width: "140px" }}>
                        {row.currentAction === onboardState.init.state ? (
                          <span style={{ color: "red" }}>
                            {" "}
                            {onboardState.init.message}
                          </span>
                        ) : row.currentAction === onboardState.upload.state ? (
                          <span style={{ color: "blue" }}>
                            {onboardState.upload.message}
                          </span>
                        ) : row.currentAction === onboardState.hr.state ? (
                          <span style={{ color: "blue" }}>
                            {onboardState.hr.currmessage}
                          </span>
                        ) : row.currentAction === onboardState.ch.state ? (
                          <span style={{ color: "blue" }}>
                            {onboardState.ch.currmessage}
                          </span>
                        ) : row.currentAction === onboardState.rd.state ? (
                          <span style={{ color: "blue" }}>
                            {onboardState.rd.currmessage}
                          </span>
                        ) : row.currentAction === onboardState.done.state ? (
                          <span style={{ color: "blue" }}>
                            {onboardState.done.currmessage}
                          </span>
                        ) : row.currentAction === onboardState.ch_doc.state ? (
                          <span style={{ color: "blue" }}>
                            {onboardState.ch_doc.currmessage}
                          </span>
                        ) : row.currentAction === onboardState.completed ? (
                          <span style={{ color: "blue" }}>"COMPLETED"</span>
                        ) : row.currentAction ===
                          onboardState.ch_renew.state ? (
                          <span style={{ color: "blue" }}>
                            {onboardState.ch_renew.message}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    ),
                  },
                  {
                    title: "Next Action",
                    field: "nextAction",
                    filtering: false,
                    render: (row) => (
                      <div style={{ width: "140px" }}>
                        {row.nextAction === onboardState.init.state ? (
                          <span style={{ color: "red" }}>
                            {" "}
                            {onboardState.init.message}
                          </span>
                        ) : row.nextAction === onboardState.upload.state ? (
                          <span style={{ color: "blue" }}>
                            {onboardState.upload.message}
                          </span>
                        ) : row.nextAction === onboardState.hr.state ? (
                          <span style={{ color: "blue" }}>
                            {onboardState.hr.message}
                          </span>
                        ) : row.nextAction === onboardState.ch.state ? (
                          <span style={{ color: "blue" }}>
                            {onboardState.ch.message}
                          </span>
                        ) : row.nextAction === onboardState.rd.state ? (
                          <span style={{ color: "blue" }}>
                            {onboardState.rd.message}
                          </span>
                        ) : row.nextAction === onboardState.done.state ? (
                          <span style={{ color: "blue" }}>
                            {onboardState.done.message}
                          </span>
                        ) : row.nextAction === onboardState.ch_doc.state ? (
                          <span style={{ color: "blue" }}>
                            {onboardState.ch_doc.message}
                          </span>
                        ) : row.nextAction === onboardState.completed ? (
                          <span style={{ color: "blue" }}>"COMPLETED"</span>
                        ) : row.nextAction === onboardState.ch_renew.state ? (
                          <span style={{ color: "blue" }}>
                            {onboardState.ch_renew.message}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    ),
                  },
                  {
                    title: "View Document",
                    field: "Action",
                    filtering: false,
                    render: (row) => (
                      <div style={{ width: "140px" }}>
                        {row.nextAction === onboardState.ch.state ? (
                          <InsertDriveFileIcon
                            style={{ color: "#810e36" }}
                            aria-label="close"
                            onClick={() => {
                              handlemClickOpen(row.doctorId);
                            }}
                          >
                            <VisibilityIcon />
                          </InsertDriveFileIcon>
                        ) : (
                          <InsertDriveFileIcon
                            style={{ color: "#810e36" }}
                            aria-label="close"
                            onClick={() => {
                              handlemClickOpen(row.doctorId);
                            }}
                          >
                            <VisibilityIcon />
                          </InsertDriveFileIcon>
                        )}
                      </div>
                    ),
                  },
                  {
                    title: "View Agreement",
                    field: "Action",
                    filtering: false,
                    render: (rows) => (
                      <div style={{ width: "140px" }}>
                        {rows &&
                        rows.agreementcurrentAction === "DR_ACCEPTED" ? (
                          <IconButton
                            style={{
                              color: "#810e36",
                              cursor: "pointer",
                            }}
                            aria-label="close"
                            onClick={() => {
                              viewAgreementDoc(rows);
                            }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            style={{ color: "gray" }}
                            aria-label="close"
                          >
                            <VisibilityOffIcon />
                          </IconButton>
                        )}
                      </div>
                    ),
                  },
                ]}
                data={datas}
                options={{
                  // filtering: true,
                  sorting: true,
                  exportButton: true,
                  pageSize: 5,
                  pageSizeOptions: [5, 10, 50, 100, 200, 500],
                  search: true,
                }}
                title="Details-new"
              />
            </div>

            {/* <Table style={{ width: "98%" }} border>
              <thead>
                <tr
                  style={{
                    textAlign: "center",
                    borderBottom: "1px solid rgb(200, 200, 200)",
                    backgroundColor: "hsla(340, 97%, 26%, 0.55)",
                    color: "white",
                  }}
                >
                  <th style={{ width: "40%" }}>Doctor Name</th>
                  <th style={{ width: "40%" }}>Current Action</th>
                  <th style={{ width: "40%" }}>View Documents</th>
                  <th style={{ width: "40%" }}>Action</th>
                </tr>
              </thead>
              <tbody style={{ whiteSpace: "nowrap" }}>
                {rows &&
                  rows
                    .filter((z) => z.nextAction === onboardState.rd.state)
                    .map((ele) => (
                      <tr
                        id="color"
                        style={{
                          textAlign: "center",
                          borderBottom: "1px solid rgb(200, 200, 200)",
                        }}
                      >
                        <td>{ele.name}</td>
                        <td>{onboardState.rd.message}</td>
                        <td>
                          <i
                            className="fas fa-eye"
                            color="black"
                            onClick={() => {
                              handlemClickOpen(ele.doctorId);
                            }}
                          ></i>
                        </td>

                        <td>
                          {ele.nextAction === onboardState.rd.state ? (
                            <div style={{ whiteSpace: "nowrap" }}>
                              <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                  handleApprove(ele);
                                }}
                              >
                                Approve
                              </Button>

                              <Button
                                style={{ marginLeft: "5px" }}
                                size="small"
                                color="secondary"
                                variant="contained"
                                onClick={() => {
                                  handlerClickOpen(ele);
                                }}
                              >
                                Rectification Required
                              </Button>
                            </div>
                          ) : (
                            " "
                          )}
                        </td>
                      </tr>
                    ))}
              </tbody>

              {rows &&
              rows.filter((z) => z.nextAction === onboardState.rd.state)
                .length === 0 ? (
                <tbody>
                  <tr style={{ textAlign: "center", width: "100%" }}>
                    <td colSpan="3"> No Record Found</td>
                  </tr>
                </tbody>
              ) : (
                ""
              )}
            </Table> */}
          </Card.Body>

          <Modal
            size="lg"
            scrollable
            dialogClassName="modalStandard"
            centered
            aria-labelledby="example-modal-sizes-title-lg"
            show={show}
            onHide={handleClose}
          >
            <Modal.Header className="modalHeader" closeButton>
              <Modal.Title className="modalTitle">Requested Id</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* {addpos.data && addpos.data.map((ele,index) =>( */}
              <Table style={{ width: "100%" }} borderless>
                <thead>
                  <tr
                    style={{
                      textAlign: "center",
                      borderBottom: "1px solid rgb(200, 200, 200)",
                    }}
                  >
                    <th className="col-2">Document Name</th>

                    <th> Documents</th>
                  </tr>
                </thead>
                <tbody>
                  {list &&
                    list.data &&
                    list.data.map((ele) => (
                      <tr
                        onClick={() => handleShow(ele.id)}
                        id="color"
                        style={{
                          textAlign: "center",
                          borderBottom: "1px solid rgb(200, 200, 200)",
                        }}
                      >
                        {/* <td>{ele.id}.</td> */}
                        <td>{ele.docTitle}</td>
                        <td>
                          <i
                            className="fas fa-file"
                            onClick={() =>
                              handleDownload(ele.url, ele.docTitle)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center"></Modal.Footer>
          </Modal>
        </div>
      </>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlemClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlemClose}
          style={{ textAlign: "center" }}
        >
          Uploaded Documents
        </DialogTitle>
        <DialogContent dividers>
          <Card style={formStyle}>
            <Card.Header style={headerStyle}></Card.Header>
            <Card.Body
              // style={bodyStyle}
              style={{ overflowY: "auto", height: "300px" }}
            >
              <Table style={{ width: "100%" }} borderless>
                <thead>
                  <tr
                    style={{
                      textAlign: "center",
                      borderBottom: "1px solid rgb(200, 200, 200)",
                    }}
                  >
                    <th className="col-2">Document Title</th>
                    <th className="col-2"> File</th>
                    <th className="col-2"> Actions</th>
                  </tr>
                  {/* )} */}
                </thead>
                <tbody>
                  {list &&
                    list.data &&
                    list.data.map((ele) => (
                      <tr>
                        <td style={{ textAlign: "center" }}>{ele.docTitle}</td>
                        <td style={{ cursor: "pointer", textAlign: "center" }}>
                          {" "}
                          <i
                            className="fas fa-file"
                            onClick={() =>
                              handleDownload(ele.url, ele.docTitle)
                            }
                            title={"Download"}
                            style={{ fontSize: "22px" }}
                          />
                        </td>
                        {/* <td
                            style={{ cursor: "pointer", textAlign: "center" }}
                          > */}

                        {/* {list &&
                            list.data &&
                            list.data.length === 12 &&
                            list ? ( */}
                        {/* {ele.isVerified === "APPROVED" ? (
                            "APPROVED"
                          ) : ele.isVerified === "REJECTED" ? (
                            "REJECTED"
                          ) : (
                              <div style={{ whiteSpace: "nowrap" }}>
                                <Button
                                  size="small"
                                  color="primary"
                                  variant="contained"
                                  onClick={() => {
                                    handleApproveDoc(ele);
                                  }}
                                >
                                  Approve
                                </Button>

                                <Button
                                  style={{ marginLeft: "5px" }}
                                  size="small"
                                  color="secondary"
                                  variant="contained"
                                  onClick={() => {
                                    handlerClickOpenDoc(ele.id);
                                  }}
                                >
                                  Reject
                                </Button>
                              </div>
                            ) : (
                              ""
                            )}  */}
                        {/* </td> */}
                        <td style={{ cursor: "pointer", textAlign: "center" }}>
                          {ele.isVerified === "APPROVED" ? (
                            "APPROVED"
                          ) : ele.isVerified === "REJECTED" ? (
                            "REJECTED"
                          ) : (
                            <div style={{ whiteSpace: "nowrap" }}>
                              <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                  handleApproveDoc(ele);
                                }}
                              >
                                Approve
                              </Button>

                              <Button
                                style={{ marginLeft: "5px" }}
                                size="small"
                                color="secondary"
                                variant="contained"
                                onClick={() => {
                                  handlerClickOpenDoc(ele.id);
                                }}
                              >
                                Review
                              </Button>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handlemClose}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerClose}
        aria-labelledby="customized-dialog-title"
        open={openr}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClose}
          style={{ textAlign: "center" }}
        >
          Review
        </DialogTitle>
        <DialogContent dividers>
          <label>
            <b>Remark</b>
          </label>
          <textarea
            type="text"
            onChange={Remark}
            style={{ color: "black", border: "1px solid black" }}
            className="form-control"
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleSubmitRejectDoc}
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
          <Button
            autoFocus
            onClick={handlerClose}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handleCloseRemark}
        aria-labelledby="customized-dialog-title"
        open={openRemark}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClose}
          style={{ textAlign: "center" }}
        >
          Remark
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>{viewRemark}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleCloseRemark}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen
        open={viewAgreement}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="end"
              style={{ marginLeft: "99%" }}
              color="inherit"
              onClick={closeAgreement}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <List>
          {categoty === "Fixed Consultant" || categoty === 'Retainer' ? (
            <FixedfullTime
              history={agreementrow}
              recData={docData}
              catagory={categoty}
            />
          ) : categoty === "Minimum Guarentee(MG)" ? (
            <FixedMG
              history={agreementrow}
              recData={docData}
              catagory={categoty}
            />
          ) : categoty === "Visiting Consultant" ? (
            <VisitingCon
              history={agreementrow}
              recData={docData}
              catagory={categoty}
            />
          ) : categoty === "Visiting Consultant Chennai" ? (
            <VcChennai
              history={agreementrow}
              recData={docData}
              catagory={categoty}
            />
          ) : (
            "hi"
          )}
        </List>
      </Dialog>
    </>
  );
}
