import React, { useEffect, useState } from "react";
// @material-ui/core components
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { IconButton } from "@material-ui/core";
import ListIcon from "@material-ui/icons/List";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import { useDispatch, useSelector } from "react-redux";
// import { SnackbarContent } from "@material-ui/core";
import SnackbarContent from "@material-ui/core/SnackbarContent";
// axios
import axios from "axios";
//bootstrap

//ui components
import AddAgreementAnnexure from "./forms/AddAgreementAnnexure";
import EditAgreementAnnexure from "./forms/EditAgreementAnnexure";
import TableAgreementAnnexure from "./tables/TableAgreementAnnexure";
import Navbar from "../../Components/Navbar";
import DoctorSidebar from "../../Components/DoctorSidebar";
import AppSidebar from "../../Components/AppSidebar";
import Sidebar from "../../Components/Sidebar";
import { APIURL } from "../../CommomMethods/Fetch";

const styleSheet = makeStyles((theme) => ({
  iconButtonStyle: {
    float: "right",
  },
  marginforMaster: {
    marginTop: "30px",
  },
}));

export default function ManageDepartment() {
  const classes = styleSheet();
  //useStae for Snackbar
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    color: "",
    icon: "",
  });
  //For user detail
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, user } = userLogin;
  //variables for snack bar
  const { vertical, horizontal, open } = state;
  //function for snackbar
  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const initialFormState = {
    id: null,
    procedurename: "",
    platinum: "",
    payout: "",
    fromdate: "",
    todate: "",
    center: "",
    designationlist: "",
  };
  const [department, setDepartment] = useState(initialFormState);
  const [preview, setPreview] = useState(true);
  const [editing, setEditing] = useState(false);
  const [centerRows, setCenterRows] = useState([]);
  const [procedures, setProcedures] = useState([]);
  const [designationRows, setDesignationRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [data, setData] = useState(false);

  // get all records from department
  useEffect(() => {
    getagreementannexures()
    axios.get(APIURL() + "departments/").then((res) => {
      setDesignationRows(res.data.data);
    });
    getCenterData()
    getProcedures()
  }, [data]);

  const getagreementannexures = async () => {
    await axios
      .get(APIURL() + "agreementannexures/")
      .then((res) => res.data)
      .then(async (rows) => {
        var records = []
        for (let i = 0; i < rows.data.length; i++) {
          const element = rows.data[i];
          for (let j = 0; j < user.user.usermap.length; j++) {
            if (element.center.includes(user.user.usermap[j].siteCode) && !records.find(elem => elem.id == element.id)) {
              records.push(element)
            }
          }
        }
        await setRows(records);
      });
  }

  const getCenterData = async () => {
    if (user.user.roles === 2) {
      var centerRows = await user.user.usermap.map(element => {
        element.id = element.centerId
        element.code = element.siteCode
        element.name = element.centername
        return element
      });
  // console.log(centerRows, rows, 'user-manage-south-annexure')
      await setCenterRows(centerRows);
    } else {
      await axios
        .get(APIURL() + "centers/")
        .then((res) => res.data)
        .then((rows) => {
          setCenterRows(rows.filter((center) => center.status));
        });
    }
  }

  // Get Procedures
  const getProcedures = async () => {
    await axios
      .post(APIURL() + "onboard/ot-procedures")
      .then((res) => res.data)
      .then((rows) => {
        setProcedures(rows);
      });
  }

  //populate single record in the Edit component
  const editRow = (department) => {
    setPreview(false);
    setEditing(true);
    setDepartment({
      id: department.id,
      procedurename: department.procedurename,
      platinum: department.platinum,
      payout: department.payout,
      fromdate: department.fromdate,
      todate: department.todate,
      center: department.center,
      designationlist: department.designationlist,
    });
// console.log(department, 'currentDepartment')
  };

  //update department
  const updateAgreementAnnexure = (id, department) => {
    setData(false);
    axios.put(APIURL() + "agreementannexures/" + id, department).then((res) => {
      if (res.status === 200) {
        setEditing(false);
        setPreview(true);
        setData(true);

        setState({
          open: true,
          vertical: "top",
          horizontal: "center",
          message: res.data.message,
          color: "success",
        });
      }
    });
  };

  //add designation
  const addAgreementAnnexure = (department) => {
// console.log(department, "dept-mnt");
    setData(false);
    axios
      .post(APIURL() + "agreementannexures/create", department)
      .then((res) => {
        // console.log(res, "res-ponse");
        setPreview(true);
        setData(true);
        if (res.status === 201) {
          setState({
            open: true,
            vertical: "top",
            horizontal: "center",
            message: res.data.message,
            color: "success",
          });
        }
      });
  };

  //deletion of designation
  const deleteDepartment = (row) => {
    setData(false);
    var k = window.confirm("Are you sure want to delete this record ?");
    if (k) {
      axios.post(APIURL() + "agreementannexures/delete/" + row.id, {
        user_id: user.user.id
      }).then((res) => {
        setData(true);
        if (res.status === 200) {
          setState({
            open: true,
            vertical: "top",
            horizontal: "center",
            message: res.data.message,
            color: "danger",
          });
        }
      });
    }
  };
  console.log(user, 'user-manage-south-annexure')
  return (
    <>
      {
        user.user.roles == 4 ? <DoctorSidebar />
          :
          user.user.roles !== 0 ? <Sidebar />
            : <AppSidebar />
      }
      <div className="main-content side-content pt-0 mg-b-30">
        <div className="container-fluid">
          <div className="inner-body">
            <Navbar />
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                className={classes.marginforMaster}
              >
                <Typography variant="h6">Manage Agreement-Annexure</Typography>
                {preview ? (
                  <Typography variant="body2">
                    Masters / Manage Agreement-Annexure / Agreement-Annexure
                    List
                  </Typography>
                ) : editing ? (
                  <Typography variant="body2">
                    Masters / Manage Agreement-Annexure / Edit
                    Agreement-Annexure
                  </Typography>
                ) : (
                  <Typography variant="body2">
                    Masters / Manage Agreement-Annexure / Add Agreement-Annexure
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                className={classes.marginforMaster}
              >
                <div className={classes.iconButtonStyle}>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setPreview(true);
                      setEditing(false);
                    }}
                  >
                    <ListIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setPreview(false);
                    }}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {preview ? (
                  <TableAgreementAnnexure
                    rows={rows}
                    user={user}
                    editRow={editRow}
                    deleteDepartment={deleteDepartment}
                  />
                ) : editing ? (
                  <EditAgreementAnnexure
                    rows={rows}
                    user={user}
                    editing={editing}
                    setEditing={setEditing}
                    setPreview={setPreview}
                    procedures={procedures}
                    currentDepartment={department}
                    updateAgreementAnnexure={updateAgreementAnnexure}
                    centerRows={centerRows}
                    designationRows={designationRows}
                  />
                ) : (
                  <AddAgreementAnnexure
                    rows={rows}
                    user={user}
                    addAgreementAnnexure={addAgreementAnnexure}
                    centerRows={centerRows}
                    procedures={procedures}
                    designationRows={designationRows}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={5000}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <SnackbarContent color={state.color} message={state.message} />
      </Snackbar>
    </>
  );
}
