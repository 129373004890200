import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@mui/material/Typography";
import Button from "@material-ui/core/Button";
import { useLocation } from "react-router-dom";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
//ui component
import Sidebar from "../../Components/Sidebar";
import { APIURL } from "../../CommomMethods/Fetch";
import TextField from "@mui/material/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const myTableStyle = makeStyles((theme) => ({
  root: {
    whiteSpace: "nowrap",
    width: "100%",
    height: "20%",
    marginTop: theme.spacing(3),
    "& td": {
      fontFamili: "Roboto !important",
    },
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflow: "auto",
    marginBottom: "50px",
  },
  customizedButton: {
    position: "absolute",
    left: "95%",
    top: "-9%",
    backgroundColor: "lightgray",
    color: "gray",
  },
}));

export default function UnProcessedPayout() {
  const tables = myTableStyle();
  const location = useLocation();
  const [rows, setRows] = useState([]);
  const [data, setData] = useState({});
  const [filteredRows, setFilteredRows] = useState([]);
  const [val, setVal] = useState([]);
  const [opPayoutRowId, setOpPayoutRowId] = useState([]);
  const [rowDetail, setRowDetail] = useState({
    doctorname: "",
    ratio: "",
    percentage: "",
  });
  const [openr, setOpenr] = React.useState(false);
  const [changeRowValue, setChangeRowValue] = useState([]);
  const [reload, setReload] = useState(false);
  const [rowSelection, setRowSelection] = useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;

  var doctorList = [
    { id: 0, name: "Dr. Preety Aggarwal" },
    { id: 1, name: "Dr. Shweta Bansal Wazir" },
    { id: 2, name: "Dr. Manish Balde" },
    { id: 3, name: "Dr. Witty Raina" },
    { id: 4, name: "Dr. Binal Shah" },
    { id: 5, name: "Dr. Beena Muktesh" },
    { id: 6, name: "Dr. Shalu Gupta" },
    { id: 7, name: "Dr. Bhavna Dewan" },
    { id: 8, name: "Dr. Radhika Sheth" },
    { id: 9, name: "Dr. Hena Bhagde" },
  ];

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setRowDetail({ ...rowDetail, [name]: value });
  };
  console.log(changeRowValue, "****");
  useEffect(() => {
    axios
      .get(APIURL() + "billings/findAllBillingDet/" + location.state.data)
      .then((res) => res.data)
      .then((rows) => {
        setRows(rows);
        var k = rows.filter((z) => z.status == "UP");
        setFilteredRows(k);
      });
  }, [reload]);

  function selectedDropdownvalue(e) {
    // newdata = { ...data };
    // newdata[e.target.id] = e.target.value;
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  }

  var newdata = [];
  // function payout(row, e) {
  // // console.log(row, "pppaa")
  //     newdata = { ...Payout };
  //     newdata[row.id] = e.target.value;
  //     setPayout(newdata);
  //     // setOpPayoutRowId([...opPayoutRowId, { id: row.id, value: e.target.value }]);
  // }

  const editPayout = (row) => {
    setVal(row.id);
    setOpenr(true);
  };
  //   function handlePayoutEdit() {
  //     setOpPayoutRowId([
  //       ...opPayoutRowId,
  //       ,
  //       {
  //         id: val,
  //         Payout,
  //       },
  //     ]);
  //     setOpenr(false);
  //   }

  const handlePayoutEdit = () => {
    setReload(true);
    const obj = { rowDetail: rowDetail, changeRowValue: changeRowValue };
    axios.put(APIURL() + "billings/updateBillingRowData", obj).then((res) => {
      setReload(false);
      setOpenr(false);
    });
  };

  const handlerClose = () => {
    setOpenr(false);
  };

  const Process = (row) => {
    // const obj = {
    //   status: "P",
    //   DoctorPayout: Payout,
    // };
    // axios
    //   .put(APIURL() + "billings/centerupdate/" + row.id, obj)
    //   .then((res) => {});
  };
  return (
    <>
      <Sidebar />
      <CssBaseline />
      <Container style={{ margin: "10px 0px 7px 235px", width: "80%" }}>
        <Typography variant="subtitle1">
          <h4
            style={{
              margin: ".4rem",
              textAlign: "center",
              padding: "7px",
              backgroundColor: "#810e36",
              color: "white",
            }}
          >
            <b>Un Processed Payout</b>
          </h4>
        </Typography>

        <Grid container spacing={1} style={{ marginBottom: "40px" }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            // className={classes.marginforMaster}
          >
            {rowSelection ? (
              <IconButton aria-label="close" tooltip="Edit Selected Row">
                <EditIcon
                  style={{ color: "#810e36" }}
                  onClick={() => {
                    setOpenr(true);
                  }}
                />
              </IconButton>
            ) : (
              ""
            )}

            <Paper className={tables.root}>
              <MaterialTable
                columns={[
                  { title: "SlNo", field: "SlNo" },
                  { title: "MPI", field: "MPI" },
                  { title: "Item Group Name", field: "ItemGroupName" },
                  { title: "Item Name", field: "ItemName" },
                  { title: "Specialisation", field: "Specialisation" },
                  { title: "Location", field: "Location" },
                  { title: "Conducted By", field: "ConductedBy" },
                  //   {
                  //     title: "Payout",
                  //     field: "Action",
                  //     filtering: false,
                  //     render: (row) => (
                  //       <div style={{ width: "140px" }}>
                  //         {!opPayoutRowId.includes(row.id) ? (
                  //           <Button
                  //             style={{ color: "black" }}
                  //             aria-label="close"
                  //             tooltip="Process"
                  //             onClick={() => {
                  //               editPayout(row);
                  //             }}
                  //           >
                  //             <i
                  //               className="fas fa-edit"
                  //               style={{ color: "black" }}
                  //             />
                  //           </Button>
                  //         ) : (
                  //           Payout
                  //         )}
                  //       </div>
                  //     ),
                  //   },
                  {
                    title: "Process",
                    field: "Action",
                    filtering: false,
                    render: (row) => (
                      <div style={{ width: "140px" }}>
                        <Button
                          style={{ color: "black" }}
                          aria-label="close"
                          tooltip="Process"
                          onClick={() => {
                            Process(row);
                          }}
                        >
                          <i
                            className="fas fa-check"
                            style={{ color: "green" }}
                          />
                        </Button>
                      </div>
                    ),
                  },

                  { title: "Doctor Payout", field: "DoctorPayout" },
                ]}
                data={filteredRows}
                options={{
                  selection: true,
                  sorting: true,
                  exportButton: true,
                  pageSize: 5,
                  pageSizeOptions: [5, 10, 50, 100, 200, 500],
                  search: true,
                  exportAllData: true,
                }}
                onSelectionChange={(rows) => {
                  setRowSelection(true);
                  setChangeRowValue(rows);
                }}
                title="UP-Payout-Detail"
              />
            </Paper>
          </Grid>
        </Grid>

        <Dialog
          fullWidth={true}
          maxWidth="md"
          onClose={handlerClose}
          aria-labelledby="customized-dialog-title"
          open={openr}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handlerClose}
            style={{ textAlign: "center" }}
          >
            Edit Doctor Details
          </DialogTitle>
          <DialogContent dividers>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-outlined-label">
                Select Doctor Name
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name="doctorname"
                value={rowDetail.doctorname}
                onChange={handleInputChange}
                label="Select Role"
              >
                {doctorList &&
                  doctorList.map((opt) => (
                    <MenuItem key={opt.id} value={opt.name}>
                      {opt.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <label>
              <b>Ratio</b>
            </label>
            <TextField
              id="Payout"
              label="Ratio"
              variant="outlined"
              fullWidth
              name="ratio"
              value={rowDetail.ratio}
              onChange={handleInputChange}
              style={{ marginBottom: "20px" }}
              autoComplete="off"
            />

            <label>
              <b> Percentage</b>
            </label>
            <TextField
              id="Payout"
              label="Percentage"
              variant="outlined"
              fullWidth
              name="percentage"
              value={rowDetail.percentage}
              onChange={handleInputChange}
              //   onChange={(e) => setPayout(e.target.value)}

              style={{ marginBottom: "20px" }}
              autoComplete="off"
            />
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={handlePayoutEdit}
              color="primary"
              variant="contained"
            >
              Update
            </Button>
            <Button
              autoFocus
              onClick={handlerClose}
              color="secondary"
              variant="contained"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}
