import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//ui components
import AppSidebar from "../../Components/PTSidebar";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import uploadIpBillingFile from "../../SampleFile/IPBILINGSAMPLEFILE.xlsx";
import ipBillItemSampleFIle from "../../SampleFile/ipBillItemSampleFIle.xlsx";
import opBillingSampleFile from "../../SampleFile/opBillingSampleFile.xlsx";
import c9gif from "../../SampleFile/cloudnine.gif";
//materia components
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { Button, Typography } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import { FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import WorkIcon from "@material-ui/icons/Work";
import Divider from "@material-ui/core/Divider";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import Tooltip from "@material-ui/core/Tooltip";
import { Link, useHistory } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";

import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import ConfirmDialog from "../../Components/ConfirmDialog";
//
import axios from "axios";
import { APIURL } from "../../CommomMethods/Fetch";
import { Skeleton } from "@mui/material";
import { toast } from "react-toastify";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    width: "100%",
    marginBottom: "10px",
  },
  root2: {
    height: "235px",
    overflowY: "auto",
    // marginTop: "10px",
    width: "100%",
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  iconStyle: {
    color: "#820e37",
    cursor: "pointer",
    marginRight: "20px",
    height: "20px",
    width: "20px",
  },
}));

const backDropStyle = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
export default function PayoutDashboardNew() {
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });
  const [value, setValue] = React.useState("1");

  const handleChangetab = (event, newValue) => {
    setValue(newValue);
    setFile({});
    setFilename('');
  };
  let history = useHistory();
  const classes = useStyles();
  const initialFormState = {
    Year: "",
    Month: "",
    Center: "",
    region: "",
  };
  const [payoutDtls, setPayoutDtls] = useState(initialFormState);
  const [file, setFile] = useState({});
  const [filename, setFilename] = useState('');
  const [rows, setRows] = useState([]);
  // const [centerName, setCenterName] = useState([]);
  const [billitemrows, setBillitemRows] = useState([]);
  const [iprows, setIpRows] = useState([]);
  const [ipPaytermRows, setIpPaytermRows] = useState([]);
  const [backDropOpen, setBackDropOpen] = useState(false);
  const backClasses = backDropStyle();
  const [opPayoutDiscount, setOpPayoutDiscount] = useState([]);
  const [ipPayoutDiscount, setIpPayoutDiscount] = useState([]);
  const [loadingPayout, setLoadingpayout] = useState(true);
  const [loading, setLoading] = useState(false);
  const [opBillingDump, setOpBillingDump] = useState([]);
  const [vals, setVals] = useState("false");
  const [circularLoader, setCirculatLoader] = useState(false);

  const [centerName, setCenterName] = useState([]);
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;

  console.log(user.user, "user from payout team login");

  const unique = [...new Set(user.user.usermap.map((item) => item.regionname))];
  console.log(unique, "unique records");

  useEffect(() => {
    setLoading(false);
    axios
      .get(APIURL() + "billings/findAllBillingDet")
      .then((res) => res.data)
      .then((rows) => {
        setOpBillingDump(rows.data);
      });
  }, []);

  useEffect(() => {
    setLoadingpayout(true);
    axios.get(APIURL() + "billings/opPayoutBilling").then((res) => {
  // console.log(res.data.data, "!!!!!!######");
      var filterData = res.data.data;
      // if (user.user.id == 763) {
      //   filterData = res.data.data.filter((z) => z.region == "NORTH" || z.region == "North");
      // }
      // if (user.user.id == 400) {
      //   filterData = res.data.data.filter((z) => z.region == "SOUTH");
      // }
      // if (user.user.id == 402) {
      //   filterData = res.data.data.filter((z) => z.region == "WEST");
      // }
      setRows(filterData);
      setLoadingpayout(false);
    });
  }, [loading]);

  useEffect(() => {
    setLoadingpayout(true);
    axios.get(APIURL() + "billings/ipPayoutBilling").then((res) => {
      var filterData = res.data.data;
      // if (user.user.id == 763) {
      //   filterData = res.data.data.filter((z) => z.region == "NORTH");
      // }
      // if (user.user.id == 400) {
      //   filterData = res.data.data.filter((z) => z.region == "SOUTH");
      // }
      // if (user.user.id == 402) {
      //   filterData = res.data.data.filter((z) => z.region == "WEST");
      // }
      setIpRows(filterData);
      setLoadingpayout(false);
    });
  }, [loading]);

  //
  useEffect(() => {
    setLoadingpayout(true);
    axios.get(APIURL() + "billings/ipPayTerm").then((res) => {
      var filterData = res.data.data;
      // if (user.user.id == 763) {
      //   filterData = res.data.data.filter((z) => z.Center == "NORTH");
      // }
      // if (user.user.id == 400) {
      //   filterData = res.data.data.filter((z) => z.Center == "SOUTH");
      // }
      // if (user.user.id == 402) {
      //   filterData = res.data.data.filter((z) => z.Center == "WEST");
      // }
      setIpPaytermRows(filterData);
      setLoadingpayout(false);
    });
  }, [loading]);

  //setBillitemRows
  useEffect(() => {
    setLoadingpayout(true);
    axios.get(APIURL() + "billings/ipPayoutBillingItem").then((res) => {
      var filterData = res.data.data;
      // if (user.user.id == 763) {
      //   filterData = res.data.data.filter((z) => z.region == "NORTH");
      // }
      // if (user.user.id == 400) {
      //   filterData = res.data.data.filter((z) => z.region == "SOUTH");
      // }
      // if (user.user.id == 402) {
      //   filterData = res.data.data.filter((z) => z.region == "WEST");
      // }
      setBillitemRows(filterData);
      setLoadingpayout(false);
    });
  }, [loading]);

  const uplodBabyRcvFIle = () => {
    setLoading(false);

    setLoadingpayout(true);
    var fileupld = document.getElementById("fileUpload").innerHTML;
    const formData = new FormData();
    formData.append("name", filename);
    formData.append("docFile", file);
    formData.append("uploadedBy", user.user.username);
    formData.append("payoutDtls", JSON.stringify(payoutDtls));
    const obj = { formData: formData, payoutDtls: payoutDtls };
    axios
      .post(APIURL() + "billings/createIpbabyrcv", formData)
      .then(function (response) {
        if (response.data.message != "ok") {
          setConfirmDialog({
            isOpen: true,
            title: "Alert",
            subtitle: response.data.message,
          });
          setLoading(true);
          setPayoutDtls(initialFormState);
          setLoadingpayout(false);
          return;
        }
        if (response.data.msgdata) {
          setLoading(true);
          setPayoutDtls(initialFormState);
          setLoadingpayout(false);
          setConfirmDialog({
            isOpen: true,
            title: "Alert",
            subtitle: "Total " + response.data.msgdata + " record dumped.",
          });
          return;
        }
        setLoading(true);
        setPayoutDtls(initialFormState);
        setLoadingpayout(false);
      });
    setFile({});
    setFilename('');
  };

  // const upLoadPayterm
  const upLoadPayterm = () => {
    setLoading(false);

    setLoadingpayout(true);
    var fileupld = document.getElementById("fileUpload").innerHTML;
    const formData = new FormData();
    formData.append("name", filename);
    formData.append("docFile", file);
    formData.append("uploadedBy", user.user.username);
    formData.append("payoutDtls", JSON.stringify(payoutDtls));
    const obj = { formData: formData, payoutDtls: payoutDtls };
    axios
      .post(APIURL() + "billings/createipPayterm", formData)
      .then(function (response) {
        if (response.data.message != "ok") {
          setConfirmDialog({
            isOpen: true,
            title: "Alert",
            subtitle: response.data.message,
          });
          setLoading(true);
          setPayoutDtls(initialFormState);
          setLoadingpayout(false);
          return;
        }
        if (response.data.msgdata) {
          setLoading(true);
          setPayoutDtls(initialFormState);
          setLoadingpayout(false);
          setConfirmDialog({
            isOpen: true,
            title: "Alert",
            subtitle: "Total " + response.data.msgdata + " record dumped.",
          });
          return;
        }
        setLoading(true);
        setPayoutDtls(initialFormState);
        setLoadingpayout(false);
      });
    setFile({});
    setFilename('');
  };
  //deleteIpPaytermData

  const deleteIpPaytermData = (rowData) => {
    setLoadingpayout(true);
    setLoading(false);
// console.log(rowData, "rowData");
    //deleteOpPayoutBillProcess
    axios
      .post(APIURL() + "billings/deletePaytermData", rowData)
      .then(function (response) {
        setLoadingpayout(false);
        setLoading(true);
        if (response.data) {
      // console.log(response);
          alert("Total record deleted" + response.data);
        }
      });
  };

  const deleteBabyrcvFileData = (rowData) => {
    setLoadingpayout(true);
    setLoading(false);
// console.log(rowData, "rowData");
    //deleteOpPayoutBillProcess
    axios
      .post(APIURL() + "billings/deletebabyrcvdata", rowData)
      .then(function (response) {
        setLoadingpayout(false);
        setLoading(true);
        if (response.data) {
      // console.log(response);
          alert("Total record deleted" + response.data);
        }
      });
  };
  //oppayoutDiscountReport
  useEffect(() => {
    setLoadingpayout(true);
    axios.get(APIURL() + "billings/oppayoutDiscountReport").then((res) => {
      var filterData = [];
      if (user.user.id == 763) {
        filterData = res.data.data.filter((z) => z.region == "NORTH");
      }
      if (user.user.id == 400) {
        filterData = res.data.data.filter((z) => z.region == "SOUTH");
      }
      if (user.user.id == 402) {
        filterData = res.data.data.filter((z) => z.region == "WEST");
      }
      setOpPayoutDiscount(filterData);
      setLoadingpayout(false);
    });
  }, [loading]);
  //ippayoutDiscountReport
  useEffect(() => {
    setLoadingpayout(true);
    axios.get(APIURL() + "billings/ippayoutDiscountReport").then((res) => {
      var filterData = [];
      if (user.user.id == 763) {
        filterData = res.data.data.filter((z) => z.Center == "NORTH");
      }
      if (user.user.id == 400) {
        filterData = res.data.data.filter((z) => z.Center == "SOUTH");
      }
      if (user.user.id == 402) {
        filterData = res.data.data.filter((z) => z.Center == "WEST");
      }
      setIpPayoutDiscount(filterData);
      setLoadingpayout(false);
    });
  }, [loading]);

  const onChangeFIle = (element) => {
    setFile(element.target.files[0]);
    setFilename(element.target.files[0].name);
  };
  const uplodFIle = () => {
    setCirculatLoader(true);
    setLoading(false);

    setLoadingpayout(true);
    var fileupld = document.getElementById("fileUpload").innerHTML;
    const formData = new FormData();
    formData.append("name", filename);
    formData.append("docFile", file);
    formData.append("uploadedBy", user.user.username);
    formData.append("payoutDtls", JSON.stringify(payoutDtls));
    const obj = { formData: formData, payoutDtls: payoutDtls };
    axios
      .post(APIURL() + "billings/uploadOne", formData)
      .then(function (response) {
        if (response.data.message != "ok") {
          setConfirmDialog({
            isOpen: true,
            title: "Error",
            subtitle: response.data.message,
          });
          setLoading(true);
          setPayoutDtls(initialFormState);
          setLoadingpayout(false);
          setCirculatLoader(false);

          return;
        }
        if (response.data.msgdata) {
          setLoading(true);
          setPayoutDtls(initialFormState);
          setLoadingpayout(false);
          setCirculatLoader(false);

          // setConfirmDialog({
          //   isOpen: true,
          //   title: "Alert",
          //   subtitle: "Total " + response.data.msgdata + " record dumped.",
          // });
          return;
        }
        setCirculatLoader(false);

        setLoading(true);
        setPayoutDtls(initialFormState);
        setLoadingpayout(false);
    // console.log(response.data);
        if (response.data.message == "invalid file") {
          alert(response.data.message);
        }
      });
    setVals("True");
    setFile({});
    setFilename('');
  };

  const uplodipFIle = () => {
    setLoading(false);
    setCirculatLoader(true);
    var fileupld = document.getElementById("fileUpload").innerHTML;
    const formData = new FormData();
    formData.append("name", filename);
    formData.append("docFile", file);
    formData.append("uploadedBy", user.user.username);
    formData.append("uploadedId", user.user.id);
    formData.append("role", user.user.roles);
    formData.append("payoutDtls", JSON.stringify(payoutDtls));
    const obj = { formData: formData, payoutDtls: payoutDtls };
    axios
      .post(APIURL() + "billings/createIppayoput", formData)
      .then(function (response) {
        setLoading(true);
        setPayoutDtls(initialFormState);
        if (response.data) {
          setCirculatLoader(false);
          setFilename();
        }
        if (response.data.message != "ok") {
          setConfirmDialog({
            isOpen: true,
            title: "Error",
            subtitle: response.data.message,
          });
          return;
        }
      });
    setFile({});
    setFilename('');
  };

  const uplodopDiscountFIle = () => {
    setLoading(false);
    var fileupld = document.getElementById("fileUpload").innerHTML;
    const formData = new FormData();
    formData.append("name", filename);
    formData.append("docFile", file);
    formData.append("payoutDtls", JSON.stringify(payoutDtls));
    const obj = { formData: formData, payoutDtls: payoutDtls };
    axios
      .post(APIURL() + "billings/createopDiscount", formData)
      .then(function (res) {
        toast.success(res.data.message);
        setLoading(true);
        setPayoutDtls(initialFormState);
      });
    setVals("True");
    setFile({});
    setFilename('');
  };

  const uplodipDiscountFIle = () => {
    setLoading(false);
    var fileupld = document.getElementById("fileUpload").innerHTML;
    const formData = new FormData();
    formData.append("name", filename);
    formData.append("docFile", file);
    formData.append("payoutDtls", JSON.stringify(payoutDtls));
    const obj = { formData: formData, payoutDtls: payoutDtls };
    axios
      .post(APIURL() + "billings/createipDiscount", formData)
      .then(function (response) {
        setLoading(true);
        setPayoutDtls(initialFormState);
      });
    setFile({});
    setFilename('');
  };

  const uplodipItem = () => {
    setLoading(false);

    setLoadingpayout(true);
    var fileupld = document.getElementById("fileUpload").innerHTML;
    const formData = new FormData();
    formData.append("name", filename);
    formData.append("docFile", file);
    formData.append("uploadedBy", user.user.username);
    formData.append("payoutDtls", JSON.stringify(payoutDtls));
    const obj = { formData: formData, payoutDtls: payoutDtls };
    axios
      .post(APIURL() + "billings/createIpItem", formData)
      .then(function (response) {
        if (response.data.message != "ok") {
          setConfirmDialog({
            isOpen: true,
            title: "Alert",
            subtitle: response.data.message,
          });
          setLoading(true);
          setPayoutDtls(initialFormState);
          setLoadingpayout(false);
          return;
        }
        if (response.data.msgdata) {
          setLoading(true);
          setPayoutDtls(initialFormState);
          setLoadingpayout(false);
          setConfirmDialog({
            isOpen: true,
            title: "Alert",
            subtitle: "Total " + response.data.msgdata + " record dumped.",
          });
          return;
        }
        setLoading(true);
        setPayoutDtls(initialFormState);
        setLoadingpayout(false);
      });
  };

  //uploadBillItemRecord
  const uploadBillItemRecord = () => {
    setLoading(false);
    setCirculatLoader(true);
    setLoadingpayout(true);
    var fileupld = document.getElementById("fileUpload").innerHTML;
    const formData = new FormData();
    formData.append("name", filename);
    formData.append("docFile", file);
    formData.append("uploadedBy", user.user.username);
    formData.append("payoutDtls", JSON.stringify(payoutDtls));
    const obj = { formData: formData, payoutDtls: payoutDtls };
    axios
      .post(APIURL() + "billings/createIpBillItem", formData)
      .then(function (response) {
        if (response.data.message != "ok") {
          setConfirmDialog({
            isOpen: true,
            title: "Alert",
            subtitle: response.data.message,
          });
          setFilename();
          setLoading(true);
          setPayoutDtls(initialFormState);
          setLoadingpayout(false);
          return;
        }
        if (response.data.msgdata) {
          setFilename();
          setLoading(true);
          setPayoutDtls(initialFormState);
          setLoadingpayout(false);
          setConfirmDialog({
            isOpen: true,
            title: "Alert",
            subtitle: "Total " + response.data.msgdata + " record dumped.",
          });
          return;
        }
        setFilename();
        setCirculatLoader(false);
        setFile();
        setLoading(true);
        setPayoutDtls(initialFormState);
        setLoadingpayout(false);
      });
    setFile({});
    setFilename('');
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
// console.log(name, value,user.user.usermap,"name, value")
    let regionName = ''
    if (name == 'Center') {
      regionName = user.user.usermap.find((elem) => elem.siteCode == value).regionname
    }
    setPayoutDtls({ ...payoutDtls, [name]: value });
  };

  const handleDownloadFile = (url) => {
    fetch(APIURL() + "billings/download/" + url, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "1";
        a.click();
      });

      //window.location.href = response.url;
    });
  };

  function payoutSkeleton() {
    return (
      <Card style={{ marginBottom: "8px" }}>
        <div style={{ textAlign: "center" }}>
          <Skeleton variant="text" />
        </div>
        <CardHeader
          avatar={
            loadingPayout ? (
              <Skeleton
                animation="wave"
                variant="circle"
                width={40}
                height={40}
              />
            ) : (
              ""
            )
          }
          title={
            loadingPayout ? (
              <>
                <Skeleton
                  animation="wave"
                  height={10}
                  width="60%"
                  style={{ marginBottom: 6 }}
                />
                <Skeleton
                  animation="wave"
                  height={10}
                  width="60%"
                  style={{ marginBottom: 6 }}
                />
              </>
            ) : (
              "Ted"
            )
          }
        />
      </Card>
    );
  }
  console.log(user.user.id, "user.user.id");
  useEffect(() => {
    axios
      .get(APIURL() + "usermapcenters/centermaps/" + user.user.id)
      .then((res) => res.data)
      .then((rows) => {
    // console.log(rows, "2323");
        setCenterName(rows.data);
      });
  }, []);

  console.log(centerName, "CENTERNAME");
  const deleteFileData = (rowData) => {
    setLoadingpayout(true);
    setLoading(false);
// console.log(rowData, "rowData");
    //deleteOpPayoutBillProcess
    axios
      .post(APIURL() + "billings/deleteOpPayoutBillProcess", rowData)
      .then(function (response) {
        setLoadingpayout(false);
        setLoading(true);
        if (response.data) {
      // console.log(response);
          // alert("Total record deleted" + response.data);
        }
      });
  };

  const deleteDiscFileData = (rowData) => {
    setLoadingpayout(true);
    setLoading(false);
// console.log(rowData, "rowData");
    //deleteOpPayoutBillProcess
    axios
      .post(APIURL() + "billings/deleteOpPayoutDiscountRep", rowData)
      .then(function (response) {
        setLoadingpayout(false);
        setLoading(true);
        if (response.data) {
      // console.log(response);
          // alert("Total record deleted" + response.data);
        }
      });
  };

  //deleteipBillitem
  const deleteipBillitem = (rowData) => {
    setLoadingpayout(true);
    setLoading(false);
// console.log(rowData, "rowData");
    //deleteOpPayoutBillProcess
    axios
      .post(APIURL() + "billings/deleteipBillItemData", rowData)
      .then(function (response) {
        setLoadingpayout(false);
        setLoading(true);
        if (response.data) {
      // console.log(response);
          // alert("Total record deleted" + response.data);
        }
      });
  };

  const deleteIpFileData = (rowData) => {
    setLoadingpayout(true);
    setLoading(false);
// console.log(rowData, "rowData");
    //deleteOpPayoutBillProcess
    axios
      .post(APIURL() + "billings/deleteIpPayoutBillProcess", rowData)
      .then(function (response) {
        setLoadingpayout(false);
        setLoading(true);
        if (response.data) {
      // console.log(response);
          // alert("Total record deleted" + response.data);
        }
      });
  };

  const processDump = (data) => {
    setBackDropOpen(true);
    data.uploadedBy = user.user.username;
    axios
      .post(APIURL() + "billings/opBillingDetailProcessDump", data)
      .then((res) => {
        setBackDropOpen(false);
        // console.log("@!!45",res );
        toast.success(res.data.message);
        setTimeout(() => {
          history.push("/oppayout-process");
        }, 3000);
      })
      .catch((err) => {
        toast.error(err.message);
    // console.log("1234", err);
      });
  };

  const discDump = (data) => {
    setBackDropOpen(true);
// console.log(data, "DATA");
    data.uploadedBy = user.user.username;
    axios
      .post(APIURL() + "billings/opDiscDump", data)
      .then((res) => {
        setBackDropOpen(false);
    // console.log("@!!45", res);
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.message);
    // console.log("1234", err);
      });
  };
  useEffect(() => {
    axios
      .get(APIURL() + "usermapcenters/centermaps/" + user.user.id)
      .then((res) => res.data)
      .then((rows) => {
    // console.log(rows, "2323");
        setCenterName(rows.data);
      });
  }, []);
  console.log(file, "file===========>")
  // console.log(centerName, "centerName");
  return (
    <>
      <Sidebar />
      <CssBaseline />
      <Container style={{ margin: "10px 0px 7px 235px", width: "80%" }}>
        <Navbar />
        <TabContext value={value}>
          <AppBar
            position="static"
            style={{ marginTop: "10px", backgroundColor: "#810e36" }}
          >
            <TabList
              onChange={handleChangetab}
              aria-label="simple tabs example"
              variant="scrollable"
            >
              <Tab label="Upload OP-Billing" value="1" />
              <Tab label="Upload IP-Billing" value="2" />
              <Tab label="Upload IP-Bill-Item" value="5" />
              <Tab label="Upload OP-Discount Report" value="3" />
              <Tab label="Upload IP-Discount Report" value="4" />
              <Tab label="Upload IP-Payterm" value="7" />
              {/* <Tab label="Ip Item" value="5" />
              <Tab label="Baby Receiving" value="6" /> */}
            </TabList>
          </AppBar>
          <TabPanel value="1">
            {" "}
            {/* <Typography variant="subtitle1"> Upload Op-Billing</Typography> */}
            <Grid container spacing={2} style={{ marginTop: "30px" }}>
              <Grid item xs={12} sm={12} xl={6} md={6} lg={6}>
                {" "}
                <Typography variant="subtitle1"> Upload OP Billing</Typography>
              </Grid>
              <Grid item xs={12} sm={12} xl={6} md={6} lg={6}>
                {" "}
                <Tooltip title="DOWNOAD SAMPLE FILE" aria-label="add">
                  <Button
                    color="secondary"
                    size="large"
                    variant="text"
                    style={{ float: "right" }}
                  >
                    <a
                      href={opBillingSampleFile}
                      download="OP BILLING SAMPLE FILE"
                    >
                      Sample File
                    </a>
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: "30px" }}>
              <Grid item xs={12} sm={12} xl={6} md={6} lg={6}>
                {" "}
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Year
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={payoutDtls.Year}
                    name="Year"
                    onChange={handleChange}
                    label="Select Year"
                  >
                    <MenuItem value="2021">2021</MenuItem>
                    <MenuItem value="2022">2022</MenuItem>
                    <MenuItem value="2023">2023</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Month
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={payoutDtls.Month}
                    name="Month"
                    onChange={handleChange}
                    label="Select Year"
                  >
                    <MenuItem value="JANUARY">JANUARY</MenuItem>
                    <MenuItem value="FEBRUARY">FEBRUARY</MenuItem>
                    <MenuItem value="MARCH">MARCH</MenuItem>
                    <MenuItem value="APRIL">APRIL</MenuItem>
                    <MenuItem value="MAY">MAY</MenuItem>
                    <MenuItem value="JUNE">JUNE</MenuItem>
                    <MenuItem value="JULY">JULY</MenuItem>
                    <MenuItem value="AUGUST">AUGUST</MenuItem>
                    <MenuItem value="SEPTEMBER">SEPTEMBER</MenuItem>
                    <MenuItem value="OCTOBER">OCTOBER</MenuItem>
                    <MenuItem value="NOVEMBER">NOVEMBER</MenuItem>
                    <MenuItem value="DECEMBER">DECEMBER</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Center
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    // value={payoutDtls.Center}
                    value={payoutDtls.Center}
                    // value={c}
                    name="Center"
                    onChange={handleChange}
                    label="Select Center"
                  >
                    {centerName.length > 0 &&
                      centerName.map((ele) => {
                        return <MenuItem value={ele.code}>{ele.name}</MenuItem>;
                      })}
                    {/* {user.user.id == 763 ? (
                      <MenuItem value="NORTH">NORTH</MenuItem>
                    ) : user.user.id == 400 ? (
                      <MenuItem value="SOUTH">SOUTH</MenuItem>
                    ) : user.user.id == 402 ? (
                      <MenuItem value="WEST">WEST</MenuItem>
                    ) : (
                      ""
                    )} */}
                  </Select>
                </FormControl>
                {/* <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Region
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={payoutDtls.region}
                    name="region"
                    // disabled
                    onChange={handleChange}
                    label="Select Region"
                  >
                    <MenuItem value="NORTH">NORTH</MenuItem>
                    <MenuItem value="SOUTH">SOUTH</MenuItem>
                    <MenuItem value="WEST">WEST</MenuItem>
                  </Select>
                </FormControl> */}
                <label htmlFor="fileUpload">
                  <Button
                    style={{ backgroundColor: "#820e37" }}
                    variant="contained"
                    color="primary"
                    component="span"
                    endIcon={<CloudUploadIcon />}
                    fullWidth
                  >
                    Upload FIle
                  </Button>
                </label>
                <input
                  accept=".xlsx,.csv,.docx,.xls,.doc"
                  id="fileUpload"
                  type="file"
                  style={{ display: "none" }}
                  onChange={onChangeFIle}
                />
                {vals === "false" ? (
                  <input
                    type="text"
                    style={{ margin: "5px" }}
                    value={filename}
                  />
                ) : (
                  ""
                )}
                {payoutDtls.Center != "" &&
                  // payoutDtls.Month != "" &&
                  // payoutDtls.Year != "" &&
                  // file ? (
                  payoutDtls.Month != "" &&
                  payoutDtls.Year != "" &&
                  file && filename != "" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    fullWidth
                    onClick={uplodFIle}
                    style={{ backgroundColor: "#820e37" }}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    disabled={true}
                    variant="contained"
                    color="primary"
                    component="span"
                    fullWidth
                    onClick={uplodFIle}
                    style={{ backgroundColor: "gray" }}
                  >
                    Submit
                  </Button>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                xl={6}
                md={6}
                lg={6}
              // style={{ marginTop: "10px" }}
              >
                {" "}
                <List className={classes.root2}>
                  {loadingPayout ? (
                    <>
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}{" "}
                    </>
                  ) : (
                    rows &&
                    rows.map((ele) => (
                      <>
                        {/* <Typography
                          style={{ marginBottom: "5px", textAlign: "center" }}
                        >
                          {ele.Center}
                        </Typography> */}
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <WorkIcon />
                            </Avatar>
                          </ListItemAvatar>

                          <ListItemText
                            primary={ele.Year}
                            secondary={ele.Month + " - " + ele.Center}
                          />
                          <div style={{ marginBottom: "15px" }}>
                            <Tooltip
                              title="Proceed for Next Action"
                              aria-label="add"
                            >
                              <SkipNextIcon
                                className={classes.iconStyle}
                                onClick={() => processDump(ele)}
                              />

                              {/* <Link
                                to={{
                                  pathname: "/oppayout-process",
                                  state: { data: ele },
                                }}
                              >
                                <SkipNextIcon className={classes.iconStyle} />
                              </Link> */}
                            </Tooltip>
                            <Tooltip title="Download" aria-label="add">
                              <GetAppIcon
                                className={classes.iconStyle}
                                onClick={() => {
                                  handleDownloadFile(ele.File);
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Remove" aria-label="add">
                              <DeleteForeverIcon
                                className={classes.iconStyle}
                                onClick={() => {
                                  setConfirmDialog({
                                    isOpen: true,
                                    title:
                                      "Discarding Op-File may result in some features unresponsive ?",
                                    subtitle:
                                      "Are you sure you want to Discard this record ?",
                                    onConfirm: () => {
                                      deleteFileData(ele);
                                      setConfirmDialog({ isOpen: false });
                                    },
                                  });
                                }}
                              />
                            </Tooltip>
                          </div>
                        </ListItem>
                        <Divider style={{ marginBottom: "18px" }} />
                      </>
                    ))
                  )}
                </List>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            {" "}
            {/* <Typography variant="subtitle1"> Upload Ip-Billing</Typography> */}
            <Grid container spacing={2} style={{ marginTop: "30px" }}>
              <Grid item xs={12} sm={12} xl={6} md={6} lg={6}>
                {" "}
                <Typography variant="subtitle1"> Upload IP Billing</Typography>
              </Grid>
              <Grid item xs={12} sm={12} xl={6} md={6} lg={6}>
                {" "}
                <Tooltip title="DOWNOAD SAMPLE FILE" aria-label="add">
                  <Button
                    color="secondary"
                    size="large"
                    variant="text"
                    style={{ float: "right" }}
                  >
                    <a href={uploadIpBillingFile} download="IP BILLING SAMPLE FILE">
                      Sample File
                    </a>
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: "30px" }}>
              <Grid item xs={12} sm={12} xl={6} md={6} lg={6}>
                {" "}
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Year
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={payoutDtls.Year}
                    name="Year"
                    onChange={handleChange}
                    label="Select Year"
                  >
                    <MenuItem value="2021">2021</MenuItem>
                    <MenuItem value="2022">2022</MenuItem>
                    <MenuItem value="2023">2023</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Month
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={payoutDtls.Month}
                    name="Month"
                    onChange={handleChange}
                    label="Select Year"
                  >
                    <MenuItem value="JANUARY">JANUARY</MenuItem>
                    <MenuItem value="FEBRUARY">FEBRUARY</MenuItem>
                    <MenuItem value="MARCH">MARCH</MenuItem>
                    <MenuItem value="APRIL">APRIL</MenuItem>
                    <MenuItem value="MAY">MAY</MenuItem>
                    <MenuItem value="JUNE">JUNE</MenuItem>
                    <MenuItem value="JULY">JULY</MenuItem>
                    <MenuItem value="AUGUST">AUGUST</MenuItem>
                    <MenuItem value="SEPTEMBER">SEPTEMBER</MenuItem>
                    <MenuItem value="OCTOBER">OCTOBER</MenuItem>
                    <MenuItem value="NOVEMBER">NOVEMBER</MenuItem>
                    <MenuItem value="DECEMBER">DECEMBER</MenuItem>
                  </Select>
                </FormControl>
                {/* <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Center
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    // value={payoutDtls.Center}
                    value={payoutDtls.Center}
                    // value={c}
                    name="Center"
                    onChange={handleChange}
                    label="Select Center"
                  >
                    {centerName.length > 0 &&
                      centerName.map((ele) => {
                        return <MenuItem value={ele.code}>{ele.name}</MenuItem>;
                      })}
                  </Select>
                </FormControl> */}
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Center
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={payoutDtls.Center}
                    name="Center"
                    onChange={handleChange}
                    label="Select Center"
                  >
                    {centerName.length > 0 &&
                      centerName.map((ele) => {
                        return <MenuItem value={ele.code}>{ele.name}</MenuItem>;
                      })}
                    {/* {user.user.id == 763 ? (
                      <MenuItem value="NORTH">NORTH</MenuItem>
                    ) : user.user.id == 400 ? (
                      <MenuItem value="SOUTH">SOUTH</MenuItem>
                    ) : user.user.id == 402 ? (
                      <MenuItem value="WEST">WEST</MenuItem>
                    ) : (
                      ""
                    )} */}
                  </Select>
                </FormControl>
                {/* <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Region
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={payoutDtls.region}
                    name="region"
                    // disabled
                    onChange={handleChange}
                    label="Select Region"
                  >
                    <MenuItem value="NORTH">NORTH</MenuItem>
                    <MenuItem value="SOUTH">SOUTH</MenuItem>
                    <MenuItem value="WEST">WEST</MenuItem>
                  </Select>
                </FormControl> */}
                <label htmlFor="fileUpload">
                  <Button
                    style={{ backgroundColor: "#820e37" }}
                    variant="contained"
                    color="primary"
                    component="span"
                    endIcon={<CloudUploadIcon />}
                    fullWidth
                  >
                    Upload FIle
                  </Button>
                </label>
                <input
                  accept=".xlsx,.csv,.docx,.xls,.doc"
                  id="fileUpload"
                  type="file"
                  style={{ display: "none" }}
                  onChange={onChangeFIle}
                />
                {vals === "false" ? (<span style={{ marginLeft: "18px", color: "#7e0b33" }}>
                  {filename}
                </span>) : ""}
                {payoutDtls.Center != "" &&
                  payoutDtls.Month != "" &&
                  payoutDtls.Year != "" &&
                  file && filename != "" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    fullWidth
                    onClick={uplodipFIle}
                    style={{ backgroundColor: "#820e37" }}
                  >
                    Submit
                  </Button>)
                  : <Button
                    disabled={true}
                    variant="contained"
                    color="primary"
                    component="span"
                    fullWidth
                    onClick={uplodipFIle}
                    style={{ backgroundColor: "gray" }}
                  >
                    Submit
                  </Button>
                }
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                xl={6}
                md={6}
                lg={6}
              // style={{ marginTop: "10px" }}
              >
                {" "}
                <List className={classes.root2}>
                  {loadingPayout ? (
                    <>
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}{" "}
                    </>
                  ) : (
                    iprows &&
                    iprows.map((ele) => (
                      <>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <WorkIcon />
                            </Avatar>
                          </ListItemAvatar>

                          <ListItemText
                            primary={ele.Year}
                            secondary={ele.Month + " - " + ele.Center}
                          />
                          <div style={{ marginBottom: "15px" }}>
                            <Tooltip
                              title="Proceed for Next Action"
                              aria-label="add"
                            >
                              <Link
                                to={{
                                  pathname: "/ippayout-process",
                                  state: { data: ele },
                                }}
                              >
                                <SkipNextIcon className={classes.iconStyle} />
                              </Link>
                            </Tooltip>
                            <Tooltip title="Download" aria-label="add">
                              <GetAppIcon
                                className={classes.iconStyle}
                                onClick={() => {
                                  handleDownloadFile(ele.File);
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Remove" aria-label="add">
                              <DeleteForeverIcon
                                className={classes.iconStyle}
                                onClick={() => {
                                  setConfirmDialog({
                                    isOpen: true,
                                    title:
                                      "Discarding Op-File may result in some features unresponsive ?",
                                    subtitle:
                                      "Are you sure you want to discard this record ?",
                                    onConfirm: () => {
                                      deleteIpFileData(ele);
                                      setConfirmDialog({ isOpen: false });
                                    },
                                  });
                                }}
                              />
                            </Tooltip>
                          </div>
                        </ListItem>
                        <Divider style={{ marginBottom: "18px" }} />
                      </>
                    ))
                  )}
                </List>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="3">
            <Typography variant="subtitle1">
              {" "}
              Upload OP-Discount Report
            </Typography>

            <Grid container spacing={2} style={{ marginTop: "30px" }}>
              <Grid item xs={12} sm={12} xl={6} md={6} lg={6}>
                {" "}
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Year
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={payoutDtls.Year}
                    name="Year"
                    onChange={handleChange}
                    label="Select Year"
                  >
                    <MenuItem value="2021">2021</MenuItem>
                    <MenuItem value="2022">2022</MenuItem>
                    <MenuItem value="2023">2023</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Month
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={payoutDtls.Month}
                    name="Month"
                    onChange={handleChange}
                    label="Select Year"
                  >
                    <MenuItem value="JANUARY">JANUARY</MenuItem>
                    <MenuItem value="FEBRUARY">FEBRUARY</MenuItem>
                    <MenuItem value="MARCH">MARCH</MenuItem>
                    <MenuItem value="APRIL">APRIL</MenuItem>
                    <MenuItem value="MAY">MAY</MenuItem>
                    <MenuItem value="JUNE">JUNE</MenuItem>
                    <MenuItem value="JULY">JULY</MenuItem>
                    <MenuItem value="AUGUST">AUGUST</MenuItem>
                    <MenuItem value="SEPTEMBER">SEPTEMBER</MenuItem>
                    <MenuItem value="OCTOBER">OCTOBER</MenuItem>
                    <MenuItem value="NOVEMBER">NOVEMBER</MenuItem>
                    <MenuItem value="DECEMBER">DECEMBER</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Center
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    // value={payoutDtls.Center}
                    value={payoutDtls.Center}
                    // value={c}
                    name="Center"
                    onChange={handleChange}
                    label="Select Center"
                  >
                    {centerName.length > 0 &&
                      centerName.map((ele) => {
                        return <MenuItem value={ele.code}>{ele.name}</MenuItem>;
                      })}
                  </Select>
                </FormControl>
                {/* <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Center
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={payoutDtls.Center}
                    // value={c}
                    name="Center"
                    onChange={handleChange}
                    label="Select Center"
                  >
                    {centerName.length > 0 &&
                      centerName.map((ele) => {
                        return <MenuItem value={ele.code}>{ele.name}</MenuItem>;
                      })}
                  </Select>
                </FormControl> */}
                {/* <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Region
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={payoutDtls.region}
                    name="region"
                    // disabled
                    onChange={handleChange}
                    label="Select Region"
                  >
                    <MenuItem value="NORTH">NORTH</MenuItem>
                    <MenuItem value="SOUTH">SOUTH</MenuItem>
                    <MenuItem value="WEST">WEST</MenuItem>
                  </Select>
                </FormControl> */}
                <label htmlFor="fileUpload">
                  <Button
                    style={{ backgroundColor: "#820e37" }}
                    variant="contained"
                    color="primary"
                    component="span"
                    endIcon={<CloudUploadIcon />}
                    fullWidth
                  >
                    Upload FIle
                  </Button>
                </label>
                <input
                  accept=".xlsx,.csv,.docx,.xls,.doc"
                  id="fileUpload"
                  type="file"
                  style={{ display: "none" }}
                  onChange={onChangeFIle}
                />
                {vals === "false" ? (<input
                  type="text"
                  style={{ margin: "5px" }}
                  value={filename}
                />) : ""}
                {payoutDtls.Center != "" &&
                  payoutDtls.Month != "" &&
                  payoutDtls.Year != "" &&
                  file && filename != "" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    fullWidth
                    onClick={uplodopDiscountFIle}
                    style={{ backgroundColor: "#820e37" }}
                  >
                    Submit
                  </Button>)
                  : <Button
                    disabled={true}
                    variant="contained"
                    color="primary"
                    component="span"
                    fullWidth
                    onClick={uplodopDiscountFIle}
                    style={{ backgroundColor: "gray" }}
                  >
                    Submit
                  </Button>
                }
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                xl={6}
                md={6}
                lg={6}
              // style={{ marginTop: "10px" }}
              >
                {" "}
                <List className={classes.root2}>
                  {loadingPayout ? (
                    <>
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}{" "}
                    </>
                  ) : (
                    opPayoutDiscount &&
                    opPayoutDiscount.map((ele) => (
                      <>
                        <Typography
                          style={{ marginBottom: "5px", textAlign: "center" }}
                        >
                          {ele.Center}
                        </Typography>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <WorkIcon />
                            </Avatar>
                          </ListItemAvatar>

                          <ListItemText
                            primary={ele.Year}
                            secondary={ele.Month}
                          />
                          <Tooltip
                            title="Proceed To Dump Data"
                            aria-label="add"
                          >
                            <SkipNextIcon
                              className={classes.iconStyle}
                              onClick={() => discDump(ele)}
                            />
                          </Tooltip>
                          <Tooltip title="Remove" aria-label="add">
                            <DeleteForeverIcon
                              className={classes.iconStyle}
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title:
                                    "Discarding OP-File may result in some features unresponsive ?",
                                  subtitle:
                                    "Are you sure you want to Discard this record ?",
                                  onConfirm: () => {
                                    deleteDiscFileData(ele);
                                    setConfirmDialog({ isOpen: false });
                                  },
                                });
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Download" aria-label="add">
                            <GetAppIcon
                              style={{ color: "#820e37", cursor: "pointer" }}
                              onClick={() => {
                                handleDownloadFile(ele.File);
                              }}
                            />
                          </Tooltip>
                        </ListItem>
                        <Divider />
                      </>
                    ))
                  )}
                </List>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="4">
            <Typography variant="subtitle1">
              {" "}
              Upload IP Discount Report
            </Typography>
            <Grid container spacing={2} style={{ marginTop: "30px" }}>
              <Grid item xs={12} sm={12} xl={6} md={6} lg={6}>
                {" "}
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Year
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={payoutDtls.Year}
                    name="Year"
                    onChange={handleChange}
                    label="Select Year"
                  >
                    <MenuItem value="2021">2021</MenuItem>
                    <MenuItem value="2022">2022</MenuItem>
                    <MenuItem value="2023">2023</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Month
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={payoutDtls.Month}
                    name="Month"
                    onChange={handleChange}
                    label="Select Year"
                  >
                    <MenuItem value="JANUARY">JANUARY</MenuItem>
                    <MenuItem value="FEBRUARY">FEBRUARY</MenuItem>
                    <MenuItem value="MARCH">MARCH</MenuItem>
                    <MenuItem value="APRIL">APRIL</MenuItem>
                    <MenuItem value="MAY">MAY</MenuItem>
                    <MenuItem value="JUNE">JUNE</MenuItem>
                    <MenuItem value="JULY">JULY</MenuItem>
                    <MenuItem value="AUGUST">AUGUST</MenuItem>
                    <MenuItem value="SEPTEMBER">SEPTEMBER</MenuItem>
                    <MenuItem value="OCTOBER">OCTOBER</MenuItem>
                    <MenuItem value="NOVEMBER">NOVEMBER</MenuItem>
                    <MenuItem value="DECEMBER">DECEMBER</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Center
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    // value={payoutDtls.Center}
                    value={payoutDtls.Center}
                    // value={c}
                    name="Center"
                    onChange={handleChange}
                    label="Select Center"
                  >
                    {centerName.length > 0 &&
                      centerName.map((ele) => {
                        return <MenuItem value={ele.code}>{ele.name}</MenuItem>;
                      })}
                  </Select>
                </FormControl>
                <label htmlFor="fileUpload">
                  <Button
                    style={{ backgroundColor: "#820e37" }}
                    variant="contained"
                    color="primary"
                    component="span"
                    endIcon={<CloudUploadIcon />}
                    fullWidth
                  >
                    Upload FIle
                  </Button>
                </label>
                <input
                  accept=".xlsx,.csv,.docx,.xls,.doc"
                  id="fileUpload"
                  type="file"
                  style={{ display: "none" }}
                  onChange={onChangeFIle}
                />
                {vals === "false" ? (<input
                  type="text"
                  style={{ margin: "5px" }}
                  value={filename}
                />) : ""}
                {payoutDtls.Center != "" &&
                  payoutDtls.Month != "" &&
                  payoutDtls.Year != "" &&
                  file && filename != "" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    fullWidth
                    onClick={uplodipDiscountFIle}
                    style={{ backgroundColor: "#820e37" }}
                  >
                    Submit
                  </Button>)
                  : <Button
                    disabled={true}
                    variant="contained"
                    color="primary"
                    component="span"
                    fullWidth
                    onClick={uplodipDiscountFIle}
                    style={{ backgroundColor: "gray" }}
                  >
                    Submit
                  </Button>}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                xl={6}
                md={6}
                lg={6}
              // style={{ marginTop: "10px" }}
              >
                {" "}
                <List className={classes.root2}>
                  {loadingPayout ? (
                    <>
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}{" "}
                    </>
                  ) : (
                    ipPayoutDiscount &&
                    ipPayoutDiscount.map((ele) => (
                      <>
                        <Typography
                          style={{ marginBottom: "5px", textAlign: "center" }}
                        >
                          {ele.Center}
                        </Typography>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <WorkIcon />
                            </Avatar>
                          </ListItemAvatar>

                          <ListItemText
                            primary={ele.Year}
                            secondary={ele.Month}
                          />
                          <Tooltip
                            title="Proceed for Next Action"
                            aria-label="add"
                          >
                            <Link
                              to={{
                                pathname: "/oppayout-process",
                                // state: { data: findpos },
                              }}
                            >
                              <SkipNextIcon
                                style={{
                                  color: "#820e37",
                                  marginRight: "50px",
                                  height: "30px",
                                  width: "30px",
                                  cursor: "pointer",
                                }}
                              />
                            </Link>
                          </Tooltip>
                          <Tooltip title="Download" aria-label="add">
                            <GetAppIcon
                              style={{ color: "#820e37", cursor: "pointer" }}
                              onClick={() => {
                                handleDownloadFile(ele.File);
                              }}
                            />
                          </Tooltip>
                        </ListItem>
                        <Divider />
                      </>
                    ))
                  )}
                </List>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="5">
            {" "}
            {/* <Typography variant="subtitle1"> Upload Ip-Item</Typography> */}
            <Grid container spacing={2} style={{ marginTop: "30px" }}>
              <Grid item xs={12} sm={12} xl={6} md={6} lg={6}>
                {" "}
                <Typography variant="subtitle1">
                  {" "}
                  Upload IP Bill-Item
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} xl={6} md={6} lg={6}>
                {" "}
                <Tooltip title="DOWNOAD SAMPLE FILE" aria-label="add">
                  <Button
                    color="secondary"
                    size="large"
                    variant="text"
                    style={{ float: "right" }}
                  >
                    <a
                      href={ipBillItemSampleFIle}
                      download="IP BILL-ITEM SAMPLE FILE"
                    >
                      Sample File
                    </a>
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: "30px" }}>
              <Grid item xs={12} sm={12} xl={6} md={6} lg={6}>
                {" "}
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Year
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={payoutDtls.Year}
                    name="Year"
                    onChange={handleChange}
                    label="Select Year"
                  >
                    <MenuItem value="2021">2021</MenuItem>
                    <MenuItem value="2022">2022</MenuItem>
                    <MenuItem value="2023">2023</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Month
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={payoutDtls.Month}
                    name="Month"
                    onChange={handleChange}
                    label="Select Year"
                  >
                    <MenuItem value="JANUARY">JANUARY</MenuItem>
                    <MenuItem value="FEBRUARY">FEBRUARY</MenuItem>
                    <MenuItem value="MARCH">MARCH</MenuItem>
                    <MenuItem value="APRIL">APRIL</MenuItem>
                    <MenuItem value="MAY">MAY</MenuItem>
                    <MenuItem value="JUNE">JUNE</MenuItem>
                    <MenuItem value="JULY">JULY</MenuItem>
                    <MenuItem value="AUGUST">AUGUST</MenuItem>
                    <MenuItem value="SEPTEMBER">SEPTEMBER</MenuItem>
                    <MenuItem value="OCTOBER">OCTOBER</MenuItem>
                    <MenuItem value="NOVEMBER">NOVEMBER</MenuItem>
                    <MenuItem value="DECEMBER">DECEMBER</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Center
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    // value={payoutDtls.Center}
                    value={payoutDtls.Center}
                    // value={c}
                    name="Center"
                    onChange={handleChange}
                    label="Select Center"
                  >
                    {centerName.length > 0 &&
                      centerName.map((ele) => {
                        return <MenuItem value={ele.code}>{ele.name}</MenuItem>;
                      })}
                    {/* {user.user.id == 763 ? (
                      <MenuItem value="NORTH">NORTH</MenuItem>
                    ) : user.user.id == 400 ? (
                      <MenuItem value="SOUTH">SOUTH</MenuItem>
                    ) : user.user.id == 402 ? (
                      <MenuItem value="WEST">WEST</MenuItem>
                    ) : (
                      ""
                    )} */}
                  </Select>
                </FormControl>
                {/* <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Region
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={payoutDtls.region}
                    name="region"
                    // disabled
                    onChange={handleChange}
                    label="Select Region"
                  >
                    <MenuItem value="NORTH">NORTH</MenuItem>
                    <MenuItem value="SOUTH">SOUTH</MenuItem>
                    <MenuItem value="WEST">WEST</MenuItem>
                  </Select>
                </FormControl> */}
                <label htmlFor="fileUpload">
                  <Button
                    style={{ backgroundColor: "#820e37" }}
                    variant="contained"
                    color="primary"
                    component="span"
                    endIcon={<CloudUploadIcon />}
                    fullWidth
                  >
                    Upload FIle
                  </Button>
                </label>
                <input
                  accept=".xlsx,.csv,.docx,.xls,.doc"
                  id="fileUpload"
                  type="file"
                  style={{ display: "none" }}
                  onChange={onChangeFIle}
                />
                {vals === "false" ? (<input
                  type="text"
                  style={{ margin: "5px" }}
                  value={filename}
                />) : ""}
                {payoutDtls.Center != "" &&
                  // payoutDtls.Month != "" &&
                  // payoutDtls.Year != "" &&
                  // file ? (
                  payoutDtls.Month != "" &&
                  payoutDtls.Year != "" &&
                  file && filename != "" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    fullWidth
                    onClick={uploadBillItemRecord}
                    style={{ backgroundColor: "#820e37" }}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    disabled={true}
                    variant="contained"
                    color="primary"
                    component="span"
                    fullWidth
                    onClick={uploadBillItemRecord}
                    style={{ backgroundColor: "gray" }}
                  >
                    Submit
                  </Button>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                xl={6}
                md={6}
                lg={6}
              // style={{ marginTop: "10px" }}
              >
                {" "}
                <List className={classes.root2}>
                  {loadingPayout ? (
                    <>
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}{" "}
                    </>
                  ) : (
                    billitemrows &&
                    billitemrows.map((ele) => (
                      <>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <WorkIcon />
                            </Avatar>
                          </ListItemAvatar>

                          <ListItemText
                            primary={ele.Year}
                            secondary={ele.Month + " - " + ele.Center}
                          />
                          <div style={{ marginBottom: "15px" }}>
                            {/* <Tooltip
                              title="Proceed for Next Action"
                              aria-label="add"
                            >
                              <Link
                                to={{
                                  pathname: "/oppayout-process",
                                  state: { data: ele },
                                }}
                              >
                                <SkipNextIcon className={classes.iconStyle} />
                              </Link>
                            </Tooltip> */}
                            <Tooltip title="Download" aria-label="add">
                              <GetAppIcon
                                className={classes.iconStyle}
                                onClick={() => {
                                  handleDownloadFile(ele.File);
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Remove" aria-label="add">
                              <DeleteForeverIcon
                                className={classes.iconStyle}
                                onClick={() => {
                                  setConfirmDialog({
                                    isOpen: true,
                                    title:
                                      "Discarding OP-File may result in some features unresponsive ?",
                                    subtitle:
                                      "Are you sure you want to Discard this record ?",
                                    onConfirm: () => {
                                      deleteipBillitem(ele);
                                      setConfirmDialog({ isOpen: false });
                                    },
                                  });
                                }}
                              />
                            </Tooltip>
                          </div>
                        </ListItem>
                        <Divider style={{ marginBottom: "18px" }} />
                      </>
                    ))
                  )}
                </List>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="6">
            {" "}
            <Typography variant="subtitle1"> Upload Baby-Receiving</Typography>
            <Grid container spacing={2} style={{ marginTop: "30px" }}>
              <Grid item xs={12} sm={12} xl={6} md={6} lg={6}>
                {" "}
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Year
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={payoutDtls.Year}
                    name="Year"
                    onChange={handleChange}
                    label="Select Year"
                  >
                    <MenuItem value="2021">2021</MenuItem>
                    <MenuItem value="2022">2022</MenuItem>
                    <MenuItem value="2023">2023</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Month
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={payoutDtls.Month}
                    name="Month"
                    onChange={handleChange}
                    label="Select Year"
                  >
                    <MenuItem value="JANUARY">JANUARY</MenuItem>
                    <MenuItem value="FEBRUARY">FEBRUARY</MenuItem>
                    <MenuItem value="MARCH">MARCH</MenuItem>
                    <MenuItem value="APRIL">APRIL</MenuItem>
                    <MenuItem value="MAY">MAY</MenuItem>
                    <MenuItem value="JUNE">JUNE</MenuItem>
                    <MenuItem value="JULY">JULY</MenuItem>
                    <MenuItem value="AUGUST">AUGUST</MenuItem>
                    <MenuItem value="SEPTEMBER">SEPTEMBER</MenuItem>
                    <MenuItem value="OCTOBER">OCTOBER</MenuItem>
                    <MenuItem value="NOVEMBER">NOVEMBER</MenuItem>
                    <MenuItem value="DECEMBER">DECEMBER</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Center
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    // value={payoutDtls.Center}
                    value={payoutDtls.Center}
                    // value={c}
                    name="Center"
                    onChange={handleChange}
                    label="Select Center"
                  >
                    {centerName.length > 0 &&
                      centerName.map((ele) => {
                        return <MenuItem value={ele.code}>{ele.name}</MenuItem>;
                      })}
                  </Select>
                </FormControl>
                {/* <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Region
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={payoutDtls.region}
                    name="region"
                    // disabled
                    onChange={handleChange}
                    label="Select Region"
                  >
                    <MenuItem value="NORTH">NORTH</MenuItem>
                    <MenuItem value="SOUTH">SOUTH</MenuItem>
                    <MenuItem value="WEST">WEST</MenuItem>
                  </Select>
                </FormControl> */}
                <label htmlFor="fileUpload">
                  <Button
                    style={{ backgroundColor: "#820e37" }}
                    variant="contained"
                    color="primary"
                    component="span"
                    endIcon={<CloudUploadIcon />}
                    fullWidth
                  >
                    Upload FIle
                  </Button>
                </label>
                <input
                  accept=".xlsx,.csv,.docx,.xls,.doc"
                  id="fileUpload"
                  type="file"
                  style={{ display: "none" }}
                  onChange={onChangeFIle}
                />
                {vals === "false" ? (<input
                  type="text"
                  style={{ margin: "5px" }}
                  value={filename}
                />) : ""}
                {payoutDtls.Center != "" &&
                  // payoutDtls.Month != "" &&
                  // payoutDtls.Year != "" &&
                  // file ? (
                  payoutDtls.Month != "" &&
                  payoutDtls.Year != "" &&
                  file && filename != "" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    fullWidth
                    onClick={uplodBabyRcvFIle}
                    style={{ backgroundColor: "#820e37" }}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    disabled={true}
                    variant="contained"
                    color="primary"
                    component="span"
                    fullWidth
                    onClick={uplodBabyRcvFIle}
                    style={{ backgroundColor: "gray" }}
                  >
                    Submit
                  </Button>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                xl={6}
                md={6}
                lg={6}
              // style={{ marginTop: "10px" }}
              >
                {" "}
                <List className={classes.root2}>
                  {loadingPayout ? (
                    <>
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}{" "}
                    </>
                  ) : (
                    iprows &&
                    iprows.map((ele) => (
                      <>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <WorkIcon />
                            </Avatar>
                          </ListItemAvatar>

                          <ListItemText
                            primary={ele.Year}
                            secondary={ele.Month}
                          />
                          <div style={{ marginBottom: "15px" }}>
                            <Tooltip
                              title="Proceed for Next Action"
                              aria-label="add"
                            >
                              <Link
                                to={{
                                  pathname: "/oppayout-process",
                                  state: { data: ele },
                                }}
                              >
                                <SkipNextIcon className={classes.iconStyle} />
                              </Link>
                            </Tooltip>
                            <Tooltip title="Download" aria-label="add">
                              <GetAppIcon
                                className={classes.iconStyle}
                                onClick={() => {
                                  handleDownloadFile(ele.File);
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Remove" aria-label="add">
                              <DeleteForeverIcon
                                className={classes.iconStyle}
                                onClick={() => {
                                  setConfirmDialog({
                                    isOpen: true,
                                    title:
                                      "Discarding OP-File may result in some features unresponsive ?",
                                    subtitle:
                                      "Are you sure you want to Discard this record ?",
                                    onConfirm: () => {
                                      deleteBabyrcvFileData(ele);
                                      setConfirmDialog({ isOpen: false });
                                    },
                                  });
                                }}
                              />
                            </Tooltip>
                          </div>
                        </ListItem>
                        <Divider style={{ marginBottom: "18px" }} />
                      </>
                    ))
                  )}
                </List>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="7">
            <Typography>IP-Payterm</Typography>
            <Grid container spacing={2} style={{ marginTop: "30px" }}>
              <Grid item xs={12} sm={12} xl={6} md={6} lg={6}>
                {" "}
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Year
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={payoutDtls.Year}
                    name="Year"
                    onChange={handleChange}
                    label="Select Year"
                  >
                    <MenuItem value="2021">2021</MenuItem>
                    <MenuItem value="2022">2022</MenuItem>
                    <MenuItem value="2023">2023</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Month
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={payoutDtls.Month}
                    name="Month"
                    onChange={handleChange}
                    label="Select Year"
                  >
                    <MenuItem value="JANUARY">JANUARY</MenuItem>
                    <MenuItem value="FEBRUARY">FEBRUARY</MenuItem>
                    <MenuItem value="MARCH">MARCH</MenuItem>
                    <MenuItem value="APRIL">APRIL</MenuItem>
                    <MenuItem value="MAY">MAY</MenuItem>
                    <MenuItem value="JUNE">JUNE</MenuItem>
                    <MenuItem value="JULY">JULY</MenuItem>
                    <MenuItem value="AUGUST">AUGUST</MenuItem>
                    <MenuItem value="SEPTEMBER">SEPTEMBER</MenuItem>
                    <MenuItem value="OCTOBER">OCTOBER</MenuItem>
                    <MenuItem value="NOVEMBER">NOVEMBER</MenuItem>
                    <MenuItem value="DECEMBER">DECEMBER</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Center
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    // value={payoutDtls.Center}
                    value={payoutDtls.Center}
                    // value={c}
                    name="Center"
                    onChange={handleChange}
                    label="Select Center"
                  >
                    {centerName.length > 0 &&
                      centerName.map((ele) => {
                        return <MenuItem value={ele.code}>{ele.name}</MenuItem>;
                      })}
                  </Select>
                </FormControl>
                {/* <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Region
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={payoutDtls.region}
                    name="region"
                    // disabled
                    onChange={handleChange}
                    label="Select Region"
                  >
                    <MenuItem value="NORTH">NORTH</MenuItem>
                    <MenuItem value="SOUTH">SOUTH</MenuItem>
                    <MenuItem value="WEST">WEST</MenuItem>
                  </Select>
                </FormControl> */}
                <label htmlFor="fileUpload">
                  <Button
                    style={{ backgroundColor: "#820e37" }}
                    variant="contained"
                    color="primary"
                    component="span"
                    endIcon={<CloudUploadIcon />}
                    fullWidth
                  >
                    Upload FIle
                  </Button>
                </label>
                <input
                  accept=".xlsx,.csv,.docx,.xls,.doc"
                  id="fileUpload"
                  type="file"
                  style={{ display: "none" }}
                  onChange={onChangeFIle}
                />
                {vals === "false" ? (<input
                  type="text"
                  style={{ margin: "5px" }}
                  value={filename}
                />) : ""}
                {
                  // payoutDtls.Center != "" &&
                  // payoutDtls.Month != "" &&
                  // payoutDtls.Year != "" &&
                  // file ? (
                  payoutDtls.Month != "" &&
                    payoutDtls.Year != "" &&
                    file && filename != "" ? (
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      fullWidth
                      onClick={upLoadPayterm}
                      style={{ backgroundColor: "#820e37" }}
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      disabled={true}
                      variant="contained"
                      color="primary"
                      component="span"
                      fullWidth
                      onClick={upLoadPayterm}
                      style={{ backgroundColor: "gray" }}
                    >
                      Submit
                    </Button>
                  )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                xl={6}
                md={6}
                lg={6}
              // style={{ marginTop: "10px" }}
              >
                {" "}
                <List className={classes.root2}>
                  {loadingPayout ? (
                    <>
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}{" "}
                    </>
                  ) : (
                    ipPaytermRows &&
                    ipPaytermRows.map((ele) => (
                      <>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <WorkIcon />
                            </Avatar>
                          </ListItemAvatar>

                          <ListItemText
                            primary={ele.Year}
                            secondary={ele.Month}
                          />
                          <div style={{ marginBottom: "15px" }}>
                            <Tooltip
                              title="Proceed for Next Action"
                              aria-label="add"
                            >
                              <Link
                                to={{
                                  pathname: "/oppayout-process",
                                  state: { data: ele },
                                }}
                              >
                                <SkipNextIcon className={classes.iconStyle} />
                              </Link>
                            </Tooltip>
                            <Tooltip title="Download" aria-label="add">
                              <GetAppIcon
                                className={classes.iconStyle}
                                onClick={() => {
                                  handleDownloadFile(ele.File);
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Remove" aria-label="add">
                              <DeleteForeverIcon
                                className={classes.iconStyle}
                                onClick={() => {
                                  setConfirmDialog({
                                    isOpen: true,
                                    title:
                                      "Discarding OP-File may result in some features unresponsive ?",
                                    subtitle:
                                      "Are you sure you want to Discard this record ?",
                                    onConfirm: () => {
                                      deleteIpPaytermData(ele);
                                      setConfirmDialog({ isOpen: false });
                                    },
                                  });
                                }}
                              />
                            </Tooltip>
                          </div>
                        </ListItem>
                        <Divider style={{ marginBottom: "18px" }} />
                      </>
                    ))
                  )}
                </List>
              </Grid>
            </Grid>
          </TabPanel>
        </TabContext>
      </Container>
      <Backdrop
        className={backClasses.backdrop}
        open={backDropOpen}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Backdrop className={backClasses.backdrop} open={circularLoader}>
        <img src={c9gif} alt="loading..." />
      </Backdrop>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
