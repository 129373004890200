import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Navbar from "../../Components/Navbar";
import { APIURL } from "../../CommomMethods/Fetch";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
//ui component
import Sidebar from "../../Components/Sidebar";
import { toast } from "react-toastify";
import {
  addpositions,
  removeRequest,
  findpositions,
  dateFormatter
} from "../../actions/createPositionActions";

const myTableStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "20%",
    whiteSpace: "nowrap",
    marginTop: theme.spacing(3),
    "& td": {
      fontFamili: "Roboto !important",
    },
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflow: "auto",
    marginBottom: "50px",
  },
  customizedButton: {
    position: "absolute",
    left: "95%",
    top: "-9%",
    backgroundColor: "lightgray",
    color: "gray",
  },
}));
const styleSheet = makeStyles((theme) => ({
  iconButtonStyle: {
    float: "right",
  },
  marginforMaster: {
    marginTop: "30px",
  },
}));
export default function CenterWisePayoutDetails() {
  const tables = myTableStyle();
  const location = useLocation();
  console.log(location.state.data, "location");
  const [rows, setRows] = useState([]);
  const [reload, setReload] = useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;
  const [loading, setLoading] = useState(false);
  const classes = styleSheet();
  const [values, setValues] = useState([]);
  const [data, setData] = useState("Select the value");
  function selectedDropdownvalue(e) {
    setLoading(false);
    const { name, value } = e.target;
    setData(value);
    axios
      .get(APIURL() + "billings/MonthwiseCenter/" + value)
      .then((res) => res.data)
      .then((rows) => {
        var k = rows.data.filter((z) => z.center === location.state.data);
    // console.log(k, "center details bh");
        setValues(k);
        setLoading(true);
      })
      .catch((err)=>{
        toast.error(err.message);
      });
  }
  useEffect(() => {
    axios
      .get(APIURL() + "billings/findByCenterwise/" + location.state.data)
      .then((res) => res.data)
      .then((rows) => {
    // console.log(rows, "AllExcelData");
        setRows(rows);
      })
      .catch((err)=>{
        toast.error(err.message);
      });
  }, [reload]);
  console.log(rows, "123");
  return (
    <>
      <Sidebar />
      <CssBaseline />
      <Container style={{ margin: "10px 0px 7px 235px", width: "80%" }}>
        <Navbar />
        <Typography
          variant="subtitle1"
          style={{
            margin: ".4rem",
            textAlign: "center",
            padding: "7px",
            backgroundColor: "#810e36",
            color: "white",
          }}
        >
          Center Wise PayoutDetails
        </Typography>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel
            id="demo-simple-select-outlined-label"
            style={{ margin: "15px" }}
          >
            <b>Filter By-</b>
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={data}
            onChange={selectedDropdownvalue}
            label="select the value"
            fullWidth
          >
            <MenuItem value="Select the value" disabled>
              Select the value
            </MenuItem>
            <MenuItem value="Current Month">Current Month</MenuItem>
            <MenuItem value="Previous Month">Previous Month</MenuItem>
            <MenuItem value="Two Months Ago">Two Months Ago</MenuItem>
          </Select>
        </FormControl>
        <Grid container spacing={1} style={{ marginBottom: "40px" }}>
          <Paper className={tables.root}>
            <MaterialTable
              columns={[
                { title: "SlNo", field: "SlNo" },
                // { title: "MPI", field: "MPI" },
                { title: "Item Group Name", field: "ItemGroupName" },
                { title: "Item Name", field: "ItemName" },
                { title: "Bill No", field: "BillNo" },
                { title: "Bill Date", field: "BillDate", render: (row) => (<div>{dateFormatter(row.BillDate)}</div>) },
                { title: "Bill Time", field: "BillTime" },
                { title: "Specialisation", field: "Specialisation" },
                { title: "Center", field: "center" },
                { title: "Ordering Physician", field: "OrderingPhysician" },
                {
                  title: "Conducted By",
                  field: "ConductedBy",
                  filtering: false,
                  render: (row) => (
                    <div>
                      {row.ConductedBy !== "nan" ? row.ConductedBy : "NA"}
                    </div>
                  ),
                },
                {
                  title: "Conducted By Final",
                  field: "ConductedByFinal",
                  filtering: false,
                  render: (row) => (
                    <div>
                      {row.ConductedBy !== "nan"
                        ? row.ConductedBy
                        : row.OrderingPhysician}
                    </div>
                  ),
                },
                { title: "Doctor Payout", field: "calculatedValue" },
              ]}
              data={values}
              options={{
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
                exportAllData: true,
              }}
              title="Payout-Detail"
            />
          </Paper>
        </Grid>
      </Container>
    </>
  );
}
