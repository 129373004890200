import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Table, Toast } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import XLSX from 'xlsx'
import moment from "moment";
import ConfirmDialog from "../Components/ConfirmDialog";
import Navbar from "../Components/Navbar";
import SidebarRD from "../Components/Rd-sidebar";
import HrSidebar from "../Components/hrSidebar";
import Sidebar from "../Components/Sidebar";
import ClearAllIcon from '@mui/icons-material/ClearAll';
import axios from "axios";
import MaterialTable from "material-table";
import { listusers, userslistall } from "../actions/userActions";
import { documentlist } from "../actions/documentActions";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import AppBar from "@material-ui/core/AppBar";
import Slide from "@material-ui/core/Slide";
import { Backdrop } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Toolbar from "@material-ui/core/Toolbar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { FormControl } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { onboardState } from "../CommomMethods/States";
import { toast } from "react-toastify";
import { APIURL } from "../CommomMethods/Fetch";
import Agreement from "../Pages/Payout/NEW/Agreement";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import c9gif from '../SampleFile/cloudnine.gif';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "fixed",
    backgroundColor: "#810e36",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  buttonProgress: {
    // backgroundColor: "red",
    color: "white",
    position: "absolute",
    top: "50%",
    left: "20%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const backDropStyle = makeStyles((theme) => ({
  backdrop: {
    zIndex: 999999,
    color: "#fff",
  },
}));

function DoctorList(props) {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const backClasses = backDropStyle();
  const [remark, setRemark] = useState("");
  const [doctorid, setdoctorId] = useState(0);
  const [step, setStep] = useState(1);
  const [rows, setRows] = useState([]);
  const [id, setId] = useState("");
  const [rid, setrId] = useState("");
  const [orow, setOrow] = useState({});
  const [open, setOpen] = React.useState(false);
  const [openr, setOpenr] = React.useState(false);
  const [lists, setList] = useState([]);
  const [uploadData, setUploadData] = useState([]);
  const [center, setCenter] = useState("Select Center");
  const [oboardData, setOnboardData] = useState([]);
  const [usersall, setUsersall] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [openRemark, setOpenRemark] = React.useState(false);
  const [viewRemark, setViewRemark] = useState("");
  const [muiTableKey, setMuiTableKey] = React.useState(0);
  const [agreementrow, setAgreementRow] = useState({});
  const [categoty, setCategory] = useState("");
  const [docData, setDocData] = useState([]);
  const [viewAgreement, setViewAgreement] = useState(false);
  const [filterCenters, setFilterCenters] = useState([]);
  const [filterDatas, setfilterDatas] = useState([])
  const [backDropOpen, setBackDropOpen] = useState(false);
  const [docdetail, setdetails] = useState([])
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });

  // State for All the Agreement
  const [isAgreementModalOpen, setIsAgreementModalOpen] = useState(false);
  const [agreementList, setAgreementList] = useState('');

  let timoutId = null;

  const classes = useStyles();
  const handlemClickOpen = (id) => {
    dispatch(documentlist(id));
    setId(id);
    setOpen(true);
    axios.get(APIURL() + "onboard/" + id)
      .then((response) => {
    // console.log(response, "response");
        setdetails(response.data.doctorCommercial)
      })
  };
  const handlemClose = () => {
    setOpen(false);
  };

  const handlerClickOpen = (row) => {
    // setrId(id);
    setOrow(row);
    setOpenr(true);
  };

  useEffect(() => {
    // dispatch(listusers());
    // dispatch(userslistall());
    // dispatch(documentlist(usersall && usersall.data && usersall.data.id));
  }, [dispatch]);
  const handlerClickOpenDoc = (id) => {
    setrId(id);
    setOpenr(true);
    // const obj = {
    //   isVerified: 1,
    //   verifiedBy:users[0] && users[0].users_role_id  ,
    //   verificationRemark:remark
    // };
    // var k = window.confirm("Are you sure want to delete this record ?");
    // if (k) {
    //   axios.put(APIURL()+"documents/documents/" + id, obj).then(res => {
    //   })
    // }
  };
  function selectedCenter(e) {
    const { name, value } = e.target;
    setCenter(value);
  }
  const handlerClose = () => {
    setOpenr(false);
  };

  const handleSubmitReject = () => {
    const obj = {
      doctorId: orow.doctorId,
      name: orow.name,
      currentAction: onboardState.ch.state,
      currentActionBy: users[0] && users[0].users_role_id,
      nextAction: onboardState.hr.state,
      remark: remark,
      status: 1,
    };

    axios.post(APIURL() + "onboard/action/", obj).then((res) => {
      toast.success("Rectification successfully submitted");
    });
    window.location.reload();
  };
  const Remark = (e) => {
    setRemark(e.target.value);
  };
  const back = useRef(null);
  const next = useRef(null);
  const reviewAndSubmit = useRef(null);
  const handleClose = () => setShow(false);
  const handleShow = (doctorid) => {
    setShow(true);

    dispatch(documentlist(doctorid));
  };
  const listDoc = useSelector((state) => state.listDoc);
  const { list } = listDoc;

  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;

  const userList = useSelector((state) => state.userList);
  const { users } = userList;

  // const usersAll = useSelector((state) => state.usersAll);
  // const { usersall } = usersAll;

  useEffect(() => {
    // axios
    //   .get(APIURL() + "onboard/")
    //   .then((res) => {
    //     setOnboardData(res.data);
    //   })
    //   .catch((error) => {
    //   });
    axios
      .get(APIURL() + "centers/")
      .then((res) => res.data)
      .then((rows) => {
        var centerFilter = rows.reduce((a, v) => ({ ...a, [v.name]: v.name }), {})
        setFilterCenters(centerFilter);
      });
    axios.get(APIURL() + "designations/").then((res) => {
      var desFilters = res.data.data.reduce((a, v) => ({ ...a, [v.name]: v.name }), {})
      setfilterDatas(desFilters);
    });
  }, []);

  const getDoctorList = async () => {
    axios
      .get(APIURL() + "users/doctorlist/" + user.user.id)
      .then((res) => {
        setUsersall(res.data);
      })
      .catch((error) => {
      });
  }
  // useEffect(() => {
  //   dispatch(userslistall());
  // }, [dispatch]);
  function clickNext() {
    if (step == 1) {
      back.current.className = "standardButton rejectButton";
    } else if (step == 3) {
      next.current.className += " hide";
      back.current.className += " hide";
      reviewAndSubmit.current.className = "standardButton acceptButton";
    }
    setStep((s) => s + 1);
  }

  function clickBack() {
    if (step == 2) {
      back.current.className += " hide";
    }
    setStep((s) => s - 1);
  }
  function clickReviewAndSubmit() { }

  const formStyle = {
    borderRadius: "5px",
    border: "1px solid black",
  };
  const headerStyle = {
    backgroundColor: "#94248E",
    margins: "0px",
    padding: "10px",
    color: "white",
    borderRadius: "5px",
    borderBottom: "3px black solid",
  };
  const bodyStyle = {
    borderRadius: "5px",
  };
  const handleDownload = (url, filename) => {
    fetch(APIURL() + "documents/download/" + url, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
        // let a = document.createElement("a");
        // a.href = url;
        // a.download = filename;
        // a.click();
      });

      //window.location.href = response.url;
    });
  };
  console.log(usersall, "###########");
  // useEffect(() => {
  //   axios
  //     .get(APIURL() + "onboard/currentstatus/")
  //     .then((res) => res.data)
  //     .then((rows) => {
  //       // let k = rows.data.filter((z) => z.doctorcommercial != null);

  //       setRows(rows.data);
  //     });
  // }, []);
  // useEffect(() => {
  //   axios
  //     .get(APIURL() + "documents/list/" + user.user.id)
  //     .then((res) => res.data)
  //     .then((rows) => {
  //       setList(rows.data);
  //       var k = rows.data;
  //       var l = [];
  //       k.forEach((element) => {
  //         l.push(element.docTitle);
  //       });
  //       setUploadData(l);
  //     });
  // }, [refresh]);

  const handleApprove = (row) => {
    const updateData = {
      doctorId: row.doctorId,
      name: row.name,
      currentAction: onboardState.ch.state,
      currentActionBy: users[0] && users[0].users_role_id,
      nextAction: onboardState.rd.state,
      status: 1,
    };
    axios
      .post(APIURL() + "onboard/action/", updateData)
      .then((res) => {
        window.location.reload();
      });
  };

  function viewDocuments(id) {
    setdoctorId(id);
  }

  const handleApproveDoc = (row) => {
    const updateData = {
      isVerified: "APPROVED",
      verificationRemark: "2",
      verifiedBy: users[0] && users[0].users_role_id,
    };
    axios
      .put(APIURL() + "documents/documents/" + row.id,
        updateData
      )
      .then((res) => {
      });
    window.location.reload();
  };

  const handleRemarkOpen = (remark) => {
    setViewRemark(remark);
    setOpenRemark(true);
  };
  const handleRemarkOpenDoc = (remark) => {
    setViewRemark(remark);
    setOpenRemark(true);
  };
  const handleCloseRemark = () => {
    setOpenRemark(false);
  };
  // useEffect(() => {
  //   dispatch(listusers());
  // }, [dispatch]);
// Older donwload
  // const viewSignedAgreementDoc = async (row) => {
  //   console.log(row, "view-agreement-ch");
  //   if (row.doctorAgreement) {
  //     await fetch(APIURL() + "documents/download/" + row.doctorAgreement, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/pdf",
  //       },
  //     }).then((response) => {
  //       response.blob().then((blob) => {
  //         let url = window.URL.createObjectURL(blob);
  //         let a = document.createElement("a");
  //         a.href = url;
  //         a.download = row.name + "_Agreement.pdf";
  //         a.click();
  //       });
  //     });
  //   } else if(row.digio_doc_id) {
  //     // setAgreementRow(row);
  //     await axios.get(APIURL() + "onboard/download-agreement/" + row.digio_doc_id, {
  //       responseType: 'blob',
  //       headers: {
  //         Accept: 'application/pdf',
  //       },
  //     }).then((res) => {
  //   // console.log(res)
  //       const url = window.URL.createObjectURL(new Blob([res.data]));
  //       const link = document.createElement("a");
  //       link.target = "_blank";
  //       link.download = row.name + "_Agreement.pdf"
  //       link.href = url;
  //       // link.setAttribute('download', 'Doctor Agreement.pdf'); //or any other extension
  //       document.body.appendChild(link);
  //       link.click();
  //     })
  //   }
  // };

  const viewSignedAgreementDoc = async (id, name, doctorAgreement = false) => {
    if (doctorAgreement) {
      await fetch(APIURL() + "documents/download/" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
        },
      }).then((response) => {
    // console.log(response.status);
        if(response.status === 500){
          toast.error("Something went wrong. Please try again!");
        }
        else{
          response.blob().then((blob) => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = name + "_Agreement.pdf";
            a.click();
          });
        }
      });
    }else {
      // setAgreementRow(row);
      await axios.get(APIURL() + "onboard/download-agreement/" + id, {
        responseType: 'blob',
        headers: {
          Accept: 'application/pdf',
        },
      }).then((res) => {
    // console.log(res)
        if(res.status === 200){
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.target = "_blank";
        link.download = name + "_Agreement.pdf"
        link.href = url;
        // link.setAttribute('download', 'Doctor Agreement.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
        }
        else{
          toast.error(res.statusText);
        }
      }).catch((err)=>{
    // console.log("err"+err);
      })
    }
  };

  const viewAgreementDoc = (row) => {
// console.log(row, "view-agreement-ch");
    setAgreementRow(row);
    axios
      .get(APIURL() + "onboard/agreementView/" + row.id)
      .then((res) => {
        if (res.data != null) {
      // console.log(res.data.data, "category-view");
          setCategory(res.data.data.vacancyData[0].category);
          setDocData(res.data.data);
        }
      });
    setViewAgreement(true);
  };

  const closeAgreement = () => {
    setViewAgreement(false);
  };

  const downloadExcel = async () => {
    var count = 1
    await axios
      .get(APIURL() + "users/doctorlist/" + user.user.id + "?per_page=All")
      .then((res) => {
        var users = res.data
        const newData = users.data.map(row => {
          var status = 'Request Initiated'
          var newRow = {
            'SI. No': count,
            'Name of Doctor': row.name,
            'D365 ID': row.d365details,
            'Designation': row.designationLabel,
            'Type': row.category,
            'Department': row.departmentLabel,
            'DOB': row.birthday ? moment(moment(row.birthday)).format("DD-MM-YYYY") : "---",
            'Joining Date': row.doj ? moment(moment(row.doj)).format("DD-MM-YYYY") : "---",
            'Commencement Date': row.cdoj ? moment(moment(row.cdoj)).format("DD-MM-YYYY") : "---",
            'Contract Execution Date': row.stampdate ? moment(moment(row.stampdate)).format("DD-MM-YYYY") : "---",
            'Term in Months': row.contractterm,
            'Next Renewal': row.doj ? (moment(moment(row.doj.substring(0, 10)).add(row.contractterm, 'M').calendar()).format("DD-MM-YYYY")) : "---",
            'Center': row.centerName,
            'Contact No': row.mobile,
            'Personal Email ID': row.email,
            'LT Email ID': row.lt_email_id,
            'PAN No.': row.panno,
            'State of Registration': row.mcrstate,
            'Medical Council Certificate Number': row.mcrstate ? (row.mcrstate.substring(0, 3) + "- " + row.mcrcval) : row.mcrcval,
            'Medical Council Expiry Date': row.mcrdate ? moment(moment(row.mcrdate)).format("DD-MM-YYYY") : "---",
            'Indemnity Amount': row.indemnityvalue,
            'Valid Till': row.indemnityexpiry ? moment(moment(row.indemnityexpiry)).format("DD-MM-YYYY") : "---",
            'Qualification': row.medicaldegree + ", " + row.pgdegree,
            'Current Address': row.Address,
            'Current State': row.State,
            'Cureent City': row.City,
            'Current Pin Code': row.PinCode,
            'Permanent Address': row.PermanentAddress,
            'Permanent State': row.PermanentState,
            'Permanent City': row.PermanentCity,
            'Permanent Pin Code': row.PermanentPinCode,
            'Gender': row.Gender,
            'Post PG Degree Total Experience': row.pgrelevantexp ? row.pgrelevantexp + " months" : "---",
            'Post PG Degree Relevant Experience': row.pgtotalexp ? row.pgtotalexp + " months" : "---",
            'Aadhar Card Number': row.adhaarno,
            'Bank Name': row.bankname,
            'IFSC Code': row.bankifsc,
            'Account Number': row.bankaccno,
            'Requisition Number': row.requisition_id ? ("REQ-" + row.requisition_id) : "",
            'Onboarding Status': row.currentStatus,
            'Manual Agreement': row.manualAgreement == 1 ? "Yes" : "No",
            'User Status': row.userStatus,
          }
          delete row.tableData
          count++
          return newRow
        })
    // console.log(newData, 'excelData')
        const workSheet = XLSX.utils.json_to_sheet(newData)
        const workBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook, workSheet, "doctors")
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" })
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
        //Download
        XLSX.writeFile(workBook, "DoctorsList.xlsx")
      })
      .catch((error) => {
      });
  }

  const downloadCommecialsExcel = async () => {
    setBackDropOpen(true)
    await axios
      .get(APIURL() + "users/doctor-commercials/" + user.user.id)
      .then(async (result) => {
    // console.log(result, 'commercials data')
        var ipcommercials = await result.data.ipcommercialsResult
        var opcommercials = await result.data.opcommercialsResult
        var count = 1
        const IPData = await ipcommercials.map(row => {
          var ratio = parseFloat(row.ratio) > 1 ? (parseFloat(row.ratio) / 100) : parseFloat(row.ratio)
          var fixedAmount = ""
          ratio = isNaN(ratio) ? 0 : ratio
          var ipmodeofpayment = row.ipmodeofpayment ? row.ipmodeofpayment : "Credit ,Cash"
          var gipsa = row.gipsa ? row.gipsa : "Yes, No"
          if (parseFloat(row.fixedAmount) > 0) {
            fixedAmount = row.fixedAmount
            ratio = ""
          }
          var newRow = {
            'SI. No': count,
            'Doctor Type': row.natureofdoc,
            'Name of Doctor': row.name,
            'Email': row.email,
            'Item Group': row.itemGroup,
            'Item Name': row.itemName,
            'Group': row.group,
            'Room Category': row.roomCategory,
            'Ratio': ratio,
            'Fixed Amount': fixedAmount,
            'Mode of Payment': ipmodeofpayment,
            'Applicable On': row.applicableOn,
            'Commercial Term': row.commercialTerm,
            'Center': row.Center,
            'Gipsa': gipsa,
          }
          delete row.tableData
          count++
          return newRow
        })
        var opcount = 1
        const OPData = await opcommercials.map(row => {
          var fixedpercentage = parseFloat(row.fixedpercentage) > 0 && parseFloat(row.fixedpercentage) <= 1 ? (parseFloat(row.fixedpercentage) * 100) : parseFloat(row.fixedpercentage)
          var ratio = parseFloat(row.fixedpercentage) > 1 ? (parseFloat(row.fixedpercentage) / 100) : parseFloat(row.fixedpercentage)
          var applicable = row.applicable ? row.applicable : "Net"
          fixedpercentage = isNaN(fixedpercentage) ? 0 : fixedpercentage
          ratio = isNaN(ratio) ? 0 : ratio
          var commercialTerm = fixedpercentage + " % of the " + applicable + " Amount"
          var fixedAmount = ""
          if (parseFloat(row.fixedAmount) > 0) {
            commercialTerm = row.fixedAmount + " /- Per case"
            applicable = "Fixed"
            fixedAmount = row.fixedAmount
            ratio = ""
          }
          var newRow = {
            'SI. No': opcount,
            'Doctor Type': row.category,
            'Name of Doctor': row.name,
            'Email': row.email,
            'Item Group': row.itemGroup,
            'Item Name': row.itemName,
            'Ratio': ratio,
            'Fixed Amount': fixedAmount,
            'Applicable On': applicable,
            'Commercial Term': commercialTerm,
            'Center': row.Center,
          }
          delete row.tableData
          opcount++
          return newRow
        })
        // console.log(newData, 'excelData')
        const workSheet = await XLSX.utils.json_to_sheet(IPData)
        const opworkSheet = await XLSX.utils.json_to_sheet(OPData)
        const workBook = await XLSX.utils.book_new()
        await XLSX.utils.book_append_sheet(workBook, workSheet, "IP-Commercials")
        await XLSX.utils.book_append_sheet(workBook, opworkSheet, "OP-Commercials")
        //Buffer
        let buf = await XLSX.write(workBook, { bookType: "xlsx", type: "buffer" })
        //Binary string
        await XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
        //Download
        await XLSX.writeFile(workBook, "DoctorCommercials.xlsx")
        setBackDropOpen(false)
      }).catch((error) => {
    // console.log(error, 'error in getting commercials')
        setBackDropOpen(false)
        toast.error("Something went wrong. Please try again!");
      })
  }

  const getAllAgreement = async (row) => {
    setIsAgreementModalOpen(true);
    setAgreementList("");
    let new_list = [];
// console.log(row);
    if(row.doctorAgreement){
      new_list.push({
        type: 'manual',
        digio_doc_id: row.doctorAgreement,
        digio_doc_file_name: row.name,
        digio_doc_date: row.doj
      })
    }
    // else if(row.digio_doc_id){
    //   new_list.push({
    //     digio_doc_id: row.digio_doc_id,
    //     digio_doc_file_name: row.name,
    //     digio_doc_date: row.doj
    //   })
    // }
    axios.get(APIURL() + "onboard/" + row.id)
    .then((response) => {
        if(response.data?.activityData.length>0){
          response.data.activityData.map((data)=>{
            let index = new_list.findIndex((o)=>o.digio_doc_id === data.digio_doc_id);
            if(index>=0){
              new_list.splice(index);
            }
          })
          new_list = new_list.concat(response.data.activityData);
        }
        setAgreementList(new_list);
      })
  }
  console.log(user, 'useruser')

  return (
    <>
      <Sidebar />

      <div className="main-content side-content pt-0 mg-b-30">
        <div className="container-fluid">
          <div className="inner-body">
            <Navbar />

            <form>
              <br />
              <Card style={formStyle}>
                <Card.Body style={bodyStyle}>
                  <Grid>
                    <Grid item xs={12} sm={12} xl={3} lg={3} md={3}>
                      {/* <FormControl variant="outlined" style={{ width: "100%" }}>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={center}
                          onChange={selectedCenter}
                          label=" Filter By Center"
                          fullWidth >
                          <MenuItem value="Select the Center">
                            Filter By Center
                          </MenuItem>
                          <MenuItem value="NORTH">NORTH</MenuItem>
                          <MenuItem value="SOUTH">SOUTH</MenuItem>
                          <MenuItem value="WEST">WEST</MenuItem>
                        </Select>
                      </FormControl> */}
                    </Grid>
                  </Grid>

                  <MaterialTable
                    columns={[
                      {
                        cellStyle: {
                          textAlign: 'center',
                        },
                        title: "Profile Picture",
                        field: "Action",
                        filtering: false,
                        render: (row) => (
                          <>
                            {(row.profile_picture === null || row.profile_picture === "") ? (
                              <>
                                {/* <img
                                  class="img-responsive"
                                  src={
                                    "https://trulabs.in/uploads/doctor_images/defualt-doc-image.jpg"
                                  }
                                  style={{ width: "17%", height: "30%" }}
                                />{" "} */}
                                <i class="fa fa-user-circle fa-3x" aria-hidden="true" style={{color:'rgb(129, 14, 54)'}}></i>
                              </>
                            ) : (
                              <>
                                {" "}
                                <img
                                  class="img-responsive"
                                  src={
                                    APIURL() + "documents/download/" +
                                    row.profile_picture
                                  }
                                // style={{ width: "17%", height: "30%" }}
                                />
                              </>
                            )}
                          </>
                        ),
                      }, {
                        title: "View Documents",
                        field: "Action",
                        filtering: false,
                        hidden: user.user.activityAssigments.filter((elem) => elem.activityId === 21 || elem.activityId === 22).find(item => item.RoleId === user.user.roles) ? false : true,
                        // render: (row) => (
                        //   row.OnextAction === onboardState.ch.state ? (
                        //     <InsertDriveFileIcon
                        //       style={{ color: "#810e36" }}
                        //       aria-label="close"
                        //       onClick={() => {
                        //         handlemClickOpen(row.doctorId);
                        //       }}
                        //     >
                        //       <VisibilityIcon />
                        //     </InsertDriveFileIcon>
                        //   ) : (
                        //     <InsertDriveFileIcon
                        //       style={{ color: "#810e36" }}
                        //       aria-label="close"
                        //       onClick={() => {
                        //         handlemClickOpen(row.doctorId);
                        //       }}
                        //     >
                        //       <VisibilityIcon />
                        //     </InsertDriveFileIcon>
                        //   )
                        // ),
                        render: (row) => (
                          <span class="fa-stack fa-lg mx-3">
                            <i class="fa fa-files-o fa-stack-1x" onClick={() => { handlemClickOpen(row.doctorId) }} style={{ color: "#810e36", cursor: "pointer" }}></i>
                          </span>
                     )
                      },
                      {
                        title: "Center", field: "centerName",
                        lookup: filterCenters,
                        cellStyle: {textAlign:'center'}
                      },
                      { title: "Doctor Type", field: "doctorType", cellStyle: {textAlign:'center'}},
                      { title: "Doctor Name", field: "name", cellStyle: {textAlign:'center'}},
                      { title: "Personal Email Id", field: "email", cellStyle: {textAlign:'center'}},
                      { title: "LT Email Id", field: "lt_email_id", cellStyle: {textAlign:'center'}},
                      { title: "D365 ID", field: "d365details", cellStyle: {textAlign:'center'}},
                      {
                        title: "User Status", field: "userStatus", cellStyle: {textAlign:'center'},
                        lookup: {
                          "Active": "Active",
                          "In Active": "In Active",
                          "Pending": "Pending",
                        }
                      },
                      {
                        title: "Onboarding Status", field: "currentStatus",
                        lookup: {
                          "Initiated": "Initiated",
                          "Saved as Draft": "Saved as Draft",
                          "To be review the commercials": "To be review the commercials",
                          "Send back to edit Doctor's Personal Information": "Send back to edit Doctor's Personal Information",
                          "Doctor info to be filled": "Doctor info to be filled",
                          "Documents to be uploaded": "Documents to be uploaded",
                          "To be verified by Corporate HR": "To be verified by Corporate HR",
                          "To be verified by COO": "To be verified by COO",
                          "Sent back to review the commercials and re-submit": "Sent back to review the commercials and re-submit",
                          "To be verified by BH": "To be verified by BH",
                          "Send back to resubmit doctors document": "Send back to resubmit doctors document",
                          "Renewal Initiated": "Renewal Initiated",
                          "Agreement to be signed by Doctor": "Agreement to be signed by Doctor",
                          "Agreement to be signed by CEO": "Agreement to be signed by CEO",
                          "Manual Signed Agreement to be upload": "Manual Signed Agreement to be upload",
                          "LT Id need to link": "LT Id need to link",
                          "Onboarded": "Onboarded",
                          "Sent for Discontinue": "Sent for Discontinue",
                          "Deactivated": "Deactivated",
                          "PENDING": "PENDING",
                        },
                        cellStyle: {textAlign:'center'}
                      },
                      {
                        title: "View Agreement",
                        field: "Action",
                        filtering: false,
                        hidden: user.user.activityAssigments.filter((elem) => elem.activityId === 23).find(item => item.RoleId === user.user.roles) ? false : true,
                        // render: (rows) => (
                        //   rows.OnextAction === onboardState.completed && user.user.roles !== 5 ?
                        //     <IconButton
                        //       style={{
                        //         color: "#810e36",
                        //         cursor: "pointer",
                        //       }}
                        //       aria-label="close"
                        //       onClick={() => {
                        //         viewAgreementDoc(rows);
                        //       }}
                        //     >
                        //       <VisibilityIcon />
                        //     </IconButton>
                        //     :
                        //     <IconButton
                        //       style={{
                        //         color: "#810e36",
                        //         cursor: "pointer",
                        //       }}
                        //       aria-label="close"
                        //       onClick={() => {
                        //         viewAgreementDoc(rows);
                        //       }}
                        //     >
                        //       <VisibilityIcon />
                        //     </IconButton>

                        // ),
                        render: (rows) => (
                             <span class="fa-stack fa-lg mx-3">
                               <i class="fa fa-file-pdf-o fa-stack-1x" onClick={() => { viewAgreementDoc(rows); }} style={{ color: "#810e36", cursor: "pointer" }}></i>
                             </span>
                        )
                      },
                      {
                        title: "Download Agreement",
                        field: "Action",
                        filtering: false,
                        hidden: user.user.activityAssigments.filter((elem) => elem.activityId === 26).find(item => item.RoleId === user.user.roles) ? false : true,
                        // render: (rows) => (
                        //   rows.OnextAction === onboardState.completed && user.user.roles !== 5 ?
                        //     <IconButton
                        //       style={{
                        //         color: "#810e36",
                        //         cursor: "pointer",
                        //       }}
                        //       aria-label="close"
                        //       onClick={() => {
                        //         viewSignedAgreementDoc(rows);
                        //       }}
                        //     >
                        //       <VisibilityIcon />
                        //     </IconButton>
                        //     :
                        //     <IconButton
                        //       style={{
                        //         cursor: "pointer",
                        //       }}
                        //       aria-label="close"
                        //       disabled
                        //     >
                        //       <VisibilityOffIcon disabled />
                        //     </IconButton>
                        // ),
                        render: (row) => (
                         user.user.roles !== 5 ?
                            <span class="fa-stack fa-lg">
                              <i class="fa fa-tasks fa-stack-1x" onClick={() => { getAllAgreement(row); }} style={{ color: "#810e36", cursor: "pointer" }}></i>
                            </span>
                            :
                            <span class="fa-stack fa-lg">
                              <i class="fa fa-file-pdf-o fa-stack-1x" style={{color:'gray'}}></i>
                              <i class="fa fa-ban fa-stack-2x" style={{color:'gray'}}></i>
                            </span>
                        ),
                      },
                      // { title: "Category", field: "category" },
                      // { title: "Designation", field: "designation" },
                      {
                        title: "Doctor Profile",
                        field: "Action",
                        filtering: false,
                        hidden: user.user.activityAssigments.filter((elem) => elem.activityId === 24).find(item => item.RoleId === user.user.roles) ? false : true,
                        render: (row) => (
                          <Link
                            to={{
                              // pathname: "/DoctorProfileBH",
                              pathname: "/user-profile",
                              state: { id: row.id },
                            }}
                          >
                            {" "}
                            <i className="fa fa-user-md fa-lg" style={{color:'rgb(129, 14, 54)'}}></i>{" "}
                          </Link>
                        ),
                      },
                    ]}
                    // data={
                    //   usersall &&
                    //   usersall.data &&
                    //   usersall.data.filter((z) => z.users_role_id === 4)
                    // }
                    data={query =>
                      new Promise((resolve, reject) => {
                        let url = APIURL() + "users/doctorlist/" + user.user.id + "?"
                        url += `per_page=${query.pageSize}`
                        url += `&page=${(query.page + 1)}`
                        url += `&search=${query.search}`
                        if (query.filters.length) {
                          query.filters.forEach(item => {
                            url += `&${item.column.field}=${item.value}`
                          })
                        }
                        if (query.orderBy) {
                          url += `&orderByField=${query.orderBy.field}`
                          url += `&orderBySort=${query.orderDirection}`
                        }
                        // console.log(query, tableRef, "activitylogs")
                        clearTimeout(timoutId);
                        timoutId = setTimeout(()=>{
                          axios.get(url)
                            .then(response => response.data)
                            .then(result => {
                              resolve({
                                data: result.data,
                                page: result.page - 1,
                                totalCount: result.total,
                              })
                            })
                        },2000)
                      })
                    }
                    actions={[
                      {
                        icon: () => <button type="button">Export</button>,// you can pass icon too
                        tooltip: "Export to Excel",
                        onClick: () => downloadExcel(),
                        hidden: user.user.activityAssigments.filter((elem) => elem.activityId === 27).find(item => item.RoleId === user.user.roles) ? false : true,
                        isFreeAction: true
                      },
                      {
                        icon: () => <button type="button">Export Commercials</button>,// you can pass icon too
                        tooltip: "Export Commecials to Excel",
                        onClick: () => downloadCommecialsExcel(),
                        hidden: user.user.activityAssigments.filter((elem) => elem.activityId === 28).find(item => item.RoleId === user.user.roles) ? false : true,
                        isFreeAction: true
                      },
                      {
                        icon: () => <ClearAllIcon />,
                        tooltip: "Clear All Filters",
                        isFreeAction: true,
                        onClick: (event) => {
                          setMuiTableKey(muiTableKey + 1); // set new key causing remount
                        }
                      }
                    ]}
                    options={{
                      filtering: true,
                      sorting: true,
                      // exportButton: true,
                      pageSize: 5,
                      pageSizeOptions: [5, 10, 50, 100, 200, 500],
                      search: true,
                    }}
                    icons={{
                      Filter: "filter",
                    }}
                    title="Doctor Details"
                  />
                  {/* <Table style={{ width: "60.5%" }} borderless>
                    <thead>
                      <tr
                        style={{
                          textAlign: "center",
                          borderBottom: "1px solid rgb(200, 200, 200)",
                          backgroundColor: "hsla(340, 97%, 26%, 0.55)",
                          color: "white",
                        }}
                      >
                        <th className="col-2">Doctor Name</th>
                        <th>Doctor Profile</th>
                      </tr>
                    </thead>
                    <tbody style={{ whiteSpace: "nowrap" }}>
                      { usersall && usersall.data && usersall.data.filter(z => z.users_role_id === 4).map((ele) => (
                            <tr
                              id="color"
                              style={{
                                textAlign: "center",
                                borderBottom: "1px solid rgb(200, 200, 200)",
                              }}
                            >
                              <td>{ele.name}</td>
                             
                                 <td>
                                 <Link to={{
                                 pathname: '/DoctorProfileFinal',
                                 state: { id: ele.id }
                               }}> <i
                                 className="fas fa-eye"
                                 color="black"
                               ></i> </Link>
                                  </td> 
                            </tr>
                          ))}
                    </tbody>
                  </Table> */}
                </Card.Body>
              </Card>
            </form>
          </div>
        </div>
        <Modal
          size="lg"
          scrollable
          dialogClassName="modalStandard"
          centered
          aria-labelledby="example-modal-sizes-title-lg"
          show={show}
          onHide={handleClose}
        >
          <Modal.Header className="modalHeader" closeButton>
            <Modal.Title className="modalTitle">Requested Id</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* {addpos.data && addpos.data.map((ele,index) =>( */}
            <Table style={{ width: "100%" }} borderless>
              <thead>
                <tr
                  style={{
                    textAlign: "center",
                    borderBottom: "1px solid rgb(200, 200, 200)",
                  }}
                >
                  <th className="col-2">Document Name</th>

                  <th> Documents</th>
                </tr>
              </thead>
              <tbody>
                {list.data &&
                  list.data.map((ele) => (
                    <tr
                      onClick={() => handleShow(ele.id)}
                      id="color"
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid rgb(200, 200, 200)",
                      }}
                    >
                      {/* <td>{ele.id}.</td> */}
                      <td>{ele.docTitle}</td>
                      <td>
                        <i
                          className="fas fa-file"
                          onClick={() => handleDownload(ele.url, ele.docTitle)}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center"></Modal.Footer>
        </Modal>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlemClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlemClose}
          style={{ textAlign: "center" }}
        >
          Uploaded Documents
        </DialogTitle>
        <DialogContent dividers>
          <Card style={formStyle}>
            <Card.Header style={headerStyle}></Card.Header>
            <Card.Body
              style={bodyStyle}
            // style={{ overflowY: "auto", height: "300px" }}
            >
              <Table style={{ width: "100%" }} borderless>
                <thead>
                  <tr
                    style={{
                      textAlign: "center",
                      backgroundColor: "hsla(340, 97%, 26%, 0.55)",
                      color: "white",
                      borderBottom: "1px solid rgb(200, 200, 200)",
                    }}
                  >
                    <th className="col-2">Document Title</th>
                    <th className="col-2"> File</th>
                    {/* <th className="col-2"> Actions </th> */}
                  </tr>
                  {/* )} */}
                </thead>
                <tbody>
                  {list &&
                    list.data &&
                    list.data.filter((doc) => (user.user.activityAssigments.filter((elem) => elem.activityId === 22).find(item => item.RoleId === user.user.roles) && (doc.docTitle === 'PAN Card' || doc.docTitle === 'AADHAR Card' || doc.docTitle === 'Cancelled Cheque (Bank Details)')) || (!user.user.activityAssigments.filter((elem) => elem.activityId === 22).find(item => item.RoleId === user.user.roles) && doc.docTitle)).map((ele) => (
                      <tr>
                        <td style={{ textAlign: "center" }}>{ele.docTitle}</td>
                        <td style={{ cursor: "pointer", textAlign: "center" }}>
                          {" "}
                          <i
                            className="fas fa-file"
                            onClick={() =>
                              handleDownload(ele.url, ele.docTitle)
                            }
                            title={"Download"}
                            style={{ fontSize: "22px" }}
                          />
                        </td>

                        {/* <td style={{ cursor: "pointer", textAlign: "center" }}>
                          {ele.isVerified === "APPROVED" ? (
                            "APPROVED"
                          ) : ele.isVerified === "REJECTED" ? (
                            "REJECTED"
                          ) : (
                            <div style={{ whiteSpace: "nowrap" }}>
                              <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                  handleApproveDoc(ele);
                                }}
                              >
                                Approve
                              </Button>

                              <Button
                                style={{ marginLeft: "5px" }}
                                size="small"
                                color="secondary"
                                variant="contained"
                                onClick={() => {
                                  handlerClickOpenDoc(ele.id);
                                }}
                              >
                                Reject
                              </Button>
                            </div>
                          )}
                        </td> */}
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handlemClose}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerClose}
        aria-labelledby="customized-dialog-title"
        open={openr}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClose}
          style={{ textAlign: "center" }}
        >
          Review
        </DialogTitle>
        <DialogContent dividers>
          <label>
            <b>Remark</b>
          </label>
          <textarea
            type="text"
            onChange={Remark}
            style={{ color: "black", border: "1px solid black" }}
            className="form-control"
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleSubmitReject}
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
          <Button
            autoFocus
            onClick={handlerClose}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handleCloseRemark}
        aria-labelledby="customized-dialog-title"
        open={openRemark}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClose}
          style={{ textAlign: "center" }}
        >
          Remark
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>{viewRemark}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleCloseRemark}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen
        open={viewAgreement}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="end"
              style={{ marginLeft: "99%" }}
              color="inherit"
              onClick={closeAgreement}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <List>
          {docData && docData.agreementTemplate ?
            <Agreement
              history={agreementrow}
              recData={docData}
              catagory={categoty}
            />
            :
            (
              "hi"
            )}
        </List>
      </Dialog>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Backdrop
        className={backClasses.backdrop}
        open={backDropOpen}
      >
        <img src={c9gif} alt="loading..." />
      </Backdrop>


      {/* new all agreement modal */}
      <Modal
          size="lg"
          scrollable
          dialogClassName="modalStandard"
          centered
          aria-labelledby="all-agreement-modal-sizes-title-lg"
          show={isAgreementModalOpen}
          onHide={()=>{setIsAgreementModalOpen(false)}}
        >
          <Modal.Header className="modalHeader" style={{backgroundColor: '#810e36'}} closeButton>
            <Modal.Title className="modalTitle">All The Agreements</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table borderless style={{display: 'inline-table', width: '-webkit-fill-available', textAlign: 'center'}}>
              <thead>
                <tr
                  style={{
                    borderBottom: "1px solid rgb(200, 200, 200)",
                  }}
                >
                  <th> Name </th>
                  <th> Date </th>
                  <th> Download </th>
                </tr>
              </thead>
              <tbody>
                {
                  agreementList === ""?
                  <tr><td colSpan="3">Loading....</td></tr>
                  :
                  agreementList.length===0 ?
                  <tr><td colSpan="3">No Agreement Generated Yet!</td></tr>
                  :
                  agreementList.map((ag, ai)=>(
                    <tr key={ai}>
                      <td>{ag.digio_doc_file_name}</td>
                      <td>{new Date(ag.digio_doc_date).toDateString()}</td>
                      <td><i class="fa fa-download" aria-hidden="true" style={{ color: "#810e36", cursor: "pointer" }} onClick={()=>{viewSignedAgreementDoc(ag.digio_doc_id, ag.digio_doc_file_name, ag.type === 'manual')}}></i></td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center"></Modal.Footer>
        </Modal>
    </>
  );
}

export default DoctorList;
