import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Navbar from "../../Components/Navbar";
import { login } from "../../actions/authActions";
import DoctorSidebar from "../../Components/DoctorSidebar";
import axios from "axios";
import Button from "@mui/material/Button";
import { Modal, Row, Col, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import TextField from "@mui/material/TextField";
import DateFnsUtils from "@date-io/date-fns";
import { Grid } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { APIURL } from "../../CommomMethods/Fetch";
const stylesmodal = makeStyles({
  root: {
    marginTop: "18px",
  },
});
export default function AddQualification() {
  const styleClasses = stylesmodal();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  // bringing things from the reducer
  const { user } = userLogin;
  const [data, setData] = React.useState("");
  const initialForm = {
    qualificationug: "",
    qualificationpg: "",
    qualificationhigher: "",
    qualificationugyop: "",
    qualificationpgyop: "",
    qualificationhigherop: "",
    qualificationuguniversity: "",
    qualificationpguniversity: "",
    qualificationhigheruniversity: "",
    doctorId: user.user.id,
    hrverify: "No",
    status: 1,
  };
  const [qualification, setQualification] = useState(initialForm);
  const [date, setDate] = React.useState(new Date());
  const [toDate, setToDate] = React.useState(new Date());
  const [highDate, sethighDate] = React.useState(new Date());
  const [error, setError] = useState({});
  const addQualification = () => {
    let temp = {};
    temp.qualificationug =
      qualification.qualificationug != "" ? "" : "This is required field.";
    temp.qualificationuguniversity =
      qualification.qualificationuguniversity != ""
        ? ""
        : "This is required field.";
    temp.qualificationhigher =
      qualification.qualificationhigher != "" ? "" : "This is required field.";
    temp.qualificationhigheruniversity =
      qualification.qualificationhigheruniversity != ""
        ? ""
        : "This is required field.";

    temp.qualificationpg =
      qualification.qualificationpg != "" ? "" : "This is required field.";
    temp.qualificationpguniversity =
      qualification.qualificationpguniversity != ""
        ? ""
        : "This is required field.";
    setError({ ...temp });
    if (Object.keys(error).length) {
      const isEmpty = Object.values(error).every((x) => x === "");
  // console.log(isEmpty, "rcc");
      if (isEmpty === true) {
        axios
          .post(APIURL() + "qualification", qualification)
          .then((res) => {
        // console.log(res,"SHSHSHHS");
            toast.success("Successfully Added!")
            let data = {
              qualificationug: "",
              qualificationpg: "",
              qualificationhigher: "",
              qualificationugyop: "",
              qualificationpgyop: "",
              qualificationhigherop: "",
              qualificationuguniversity: "",
              qualificationpguniversity: "",
              qualificationhigheruniversity: "",
              doctorId: user.user.id,
              hrverify: "No",
              status: 1,
            };
            setQualification(data)
          });
      }
    }
  };

  function selectedValue(event) {
    const { name, value } = event.target;
    if (name === "qualificationug") {
      if (value.length > 0) {
        error.qualificationug = "";
        setError({ ...error });
      } else {
        error.qualificationug = "This is required field.";
        setError({ ...error });
      }
    }

    if (name === "qualificationuguniversity") {
      if (value.length > 0) {
        error.qualificationuguniversity = "";
        setError({ ...error });
      } else {
        error.qualificationuguniversity = "This is required field.";
        setError({ ...error });
      }
    }

    if (name === "qualificationhigher") {
      if (value.length > 0) {
        error.qualificationhigher = "";
        setError({ ...error });
      } else {
        error.qualificationhigher = "This is required field.";
        setError({ ...error });
      }
    }

    if (name === "qualificationhigheruniversity") {
      if (value.length > 0) {
        error.qualificationhigheruniversity = "";
        setError({ ...error });
      } else {
        error.qualificationhigheruniversity = "This is required field.";
        setError({ ...error });
      }
    }

    if (name === "qualificationpg") {
      if (value.length > 0) {
        error.qualificationpg = "";
        setError({ ...error });
      } else {
        error.qualificationpg = "This is required field.";
        setError({ ...error });
      }
    }

    if (name === "qualificationpguniversity") {
      if (value.length > 0) {
        error.qualificationpguniversity = "";
        setError({ ...error });
      } else {
        error.qualificationpguniversity = "This is required field.";
        setError({ ...error });
      }
    }

    if (name === "qualificationpguniversity") {
      if (value.length > 0) {
        error.qualificationpguniversity = "";
        setError({ ...error });
      } else {
        error.qualificationpguniversity = "This is required field.";
        setError({ ...error });
      }
    }
    setQualification({ ...qualification, [name]: value });
  }
  const handleChange = (a) => {
    setDate(a);
  };
  const handleChangeworkfrom = (a) => {
    setToDate(a);
  };

  const handleChangeworkfromhigh = (a) => {
    sethighDate(a);
  };

  return (
    <>
      <DoctorSidebar />
      <CssBaseline />

      <Container style={{ margin: "10px 0px 7px 235px", width: "80%" }}>
        <Navbar />
        <div style={{
          backgroundColor: "#810e36",
          color: "white",
          padding: "5px",
          textAlign: "center",
          margin: "1rem",
        }}>Add Qualification</div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            qualification.qualificationugyop = date;
            qualification.qualificationhigherop = highDate;
            qualification.qualificationpgyop = toDate;
            addQualification();
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6} sm={12} xl={6}>
              <TextField
                style={{ marginTop: "10px", marginBottom: "10px" }}
                name="qualificationug"
                value={qualification.qualificationug}
                className={styleClasses.root}
                label="Graduation"
                onChange={selectedValue}
                variant="outlined"
                fullWidth
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.qualificationug}
              />
              <TextField
                style={{ marginTop: "10px", marginBottom: "10px" }}
                name="qualificationuguniversity"
                value={qualification.qualificationuguniversity}
                label="University"
                onChange={selectedValue}
                variant="outlined"
                className={styleClasses.root}
                fullWidth
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.qualificationuguniversity}
              />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  autoOk
                  label="Year of Passing"
                  // disablePast
                  id="qualificationugyop"
                  value={date}
                  className={styleClasses.root}
                  onChange={handleChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  fullWidth
                />
              </MuiPickersUtilsProvider>

              <TextField
                style={{ marginTop: "10px", marginBottom: "10px" }}
                name="qualificationhigher"
                value={qualification.qualificationhigher}
                label="Other"
                onChange={selectedValue}
                className={styleClasses.root}
                variant="outlined"
                fullWidth
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.qualificationhigher}
              />
              <TextField
                style={{ marginTop: "10px", marginBottom: "10px" }}
                name="qualificationhigheruniversity"
                value={qualification.qualificationhigheruniversity}
                label="University"
                onChange={selectedValue}
                className={styleClasses.root}
                variant="outlined"
                fullWidth
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.qualificationhigheruniversity}
              />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  className={styleClasses.root}
                  autoOk
                  label="Year of Passing"
                  // disablePast
                  id="qualificationhigherop"
                  value={highDate}
                  onChange={handleChangeworkfromhigh}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={6} lg={6} sm={12} xl={6}>
              <TextField
                style={{ marginTop: "10px", marginBottom: "10px" }}
                label="Post Graduation"
                name="qualificationpg"
                value={qualification.qualificationpg}
                variant="outlined"
                onChange={selectedValue}
                className={styleClasses.root}
                fullWidth
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.qualificationpg}
              />
              <TextField
                style={{ marginTop: "10px", marginBottom: "10px" }}
                label="University"
                name="qualificationpguniversity"
                value={qualification.qualificationpguniversity}
                onChange={selectedValue}
                className={styleClasses.root}
                variant="outlined"
                fullWidth
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.qualificationpguniversity}
              />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  className={styleClasses.root}
                  autoOk
                  label="Year of Passing"
                  // disablePast
                  id="qualificationpgyop"
                  value={toDate}
                  onChange={handleChangeworkfrom}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Button
              variant="contained"
              style={{
                float: "Right", margin: "2rem", backgroundColor: "#810e36",
                color: "white",
              }}
              type="submit"
            >
              Submit
            </Button>
            {/* <Button variant="contained" onClick={Updatequalification} style={{ float: "Right", margin: "2rem" }}>Update</Button> */}
          </Grid>
        </form>
      </Container>
    </>
  );
}