import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Navmain from "../../Components/Navbar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MaterialTable from "material-table";
import DoctorSidebar from "../../Components/DoctorSidebar";
import GraphsDashBoard from "../../Components/GraphsDashBoard";
import StackedDualBarGraph from "../../Components/StackedDualBarGraph";
import DualBarGraph from "../../Components/DualBarGraph";
import Paper from "@mui/material/Paper";
import Footer from "../../Components/Footer";
import Checkbox from "@material-ui/core/Checkbox";
import {
  // FormControl,
  FormGroup,
  // FormLabel,
} from "@material-ui/core";
import RequestTable from "../../Components/RequestTable";
import RequestCard from "../../Components/RequestCard";
import ChartComponent1 from "../../Components/ChartComponent";
import ComposedChart from "../../Components/ComposedChart";
import BarChartStatusCount from "../../Components/BarChartStatusCount";
import AreaChart from "../../Components/areaChart";
import { APIURL } from "../../CommomMethods/Fetch";
import ActivitiesCard from "../../Components/ActivitiesCard";
import StatsCard from "../../Components/statsCard";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import {
  createPositions,
  formlist,
  addpositions,
} from "../../actions/createPositionActions";
import { listusers } from "../../actions/userActions";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Slide from "@material-ui/core/Slide";
import { centerlist } from "../../actions/centerActions";
// import { APIURL } from "../../CommomMethods/Fetch";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function Dashboard_Doctor({ reqTableData, history }) {
  toast.configure();
  const [newVis, setNewVis] = useState("hiddenDiv");
  const [ongVis, setOngVis] = useState("hiddenDiv");
  const [compVis, setCompVis] = useState("hiddenDiv");
  const dispatch = useDispatch();

  const [data, setData] = useState({});
  const [centerData, setCenterData] = useState({});
  const [show, setShow] = useState(false);
  const [showUpdatePosition, setShowUpdatePosition] = useState(false);

  const [dashBoardCount, setDashBoardCount] = useState([]);
  const [centerwiseData, setCenterwiseData] = useState([]);
  const [filterDatas, setfilterDatas] = useState([]);
  const [openpos, setOpenPos] = useState([]);
  const [count, setCount] = useState([]);
  const [statcount, setStatCount] = useState([]);
  const [selectedMin, setselectedMin] = useState([]);
  const [nofreq, setNofReq] = useState(1);
  const [selectedMax, setselectedMax] = useState([]);
  const [fgchecked, setfgchecked] = useState(false);
  const [posModelValue, setPosModelValue] = useState({});
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;
  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };
  useEffect(() => {

    axios
      .get(APIURL() + "onboard/onboardStatus")
      .then((res) => {
        setCount(res.data.data);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, []);

  useEffect(() => {
    axios
      .get(APIURL() + "vacancies/centerwise")
      .then((res) => {
        setCenterwiseData(res.data);
      })
      .catch((err) => {
        toast.error(err.message);
      });
    axios
      .get(APIURL() + "vacancies/allvacancy")
      .then((res) => {
        setDashBoardCount(res.data);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, []);
  // setfgchecked;
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  console.log(count, "countcountcountcount");
  const userList = useSelector((state) => state.userList);
  const { users } = userList;

  const uploadDoc = useSelector((state) => state.uploadDoc);
  const { upload } = uploadDoc;

  const formList = useSelector((state) => state.formList);
  const { form, success } = formList;
  const handleChangeFg = (e) => {
    setfgchecked(e.target.checked);
  };
  useEffect(() => {
    axios.get(APIURL() + "designations/").then((res) => {
      setfilterDatas(res.data.data);
    });
  }, []);
  // console.log(filterDatas, "filterDatas");
  const [datastamp, setDatastamp] = useState({});
  const [centerposition, setCenterposition] = useState([]);
  const userloginid = users && users[0] && users[0].id;
  // console.log(userloginid, "user  login   id");

  // console.log(centerposition, "centerposition");
  const getposition = () => {
    axios
      .get(
        APIURL() + `centerpositionmaps/findAllCenterPositionByID/${userloginid}`
      )
      .then((res) => {
    // console.log(res.data.data, "center map data");
        const fetchdata = res.data.data.filter(
          (z) => z.centername == centerData
        );
        setCenterposition(fetchdata);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  console.log(centerposition, "centerpostionnn");
  useEffect(() => {
    getposition();
  }, [users, data.centerlist, centerData]);

  const [value, setValue] = React.useState("New Position");
  const [openNoReq, setOpenNoReq] = React.useState(false);
  const [salary, setSalary] = React.useState("Monthly");
  const [radioSelect, setRaddioSelect] = React.useState(0);
  const [select, setSelect] = React.useState(0);
  const [selectPositionData, setSelectPOsitiondata] = React.useState(0);

  const handleChange = (event) => {
    setValue(event.target.value);
    setData({});
  };
  const closeDialog = () => {
    setOpenNoReq(false);
    setShow(true);
  };
  const handleChnageSalary = (event) => {
    setSalary(event.target.value);
  };
  const handleChangeSelect = (e, data) => {
    // alert(data.id);
// console.log(data, "TTTTTTTT");
    setRaddioSelect(data.id);
    setSelect(e.target.value);
    setSelectPOsitiondata(data);
    // setRaddioSelect(event.target.value);
  };

  const cardStyle = {
    background: "transparent",
    border: "0px",
  };

  const cardStyle1 = {
    background: "transparent",
    border: "0px",
    marginTop: "5px",
  };
  const infoStyle = {
    border: "2px solid #858585",
  };
  const subTitle = {
    fontSize: "13px",
  };

  useEffect(() => {
    dispatch(listusers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(formlist());
  }, [dispatch]);
  const handleCreatePosition = () => {
// console.log(
    //   selectPositionData,
    //   "selectPositionDataselectPositionData000000"
    // );
    if (selectPositionData != 0) {
      data.salaryType = salary;
      data.nofreq = nofreq;
      data.centercode = selectPositionData.centerid;
      data.departmentlist = selectPositionData.departmentname;
      data.minsalary = selectPositionData.fg_from;
      data.category = selectPositionData.category;
      // data.category = data.category;
      data.maxsalary = selectPositionData.fg_to;
      data.financialGrid = "Yes";
      let k = {
        positionType: value,
        postedBy: users && users[0] && users[0].id,
        ...data,
      };
  // console.log(k, "data2");
      // console.log(k, "passing data to vacancy");
      dispatch(createPositions(k));
      getposition();
      toast.success("Success");
      // }
    } else {
      // console.log(data, "data3");
      if (selectPositionData == 0) {
        // alert("Please select the vacancy position");
        // return;
      }
      data.nofreq = nofreq;
      data.financialGrid = "Yes";
      data.category = data.typelist;
      data.salaryType = salary;
      data.centercode = users[0] && users[0].centers && users[0].centers.find(elem => elem.name == data.centerlist).id
      let k = {
        positionType: value,
        postedBy: users && users[0] && users[0].id,
        request_type: "Onboarding",
        ...data,
      };
      dispatch(createPositions(k));
      getposition();
      toast.success("Success");
    }
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const handleUpdatePosition = () => {
    // console.log(data, "data3");
    data.minsalary = selectedMin;
    data.maxsalary = selectedMax;
    data.financialGrid = fgchecked ? "No" : "Yes";
    let k = {
      positionType: value,
      rdApproval: fgchecked ? "R" : "NR",
      postedBy: users && users[0] && users[0].id,
      ...data,
    };
    // console.log(k, "passing data to vacancy");
    axios.put(APIURL() + "vacancies/" + posModelValue.id, k).then((res) => { });
    getposition();
    toast.success("Success");
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };
  const info = "Clearing Exceptions";

  function tabChange(stat) {
    if (stat == "new") {
      if (newVis == "hiddenDiv") {
        setNewVis("visibleDiv col-xl-12 col-md-12");
        setOngVis("hiddenDiv");
        setCompVis("hiddenDiv");
      } else {
        setNewVis("hiddenDiv");
      }
    }
  }
  var testdata = newdata;
  var newdata = [];
  var departmentArray = [];

  function selectedValue(e) {
// console.log(e, "all select");
    var selecteddept = document.getElementById("departmentlist");

// console.log(e, "check e");
    newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
    setCenterData(newdata.centerlist);
  }

  console.log(data, "department list");

  var newcenterdata = [];
  function centerOnchange(e) {
    //setCenterData
// console.log(e.target.value, "e.target.value");
    newcenterdata = { ...centerData };
    newcenterdata[e.target.id] = e.target.value;
    setCenterData(newcenterdata);
  }

  const [financialData, setFinancilaData] = useState({});

  var newadddata = [];
  function onchangeFinancialGrid(e) {
    newadddata = { ...financialData };
    newadddata[e.target.id] = e.target.value;
    setFinancilaData(newadddata);
  }
  // console.log(data, "comma indicator");

  const openPosModalParent = (value) => {
// console.log("openPosModalParent", value);
    setPosModelValue(value);
    setShowUpdatePosition(true);
    setValue(value.positionType)
    if (value.financialGrid == "No") {
      setfgchecked(true);
    } else {
      setfgchecked(false);
    }
    newdata = { ...data };
    newdata["designationlist"] = value.designationLabel;
    newdata["typelist"] = value.category;
    setData(newdata);
    setselectedMax(value.maxsalary)
    setselectedMin(value.minsalary)
  }

  const dataCompoToggle = () => {
    tabChange("new");
  }

  const dataCompoToggle2 = (dataTable) => {
    setNewVis("hiddenDiv");
// console.log('dataTable check value', dataTable);
    // tabChange("new"); 
  }

  const handleReq = (e) => {
    setNofReq(e.target.value);
  };

  useEffect(() => {
    var k = centerposition.map((z) => setOpenPos(z.openposition));
  }, [centerposition]);
  console.log(openpos, "openposopenpos");
  return (
    <>
      <DoctorSidebar />

      <div className="main-content side-content pt-0">
        <div className="container-fluid">
          <div className="inner-body">
            <Navmain />

            <div className="page-header">
              <div>
                {/* <h2 className="main-content-title tx-24 mg-b-5">
                  Welcome {users && users[0] && users[0].name}{" "}
                </h2>
                <br /> */}
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="dashboard">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Dashboard
                  </li>
                </ol>
              </div>
            </div>
            {/* new request */}

            <div className="row">
              <div className="col-xl-4 col-md-5 pr-1">
                {user.user.activityAssigments.filter((elem) => elem.activityId === 9 || elem.activityId === 10 || elem.activityId === 11).find(item => item.RoleId === user.user.roles) ?
                  <Link to="/payout-dasboard" onClick={handleShow}>
                    <div className="card requestInfo">
                      <div style={{
                        paddingTop: "5px",
                        paddingLeft: "5px",
                        paddingBottom: "5px",
                        borderBottom: "1px solid #858585",
                      }}>
                        <h3>Payouts</h3>
                      </div>
                      <div className="card-footer align-items-center" style={{ padding: '28px', alignContent: 'center', height: '108px' }}>

                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          {/* <div style={{ textAlign: 'center', fontSize: "50px", fontWeight: "normal" }}> */}
                          <i class="fas fa-money-check text-light bg-primary" style={{ borderRadius: '50%', fontSize: '20px', padding: '12px' }}></i>
                          {/* </div> */}

                          <div className="text-center ml-3" style={{ display: "flex", alignItems: "center" }}>
                            <h6>Payout Dashboard</h6>
                          </div>

                        </div>
                      </div>
                    </div>
                  </Link>
                  :
                  <RequestCard
                    reqType="new"
                    num={5}
                    datalist={count}
                    clickReturn={() => { dataCompoToggle() }}
                  />
                }
              </div>
              <div className="col-xl-4 col-md-5 pr-1 pl-1">
                <Link to="/DoctorDashboard" onClick={handleShow}>
                  <div className="card requestInfo">
                    <div style={{
                      paddingTop: "5px",
                      paddingLeft: "5px",
                      paddingBottom: "5px",
                      borderBottom: "1px solid #858585",
                    }}>
                      <h3>Documents</h3>
                    </div>
                    <div className="card-footer align-items-center" style={{ padding: '28px', alignContent: 'center', height: '108px' }}>

                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {/* <div style={{ textAlign: 'center', fontSize: "50px", fontWeight: "normal" }}> */}
                        <i class="ti-clip text-light bg-primary" style={{ borderRadius: '50%', fontSize: '20px', padding: '12px' }}></i>
                        {/* </div> */}

                        <div className="text-center ml-3" style={{ display: "flex", alignItems: "center" }}>
                          <h6>Document Dashboard</h6>
                        </div>

                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-xl-4 col-md-5 pl-1">
              <Link to="/DoctorOpPayout" onClick={handleShow}>
                  <div className="card requestInfo">
                    <div style={{
                      paddingTop: "5px",
                      paddingLeft: "5px",
                      paddingBottom: "5px",
                      borderBottom: "1px solid #858585",
                    }}>
                      <h3>Payout</h3>
                    </div>
                    <div className="card-footer align-items-center" style={{ padding: '28px', alignContent: 'center', height: '108px' }}>

                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {/* <div style={{ textAlign: 'center', fontSize: "50px", fontWeight: "normal" }}> */}
                        <i class="ti-credit-card text-light bg-primary" style={{ borderRadius: '50%', fontSize: '20px', padding: '12px' }}></i>
                        {/* </div> */}

                        <div className="text-center ml-3" style={{ display: "flex", alignItems: "center" }}>
                          <h6>Payout Summary</h6>
                        </div>

                      </div>
                    </div>
                  </div>
                </Link>
              </div>


              {/* hidden new requests table */}

              <div className={`${newVis}`} id="newReqTable">
                <RequestTable
                  reqTableData={reqTableData}
                  reqType="new"
                  history={history}
                  openPosModal={(row) => openPosModalParent(row)}
                />
              </div>

              <div className={`${ongVis}`} id="ongReqTable">
                <RequestTable
                  reqTableData={reqTableData}
                  reqType="ongoing"
                  openPosModal={(row) => openPosModalParent(row)}
                />
              </div>

              <div className={`${compVis}`} id="compReqTable">
                <RequestTable
                  reqTableData={reqTableData}
                  reqType="completed"
                  openPosModal={(row) => openPosModalParent(row)}
                />
              </div>

              {/* react charts */}
              {/* <div className="col-md-6 col-xl-6 mb-4">
                <div className="card mb-4 cardsTheme1 text-center d-flex align-content-center" style={{ height: '300px' }}>
                  <StackedDualBarGraph />
                </div>
              </div>

              <div className="col-md-6 col-xl-6 mb-4">
                <div className="card mb-4 cardsTheme1 text-center d-flex align-content-center" style={{ height: '300px' }}>
                  <DualBarGraph />
                </div>
              </div> */}
              {user.user.activityAssigments.filter((elem) => elem.activityId === 1).find(item => item.RoleId === user.user.roles) ?
                <div className="col-md-6 col-xl-4">
                  <Link to="#" onClick={handleShow}>
                    <div className="card mb-4 cardsTheme1 text-center d-flex align-content-center" style={{ height: '100px' }}>
                      <i className="fe fe-user h1"></i>
                      <span className="h5">Onboarding Requisition</span>
                    </div>
                  </Link>
                </div>
                : null}            
            </div>
            <GraphsDashBoard />
          </div>
        </div>
        <Footer style={{}} />
      </div>
      <Modal
        size="lg"
        scrollable
        dialogClassName="modalStandard"
        centered
        aria-labelledby="example-modal-sizes-title-lg"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="modalHeader" closeButton>
          {/* {success && <Message variant="success">{form.message}</Message>} */}
          <Modal.Title className="modalTitle">Onboarding Requisition</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {/* <div className="col-12">
              <FormControl component="fieldset">
                <FormLabel component="legend">Position</FormLabel>
                <RadioGroup
                  row
                  aria-label="Position"
                  id="positionType"
                  name="positionType"
                  value={value}
                  onChange={handleChange}
                >
                  {/* <FormControlLabel
                    value="Replacement"
                    control={<Radio color="#fd7e14" />}
                    label="Replacement"
                    checked={value == "Replacement" ? true : false}
                  /> 
                  <FormControlLabel
                    value="New Position"
                    control={<Radio color="#00963f" />}
                    label="New Position"
                    checked={value == "New Position" ? true : false}
                  />

                  <FormControlLabel
                    value="Open Position"
                    control={<Radio color="#17a2b8" />}
                    label="Open Position"
                    checked={value == "Open Position" ? true : false}
                  />
                  <FormControlLabel
                    value="Locum"
                    control={<Radio color="#17a2b8" />}
                    label="Locum"
                    checked={value == "Locum" ? true : false}
                  /> 
                </RadioGroup>
              </FormControl>
            </div> 

            <div className="col-12" style={{ marginBottom: "10px" }}>
              {/* {value == "Open Position" ? (
                <div className="col-6">
                  <label className="tx-inverse">
                    Select the Branch{" "}
                    <i
                      class="fa fa-asterisk"
                      style={{
                        color: "red",
                        fontSize: "10px",
                        marginLeft: "6px",
                      }}
                      aria-hidden="true"
                    ></i>
                  </label>
                  <select
                    className="form-control"
                    style={{ color: "black" }}
                    id="centerlist"
                    onChange={(e) => {
                      selectedValue(e);
                    }}
                    required
                  >
                    <option>Select Branch</option>
                    {users &&
                      users[0] &&
                      users[0].centers &&
                      users[0].centers.map((ele, index) => (
                        <option value={ele.name}>{ele.name}</option>
                      ))}
                  </select>
                  <br />
                </div>
              ) : (
                ""
              )}
              {value == "Open Position" ? (
                data.centerlist && centerposition.length > 0 ? (
                  <>
                    <table
                      style={{
                        fontFamily: " arial, sans-serif",
                        borderCollapse: "collapse",
                        width: " 100%",
                      }}
                    >
                      {openpos > 0 ? (
                        <>
                          <tr style={{ backgroundColor: "#7f0c348c" }}>
                            <td
                              style={{
                                border: "1px solid #dddddd",
                                textAlign: "left",
                                padding: "8px",
                              }}
                            >
                              Select
                            </td>
                            <td
                              style={{
                                border: "1px solid #dddddd",
                                textAlign: "left",
                                padding: "8px",
                              }}
                            >
                              Doctor Type
                            </td>
                            <td
                              style={{
                                border: "1px solid #dddddd",
                                textAlign: "left",
                                padding: "8px",
                              }}
                            >
                              Department
                            </td>
                            {/* <td
                        style={{
                          border: "1px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                        }}
                      >
                        Category
                      </td> 
                            <td
                          style={{
                            border: "1px solid #dddddd",
                            textAlign: "left",
                            padding: "8px",
                          }}
                        >
                          Total
                        </td> 
                             <td
                          style={{
                            border: "1px solid #dddddd",
                            textAlign: "left",
                            padding: "8px",
                          }}
                        >
                          Number Of Positions
                        </td> 
                            <td
                              style={{
                                border: "1px solid #dddddd",
                                textAlign: "left",
                                padding: "8px",
                              }}
                            >
                              Open Position
                            </td>

                            <td
                              style={{
                                border: "1px solid #dddddd",
                                textAlign: "left",
                                padding: "8px",
                              }}
                            >
                              Professional Fees (Minimum)
                            </td>
                            <td
                              style={{
                                border: "1px solid #dddddd",
                                textAlign: "left",
                                padding: "8px",
                              }}
                            >
                              Professional Fees (Maximum)
                            </td>
                          </tr>
                        </>
                      ) : (
                        <>
                          <div className="col-12">
                            <span
                              style={{
                                color: "orangered",
                                textAlign: "left",
                                padding: "8px",
                              }}
                            >
                              No positions are open for this center
                            </span>
                          </div>
                        </>
                      )}
                      {centerposition.length > 0 ? (
                        centerposition.map((item) => (
                          <tr>
                            {parseFloat(item.allocated) -
                              parseFloat(item.occupiedposition) ===
                              0 ||
                            parseFloat(item.allocated) -
                              parseFloat(item.occupiedposition) <
                              0 ? (
                              <>
                                <div className="col-12">
                                  <span
                                    style={{
                                      color: "orangered",
                                      textAlign: "left",
                                      padding: "8px",
                                    }}
                                  >
                                     {alert("No positions are open for this center")} 
                                  </span>
                                </div>
                              </>
                            ) : (
                              <>
                                <td
                                  style={{
                                    border: "1px solid #dddddd",
                                    textAlign: "center",
                                    padding: "8px",
                                  }}
                                >
                                  <FormControl component="fieldset">
                                    <FormLabel component="legend"></FormLabel>
                                    <RadioGroup
                                      row
                                      aria-label="Position"
                                      id="radioSelect"
                                      name="radioSelectType"
                                      value={radioSelect}
                                      onChange={(e) => {
                                        handleChangeSelect(e, item);
                                      }}
                                    >
                                      <FormControlLabel
                                        value={item.id}
                                        control={<Radio color="#00963f" />}
                                        // label="select"
                                        // checked={select === radioSelect}
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #dddddd",
                                    textAlign: "left",
                                    padding: "8px",
                                  }}
                                >
                                  {item.category}
                                </td>
                                {/* <td
                                  style={{
                                    border: "1px solid #dddddd",
                                    textAlign: "center",
                                    padding: "8px",
                                  }}
                                >
                                  <FormControl component="fieldset">
                                    <FormLabel component="legend"></FormLabel>
                                    <RadioGroup
                                      row
                                      aria-label="Position"
                                      id="radioSelect"
                                      name="radioSelectType"
                                      value={radioSelect}
                                      onChange={(e) => {
                                        handleChangeSelect(e, item);
                                      }}
                                    >
                                      <FormControlLabel
                                        value={item.id}
                                        control={<Radio color="#00963f" />}
                                        // label="select"
                                        // checked={select === radioSelect}
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </td> 
                                 <td
                                  style={{
                                    border: "1px solid #dddddd",
                                    textAlign: "left",
                                    padding: "8px",
                                  }}
                                >
                                  {item.category}
                                </td> 
                                <td
                                  style={{
                                    border: "1px solid #dddddd",
                                    textAlign: "left",
                                    padding: "8px",
                                  }}
                                >
                                  {item.departmentname}
                                </td>
                                 <td
                          style={{
                            border: "1px solid #dddddd",
                            textAlign: "left",
                            padding: "8px",
                          }}
                        >
                          {item.category}
                        </td> 

                                 <td
                                    style={{
                                      border: "1px solid #dddddd",
                                      textAlign: "left",
                                      padding: "8px",
                                    }}
                                  >
                                    {item.allocated}
                                  </td> 
                                <td
                                  style={{
                                    border: "1px solid #dddddd",
                                    textAlign: "left",
                                    padding: "8px",
                                  }}
                                >
                                  {item.openposition}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #dddddd",
                                    textAlign: "left",
                                    padding: "8px",
                                  }}
                                >
                                  {item.fg_from}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #dddddd",
                                    textAlign: "left",
                                    padding: "8px",
                                  }}
                                >
                                  {item.fg_to}
                                </td>
                              </>
                            )}
                          </tr>
                        ))
                      ) : (
                        <tr>No </tr>
                      )}
                    </table>

                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        style={{ color: "black", fontSize: "13px" }}
                      ></FormLabel>
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="top"
                          control={
                            <Checkbox
                              color="primary"
                              checked={fgchecked}
                              onChange={handleChangeFg}
                            />
                          }
                          label="Override Financial Grid?"
                          labelPlacement="start"
                        />
                      </FormGroup>
                    </FormControl>
                    {!fgchecked ? (
                      <>
                        {/* <div className="col-6"> 
                        <div className="form-group">
                          <span>Min Professional Fees per month</span>
                          <input
                            className="form-control form-doctor"
                            type="text"
                            placeholder="Minimum Professional Fees"
                            id="minsalary"
                            style={{ color: "#000000" }}
                            value={selectPositionData.fg_from}
                            disabled
                          />
                        </div>
                        <div className="form-group">
                          <span>Max Professional Fees per month</span>
                          <input
                            className="form-control form-doctor"
                            type="text"
                            placeholder="Maximum Professional Fee"
                            id="maxsalary"
                            style={{ color: "#000000" }}
                            value={selectPositionData.fg_to}
                            disabled
                          />
                        </div>
                         </div> 
                      </>
                    ) : (
                      <>
                         <div className="col-6"> 
                        <div className="form-group">
                          <span>Min Professional Fees per month</span>
                          <input
                            className="form-control form-doctor"
                            type="text"
                            placeholder="Minimum Professional Fees"
                            id="minsalary"
                            style={{ color: "#000000" }}
                            value={selectedMin}
                            onChange={(e) => setselectedMin(e.target.value)}
                            // onChange={onchangeFinancialGrid}
                            // onChange={selectedValue}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <span>Max Professional Fees per month</span>
                          <input
                            className="form-control form-doctor"
                            type="text"
                            placeholder="Maximum Professional Fees"
                            id="maxsalary"
                            style={{ color: "#000000" }}
                            value={selectedMax}
                            onChange={(e) => setselectedMax(e.target.value)}
                            // onChange={selectedValue}
                            // onChange={onchangeFinancialGrid}
                            required
                          />
                        </div>
                        {/* </div> 
                      </>
                    )}

                    {/* {!fgchecked ? (
                      <>
                        <div className="col-6">
                          <div className="form-group">
                            <span>Max Professional Fee</span>
                            <input
                              className="form-control form-doctor"
                              type="text"
                              placeholder="Maximum Professional Fee"
                              id="maxsalary"
                              style={{ color: "#000000" }}
                              value={selectPositionData.fg_to}
                              disabled
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-6">
                          <div className="form-group">
                            <span>Max Professional Fee</span>
                            <input
                              className="form-control form-doctor"
                              type="text"
                              placeholder="Maximum Professional Fee"
                              id="maxsalary"
                              style={{ color: "#000000" }}
                              onChange={selectedValue}
                              // onChange={onchangeFinancialGrid}
                              required
                            />
                          </div>
                        </div>
                      </>
                    )} 
                  </>
                ) : (
                  // centerposition.map((z) => {
                  //   return z.center + " - " + z.category;
                  // })
                  <span style={{ color: "orangered" }}>
                    No positions are open for this center
                  </span>
                )
              ) : (
                ""
              )}
            </div>

            <div className="col-6">
              <label className="tx-inverse">
                Select Designation{" "}
                <i
                  class="fa fa-asterisk"
                  style={{
                    color: "red",
                    fontSize: "10px",
                    marginLeft: "6px",
                  }}
                  aria-hidden="true"
                ></i>
              </label>
              <select
                className="form-control"
                style={{ color: "black" }}
                id="designationlist"
                // onChange={selectedValue}
                onChange={(e) => {
                  selectedValue(e);
                }}
                required
              >
                <option>Select Designation</option>
                {filterDatas &&
                  filterDatas.map((ele) => (
                    <option value={ele.name}>{ele.name}</option>
                  ))}
              </select>
              <br />
            </div>
            {value == "Open Position" ? (
              <div className="col-6">
                <span>Number Of Requests</span>
                <input
                  className="form-control form-doctor"
                  type="text"
                  placeholder="Enter Number Of Requests"
                  id="nofreq"
                  style={{ color: "#000000", margin: "5px" }}
                  value={nofreq}
                  onChange={handleReq}
                />
              </div>
            ) : (
              ""
            )}*/}
            <div className="col-6 ">
              <label className="tx-inverse">
                Select a Specialization{" "}
                <i
                  class="fa fa-asterisk"
                  style={{
                    color: "red",
                    fontSize: "10px",
                    marginLeft: "6px",
                  }}
                  aria-hidden="true"
                ></i>
              </label>
              <select
                className="form-control"
                style={{ color: "black" }}
                id="departmentlist"
                onChange={(e) => {
                  selectedValue(e);
                  // checkDepartmentvalidation();
                }}
                required
              >
                <option>Select Specialization</option>
                {form.departmentList &&
                  form.departmentList.map((ele) => (
                    <option value={ele.name}>{ele.name}</option>
                  ))}
              </select>
            </div>
            <div className="col-6 ">
              <label className="tx-inverse">
                Select Position Type{" "}
                <i
                  class="fa fa-asterisk"
                  style={{
                    color: "red",
                    fontSize: "10px",
                    marginLeft: "6px",
                  }}
                  aria-hidden="true"
                ></i>
              </label>
              <select
                className="form-control"
                style={{ color: "black" }}
                id="typelist"
                // onChange={selectedValue}
                onChange={(e) => {
                  selectedValue(e);
                }}
                required
              >
                <option>Select Position</option>
                <option>Minimum Guarentee(MG)</option>
                <option>Visiting Consultant</option>
                <option>Full Time Consultant</option>
                <option>Retainer</option>
              </select>
            </div>
            {/* (<>
                 {fgchecked ? (
                   <div className="col-6 ">
                     <label className="tx-inverse">
                       Select a Position Type{" "}
                       <i
                         class="fa fa-asterisk"
                         style={{
                           color: "red",
                           fontSize: "10px",
                           marginLeft: "6px",
                         }}
                         aria-hidden="true"
                       ></i>
                     </label>
                     <select
                       className="form-control"
                       style={{ color: "black" }}
                       id="typelist"
                       // onChange={selectedValue}
                       onChange={(e) => {
                         selectedValue(e);
                       }}
                       required
                     >
                       <option>Select Position</option>
                       <option>Minimum Guarentee(MG)</option>
                       <option>Fixed Consultant</option>
                       <option>Retainer</option>
                     </select>
                   </div>
                   :""
                   //  (<>
                   //  <label className="tx-inverse">
                   //   Position Type{" "}
                   //   <i
                   //     class="fa fa-asterisk"
                   //     style={{
                   //       color: "red",
                   //       fontSize: "10px",
                   //       marginLeft: "6px",
                   //     }}
                   //     aria-hidden="true"
                   //   ></i>
                   // </label>   <input
                   //     className="form-control form-doctor"
                   //     type="text"
                   //     id="minsalary"
                   //     style={{ color: "#000000" }}
                   //     value={selectPositionData.category}
                   //     disabled
                   //   />
                   //   </>)
                     }</> */}
            <div className="col-6">
              <label className="tx-inverse">
                Select the Branch{" "}
                <i class="fa fa-asterisk" style={{ color: "red", fontSize: "10px", marginLeft: "6px" }} aria-hidden="true"></i>
              </label>
              <select
                className="form-control"
                style={{ color: "black" }}
                id="centerlist"
                // onChange={selectedValue}
                onChange={(e) => {
                  selectedValue(e);
                }}
                // value={centerData}
                required
              >
                <option>Select Branch</option>

                {users &&
                  users[0] &&
                  users[0].centers &&
                  users[0].centers.map((ele, index) => (
                    <option value={ele.name}>{ele.name}</option>
                  ))}
              </select>
              <br />
            </div>
            <div className="col-6">
              <label className="tx-inverse">
                Select Designation{" "}
                <i
                  class="fa fa-asterisk"
                  style={{
                    color: "red",
                    fontSize: "10px",
                    marginLeft: "6px",
                  }}
                  aria-hidden="true"
                ></i>
              </label>
              <select
                className="form-control"
                style={{ color: "black" }}
                id="designationlist"
                // onChange={selectedValue}
                onChange={(e) => {
                  selectedValue(e);
                }}
                required
              >
                <option>Select Designation</option>
                {filterDatas &&
                  filterDatas.map((ele) => (
                    <option value={ele.name}>{ele.name}</option>
                  ))}
              </select>
              <br />
            </div>
            <div className="col-6">
              <span>Number Of Requests</span>
              <input
                className="form-control form-doctor"
                type="text"
                placeholder="Enter Number Of Requests"
                id="nofreq"
                style={{ color: "#000000", margin: "5px" }}
                value={nofreq}
                onChange={handleReq}
              />
            </div>
          </div>
          {/* <div className="col-6 ">
                <label className="tx-inverse">
                  Select Onboarding Case{" "}
                  <i
                    class="fa fa-asterisk"
                    style={{
                      color: "red",
                      fontSize: "10px",
                      marginLeft: "6px",
                    }}
                    aria-hidden="true"
                  ></i>
                </label>
                <select
                  className="form-control"
                  style={{ color: "black" }}
                  id="financialGrid"
                  // onChange={selectedValue}
                  onChange={(e) => {
                    selectedValue(e);
                  }}
                  required
                >
                  <option>Select Onboarding Case</option>
                  <option value="Yes">Within Financial Grid</option>
                  <option value="No">Beyond Financial Grid</option>
                </select>
              </div> */}
          <div className="col-12">
            <label className="tx-inverse">
              Remarks
            </label>
            <textarea
              style={{ color: "black" }}
              class="form-control"
              placeholder="Textarea"
              rows="3"
              name="comments"
              id="comments"
              // onChange={selectedValue}
              onChange={(e) => {
                selectedValue(e);
              }}
            ></textarea>
          </div>
          {/* <>
              <div className="form-group">
                <span>Onboarding Case</span>
                <input
                  className="form-control form-doctor"
                  type="text"
                  placeholder="Onboarding Case"
                  id="minsalary"
                  style={{ color: "#000000" }}
                  value={
                    fgchecked
                      ? "Beyond Financial Grid"
                      : "Within Financial Grid"
                  }
                  disabled
                />
              </div>
            </> */}
          <hr
            style={{ backgroundColor: "black", height: "1px", opacity: "0.4" }}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          {value == "New Position" ? (
            <>
              {" "}
              {Object.keys(data).length <= 4 ||
                data.centerlist == "Select Branch" ||
                data.departmentlist == "Select Specialization" ||
                data.designationlist == "Select Designation" ||
                data.typelist == "Select Position" ? (
                <button className="btn btn-primary" style={{ width: "100px" }} disabled>Confirm</button>
              ) : (
                <button
                  className="btn btn-primary"
                  style={{ width: "100px" }}
                  onClick={handleCreatePosition}
                >
                  Confirm
                </button>
              )}
            </>
          ) : (
            <>
              {Object.keys(data).length <= 1 ||
                data.centerlist == "Select Branch" ||
                data.departmentlist == "Select Specialization" ||
                data.designationlist == "Select Designation" ? (
                // data.financialGrid == "Select Onboarding Case" ||
                // data.typelist == "Select Position"
                <button
                  className="btn btn-primary"
                  style={{ width: "100px" }}
                  onClick={handleCreatePosition}
                  disabled
                >
                  Confirm
                </button>
              ) : (
                <button
                  className="btn btn-primary"
                  style={{ width: "100px" }}
                  onClick={handleCreatePosition}
                >
                  Confirm
                </button>
              )}
            </>
          )}

          <button
            className="btn btn-dark"
            style={{ width: "100px" }}
            onClick={handleClose}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      {/* update position */}
      <Modal
        size="lg"
        scrollable
        dialogClassName="modalStandard"
        centered
        aria-labelledby="example-modal-sizes-title-lg"
        show={showUpdatePosition}
        onHide={() => setShowUpdatePosition(false)}
      >
        <Modal.Header className="modalHeader" closeButton>
          {/* {success && <Message variant="success">{form.message}</Message>} */}
          <Modal.Title className="modalTitle">Update Position</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <FormControl component="fieldset">
                <FormLabel component="legend">Position</FormLabel>
                <RadioGroup
                  row
                  aria-label="Position"
                  id="positionType"
                  name="positionType"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="New Position"
                    control={<Radio color="#00963f" />}
                    label="New Position"
                    disabled={value == "Open Position" ? true : false}
                    checked={value == "New Position" ? true : false}
                  />

                  <FormControlLabel
                    value="Open Position"
                    control={<Radio color="#17a2b8" />}
                    label="Open Position"
                    disabled={value == "New Position" ? true : false}
                    checked={value == "Open Position" ? true : false}
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <div className="col-12" style={{ marginBottom: "10px" }}>
              {value == "Open Position" ? (
                <div className="col-6">
                  <label className="tx-inverse">
                    Select the Branch{" "}
                    <i
                      class="fa fa-asterisk"
                      style={{
                        color: "red",
                        fontSize: "10px",
                        marginLeft: "6px",
                      }}
                      aria-hidden="true"
                    ></i>
                  </label>
                  <select
                    className="form-control"
                    style={{ color: "black" }}
                    id="centerlist"
                    value={posModelValue.centerNames}
                    onChange={selectedValue}
                    required
                  >
                    <option>Select Branch</option>
                    {users &&
                      users[0] &&
                      users[0].centers &&
                      users[0].centers.map((ele, index) => (
                        <option value={ele.name}>{ele.name}</option>
                      ))}
                  </select>
                  <br />
                </div>
              ) : (
                ""
              )}
              {value == "Open Position" ? (
                data.centerlist && centerposition.length > 0 ? (
                  <>
                    <table
                      style={{
                        fontFamily: " arial, sans-serif",
                        borderCollapse: "collapse",
                        width: " 100%",
                      }}
                    >
                      <tr style={{ backgroundColor: "#7f0c348c" }}>
                        <td
                          style={{
                            border: "1px solid #dddddd",
                            textAlign: "left",
                            padding: "8px",
                          }}
                        >
                          Select
                        </td>
                        <td
                          style={{
                            border: "1px solid #dddddd",
                            textAlign: "left",
                            padding: "8px",
                          }}
                        >
                          Doctor Type
                        </td>
                        <td
                          style={{
                            border: "1px solid #dddddd",
                            textAlign: "left",
                            padding: "8px",
                          }}
                        >
                          Department
                        </td>

                        <td
                          style={{
                            border: "1px solid #dddddd",
                            textAlign: "left",
                            padding: "8px",
                          }}
                        >
                          Open Position
                        </td>

                        <td
                          style={{
                            border: "1px solid #dddddd",
                            textAlign: "left",
                            padding: "8px",
                          }}
                        >
                          Professional Fee(minimum)
                        </td>
                        <td
                          style={{
                            border: "1px solid #dddddd",
                            textAlign: "left",
                            padding: "8px",
                          }}
                        >
                          Professional Fee(maximum)
                        </td>
                      </tr>
                      {centerposition.length > 0 ? (
                        centerposition
                          .filter((z) => z.remainingposition > 0)
                          .map((item) => (
                            <tr>
                              {item.openposition < 0 ? (
                                <>
                                  <div className="col-12">
                                    <span
                                      style={{
                                        color: "orangered",
                                        textAlign: "left",
                                        padding: "8px",
                                      }}
                                    >
                                      {/* {alert("No positions are open for this center")} */}
                                    </span>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <td
                                    style={{
                                      border: "1px solid #dddddd",
                                      textAlign: "center",
                                      padding: "8px",
                                    }}
                                  >
                                    <FormControl component="fieldset">
                                      <FormLabel component="legend"></FormLabel>
                                      <RadioGroup
                                        row
                                        aria-label="Position"
                                        id="radioSelect"
                                        name="radioSelectType"
                                        value={radioSelect}
                                        onChange={(e) => {
                                          handleChangeSelect(e, item);
                                        }}
                                      >
                                        <FormControlLabel
                                          value={item.id}
                                          control={<Radio color="#00963f" />}
                                        // label="select"
                                        // checked={select === radioSelect}
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid #dddddd",
                                      textAlign: "left",
                                      padding: "8px",
                                    }}
                                  >
                                    {item.category}
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid #dddddd",
                                      textAlign: "left",
                                      padding: "8px",
                                    }}
                                  >
                                    {item.departmentname}
                                  </td>

                                  <td
                                    style={{
                                      border: "1px solid #dddddd",
                                      textAlign: "left",
                                      padding: "8px",
                                    }}
                                  >
                                    {item.openposition}
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid #dddddd",
                                      textAlign: "left",
                                      padding: "8px",
                                    }}
                                  >
                                    {item.fg_from}
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid #dddddd",
                                      textAlign: "left",
                                      padding: "8px",
                                    }}
                                  >
                                    {item.fg_to}
                                  </td>
                                </>
                              )}
                            </tr>
                          ))
                      ) : (
                        <tr>No positions are open for this center </tr>
                      )}
                    </table>

                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        style={{ color: "black", fontSize: "13px" }}
                      ></FormLabel>
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="top"
                          control={
                            <Checkbox
                              color="primary"
                              checked={fgchecked}
                              onChange={handleChangeFg}
                            />
                          }
                          label="Override Financial Grid?"
                          labelPlacement="start"
                        />
                      </FormGroup>
                    </FormControl>
                    {!fgchecked ? (
                      <>
                        {/* <div className="col-6"> */}
                        <div className="form-group">
                          <span>Min Professional Fees per month</span>
                          <input
                            className="form-control form-doctor"
                            type="text"
                            placeholder="Minimum Professional Fee"
                            id="minsalary"
                            style={{ color: "#000000" }}
                            value={selectPositionData.fg_from}
                            disabled
                          />
                        </div>
                        <div className="form-group">
                          <span>Max Professional Fees per month</span>
                          <input
                            className="form-control form-doctor"
                            type="text"
                            placeholder="Maximum Professional Fee"
                            id="maxsalary"
                            style={{ color: "#000000" }}
                            value={selectPositionData.fg_to}
                            disabled
                          />
                        </div>
                        {/* </div> */}
                      </>
                    ) : (
                      <>
                        {/* <div className="col-6"> */}
                        <div className="form-group">
                          <span>Min Professional Fees per month</span>
                          <input
                            className="form-control form-doctor"
                            type="text"
                            placeholder="Minimum Professional Fee"
                            id="minsalary"
                            style={{ color: "#000000" }}
                            value={selectedMin}
                            onChange={(e) => setselectedMin(e.target.value)}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <span>Max Professional Fees per month</span>
                          <input
                            className="form-control form-doctor"
                            type="text"
                            placeholder="Maximum Professional Fee"
                            id="maxsalary"
                            style={{ color: "#000000" }}
                            value={selectedMax}
                            onChange={(e) => setselectedMax(e.target.value)}
                            required
                          />
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <span style={{ color: "orangered", fontWeight: "20px" }}>
                    No positions are open for this center
                  </span>
                )
              ) : (
                ""
              )}
            </div>

            <div className="col-6">
              <label className="tx-inverse">
                Select a Designation{" "}
                <i
                  class="fa fa-asterisk"
                  style={{
                    color: "red",
                    fontSize: "10px",
                    marginLeft: "6px",
                  }}
                  aria-hidden="true"
                ></i>
              </label>
              <select
                className="form-control"
                style={{ color: "black" }}
                id="designationlist"
                value={data.designationlist}
                onChange={selectedValue}
                required
              >
                <option>Select Designation</option>
                {filterDatas &&
                  filterDatas.map((ele) => (
                    <option value={ele.name}>{ele.name}</option>
                  ))}
              </select>
              <br />
            </div>

            {value == "New Position" ? (
              <div className="col-6 ">
                <label className="tx-inverse">
                  Select a Position Type{" "}
                  <i
                    class="fa fa-asterisk"
                    style={{
                      color: "red",
                      fontSize: "10px",
                      marginLeft: "6px",
                    }}
                    aria-hidden="true"
                  ></i>
                </label>
                <select
                  className="form-control"
                  style={{ color: "black" }}
                  id="typelist"
                  value={data.typelist}
                  onChange={selectedValue}
                  required
                >
                  <option>Select Position</option>
                  <option>Minimum Guarentee(MG)</option>
                  <option>Visiting Consultant</option>
                  <option>Full Time Consultant</option>
                  <option>Retainer</option>
                </select>
              </div>
            ) : (
              ""
            )}
            {value == "New Position" ? (
              <div className="col-6 ">
                <label className="tx-inverse">
                  Select a Specialization{" "}
                  <i
                    class="fa fa-asterisk"
                    style={{
                      color: "red",
                      fontSize: "10px",
                      marginLeft: "6px",
                    }}
                    aria-hidden="true"
                  ></i>
                </label>
                <select
                  className="form-control"
                  style={{ color: "black" }}
                  id="departmentlist"
                  onChange={selectedValue}
                  required
                >
                  <option>Select Specialization</option>
                  {form.departmentList &&
                    form.departmentList.map((ele) => (
                      <option value={ele.name}>{ele.name}</option>
                    ))}
                </select>
              </div>
            ) : (
              ""
            )}

            {value == "New Position" ? (
              <div className="col-6">
                <label className="tx-inverse">
                  Select the Branch{" "}
                  <i
                    class="fa fa-asterisk"
                    style={{
                      color: "red",
                      fontSize: "10px",
                      marginLeft: "6px",
                    }}
                    aria-hidden="true"
                  ></i>
                </label>
                <select
                  className="form-control"
                  style={{ color: "black" }}
                  id="centerlist"
                  onChange={selectedValue}
                  // value={centerData}
                  required
                >
                  <option>Select Branch</option>

                  {users &&
                    users[0] &&
                    users[0].centers &&
                    users[0].centers.map((ele, index) => (
                      <option value={ele.name}>{ele.name}</option>
                    ))}
                </select>
                <br />
              </div>
            ) : (
              ""
            )}
          </div>
          <FormControl component="fieldset">
            <FormLabel
              component="legend"
              style={{ color: "black", fontSize: "13px" }}
            ></FormLabel>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="top"
                control={
                  <Checkbox
                    color="primary"
                    checked={fgchecked}
                    onChange={handleChangeFg}
                  />
                }
                label="Override Financial Grid?"
                labelPlacement="start"
              />
            </FormGroup>
          </FormControl>
          <div className="form-group">
            <span style={{ width: "30%" }}>Financial Grid</span>
            <input
              className="form-control form-doctor"
              type="text"
              placeholder="Financial Grid"
              id="minsalary"
              style={{ color: "#000000" }}
              value={`INR ${selectPositionData.fg_from}- ${selectPositionData.fg_to}`}
              disabled
            />
          </div>
          {value == "New Position" ? (
            <>
              <div className="col-6 ">
                <label className="tx-inverse">
                  Select Onboarding Case{" "}
                  <i
                    class="fa fa-asterisk"
                    style={{
                      color: "red",
                      fontSize: "10px",
                      marginLeft: "6px",
                    }}
                    aria-hidden="true"
                  ></i>
                </label>
                <select
                  className="form-control"
                  style={{ color: "black" }}
                  id="financialGrid"
                  onChange={selectedValue}
                  required
                >
                  <option>Select Onboarding Case</option>
                  <option value="Yes">Within Financial Grid</option>
                  <option value="No">Beyond Financial Grid</option>
                </select>
              </div>

              {value == "New Position" ?
                (
                  <div className="col-12">
                    <label className="tx-inverse">Reason for Position Creation</label>
                    <textarea
                      style={{ color: "black" }}
                      class="form-control"
                      placeholder="Textarea"
                      rows="3"
                      name="comments"
                      id="comments"
                      onChange={selectedValue}
                    ></textarea>
                  </div>
                ) : null}
            </>
          ) : (
            <>
              {fgchecked ? (
                <>
                  <div className="form-group">
                    <span style={{ width: "30%" }}>Onboarding Case</span>
                    <input
                      className="form-control form-doctor"
                      type="text"
                      placeholder="Onboarding Case"
                      id="minsalary"
                      style={{ color: "#000000" }}
                      value="Beyond Financial Grid"
                      disabled
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="form-group">
                    <span style={{ width: "30%" }}>Onboarding Case</span>
                    <input
                      className="form-control form-doctor"
                      type="text"
                      placeholder="Onboarding Case"
                      id="minsalary"
                      style={{ color: "#000000" }}
                      value="Within Financial Grid"
                      disabled
                    />
                  </div>
                </>
              )}
            </>
          )}
          <div className="form-group">
            <span>Min Professional Fees per month</span>
            <input
              className="form-control form-doctor"
              type="text"
              placeholder="Minimum Professional Fees"
              id="minsalary"
              style={{ color: "#000000" }}
              value={selectedMin}
              onChange={(e) => setselectedMin(e.target.value)}
              // onChange={onchangeFinancialGrid}
              // onChange={selectedValue}
              required
            />
          </div>
          <div className="form-group">
            <span>Max Professional Fees per month</span>
            <input
              className="form-control form-doctor"
              type="text"
              placeholder="Maximun Professional Fees"
              id="maxsalary"
              style={{ color: "#000000" }}
              value={selectedMax}
              onChange={(e) => setselectedMax(e.target.value)}
              // onChange={selectedValue}
              // onChange={onchangeFinancialGrid}
              required
            />
          </div>
          <hr
            style={{ backgroundColor: "black", height: "1px", opacity: "0.4" }}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          {value == "New Position" ? (
            <>
              {" "}
              {Object.keys(data).length <= 4 ||
                data.centerlist == "Select Branch" ||
                data.departmentlist == "Select Specialization" ||
                data.designationlist == "Select Designation" ||
                data.financialGrid == "Select Onboarding Case" ||
                data.typelist == "Select Position" ? (
                <button
                  className="btn btn-primary"
                  style={{ width: "100px" }}
                  onClick={handleUpdatePosition}
                  disabled
                >
                  Confirm
                </button>
              ) : (
                <button
                  className="btn btn-primary"
                  style={{ width: "100px" }}
                  onClick={handleUpdatePosition}
                >
                  Confirm
                </button>
              )}
            </>) : (<>
              {
                <button
                  className="btn btn-primary"
                  style={{ width: "100px" }}
                  onClick={handleUpdatePosition}
                >
                  Confirm
                </button>
              }
            </>)}

          <button
            className="btn btn-dark"
            style={{ width: "100px" }}
            onClick={() => setShowUpdatePosition(false)}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      <Dialog
        style={{ overflowY: "unset" }}
        disableBackdropClick
        fullWidth={true}
        maxWidth="sm"
        open={openNoReq}
        onClose={closeDialog}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={closeDialog}
          style={{
            backgroundColor: "#810e36",
            color: "white",
            textAlign: "center",
          }}
        >
          Number of Requests Exceeded
        </DialogTitle>
        <hr />
        <DialogContent>
          <div style={{ color: "black", textAlign: "center" }}>
            Check the number of open positions!
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={closeDialog}
            color="primary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Dashboard_Doctor;
