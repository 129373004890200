import { Card, CardContent, CardHeader, Paper, TextField, InputAdornment, Typography, CardActions, Button, FormControl, Select, MenuItem, ListItemText } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core/styles";
import { formlist } from "../../../actions/createPositionActions";
import { onboardState } from '../../../CommomMethods/States';
import { MenuProps } from '../../../Components/utils';
import { useDispatch, useSelector } from 'react-redux';
import { APIURL } from '../../../CommomMethods/Fetch';
import axios from 'axios';

const stylesmodal = makeStyles({
    root: {
        marginTop: "18px",
    },
});

function EditRequisition({ requisition, updateOnboardingStatus, updateOnboardingDepDeg, updateOnboardingCommencementDate, sendToDigio, generateLtProfile, generateD365Vendor, generateLTButtonStatus, setGenerateLTButtonStatus }) {
    const [requisitionDetail, setRequisitionDetail] = useState(requisition)
    const [status, setStatus] = useState('')
    const [designationLabel, setDesignationLabel] = useState('')
    const [departmentLabel, setDepartmentLabel] = useState('')
    const [remarks, setRemarks] = useState('')
    const [statusRemarks, setStatusRemarks] = useState('')
    const [commencementdateRemarks, setCommencementdateRemarks] = useState('')
    const [filterDatas, setfilterDatas] = useState([])
    const [error, setError] = useState({});
    const [disableButton, setDisableButton] = useState(true);
    const [disableCommencementButton, setDisableCommencementButton] = useState(true);
    const [disableDepartManageButton, setDisableDepartManageButton] = useState(true);
    const [stampdate, setStampdate] = React.useState(new Date());
    const [joiningDate, setJoiningDate] = React.useState(new Date());
    const [disableJoiningButton, setDisableJoiningButton] = useState(true);
    const [joiningDateRemarks, setJoiningDateRemarks] = useState('')

    const dispatch = useDispatch();
    const formList = useSelector((state) => state.formList);
    const { form, success } = formList;
    const styleClasses = stylesmodal();
// console.log(requisition, 'requisition')

    useEffect(() => {
        setRequisitionDetail(requisition);
        setStatus(requisition.OnextAction)
        setStampdate(requisition.commencementdate ? requisition.commencementdate.toString().split("T")[0] : null)
        setJoiningDate(requisition.doj ? requisition.doj : new Date())
        setDesignationLabel(requisition.designationLabel)
        setDepartmentLabel(requisition.departmentLabel)
    }, [requisition]);

    useEffect(() => {
        dispatch(formlist());
    }, [dispatch]);

    useEffect(() => {
        axios.get(APIURL() + "designations/").then((res) => {
            setfilterDatas(res.data.data);
        });
    }, []);

    const changeDepartment = async (e) => {
        if (e.target.value !== requisition.departmentLabel) {
            setDisableDepartManageButton(false)
        } else {
            if (designationLabel === requisition.designationLabel) {
                setDisableDepartManageButton(true)
            }
        }
        setDepartmentLabel(e.target.value)
    }
    const changeDesignation = async (e) => {
        if (e.target.value !== requisition.designationLabel) {
            setDisableDepartManageButton(false)
        } else {
            if (departmentLabel === requisition.departmentLabel) {
                setDisableDepartManageButton(true)
            }
        }
        setDesignationLabel(e.target.value)
    }
    const changeOnboardingStatus = async (e) => {
        if (e.target.value !== requisition.OnextAction) {
            setDisableButton(false)
        } else {
            setDisableButton(true)
        }
        setStatus(e.target.value)
    }
    const changeRemarks = async (e) => {
        error.remarks = ""
        if ((e.target.value).trim() == '') {
            error.remarks = "This field is required"
            e.target.value = (e.target.value).trim()
        }
        setRemarks(e.target.value)
        setError({ ...error })
    }
    const changeStatusRemarks = async (e) => {
        error.statusRemarks = ""
        if ((e.target.value).trim() == '') {
            error.statusRemarks = "This field is required"
            e.target.value = (e.target.value).trim()
        }
        setStatusRemarks(e.target.value)
        setError({ ...error })
    }
    const changeJoiningdateRemarks = async (e) => {
        error.joiningDateRemarks = ""
        if ((e.target.value).trim() == '') {
            error.joiningDateRemarks = "This field is required"
            e.target.value = (e.target.value).trim()
        }
        setJoiningDateRemarks(e.target.value)
        setError({ ...error })
    }
    const changeCommencementdateRemarks = async (e) => {
        error.commencementdateRemarks = ""
        if ((e.target.value).trim() == '') {
            error.commencementdateRemarks = "This field is required"
            e.target.value = (e.target.value).trim()
        }
        setCommencementdateRemarks(e.target.value)
        setError({ ...error })
    }

    const updateJoiningDate = async () => {
        if (joiningDateRemarks.trim() === '') {
            error.joiningDateRemarks = "This field is required"
            setError({ ...error })
            return;
        }
        var data = {
            doj: joiningDate,
            doctorId: requisitionDetail.doctorId,
            name: requisitionDetail.name,
            requested_id: requisitionDetail.id,
            remarks: joiningDateRemarks
        }
        updateOnboardingCommencementDate(data)
    }

    const updateCommencementDate = async () => {
        if (commencementdateRemarks.trim() === '') {
            error.commencementdateRemarks = "This field is required"
            setError({ ...error })
            return;
        }
        var data = {
            stampdate: stampdate,
            doctorId: requisitionDetail.doctorId,
            name: requisitionDetail.name,
            requested_id: requisitionDetail.id,
            remarks: commencementdateRemarks,
        }
        updateOnboardingCommencementDate(data)
    }

    const updateStatus = async () => {
        if (statusRemarks.trim() === '') {
            error.statusRemarks = "This field is required"
            setError({ ...error })
            return;
        }
        var data = {
            status: status,
            doctorId: requisitionDetail.doctorId,
            name: requisitionDetail.name,
            requested_id: requisitionDetail.id,
            remarks: statusRemarks,
        }
        updateOnboardingStatus(data)
    }

    const handleChangeStamp = (a) => {
        var newstampdate = (moment(a).format("DD-MM-YYYY")).toString();
        var oldstampdate = requisition.commencementdate ? moment(requisition.commencementdate.toString().split("T")[0]).format("DD-MM-YYYY").toString() : null;
        if (newstampdate != oldstampdate) {
            setDisableCommencementButton(false)
        } else {
            setDisableCommencementButton(true)
        }
        setStampdate(a);
    };
    const handleChangeJoiningDate = (a) => {
        var newstampdate = (moment(a).format("DD-MM-YYYY")).toString();
        var oldstampdate = requisition.doj ? moment(requisition.doj.toString().split("T")[0]).format("DD-MM-YYYY").toString() : null;
        if (newstampdate != oldstampdate) {
            setDisableJoiningButton(false)
        } else {
            setDisableJoiningButton(true)
        }
        setJoiningDate(a);
    };

    const updateDepartAndDesignation = async () => {
        if (remarks.trim() === '') {
            error.remarks = "This field is required"
            setError({ ...error })
            return;
        }
        var data = {
            designationLabel: designationLabel,
            departmentLabel: departmentLabel,
            doctorId: requisitionDetail.doctorId,
            name: requisitionDetail.name,
            requested_id: requisitionDetail.id,
            remarks: remarks,
        }
        updateOnboardingDepDeg(data)
    }

    const sendRequisitionToDigio = async () => {
        var data = {
            doctorId: requisitionDetail.doctorId,
            name: requisitionDetail.name,
            requested_id: requisitionDetail.id,
            remarks: "Admin sent the request for DIGIO signature",
        }
        sendToDigio(data)
    }
    const generateLtProfileData = async () =>{
        const payload = {
            doctorId: requisitionDetail.doctorId,
            requested_id: requisitionDetail.id,
            name: requisitionDetail.name,
            currentAction: "",
            nextAction: "",
            remark: "Generate LT Profile by Admin.",
            status: 1,
        };
        generateLtProfile(payload);
    }
    const generateD365Data = async () =>{
        const payload = {
            doctorId: requisitionDetail.doctorId,
            requested_id: requisitionDetail.id,
            name: requisitionDetail.name,
            currentAction: "",
            nextAction: "",
            remark: "Generate D365 Vendor by Admin.",
            status: 1,
        };
        generateD365Vendor(payload);
    }
// console.log(status, 'requisitions===========')
    return (
        <Paper>
            <Card variant='outlined'>
                <CardActions className='float-right'>
                {!requisitionDetail.lt_email_id && (requisitionDetail.OnextAction == "COMPLETED" || requisitionDetail.OnextAction == "DR_TO_SIGN" || requisitionDetail.OnextAction == "CEO_TO_SIGN") &&
                    <Button disabled={!generateLTButtonStatus} variant="contained" color="secondary" onClick={() => {setGenerateLTButtonStatus(false); generateLtProfileData()}} size="small">Generate LT Profile</Button>}
                {!requisitionDetail.VendorAccountNumber && (requisitionDetail.OnextAction == "COMPLETED" || requisitionDetail.OnextAction == "DR_TO_SIGN" || requisitionDetail.OnextAction == "CEO_TO_SIGN") &&
                    <Button disabled={!generateLTButtonStatus || requisitionDetail.digio_doc_id === null || requisitionDetail.digio_doc_id === ''} variant="contained" color="secondary" onClick={() => {setGenerateLTButtonStatus(false); generateD365Data()}} size="small">Generate D365 vendor</Button>}
                    <Button disabled={requisitionDetail.digio_doc_id === null || requisitionDetail.digio_doc_id === ''} variant="contained" color="primary" onClick={() => sendRequisitionToDigio()} size="small">Send Again for Digio Signature</Button>
                </CardActions>
            </Card>
            <Card variant='outlined'>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} style={{ marginBottom: "20px" }} color="text.secondary" gutterBottom>
                        Request ID : {requisitionDetail.requestId}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} style={{ marginBottom: "20px" }} color="text.secondary" gutterBottom>
                        Request Type : {requisitionDetail.requestType}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} style={{ marginBottom: "20px" }} color="text.secondary" gutterBottom>
                        Center : {requisitionDetail.centerName}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} style={{ marginBottom: "20px" }} color="text.secondary" gutterBottom>
                        Designation : {requisitionDetail.designationLabel}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} style={{ marginBottom: "20px" }} color="text.secondary" gutterBottom>
                        Raised By : {requisitionDetail.label}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} style={{ marginBottom: "20px" }} color="text.secondary" gutterBottom>
                        Doctor Name : {requisitionDetail.name}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} style={{ marginBottom: "20px" }} color="text.secondary" gutterBottom>
                        Status : {requisitionDetail.currentStatus}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} style={{ marginBottom: "20px" }} color="text.secondary" gutterBottom>
                        Remarks : {requisitionDetail.remark}
                    </Typography>
                </CardContent>
            </Card>
            <Card variant='outlined'>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} style={{ marginBottom: "20px" }} color="text.secondary" gutterBottom>
                        Manage Status
                    </Typography>
                    <FormControl style={{ marginTop: "13px", marginBottom: "20px" }} >
                        <Select
                            autoFocus
                            labelId="mutiple-select-label"
                            value={status}
                            onChange={changeOnboardingStatus}
                            MenuProps={MenuProps} style={{ marginTop: "0px" }}
                        >
                            {onboardState && Object.values(onboardState).filter((elem) => requisitionDetail.OnextAction === elem.state || elem.state === 'RD_REVISION').map((option) => (
                                <MenuItem key={option.state} value={option.state}>
                                    <ListItemText primary={option.message} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {!disableButton ?
                        <TextField
                            id="outlined-basic"
                            label="Remarks"
                            variant="outlined"
                            fullWidth
                            name="email"
                            value={statusRemarks}
                            onChange={(e) => changeStatusRemarks(e)}
                            autoComplete="off"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                    </InputAdornment>
                                ),
                            }}
                            FormHelperTextProps={{
                                style: { color: "red" }
                            }}
                            helperText={error.remarks}
                        />
                        : ""}
                </CardContent>
                <CardActions className='float-right'>
                    <Button disabled={disableButton} variant="contained" color="primary" onClick={() => updateStatus()} size="small">Update Status</Button>
                </CardActions>
            </Card>
            <Card variant='outlined'>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} style={{ marginBottom: "20px" }} color="text.secondary" gutterBottom>
                        Date of joining
                    </Typography>
                    <FormControl style={{ marginTop: "13px", marginBottom: "20px" }} >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                autoOk
                                label="Joining Date"
                                // disablePast
                                id="doj"
                                name='doj'
                                value={joiningDate}
                                onChange={handleChangeJoiningDate}
                                className={styleClasses.root}
                                KeyboardButtonProps={{
                                    "aria-label": "change date",
                                }}
                                fullWidth
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                    {!disableJoiningButton ?
                        <TextField
                            id="outlined-basic"
                            label="Remarks"
                            variant="outlined"
                            fullWidth
                            name="email"
                            value={joiningDateRemarks}
                            onChange={(e) => changeJoiningdateRemarks(e)}
                            autoComplete="off"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                    </InputAdornment>
                                ),
                            }}
                            FormHelperTextProps={{
                                style: { color: "red" },
                            }}
                            helperText={error.joiningDateRemarks}
                        />
                        : ""}
                </CardContent>
                <CardActions className='float-right'>
                    <Button disabled={disableJoiningButton} variant="contained" color="primary" onClick={() => updateJoiningDate()} size="small">Update Joining Date</Button>
                </CardActions>
            </Card>
            <Card variant='outlined'>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} style={{ marginBottom: "20px" }} color="text.secondary" gutterBottom>
                        Manage Commencement Date
                    </Typography>
                    <FormControl style={{ marginTop: "13px", marginBottom: "20px" }} >
                        {
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        disableToolbar
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        autoOk
                                        label="Commencement Date"
                                        // disablePast
                                        id="stampdate"
                                        value={stampdate} 
                                        minDate={joiningDate}
                                        onChange={handleChangeStamp}
                                        className={styleClasses.root}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                        fullWidth
                                    />
                            </MuiPickersUtilsProvider>
                        }
                    </FormControl>
                    {!disableCommencementButton ?
                        <TextField
                            id="outlined-basic"
                            label="Remarks"
                            variant="outlined"
                            fullWidth
                            name="email"
                            value={commencementdateRemarks}
                            onChange={(e) => changeCommencementdateRemarks(e)}
                            autoComplete="off"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                    </InputAdornment>
                                ),
                            }}
                            FormHelperTextProps={{
                                style: { color: "red" },
                            }}
                            helperText={error.commencementdateRemarks}
                        />
                        : ""}
                </CardContent>
                <CardActions className='float-right'>
                    <Button disabled={disableCommencementButton} variant="contained" color="primary" onClick={() => updateCommencementDate()} size="small">Update Commencement Date</Button>
                </CardActions>
            </Card>
            <Card variant='outlined'>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} style={{ marginBottom: "20px" }} color="text.secondary" gutterBottom>
                        Manage Designation / Department
                    </Typography>
                    <FormControl style={{ marginTop: "13px", marginBottom: "20px" }} >
                        <Select
                            autoFocus
                            labelId="mutiple-select-label"
                            value={designationLabel}
                            onChange={changeDesignation}
                            MenuProps={MenuProps} style={{ marginTop: "0px" }}
                        >
                            {filterDatas && filterDatas.map((option) => (
                                <MenuItem key={option.name} value={option.name}>
                                    <ListItemText primary={option.name} />
                                </MenuItem>
                            ))}
                        </Select>
                        <Select
                            autoFocus
                            labelId="mutiple-select-label"
                            value={departmentLabel}
                            onChange={changeDepartment}
                            MenuProps={MenuProps} style={{ marginTop: "0px" }}
                        >
                            {form.departmentList && form.departmentList.map((option) => (
                                <MenuItem key={option.name} value={option.name}>
                                    <ListItemText primary={option.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {!disableDepartManageButton ?
                        <TextField
                            id="outlined-basic"
                            label="Remarks"
                            variant="outlined"
                            fullWidth
                            name="email"
                            value={remarks}
                            onChange={(e) => changeRemarks(e)}
                            autoComplete="off"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                    </InputAdornment>
                                ),
                            }}
                            FormHelperTextProps={{
                                style: { color: "red" },
                            }}
                            helperText={error.remarks}
                        />
                        : ""}
                </CardContent>
                <CardActions className='float-right'>
                    <Button disabled={disableDepartManageButton} variant="contained" color="primary" onClick={() => updateDepartAndDesignation()} size="small">Update</Button>
                </CardActions>
            </Card>
        </Paper>
    )
}

export default EditRequisition