import React, { useEffect, useState } from "react";
import SidebarRD from "../../Components/Rd-sidebar";
import axios from "axios";
import { Card, Table, Toast } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { agreementState } from "../../CommomMethods/States";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Navmain from "../../Components/Navbar";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MaterialTable from "material-table";
import { listusers } from "../../actions/userActions";
import { agreementlist } from "../../actions/agreementActions";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { APIURL } from "../../CommomMethods/Fetch";
import {
  dateFormatter,
  numberWithCommas,
} from "../../actions/createPositionActions";
import IconButton from "@material-ui/core/IconButton";

const { onboardState } = require("../../CommomMethods/States");

const myTableStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "20%",
    marginTop: theme.spacing(3),
    "& td": {
      fontFamili: "Roboto !important",
    },
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflow: "auto",
    marginBottom: "50px",
  },
}));

export default function CalculationData() {
  const tables = myTableStyle();
  const formStyle = {
    borderRadius: "5px",
    border: "1px solid black",
  };
  const headerStyle = {
    backgroundColor: "#94248E",
    margins: "0px",
    padding: "10px",
    color: "white",
    borderRadius: "5px",
    borderBottom: "3px black solid",
  };
  const bodyStyle = {
    borderRadius: "5px",
  };
  const [onboardData, setOnboardData] = useState([]);
  const [approve, setapprove] = useState([]);
  const [rej, setRej] = useState([]);
  const [openr, setOpenr] = React.useState(false);
  const [openOp, setOpenOp] = React.useState(false);
  const [openReject, setOpenRejectmodal] = React.useState(false);
  const [openApprove, setOpenApprovemodal] = React.useState(false);
  const [openIp, setOpenIp] = React.useState(false);
  const [openCommercials, setOpenCommercials] = React.useState(false);

  const [docid, setDocid] = useState("");
  const [doctorDetail, setDoctorDetail] = useState([]);
  const [doctorOpDetail, setDoctorOpDetail] = useState([]);

  const [doctorIpDetail, setDoctorIpDetail] = useState([]);
  const [doctorOpCommercial, setDoctorOpCommercial] = useState([]);
  const [doctorIpCommercial, setDoctorIpCommercial] = useState([]);
  const [remark, setRemark] = useState("");
  const [viewFinancialDialog, setViewFinancialDialog] = useState(false);
  const [rowData, setRowData] = useState({});
  const [review, setReview] = useState("");

  const userList = useSelector((state) => state.userList);
  const { users } = userList;

  const agreementList = useSelector((state) => state.agreementList);
  const { agreements } = agreementList;

  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;

  console.log(user, "luser data rd");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(agreementlist());
  }, [dispatch]);

  useEffect(() => {
    dispatch(listusers());
  }, [dispatch]);

  useEffect(() => {
    axios
      .get(APIURL() + "vacancies/getRecordsCentersbyRd/" + user.user.id)
      .then((res) => {
    // console.log(res.data, "rd approval");
        setOnboardData(res.data);
      })
      .catch((error) => { });
  }, [user]);

  // useEffect(() => {
  //   axios
  //     .get(APIURL() + "vacancies/")
  //     .then((res) => {
  //   // console.log(res);
  //       setOnboardData(res.data);
  //     })
  //     .catch((error) => {});
  // }, [user]);
  function getDocdetail(id) {
    axios
      .get(APIURL() + "onboard/previewOne/" + id)
      .then((res) => {
        setDoctorDetail(res.data);
        setDoctorIpDetail(res.data.doctorIPParameter);
        setDoctorOpDetail(res.data.doctorOPParameter);
        setDoctorOpCommercial(res.data.doctorOpCommercial);
        setDoctorIpCommercial(res.data.doctorIpCommercial);
      })
      .catch((error) => { });
  }

  useEffect(() => {
    getDocdetail(docid);
  }, [docid]);

  const handleRejectDialog = (row) => {
    setDocid(row.doctorId);
    setOpenRejectmodal(true);
    setRej(row);
  };

  const handleComments = (e) => {
    setReview(e.target.value);
  };
  const handleApproveDialog = (row) => {
    setDocid(row.doctorId);
    setOpenApprovemodal(true);
    setapprove(row);
  };
  const handlerClose = () => {
    setOpenr(false);
  };
  const handlerClickcloseCommercials = () => {
    setOpenCommercials(false);
  };
  console.log(onboardData, "##");
  const handlerCloseIp = () => {
    setOpenIp(false);
  };
  const handleApprove = (ele) => {
    // alert("The Agreement is APPROVED")
    if (review == '') {
      toast.error("Kindly provide some comments");
      return false;
    }
// console.log(ele, "row-data");
    const obj = {
      rdApproval: "OK",
      comments: review,
      postedBy: user.user.id,
    };
// console.log(obj, "PPPPPP");
    axios.put(APIURL() + "vacancies/" + approve.rowid, obj).then((res) => { });

    window.location.reload();
  };
  const handleReject = async (ele) => {
    // alert("The Agreement is APPROVED")
    if (review == '') {
      toast.error("Kindly provide some comments");
      return false;
    }
    const obj = {
      rdApproval: "RJ",
      comments: review,
      postedBy: user.user.id,
    };

    const rejectElement = {
      userID: user.user.id,
    };

    axios.put(APIURL() + "vacancies/" + rej.rowid, obj).then((res) => { });
    axios
      .post(APIURL() + "onboard/createOnboardOnReject/", rejectElement)
      .then((res) => { });

    window.location.reload();
  };

  const Remark = (e) => {
    setRemark(e.target.value);
  };
  const updateRecord = () => {
    doctorOpDetail.forEach((element) => {
      element.chstatus = 0;
    });
    doctorIpDetail.forEach((element) => {
      element.chstatus = 0;
    });
    doctorOpCommercial.forEach((element) => {
      element.chstatus = 0;
    });
    doctorIpCommercial.forEach((element) => {
      element.chstatus = 0;
    });
    const obj = {
      doctorOpDetail,
      doctorIpDetail,
      doctorOpCommercial,
      doctorIpCommercial,
    };
    axios
      .put(APIURL() + "onboard/updateFourtables/" + docid, obj)
      .then((res) => { });
    if (remark != "") {
      const obj1 = {
        doctorId: doctorDetail.doctorDetails.id,
        name: doctorDetail.doctorDetails.name,
        currentAction: onboardState.rd.state,
        currentActionBy: 1,
        nextAction: onboardState.upload.state,
        remark: remark,
        status: 1,
      };
      axios.post(APIURL() + "onboard/action/", obj1).then((res) => {
        // toast.success("Rectification successfully submitted");
      });
      window.location.reload();
    }
  };

  const viewFinancialGrid = (row) => {
// console.log(row, "view-financial-grid");
    setRowData(row);
    setViewFinancialDialog(true);
  };

  const closeFinancialDialog = () => {
    setViewFinancialDialog(false);
  };

  return (
    <>
      <SidebarRD />

      <div className="main-content side-content pt-0">
        <Navmain />
        <Card.Body
          // style={bodyStyle}
          style={{ width: "100%", marginTop: "4rem" }}
        >
          <div style={{ whiteSpace: "nowrap" }}>
            <MaterialTable
              columns={[
                {
                  title: "Request ID",
                  field: "rowid",
                  defaultSort: "desc",
                  width: "5%",
                },
                {
                  title: "Request Date",
                  field: "vacancycreated",
                  render: (row) =>
                    row.vacancycreated ? dateFormatter(row.vacancycreated) : "",
                },
                { title: "Position Type", field: "positionType" },
                { title: "Category", field: "vacancycat" },
                {
                  title: "Financial Grid",
                  field: "financialGrid",
                  lookup: {
                    No: <b>Beyond Financial Grid</b>,
                    Yes: "Within Financial Grid",
                  },
                },

                { title: "Department", field: "departmentLabel" },
                { title: "Designation", field: "designationLabel" },
                {
                  title: "View Financial Grid",
                  field: "Action",
                  filtering: false,
                  render: (rows) => (
                    <div style={{ width: "140px" }}>
                      <IconButton
                        style={{
                          color: "#810e36",
                          cursor: "pointer",
                        }}
                        aria-label="close"
                        onClick={() => {
                          viewFinancialGrid(rows);
                        }}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </div>
                  ),
                },
                {
                  title: "Action",
                  field: "Action",
                  filtering: false,
                  render: (row) =>
                    row.rdApproval === "R" ? (
                      <>
                        <Button
                          size="small"
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            handleApproveDialog(row);
                          }}
                        >
                          Approve
                        </Button>
                        <Button
                          style={{
                            marginLeft: "10px",
                            backgroundColor: "#810e36",
                            color: "#fff",
                          }}
                          size="small"
                          color="error"
                          variant="contained"
                          onClick={() => {
                            handleRejectDialog(row);
                          }}
                        >
                          Review
                        </Button>
                      </>
                    ) : row.rdApproval === "OK" ? (
                      <span className="badge badge-success">Approved</span>
                    ) : row.rdApproval === "RJ" ? (
                      <span className="badge badge-primary">
                        Sent For Review
                      </span>
                    ) : (
                      <span
                        className="badge"
                        style={{ backgroundColor: "gray", color: "white" }}
                      >
                        No Action
                      </span>
                    ),
                },
              ]}
              data={onboardData.data}
              options={{
                // filtering: true,
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
                exportAllData: true,
              }}
              title="Details"
            />
          </div>
        </Card.Body>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerClose}
        aria-labelledby="customized-dialog-title"
        open={openr}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClose}
          style={{ textAlign: "center" }}
        >
          Doctor Detail
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Name:{" "}
            {doctorDetail &&
              doctorDetail.doctorCommercial &&
              doctorDetail.doctorCommercial[0] &&
              doctorDetail.doctorCommercial[0].fullname}
          </Typography>
          <Typography gutterBottom>
            Email-Id:{" "}
            {doctorDetail &&
              doctorDetail.doctorCommercial &&
              doctorDetail.doctorCommercial[0] &&
              doctorDetail.doctorCommercial[0].email}
          </Typography>
          <Typography gutterBottom>
            Mobile:{" "}
            {doctorDetail &&
              doctorDetail.doctorCommercial &&
              doctorDetail.doctorCommercial[0] &&
              doctorDetail.doctorCommercial[0].mobile}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handlerClose}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={() => {
          setOpenOp(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={openOp}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpenOp(false);
          }}
          style={{ textAlign: "center" }}
        >
          <h5 style={{ margin: ".4rem", textAlign: "center" }}>
            <b>Doctor Details</b>
          </h5>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            <b>Name: </b>
            {doctorDetail &&
              doctorDetail.doctorCommercial &&
              doctorDetail.doctorCommercial[0] &&
              doctorDetail.doctorCommercial[0].fullname}
          </Typography>
          <Typography gutterBottom>
            <b> Email-Id: </b>
            {doctorDetail &&
              doctorDetail.doctorCommercial &&
              doctorDetail.doctorCommercial[0] &&
              doctorDetail.doctorCommercial[0].email}
          </Typography>
          <Typography gutterBottom>
            <b> Mobile: </b>
            {doctorDetail &&
              doctorDetail.doctorCommercial &&
              doctorDetail.doctorCommercial[0] &&
              doctorDetail.doctorCommercial[0].mobile}
          </Typography>
          <Paper className={tables.root}>
            <MaterialTable
              columns={[
                { title: "Item Group", field: "itemGroup" },
                { title: "Revenue", field: "oprevenue" },
                { title: "Pay Out", field: "oppayout" },
                { title: "Net Amount", field: "netamount" },
              ]}
              // data={doctorDetail.doctorOPParameter} setDoctorOpDetail
              data={doctorOpDetail}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    newData.status = 1;
                    setTimeout(() => {
                      const dataUpdate = [...doctorOpDetail];

                      const index = oldData.tableData.id;
                      dataUpdate[index] = newData;

                      setDoctorOpDetail([...dataUpdate]);

                      resolve();
                    }, 1000);
                  }),
              }}
              options={{
                // filtering: true,
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
                exportAllData: true,
                rowStyle: (rowData) => ({
                  backgroundColor:
                    rowData.chstatus === true ? "orangered" : "#FFF",
                }),
              }}
              title="Op Details"
            />
            <br />
            <MaterialTable
              columns={[
                { title: "Item Group", field: "itemGroup" },
                { title: "Room Type", field: "iproom" },
                { title: "Revenue", field: "revenue" },
                { title: "Pay Out", field: "payout" },
                { title: "Net Amount", field: "ipTotalAmount" },
              ]}
              // data={doctorDetail.doctorIPParameter}
              data={doctorIpDetail}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    newData.status = 1;
                    setTimeout(() => {
                      const dataUpdate = [...doctorIpDetail];
                      const index = oldData.tableData.id;
                      dataUpdate[index] = newData;
                      setDoctorIpDetail([...dataUpdate]);
                      resolve();
                    }, 1000);
                  }),
              }}
              options={{
                // filtering: true,
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
                exportAllData: true,
                rowStyle: (rowData) => ({
                  backgroundColor:
                    rowData.chstatus === true ? "orangered" : "#FFF",
                }),
              }}
              title="Ip Details"
            />
            <br />
            <MaterialTable
              columns={[
                { title: "Item Group", field: "itemgroup" },

                { title: "Commercial Term", field: "commercialterm" },
              ]}
              // data={doctorDetail.doctorIPParameter}
              data={doctorOpCommercial}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    newData.status = 1;
                    setTimeout(() => {
                      const dataUpdate = [...doctorOpCommercial];
                      const index = oldData.tableData.id;
                      dataUpdate[index] = newData;
                      setDoctorOpCommercial([...dataUpdate]);

                      resolve();
                    }, 1000);
                  }),
              }}
              options={{
                // filtering: true,
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
                exportAllData: true,
                rowStyle: (rowData) => ({
                  backgroundColor:
                    rowData.chstatus === true ? "orangered" : "#FFF",
                }),
              }}
              title="Op Commercial"
            />
            <br />
            <MaterialTable
              columns={[
                { title: "Item Group", field: "itemgroup" },
                { title: "Room Category", field: "iproom" },
                { title: "MOP", field: "ipmodeofpayment" },
                { title: "Commercial Term", field: "commercialterm" },
              ]}
              // data={doctorDetail.doctorIPParameter}
              data={doctorIpCommercial}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    newData.status = 1;
                    setTimeout(() => {
                      const dataUpdate = [...doctorIpCommercial];
                      const index = oldData.tableData.id;
                      dataUpdate[index] = newData;
                      setDoctorIpCommercial([...dataUpdate]);

                      resolve();
                    }, 1000);
                  }),
              }}
              options={{
                // filtering: true,
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
                exportAllData: true,
                rowStyle: (rowData) => ({
                  backgroundColor:
                    rowData.chstatus === true ? "orangered" : "#FFF",
                }),
              }}
              title="Ip Commercial"
            />
          </Paper>
          <br />
          <label>
            Remark{" "}
            <i
              class="fa fa-asterisk"
              style={{
                color: "red",
                fontSize: "10px",
                marginLeft: "3px",
                marginTop: "15px",
              }}
              aria-hidden="true"
            ></i>
          </label>
          <textarea
            placeholder="Remark"
            type="text"
            onChange={Remark}
            style={{ color: "black", border: "1px solid black" }}
            className="form-control"
            required
          />
          <br />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={updateRecord}
            color="primary"
            variant="contained"
            size="small"
          >
            Update Data
          </Button>
          <Button
            autoFocus
            onClick={() => {
              setOpenOp(false);
            }}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerCloseIp}
        aria-labelledby="customized-dialog-title"
        open={openIp}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerCloseIp}
          style={{ textAlign: "center" }}
        ></DialogTitle>
        <DialogContent dividers>
          <Paper className={tables.root}>
            <MaterialTable
              columns={[
                { title: "Item Group", field: "itemGroup" },
                { title: "Revenue", field: "revenue" },
                { title: "Pay Out", field: "payout" },
                { title: "Net Amount", field: "ipTotalAmount" },
              ]}
              data={doctorDetail.doctorIPParameter}
              options={{
                // filtering: true,
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
                exportAllData: true,
              }}
              title="Ip Details"
            />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handlerCloseIp}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerClickcloseCommercials}
        aria-labelledby="customized-dialog-title"
        open={openCommercials}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClickcloseCommercials}
          style={{ textAlign: "center" }}
        ></DialogTitle>
        <DialogContent dividers>
          <Paper className={tables.root}>
            <MaterialTable
              columns={[
                { title: "Item Group/Procedures", field: "itemGroup" },
                { title: "Commercial Term", field: "CommercialTerm" },
              ]}
              data={doctorDetail.doctorIPParameter}
              options={{
                // filtering: true,
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
                exportAllData: true,
              }}
              title="Commercial Details"
            />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handlerClickcloseCommercials}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={viewFinancialDialog}
        onClose={closeFinancialDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Financial Grid</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <table style={{ width: "99%", border: "1px solid black" }}>
              <tr >
                <td> </td>
                <th style={{ border: "1px solid black" }}>Min Professional Fees</th>
                <th style={{ border: "1px solid black" }}>Max Professional Fees</th>
              </tr>
              <tr>
                <th style={{ width: "20%", border: "1px solid black" }}>Pre-set</th>

                <td style={{ border: "1px solid black" }}>
                  {rowData.fg_from ? "Rs. " + numberWithCommas(rowData.fg_from) : 0}
                </td>
                <td style={{ border: "1px solid black" }}>
                  {rowData.fg_to ? "Rs. " + numberWithCommas(rowData.fg_to) : 0}
                </td>
              </tr>
              {rowData.financialGrid === 'No' ?
                <tr>
                  <th style={{ width: "20%", border: "1px solid black" }}>Requested</th>

                  <td style={{ border: "1px solid black" }}>
                    {" "}
                    {rowData.minsalary ? "Rs. " + numberWithCommas(rowData.minsalary) : 0}
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    {" "}
                    {rowData.maxsalary ? "Rs. " + numberWithCommas(rowData.maxsalary) : 0}{" "}
                  </td>
                </tr>
                : ""}
            </table>
            {/* Pre-set Min Professional Fees :{" "}
            {rowData.fg_from ? rowData.fg_from : 0}
            <br />
            Requested Min Professional Fees :{" "}
            {rowData.minsalary ? rowData.minsalary : 0}
          </DialogContentText>
          <DialogContentText>
            Pre-set Max Professional Fees : {rowData.fg_to ? rowData.fg_to : 0}
            <br /> Requested Max Professional Fees :{" "}
            {rowData.maxsalary ? rowData.maxsalary : 0}{" "} */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeFinancialDialog} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* APPROVE MODAL */}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={() => {
          setOpenApprovemodal(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={openApprove}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpenApprovemodal(false);
          }}
          style={{ textAlign: "center" }}
        >
          Approve
        </DialogTitle>
        <DialogContent dividers>
          <label>Comments </label>
          <textarea
            placeholder="Comments"
            type="text"
            onChange={handleComments}
            style={{ color: "black", border: "1px solid black" }}
            className="form-control"
            required
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleApprove}
            color="secondary"
            variant="contained"
            size="small"
          >
            Approve
          </Button>
          <Button
            autoFocus
            onClick={() => {
              setOpenApprovemodal(false);
            }}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* REJECT MODAL */}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={() => {
          setOpenRejectmodal(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={openReject}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpenRejectmodal(false);
          }}
          style={{ textAlign: "center" }}
        >
          Review
        </DialogTitle>
        <DialogContent dividers>
          <label>Comments </label>
          <textarea
            placeholder="Comments"
            type="text"
            onChange={handleComments}
            style={{ color: "black", border: "1px solid black" }}
            className="form-control"
            required
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleReject}
            color="secondary"
            variant="contained"
            size="small"
          >
            Review
          </Button>
          <Button
            autoFocus
            onClick={() => {
              setOpenRejectmodal(false);
            }}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
