import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
//ui components
import Sidebar from "../../Components/Sidebar";
import Navbar from "../../Components/Navbar";
import ConfirmDialog from "../../Components/ConfirmDialog";
//mui components
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { Button, Paper, Typography } from "@material-ui/core";
import MaterialTable from "material-table";
import Skeleton from "@material-ui/lab/Skeleton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import Tooltip from "@material-ui/core/Tooltip";
//import component
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogTitle from "@material-ui/core/DialogTitle";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { onboardById } from "../../actions/onboardActions";
import axios from "axios";
import { APIURL } from "../../CommomMethods/Fetch";
import { payoutCalculation } from "../../Pages/Calculations/payoutCalc";
import { dateFormatter } from "../../actions/createPositionActions";

const styleSheet = makeStyles((theme) => ({
    iconButtonStyle: {
        float: "right",
    },
    marginforMaster: {
        marginTop: "30px",
    },
}));

const backDropStyle = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));
export default function MiscOpCh() {
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subtitle: "",
    });
    //   const location = useLocation();
    //   console.log(location, "location from anothere state");
    const classes = styleSheet();
    const dispatch = useDispatch();
    const [backDropOpen, setBackDropOpen] = useState(false);
    const backClasses = backDropStyle();
    const [rows, setRows] = useState([]);
    const [name, setName] = useState([]);
    const [center, setCenter] = useState("Select Center");
    const [month, setMonth] = useState("Select Month");
    const [year, setYear] = useState("Select Year");
    const [loading, setLoading] = useState(false);
    const [changeRowValue, setChangeRowValue] = useState([]);
    const [rowSelection, setRowSelection] = useState(false);
    const [openr, setOpenr] = React.useState(false);
    const [reload, setReload] = useState(false);
    const [filteredRows, setFilteredRows] = useState([]);
    const [opTotPayout, setOpTotPayout] = useState([]);
    const [initCalOP, setInitCalOP] = useState([]);
    const [data, setData] = useState("Select the value");
    const [centerName, setCenterName] = useState([]);
    const [splitDialog, setSplitDialog] = useState(false);
    const [spitRecord, setSplitRecord] = useState({});
    const [splitNewRec, setSplitNewRec] = useState([]);
    const [oboard, setOboard] = useState([]);
    const [oboardVal, setOboardVal] = useState([]);
    const [dataVal, setDataVal] = useState([]);
    const [loadEachData, setLoadEachdata] = useState(false);

    const userLogin = useSelector((state) => state.userLogin);
    const { user } = userLogin;

// console.log("user", user);
    const [rowDetail, setRowDetail] = useState({
        PayableNonPayable: "",
    });
    // const onboardId = useSelector((state) => state.onboardId);
    // const { oboard } = onboardId;

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setRowDetail({ ...rowDetail, [name]: value });
    };
    const handlePayoutEdit = () => {
        setReload(true);
        const obj = { rowDetail: rowDetail, changeRowValue: changeRowValue };
        axios.put(APIURL() + "billings/updateBillingRowData", obj).then((res) => {
            setReload(false);
            setOpenr(false);
        });
    };
    useEffect(() => {
        // axios.get(APIURL() + "usermapcenters/centermaps/" + user.user.id)
        axios.get(APIURL() + "usermapcenters/centermaps/" + 400)
            .then((res) => res.data)
            .then((rows) => {
            // console.log(rows, "2323");
                setCenterName(rows.data);
            });
    }, []);

    useEffect(() => {
        setLoading(false);
        axios
            .get(APIURL() + "billings/findAllBillingDetProcess")
            .then((res) => res.data)
            .then((rows) => {
                // var k = rows.data.filter(z => z.ItemGroupName === "Other Charges")
                setRows(rows.data);
                setLoading(true);
            });
    }, []);

    useEffect(() => {
        setLoading(false);
        axios
            .get(APIURL() + "onboard/name")
            .then((res) => res.data)
            .then((rows) => {
                setName(rows);
                setLoading(true);
            });
    }, []);
    var array = [];
    var k =
        name &&
        name.data &&
        name.data.map((ele) => {
            array.push(ele.doctorName);
        });
// console.log(array, "0012");
    useEffect(() => {
        setLoading(false);
        axios
            .get(APIURL() + "onboard/name/" + array)
            .then((res) => res.data)
            .then((rows) => {
                setOboard(rows);
                setLoading(true);
            });
    }, []);

// console.log(oboard, "*!!!");
    var doctorList = [
        { id: 0, name: "Dr. Preety Aggarwal" },
        { id: 1, name: "Dr. Shweta Bansal Wazir" },
        { id: 2, name: "Dr. Manish Balde" },
        { id: 3, name: "Dr. Witty Raina" },
        { id: 4, name: "Dr. Binal Shah" },
        { id: 5, name: "Dr. Beena Muktesh" },
        { id: 6, name: "Dr. Shalu Gupta" },
        { id: 7, name: "Dr. Bhavna Dewan" },
        { id: 8, name: "Dr. Radhika Sheth" },
        { id: 9, name: "Dr. Hena Bhagde" },
    ];
    function selectedDropdownvalue(e) {
        const { name, value } = e.target;
        setData(value);
        setInitCalOP([]);
    }
    function selectedMonth(e) {
        const { name, value } = e.target;
        setMonth(value);
    }

    function selectedYear(e) {
        const { name, value } = e.target;
        setYear(value);
    }

    function selectedCenter(e) {
        const { name, value } = e.target;
        setCenter(value);
    }

    useEffect(() => {
        setInitCalOP([]);
        setOpTotPayout([]);
    }, [data]);

    // useEffect(() => {
    //   dispatch(onboardById(364));
    // }, [reload]);
    const [valRec, setValRec] = useState([]);
    const [loadVal, setLoadVal] = useState(false);
    var sumArray = [];
    var Val = [];
    var sum = 0;

    function generatePay() {
        setBackDropOpen(true);
        axios.get(APIURL() + 'billings/Payout/generateOpPay')
            .then((res) => res.data)
            .then((rows) => {
            // console.log(rows.data, "0002");
                let value = rows.data.forEach(ele => {
                    ele.ConductedByFinal = "Dr." + ele.ConductedByFinal;
                })
                setDataVal(value);
                // setLoading(true);
                setBackDropOpen(false);
            });
    }
    function calculatePayoutAll() {
        setBackDropOpen(true);
        // setLoadVal(false);
        // var filterdata = rows.filter((z) => data.includes(z.ItemGroupName));
        // Val = payoutCalculation.initiateCalOpCalc(filterdata, oboard, data);
        // // console.log(Val, "netval");
        // console.log(oboard, filterdata, "netval");

        axios
            .get(
                APIURL() +
                `billings/loadbills/${center}/${year}/${month}/calcPayByMonth`
            )
            .then((res) => res.data)
            .then((rows) => {
                var k = rows.data.filter(z => z.ItemGroupName === "Other Charges")
                setDataVal(k);
                // setLoading(true);
                setBackDropOpen(false);
            });
    }

    function getRecord() {
        setLoading(false);
        let url = APIURL() + `billings/loadbills/${center}/${year}/${month}`;
    // console.log(url, "url");
        axios
            .get(url)
            .then((res) => res.data)
            .then((rows) => {
                // console.log(rows,"###");
                var k = rows.filter(z => (z.ItemGroupName === "Other Charges" || z.ItemGroupName === "Anesthesia Services") && z.PayableNonPayable === "0")
                setOboardVal(k);
                setLoading(true);
            });
    }
    useEffect(() => {
        setInitCalOP(valRec);
    }, [loadVal, valRec]);
    const handlerClose = () => {
        setOpenr(false);
    };
    const onSplitRow = (row) => {
        setSplitNewRec([]);
        setRowDetail({ doctorname: "", ratio: "", percentage: "" });
        setSplitRecord(row);
    };
    const addRowData = (row) => {
        let rec = {};
        spitRecord.ConductedBy = rowDetail.doctorname;
        spitRecord.Share = rowDetail.ratio;
        rec = {
            ...spitRecord,
            ConductedBy: rowDetail.doctorname,
            Share: rowDetail.ratio,
        };
        let newData = [...splitNewRec];
        newData.push(rec);
        newData.forEach((i, index) => {
        // console.log(i, index, "record 21");
        });
        rec = {};
        setSplitNewRec(newData);
        setRowDetail({ doctorname: "", ratio: "", percentage: "" });
    };

    const changeSplitRecord = () => {
        axios
            .post(APIURL() + "billings/createSplidRecord", splitNewRec)
            .then(function (response) { });
        //createSplidRecord
    };
// console.log(dataVal.length, "dataVal");

    const deleteRow = (data) => {
        setLoadEachdata(false);
        axios
            .post(APIURL() + "billings/discardRecord", data)
            .then(function (response) {
                setLoadEachdata(true);
            });
    };

    const deleteMultipleRows = () => {
        setLoadEachdata(false);
        axios
            .post(APIURL() + "billings/discardRecords", changeRowValue)
            .then(function (response) {
                setLoadEachdata(true);
            });
    };
    return (
        <>
            {" "}
            <Sidebar />
            <CssBaseline />
            <Container style={{ margin: "10px 0px 7px 235px", width: "80%" }}>
                <Navbar />
                <Typography
                    variant="subtitle1"
                    style={{ margin: "1rem", textAlign: "center" }}
                >
                    OP-Payout Process
        </Typography>

                {!loading ? (
                    <Skeleton animation="wave" variant="rect" width={950} height={400} />
                ) : (
                    <div style={{ whiteSpace: "nowrap" }}>
                        <Paper
                            style={{ marginBottom: "10px", border: "1px solid #810e36" }}
                        >
                            <div style={{ padding: "8px" }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} xl={3} lg={3} md={3}>
                                        <FormControl variant="outlined" style={{ width: "100%" }}>
                                        <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={center}
                                                onChange={selectedCenter}
                                                label=" Filter By Center"
                                                fullWidth
                                            >
                                                <MenuItem value="Select the Center">
                                                    Filter By Center
                                                   </MenuItem>
                                                {centerName.length > 0 && centerName.map((ele) => {
                                                    return (<MenuItem value={ele.code}>{ele.name}</MenuItem>)
                                                })}
                                
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} xl={3} lg={3} md={3}>
                                        <FormControl variant="outlined" style={{ width: "100%" }}>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={year}
                                                onChange={selectedYear}
                                                label=" Filter By Year"
                                                fullWidth
                                            >
                                                <MenuItem value="Select the Year">
                                                    Filter By Year
                        </MenuItem>
                                                <MenuItem value="2021">2021</MenuItem>
                                                <MenuItem value="2022">2022</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} xl={3} lg={3} md={3}>
                                        <FormControl variant="outlined" style={{ width: "100%" }}>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={month}
                                                onChange={selectedMonth}
                                                label=" Filter By Month"
                                                fullWidth
                                            >
                                                <MenuItem value="Select the Month">
                                                    Filter By Month
                        </MenuItem>
                                                <MenuItem value="JANUARY">JANUARY</MenuItem>
                                                <MenuItem value="FEBRUARY">FEBRUARY</MenuItem>
                                                <MenuItem value="MARCH">MARCH</MenuItem>
                                                <MenuItem value="APRIL">APRIL</MenuItem>
                                                <MenuItem value="MAY">MAY</MenuItem>
                                                <MenuItem value="JUNE">JUNE</MenuItem>
                                                <MenuItem value="JULY">JULY</MenuItem>
                                                <MenuItem value="AUGUST">AUGUST</MenuItem>
                                                <MenuItem value="SEPTEMBER">SEPTEMBER</MenuItem>
                                                <MenuItem value="OCTOBER">OCTOBER</MenuItem>
                                                <MenuItem value="NOVEMBER">NOVEMBER</MenuItem>
                                                <MenuItem value="DECEMBER">DECEMBER</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} xl={3} lg={3} md={3}>
                                        <Button
                                            variant="contained"
                                            onClick={getRecord}
                                            fullWidth
                                            style={{
                                                height: "100%",
                                                backgroundColor: "#810e36",
                                                color: "white",
                                            }}
                                        >
                                            Load Data{" "}
                                        </Button>
                                    </Grid>
                                </Grid>


                            </div>
                        </Paper>
                        <MaterialTable
                            columns={[
                                // {
                                //   title: "Split",
                                //   field: "Action",
                                //   filtering: false,
                                //   render: (row) => (
                                //     <Tooltip title="Split Record">
                                //       <IconButton>
                                //         <CallSplitIcon
                                //           style={{
                                //             transform: "rotate(90deg)",
                                //             color: "#810e36",
                                //           }}
                                //           onClick={() => {
                                //             setSplitDialog(true);
                                //             onSplitRow(row);
                                //           }}
                                //         />
                                //       </IconButton>
                                //     </Tooltip>
                                //     // <Button

                                //     // >
                                //     //   split
                                //     // </Button>
                                //   ),
                                // },
                                // {
                                //   title: "Discard",
                                //   field: "Action",
                                //   filtering: false,
                                //   render: (row) => (
                                //     <Tooltip title="Discard Record">
                                //       <IconButton>
                                //         <RemoveCircleOutlineIcon
                                //           style={{
                                //             color: "#810e36",
                                //           }}
                                //           onClick={() => {
                                //             setConfirmDialog({
                                //               isOpen: true,
                                //               title:
                                //                 "Discarding record may result in some features unresponsive ?",
                                //               subtitle:
                                //                 "Are you sure you want to discard this record ?",
                                //               onConfirm: () => {
                                //                 deleteRow(row);
                                //                 setConfirmDialog({ isOpen: false });
                                //               },
                                //             });
                                //           }}
                                //         />
                                //       </IconButton>
                                //     </Tooltip>
                                //   ),
                                // },

                                // { title: "SlNo", field: "SlNo" },
                                { title: "MPI", field: "MPI" },
                                { title: "Patient Name", field: "PatientName" },
                                { title: "Bill Time", field: "BillTime" },
                                {
                                    title: "Bill No",
                                    field: "BillNo",
                                    render: (row) => (
                                        <div
                                            style={{ cursor: "pointer", color: "red" }}
                                            onClick={() => alert(row.BillNo)}
                                        >
                                            {row.BillNo}
                                        </div>
                                    ),
                                },
                                { title: "Bill Date", field: "BillDate", render: (row) => (<div>{dateFormatter(row.BillDate)}</div>) },
                                { title: "Admin Fee", field: "AdminFee" },
                                { title: "Item Group Name", field: "ItemGroupName" },
                                { title: "Item Name", field: "ItemName" },
                                { title: "Gross", field: "Gross" },
                                { title: "Tax", field: "Tax" },
                                { title: "Discount", field: "Discount" },
                                { title: "Net", field: "Net" },

                                // { title: "Share", field: "Share" },
                                {
                                    title: "Share",
                                    field: "Share",
                                    render: (row) =>
                                        row.Share == null ? <div>NA</div> : <div>{row.Share}</div>,
                                },
                                {
                                    title: "Ordering Physician",
                                    field: "OrderingPhysician",
                                },
                                { title: "Specialisation", field: "Specialisation" },
                                {
                                    title: "ConductedBy",
                                    field: "ConductedBy",
                                    render: (row) =>
                                        row.ConductedBy == null ? <div>NA</div> : <div>{row.ConductedBy}</div>,
                                },

                                { title: "User", field: "User" },
                                {
                                    title: "Payable NonPayable",
                                    field: "PayableNonPayable",
                                },
                                {
                                    title: "Conducted By Final",
                                    field: "ConductedByFinal",
                                },
                                { title: "Location", field: "Center" },
                            ]}
                            actions={[
                                {
                                    tooltip: "Edit Records",
                                    icon: "edit",
                                    iconProps: { style: { color: "#810e36" } },
                                    onClick: (evt, data) => setOpenr(true),
                                },
                                {
                                    tooltip: "Discard Records",
                                    icon: () => (
                                        <RemoveCircleOutlineIcon style={{ color: "#810e36" }} />
                                    ),
                                    onClick: (evt, row) =>
                                        setConfirmDialog({
                                            isOpen: true,
                                            title:
                                                "Discarding record may result in some features unresponsive ?",
                                            subtitle:
                                                "Are you sure you want to discard this record ?",
                                            onConfirm: () => {
                                                deleteMultipleRows(row);
                                                setConfirmDialog({ isOpen: false });
                                            },
                                        }),
                                },
                            ]}
                            data={oboardVal}
                            options={{
                                // filtering: true,
                                selection: true,
                                sorting: true,
                                exportButton: true,
                                pageSize: 5,
                                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                search: true,
                            }}
                            onSelectionChange={(rows) => {
                                setRowSelection(true);
                                setChangeRowValue(rows);
                            }}
                            title="OP-Payout Billing Details"
                        />

                        {rows && rows.length > 1 ? (
                            <Button
                                size="small"
                                color="secondary"
                                variant="contained"
                                onClick={calculatePayoutAll}
                            // style={{float:"right"}}
                            >
                                Initiate Calculation{" "}
                            </Button>
                        ) : (
                            ""
                        )}
                        {dataVal.length > 0 ? (
                            <MaterialTable
                                columns={[
                                    { title: "SlNo", field: "SlNo" },
                                    { title: "MPI", field: "MPI" },
                                    // {
                                    //   title: "Bill Time",
                                    //    field: "BillTime",
                                    //     render: (row) =>
                                    //        <div>{(row.BillTime).substring(0,10)}</div>,
                                    //   },
                                    {
                                        title: "Bill No",
                                        field: "BillNo",
                                        render: (row) => (
                                            <div
                                                style={{ cursor: "pointer", color: "red" }}
                                                onClick={() => alert(row.BillNo)}
                                            >
                                                {row.BillNo}
                                            </div>
                                        ),
                                    },
                                    { title: "Bill Date", field: "BillDate", render: (row) => (<div>{dateFormatter(row.BillDate)}</div>) },
                                    { title: "Bill Time", field: "BillTime" },
                                    { title: "Patient Name", field: "PatientName" },
                                    { title: "Item Group Name", field: "ItemGroupName" },
                                    { title: "Item Name", field: "ItemName" },
                                    { title: "Gross", field: "Gross" },
                                    { title: "Tax", field: "Tax" },
                                    { title: "Package Overrun", field: "PackageOverrun" },
                                    { title: "Discount", field: "Discount" },
                                    { title: "Discount Type", field: "DiscountType" },
                                    { title: "Net", field: "Net" },
                                    {
                                        title: "Calculated Value",
                                        field: "calculatedvalue",
                                        render: (row) =>
                                            row.calculatedvalue != null ? (
                                                <div style={{ cursor: "pointer", color: "green" }}>
                                                    {row.calculatedvalue.toFixed(2)}
                                                </div>
                                            ) : (
                                                <div style={{ cursor: "pointer", color: "red" }}>
                                                    0.00
                                                </div>
                                            ),
                                    },
                                    {
                                        title: "Fixed Amount",
                                        field: "fixedamount",
                                        render: (row) =>
                                            row.fixedamount != null ? (
                                                <div >
                                                    {row.fixedamount}
                                                </div>
                                            ) : (
                                                <div>
                                                    0
                                                </div>
                                            ),
                                    },
                                    {
                                        title: "Ordering Physician",
                                        field: "OrderingPhysician",
                                    },
                                    { title: "Specialisation", field: "Specialisation" },
                                    {
                                        title: "Conducted By",
                                        field: "ConductedBy",
                                        render: (row) =>
                                            row.ConductedBy == null ? <div>NA</div> : <div>{row.ConductedBy}</div>,
                                    },
                                    { title: "User", field: "User" },
                                    {
                                        title: "Payable NonPayable",
                                        field: "PayableNonPayable",
                                    },
                                    {
                                        title: "Conducted By Final",
                                        field: "ConductedByFinal",
                                        // <span>{row.ConductedByFinal}</span>
                                    },
                                    { title: "Admin Fee", field: "AdminFee" },
                                    {
                                        title: "Share",
                                        field: "Share",
                                        render: (row) =>
                                            row.Share != null ? (
                                                <div >
                                                    {row.Share}
                                                </div>
                                            ) : (
                                                <div >
                                                    0
                                                </div>
                                            ),
                                    },
                                    { title: "OP/IPD", field: "OPIPD" },
                                    { title: "Center", field: "Center" },
                                    { title: "Region", field: "region" },
                                    { title: "Month", field: "month" },
                                    { title: "Year", field: "year" },
                                ]}

                                data={dataVal}
                                options={{
                                    sorting: true,
                                    exportButton: true,
                                    pageSize: 5,
                                    pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                    search: true,
                                    exportAllData: true,
                                }}
                                onSelectionChange={(rows) => {
                                    setRowSelection(true);
                                    setChangeRowValue(rows);
                                }}
                                title="OP-Payout Calculation Details"
                            />
                        ) : (
                            ""
                        )}
                    </div>
                )}
            </Container>
            <Dialog
                fullWidth={true}
                maxWidth="md"
                onClose={handlerClose}
                aria-labelledby="customized-dialog-title"
                open={openr}
            >
                {/* <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClose}
          style={{ textAlign: "center" }}
        >
          Edit Doctor Details
        </DialogTitle>
        <DialogContent dividers>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-outlined-label">
              Select Doctor Name
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              name="doctorname"
              value={rowDetail.doctorname}
              onChange={handleInputChange}
              label="Select Role"
            >
              {doctorList &&
                doctorList.map((opt) => (
                  <MenuItem key={opt.id} value={opt.name}>
                    {opt.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <label>
            <b>Ratio</b>
          </label>
          <TextField
            id="Payout"
            label="Ratio"
            variant="outlined"
            fullWidth
            name="ratio"
            value={rowDetail.ratio}
            onChange={handleInputChange}
            style={{ marginBottom: "20px" }}
            autoComplete="off"
          />

          <label>
            <b> Percentage</b>
          </label>
          <TextField
            id="Payout"
            label="Percentage"
            variant="outlined"
            fullWidth
            name="percentage"
            value={rowDetail.percentage}
            onChange={handleInputChange}
            //   onChange={(e) => setPayout(e.target.value)}

            style={{ marginBottom: "20px" }}
            autoComplete="off"
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handlePayoutEdit}
            color="primary"
            variant="contained"
          >
            Update
          </Button>
          <Button
            autoFocus
            onClick={handlerClose}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog> */}

                <DialogTitle
                    id="customized-dialog-title"
                    onClose={handlerClose}
                    style={{ textAlign: "center" }}
                >
                    Edit Doctor Details
               </DialogTitle>
                <DialogContent dividers>
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                        <label>
                            <b>{changeRowValue && changeRowValue[0] && changeRowValue[0].ConductedByFinal}</b>
                        </label>
                    </FormControl>
                    <label>
                        <b>PayableNonPayable</b>
                    </label>
                    <TextField
                        id="Payout"
                        label="PayableNonPayable"
                        variant="outlined"
                        fullWidth
                        name="PayableNonPayable"
                        value={rowDetail.PayableNonPayable}
                        onChange={handleInputChange}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        onClick={handlePayoutEdit}
                        color="primary"
                        variant="contained"
                    >
                        Update
          </Button>
                    <Button
                        autoFocus
                        onClick={handlerClose}
                        color="secondary"
                        variant="contained"
                    >
                        Close
          </Button>
                </DialogActions>
            </Dialog>


            //splitDialog
            <Dialog
                fullWidth={true}
                maxWidth="md"
                onClose={() => {
                    setSplitDialog(false);
                }}
                aria-labelledby="customized-dialog-title"
                open={splitDialog}
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={handlerClose}
                    style={{ textAlign: "center" }}
                >
                    {spitRecord.ConductedBy}
                </DialogTitle>
                <Grid container>
                    <Grid item xs={12} xl={6} md={6} lg={6} sm={12}>
                        <DialogContent dividers>
                            <Typography>MPI: {spitRecord.MPI}</Typography>
                            <Typography>BillDate: {spitRecord.BillDate}</Typography>
                            <FormControl variant="outlined" style={{ width: "100%" }}>
                                <InputLabel id="demo-simple-select-outlined-label">
                                    Select Doctor Name
                </InputLabel>
                                <label>
                                    <b>{rowDetail.doctorname}</b>
                                </label>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    name="doctorname"
                                    value={rowDetail.doctorname}
                                    onChange={handleInputChange}
                                    label="  Select Doctor Name"
                                >
                                    {doctorList &&
                                        doctorList.map((opt) => (
                                            <MenuItem key={opt.id} value={opt.name}>
                                                {opt.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            <label>
                                <b>Ratio</b>
                            </label>
                            <TextField
                                id="Payout"
                                label="Ratio"
                                variant="outlined"
                                fullWidth
                                name="ratio"
                                value={rowDetail.ratio}
                                onChange={handleInputChange}
                                style={{ marginBottom: "20px" }}
                                autoComplete="off"
                            />
                        </DialogContent>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        xl={6}
                        md={6}
                        lg={6}
                        sm={12}
                        style={{ height: "300px", overflowY: "auto" }}
                    >
                        <MaterialTable
                            columns={[
                                // { title: "SlNo", field: "SlNo" },
                                { title: "MPI", field: "MPI" },
                                { title: "Bill No", field: "BillNo" },
                                { title: "Patient Name", field: "PatientName" },
                                { title: "ConductedBy", field: "ConductedBy" },
                                { title: "Share", field: "Share" },
                                { title: "Item Group Name", field: "ItemGroupName" },
                                { title: "Item Name", field: "ItemName" },
                                { title: "Gross", field: "Gross" },
                                { title: "Net", field: "Net" },
                            ]}
                            data={splitNewRec}
                            title="Split Rows"
                        />
                    </Grid>
                </Grid>

                <Button
                    color="secondary"
                    onClick={() => {
                        addRowData(spitRecord);
                    }}
                >
                    Add
        </Button>
                <DialogActions>
                    {splitNewRec && splitNewRec.length > 0 ? (
                        <Button
                            autoFocus
                            onClick={() => {
                                changeSplitRecord();
                            }}
                            color="secondary"
                            variant="contained"
                        >
                            Submit
                        </Button>
                    ) : (
                        ""
                    )}

                    <Button
                        autoFocus
                        onClick={() => {
                            setSplitDialog(false);
                        }}
                        color="secondary"
                        variant="contained"
                    >
                        Close
          </Button>
                </DialogActions>
            </Dialog>
            <Backdrop
                className={backClasses.backdrop}
                open={backDropOpen}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </>
    );
}
