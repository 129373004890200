import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
//import ui
import SidebarRD from "../../Components/Rd-sidebar";
import Navmain from "../../Components/Navbar";
import { Card, Table, Toast } from "react-bootstrap";
import MaterialTable from "material-table";
import ViewAgendaIcon from "@material-ui/icons/ViewAgenda";
import Dialog from "@material-ui/core/Dialog";
import Iframe from "react-iframe";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Button } from "@material-ui/core";
import { APIURL } from "../../CommomMethods/Fetch";

export default function SignedAgreement() {
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;

  console.log(user, "user-login");
  const [row, setRow] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [urlData, setUrlData] = useState("");
  useEffect(() => {
    axios
      .get(APIURL() + "users/gelAllsignedAgreement/getsigned/" + user.user.id)
      .then((res) => {
    // console.log(res.data.data, "agreement-signed");
        setRow(res.data.data);
      })
      .catch((error) => {});
  }, []);

  const viewDocFile = (url, filename) => {
// console.log(url, filename, "url-file");
    setOpenDialog(true);

    fetch(APIURL() + "billings/download/" + url, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        setUrlData(url);
      });
    });
  };

  const handleDownloadFile = (url) => {
    fetch(APIURL() + "billings/download/" + url, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "Agreement";
        a.click();
      });

      //window.location.href = response.url;
    });
  };
  return (
    <>
      <SidebarRD />

      <div className="main-content side-content pt-0">
        <Navmain />
        <Card.Body
          // style={bodyStyle}
          style={{ width: "100%", marginTop: "4rem" }}
        >
          <MaterialTable
            columns={[
              { title: "DOCTOR NAME", field: "name" },
              { title: "EMAIL", field: "email" },
              {
                title: "VIEW AGREEMENT",
                field: "Action",
                filtering: false,
                render: (row) => (
                  <ViewAgendaIcon
                    style={{
                      color: "#810e36",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      viewDocFile(row.agreement, "Agreement");
                    }}
                  />
                ),
              },
              {
                title: "DOWNLOAD",
                field: "Action",
                filtering: false,
                render: (row) => (
                  <GetAppIcon
                    style={{
                      color: "#810e36",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleDownloadFile(row.agreement);
                    }}
                  />
                ),
              },
            ]}
            data={row}
            options={{
              // filtering: true,
              sorting: true,
              exportButton: true,
              pageSize: 5,
              pageSizeOptions: [5, 10, 50, 100, 200, 500],
              search: true,
              exportAllData: true,
            }}
            title="Signed Agreement"
          />
        </Card.Body>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={() => {
          setOpenDialog(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        disableBackdropClick
      >
        <DialogTitle
          id="customized-dialog-title"
          // onClose={handlerClickcloseCommercials}
          style={{ textAlign: "center" }}
        ></DialogTitle>
        <DialogContent dividers></DialogContent>
        <Iframe
          url={urlData}
          width="900px"
          height="800px"
          id="myId"
          className="myClassname"
          display="initial"
          position="relative"
        />

        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
            }}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
