import React, { useState } from "react";
import { useSelector } from "react-redux";
// @material-ui/core components
import {
  FormControlLabel,
  FormGroup,
  IconButton,
  Paper,
} from "@material-ui/core";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { pink } from '@mui/material/colors';
import axios from "axios";
import { APIURL } from "../../../CommomMethods/Fetch";
import { propTypes } from "react-bootstrap/esm/Image";
import Switch from '@mui/material/Switch';
import { alpha, styled } from '@mui/material/styles';
import { toast } from "react-toastify";
export default function AddActivityAssignment({
  EditData,
  setPreview,
  setVariable
}) {
  const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: pink[600],
      '&:hover': {
        backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: pink[600],
    },
  }));
  console.log(EditData, "activitiesactivities")
  // Red : #ED6773
  // Green : #90ee90
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;
  const handleChange = async (val, item) => {
    if (val[item] == 'Yes') {
      val[item] = "No"
    } else if (val[item] != "Yes") {
      val[item] = "Yes"
    }
    await axios
      .post(APIURL() + "usercentermap/addAcitivityAssignments", { activityId: val["ActivityId"], RoleId: item, userId: user.user.id, val: val })
      .then((res) => {
        if(res.status === 200){
          toast.success('Successfully Updated');
          setVariable("Change")
          window.location.reload();
        }
        else{
          toast.error(res.statusText);
        }
      }).catch(err=>{
        toast.error(err.message);
      });
  }
  return (
    <Paper style={{ border: "solid", marginTop: "10px" }}>
      <table style={{ margin: "auto", width: "-webkit-fill-available" }}>
        <tr>
          {Object.keys(EditData).filter(elem => elem != "tableData" && elem != "ActivityId").map((elem =>
            <th className="pl-2" style={{ borderBottom: "1px solid" }}>{elem}</th>
          ))}
        </tr>
        <tr>
          {Object.keys(EditData).filter(elem => elem != "tableData" && elem != "ActivityId").map((item) =>
            <td className="pr-4">
              {item != "Name" ?
                <FormGroup>
                  <FormControlLabel control={<GreenSwitch checked={EditData[item] == "Yes" ? true : false} onChange={() => handleChange(EditData, item)} />} />
                </FormGroup>
                : EditData[item]}
            </td>
          )}
        </tr>
      </table>
    </Paper>
  );
}
