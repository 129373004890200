import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Table, Toast } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Navbar from "../../Components/Navbar";
import SidebarRD from "../../Components/Rd-sidebar";
import HrSidebar from "../../Components/hrSidebar";
import MaterialTable from "material-table";
import Sidebar from "../../Components/Sidebar";
import axios from "axios";
import { onboardById, addonboard } from "../../actions/onboardActions";
import { listusers, userslistall } from "../../actions/userActions";
import { documentlist } from "../../actions/documentActions";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { onboardState } from "../../CommomMethods/States";
import { toast } from "react-toastify";
import { profile } from "../../images/profile.jpg";
import { APIURL } from "../../CommomMethods/Fetch";
function DoctorListHR(props) {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [remark, setRemark] = useState("");
  const [doctorid, setdoctorId] = useState(0);
  const [step, setStep] = useState(1);
  const [rows, setRows] = useState([]);
  const [doclists, setDocLists] = useState([]);
  const [id, setId] = useState("");
  const [rid, setrId] = useState("");
  const [onboard, setOnboard] = useState([]);
  const [orow, setOrow] = useState({});
  const [open, setOpen] = React.useState(false);
  const [openr, setOpenr] = React.useState(false);
  const [lists, setList] = useState([]);
  const [uploadData, setUploadData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [openRemark, setOpenRemark] = React.useState(false);
  const [viewRemark, setViewRemark] = useState("");

  const getOnboard = useSelector((state) => state.getOnboard);
  const { getonboard } = getOnboard;
  useEffect(() => {
    dispatch(addonboard());
  }, [dispatch]);

  const handlemClickOpen = (id) => {
    dispatch(documentlist(id));
    setId(id);
    setOpen(true);
  };

  useEffect(() => {
    axios
      .get(APIURL() + "documents/list/" + user.user.id)
      .then((res) => res.data)
      .then((rows) => {
        setList(rows.data);
      });
  }, [refresh]);

  useEffect(() => {
    axios
      .get(APIURL() + "onboard/currentstatus")
      .then((res) => {
        setOnboard(res.data.data);
    // console.log(res.data.data, "onboarding in progress");
      })
      .catch((error) => { });
  }, []);

  const handlemClose = () => {
    setOpen(false);
  };

  const handlerClickOpen = (row) => {
    // setrId(id);
    setOrow(row);
    setOpenr(true);
  };
  const handlerClickOpenDoc = (id) => {
    setrId(id);
    setOpenr(true);
    // const obj = {
    //   isVerified: 1,
    //   verifiedBy:users[0] && users[0].users_role_id  ,
    //   verificationRemark:remark
    // };
    // var k = window.confirm("Are you sure want to delete this record ?");
    // if (k) {
    //   axios.put(APIURL()+"documents/documents/" + id, obj).then(res => {
    //   })
    // }
  };
  const handlerClose = () => {
    setOpenr(false);
  };
  console.log(getonboard, "12");
  const handleSubmitReject = () => {
    const obj = {
      doctorId: orow.doctorId,
      name: orow.name,
      currentAction: onboardState.ch.state,
      currentActionBy: users[0] && users[0].users_role_id,
      nextAction: onboardState.hr.state,
      remark: remark,
      status: 1,
    };

    axios.post(APIURL() + "onboard/action/", obj).then((res) => {
      toast.success("Rectification successfully submitted");
    });
    window.location.reload();
  };
  const Remark = (e) => {
    setRemark(e.target.value);
  };
  const back = useRef(null);
  const next = useRef(null);
  const reviewAndSubmit = useRef(null);
  const handleClose = () => setShow(false);
  const handleShow = (doctorid) => {
    setShow(true);

    dispatch(documentlist(doctorid));
  };
  const listDoc = useSelector((state) => state.listDoc);
  const { list } = listDoc;

  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;

  const userList = useSelector((state) => state.userList);
  const { users } = userList;

  const usersAll = useSelector((state) => state.usersAll);
  const { usersall } = usersAll;

  useEffect(() => {
    dispatch(userslistall());
  }, [dispatch]);
  function clickNext() {
    if (step == 1) {
      back.current.className = "standardButton rejectButton";
    } else if (step == 3) {
      next.current.className += " hide";
      back.current.className += " hide";
      reviewAndSubmit.current.className = "standardButton acceptButton";
    }
    setStep((s) => s + 1);
  }

  function clickBack() {
    if (step == 2) {
      back.current.className += " hide";
    }
    setStep((s) => s - 1);
  }
  function clickReviewAndSubmit() { }

  const formStyle = {
    borderRadius: "5px",
    border: "1px solid black",
  };
  const headerStyle = {
    backgroundColor: "#94248E",
    margins: "0px",
    padding: "10px",
    color: "white",
    borderRadius: "5px",
    borderBottom: "3px black solid",
  };
  const bodyStyle = {
    borderRadius: "5px",
  };
  const handleDownload = (url, filename) => {
    fetch(APIURL() + "documents/download/" + url, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
        // let a = document.createElement("a");
        // a.href = url;
        // a.download = filename;
        // a.click();
      });

      //window.location.href = response.url;
    });
  };

  useEffect(() => {
    axios
      .get(APIURL() + "onboard/currentstatus/")
      .then((res) => res.data)
      .then((rows) => {
        // let k = rows.data.filter((z) => z.doctorcommercial != null);

        setRows(rows.data);
      });
  }, []);
  useEffect(() => {
    axios
      .get(APIURL() + "documents/list/" + user.user.id)
      .then((res) => res.data)
      .then((rows) => {
        setList(rows.data);
        var k = rows.data;
        var l = [];
        k.forEach((element) => {
          l.push(element.docTitle);
        });
        setUploadData(l);
      });
  }, [refresh]);

  const handleApprove = (row) => {
    const updateData = {
      doctorId: row.doctorId,
      name: row.name,
      currentAction: onboardState.ch.state,
      currentActionBy: users[0] && users[0].users_role_id,
      nextAction: onboardState.rd.state,
      status: 1,
    };
    axios.post(APIURL() + "onboard/action/", updateData).then((res) => {
      window.location.reload();
    });
  };

  function viewDocuments(id) {
    setdoctorId(id);
  }

  useEffect(() => {
    axios.get(APIURL() + "documents/Alllist")
      .then((res) => {
    // console.log(res, "PPPPPPPPPP");
        // var p = res.data.data.filter((z)=> z.docTitle == "Passport Size Photograph")
        setDocLists(res.data.data);
      });
  }, [dispatch]);
  console.log(doclists, "list.data");
  const handleApproveDoc = (row) => {
    const updateData = {
      isVerified: "APPROVED",
      verificationRemark: "2",
      verifiedBy: users[0] && users[0].users_role_id,
    };
    axios
      .put(APIURL() + "documents/documents/" + row.id, updateData)
      .then((res) => { });
    window.location.reload();
  };

  const handleRemarkOpen = (remark) => {
    setViewRemark(remark);
    setOpenRemark(true);
  };
  const handleRemarkOpenDoc = (remark) => {
    setViewRemark(remark);
    setOpenRemark(true);
  };
  const handleCloseRemark = () => {
    setOpenRemark(false);
  };
  useEffect(() => {
    dispatch(listusers());
  }, [dispatch]);

  return (
    <>
      <HrSidebar />

      <div className="main-content side-content pt-0 mg-b-30">
        <div className="container-fluid">
          <div className="inner-body">
            <Navbar />

            <form>
              <br />
              <Card style={formStyle}>
                <Card.Body style={bodyStyle}>
                  <MaterialTable
                    columns={[
                      // {
                      //   title: "Profile Picture",
                      //   field: "Action",
                      //   filtering: false,
                      //   render: (row) => (
                      //     <>

                      //       {row.profile_picture != null || row.profile_picture != "" ? (
                      //         <>
                      //           {row.docTitle != null && row.docTitle == "Passport Size Photograph"?
                      //             <>
                      //               {doclists &&
                      //                 doclists.filter((z) => z.docTitle == "Passport Size Photograph")
                      //                   .map((ele) => (
                      //                     <img
                      //                       class="img-responsive"
                      //                       src={APIURL() + "documents/download/" + ele.url}
                      //                       style={{
                      //                         borderRadius: "49%",
                      //                         marginRight: "1rem",
                      //                         width: "500px",
                      //                       }}
                      //                     />
                      //                   ))}
                      //             </>
                      //             :
                      //             <>
                      //               <img
                      //                 class="img-responsive"
                      //                 src="https://trulabs.in/uploads/doctor_images/defualt-doc-image.jpg"
                      //                 style={{ width: "50px" }}
                      //               />{" "}
                      //             </>}
                      //         </>
                      //       ) : (
                      //         <>
                      //           <img
                      //             class="img-responsive"
                      //             src="https://trulabs.in/uploads/doctor_images/defualt-doc-image.jpg"
                      //             style={{ width: "50px" }}
                      //           />{" "}
                      //         </>
                      //       )}
                      //     </>
                      //   ),
                      // },
                      // { title: "Doctor Centers", field: "centers" },
                      { title: "Doctor Name", field: "name" },
                      { title: "Email Id", field: "email" },
                      {
                        title: " Doctor Profile",
                        field: "Action",
                        filtering: false,
                        render: (row) => (
                          <Link
                            to={{
                              pathname: "/DoctorProfileHR",
                              state: { id: row.id },
                            }}
                          >
                            {" "}
                            <i className="fas fa-eye" color="black"></i>{" "}
                          </Link>
                        ),
                      },
                    ]}
                    data={
                      onboard &&
                      onboard.filter(
                        (z) => z.currentAction !== "FINANCE_APPROVED"
                      )
                    }
                    // data={usersall && usersall.data && usersall.data.filter(z => z.users_role_id === 4)}
                    options={{
                      // filtering: true,
                      sorting: true,
                      exportButton: true,
                      pageSize: 5,
                      pageSizeOptions: [5, 10, 50, 100, 200, 500],
                      search: true,
                    }}
                    title="Onboarding in Process"
                  />
                  <br />
                  <br />
                  <MaterialTable
                    columns={[
                      { title: "Doctor Name", field: "name" },

                      {
                        title: " Doctor Profile",
                        field: "Action",
                        filtering: false,
                        render: (row) => (
                          <Link
                            to={{
                              pathname: "/DoctorProfileHR",
                              state: { id: row.doctorId },
                            }}
                          >
                            {" "}
                            <i className="fas fa-eye" color="black"></i>{" "}
                          </Link>
                        ),
                      },
                    ]}
                    data={
                      getonboard &&
                      getonboard.data &&
                      getonboard.data.filter(
                        (z) =>
                          z.nextAction === "COMPLETED" &&
                          z.currentAction == "FINANCE_APPROVED"
                      )
                    }
                    options={{
                      // filtering: true,
                      sorting: true,
                      exportButton: true,
                      pageSize: 5,
                      pageSizeOptions: [5, 10, 50, 100, 200, 500],
                      search: true,
                    }}
                    title="Onboarded Doctors"
                  />
                  {/* <Table style={{ width: "60.5%" }} borderless>
                    <thead>
                      <tr
                        style={{
                          textAlign: "center",
                          borderBottom: "1px solid rgb(200, 200, 200)",
                          backgroundColor: "hsla(340, 97%, 26%, 0.55)",
                          color: "white",
                        }}
                      >
                        <th className="col-2">Doctor Name</th>
                        <th>Doctor Profile</th>
                      </tr>
                    </thead>
                    <tbody style={{ whiteSpace: "nowrap" }}>
                      { usersall && usersall.data && usersall.data.filter(z => z.users_role_id === 4).map((ele) => (
                            <tr
                              id="color"
                              style={{
                                textAlign: "center",
                                borderBottom: "1px solid rgb(200, 200, 200)",
                              }}
                            >
                              <td>{ele.name}</td>
                              
                                  <td>
                                  <Link to={{
                                  pathname: '/DoctorProfileHR',
                                  state: { id: ele.id }
                                }}> <i
                                  className="fas fa-eye"
                                  color="black"
                                ></i> </Link>
                                   </td>
                             
                            </tr>
                          ))}
                    </tbody>
                  </Table> */}
                </Card.Body>
              </Card>
            </form>
          </div>
        </div>
        <Modal
          size="lg"
          scrollable
          dialogClassName="modalStandard"
          centered
          aria-labelledby="example-modal-sizes-title-lg"
          show={show}
          onHide={handleClose}
        >
          <Modal.Header className="modalHeader" closeButton>
            <Modal.Title className="modalTitle">Requested Id</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* {addpos.data && addpos.data.map((ele,index) =>( */}
            <Table style={{ width: "100%" }} borderless>
              <thead>
                <tr
                  style={{
                    textAlign: "center",
                    borderBottom: "1px solid rgb(200, 200, 200)",
                  }}
                >
                  <th className="col-2">Document Name</th>

                  <th> Documents</th>
                </tr>
              </thead>
              <tbody>
                {list.data &&
                  list.data.map((ele) => (
                    <tr
                      onClick={() => handleShow(ele.id)}
                      id="color"
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid rgb(200, 200, 200)",
                      }}
                    >
                      {/* <td>{ele.id}.</td> */}
                      <td>{ele.docTitle}</td>
                      <td>
                        <i
                          className="fas fa-file"
                          onClick={() => handleDownload(ele.url, ele.docTitle)}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center"></Modal.Footer>
        </Modal>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlemClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlemClose}
          style={{ textAlign: "center" }}
        >
          Uploaded Documents
        </DialogTitle>
        <DialogContent dividers>
          <Card style={formStyle}>
            <Card.Header style={headerStyle}></Card.Header>
            <Card.Body
              style={bodyStyle}
              style={{ overflowY: "auto", height: "300px" }}
            >
              <Table style={{ width: "100%" }} borderless>
                <thead>
                  <tr
                    style={{
                      textAlign: "center",
                      backgroundColor: "hsla(340, 97%, 26%, 0.55)",
                      color: "white",
                      borderBottom: "1px solid rgb(200, 200, 200)",
                    }}
                  >
                    <th className="col-2">Document Title</th>
                    <th className="col-2"> File</th>
                    {/* <th className="col-2"> Actions </th> */}
                  </tr>
                  {/* )} */}
                </thead>
                <tbody>
                  {list &&
                    list.data &&
                    list.data.map((ele) => (
                      <tr>
                        <td style={{ textAlign: "center" }}>{ele.docTitle}</td>
                        <td style={{ cursor: "pointer", textAlign: "center" }}>
                          {" "}
                          <i
                            className="fas fa-file"
                            onClick={() =>
                              handleDownload(ele.url, ele.docTitle)
                            }
                            title={"Download"}
                            style={{ fontSize: "22px" }}
                          />
                        </td>

                        {/* <td style={{ cursor: "pointer", textAlign: "center" }}>
                          {ele.isVerified === "APPROVED" ? (
                            "APPROVED"
                          ) : ele.isVerified === "REJECTED" ? (
                            "REJECTED"
                          ) : (
                            <div style={{ whiteSpace: "nowrap" }}>
                              <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                  handleApproveDoc(ele);
                                }}
                              >
                                Approve
                              </Button>

                              <Button
                                style={{ marginLeft: "5px" }}
                                size="small"
                                color="secondary"
                                variant="contained"
                                onClick={() => {
                                  handlerClickOpenDoc(ele.id);
                                }}
                              >
                                Reject
                              </Button>
                            </div>
                          )}
                        </td> */}
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handlemClose}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerClose}
        aria-labelledby="customized-dialog-title"
        open={openr}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClose}
          style={{ textAlign: "center" }}
        >
          Review
        </DialogTitle>
        <DialogContent dividers>
          <label>
            <b>Remark</b>
          </label>
          <textarea
            type="text"
            onChange={Remark}
            style={{ color: "black", border: "1px solid black" }}
            className="form-control"
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleSubmitReject}
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
          <Button
            autoFocus
            onClick={handlerClose}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handleCloseRemark}
        aria-labelledby="customized-dialog-title"
        open={openRemark}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClose}
          style={{ textAlign: "center" }}
        >
          Remark
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>{viewRemark}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleCloseRemark}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DoctorListHR;
