import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
// import 'react-owl-carousel2/style.css';
// import 'src/cloudnine-portal-frontend/node_modules/react-owl-carousel2/lib/styles.css'
// import '../node_modules/react-slick/lib/'
import './index.css';
import App from './App/App';
import { Provider } from 'react-redux';
import store from './store.js'
import reportWebVitals from './reportWebVitals';

import axios from 'axios';


// useEffect(()=>{

// }, [JSON.parse(localStorage.getItem('user'))?.accessToken]);

var thisIns = this;

axios.interceptors.request.use(
  function(successfulReq) {
    // console.clear();
    // console.log('chandan',successfulReq);
 
    // const siteUrl = (successfulReq.url).split("/")
    // const isUserLogin = !siteUrl.find(ele => (ele == "UsersLogin" && ele == "login" && ele == "otp"))
    
    
    // console.log(successfulReq.url, token, isUserLogin, "interceptors")


    if(successfulReq.url.includes('cloudninecare') || successfulReq.url.includes('localhost')){
      const token = JSON.parse(localStorage.getItem('user'))?.accessToken;
      if(token){
        successfulReq.headers.Authorization = "Bearer "+ token;
        successfulReq.headers['x-access-token'] = token;
      }
    }
   
    return successfulReq;
  },
  function(error) {
// console.log(error, "dkhfbsjdbfjksdbfjdk")
    thisIns.manageResponse(error.response)
    return Promise.reject(error);
  }
);

axios.interceptors.response.use((response) => {
  return response;
},async error => {
  if (error.response.status === 401) {
// console.log('Token Expire')
    // localStorage.removeItem('userData');
    // localStorage.removeItem('token');
    localStorage.clear();
    window.location.href = '/';
  }
  // else if (error.response.status === 403){
  //   // //console.log(error.response.data.refresh_token);
  //   if(error.response.data.refresh_token){
  //     if(refresh_token){
  //       refresh_token = false;
  //       //console.log('refreshing the token')
  //       await axios.get(`${ENV.API_END_POINT}UsersLogin/refresh`)
  //       .then(async (res) => {
  //         refresh_token = true;
  //           //console.log(res)
  //       });
  //     }
  //   }else{
  //     localStorage.removeItem('userData');
  //     localStorage.removeItem('token');
  //     localStorage.clear();
  //     window.location.href = '/';
  //   }
  // }
  return error.response
});



// Save the original fetch function
const originalFetch = window.fetch;

// Override the fetch function to intercept requests and responses
window.fetch = async function(url, options = {}) {
  // Intercept request: Modify the request here if needed
  // console.log('Request Intercepted:', url, options);

  // You can add headers or modify the options
  const token = JSON.parse(localStorage.getItem('user'))?.accessToken;

  options.headers = {
    ...options.headers,
    'Authorization': 'Bearer '+ token,  // Example: Add auth token to headers
    'x-access-token': token,  // Example: Add auth token to headers
  };

  try {
    // Proceed with the original fetch request
    const response = await originalFetch(url, options);

    // Intercept response: Do something with the response if needed
    // console.log('Response Intercepted:', response);

    // Check if response is ok (status code is 200-299)
    if (!response.ok) {
      // You can handle errors here (e.g., log them, throw custom errors)
      console.error('Error response:', response.statusText);
    }

    return response; // Return the intercepted response
  } catch (error) {
    // Handle fetch errors (network errors, etc.)
    console.error('Fetch failed:', error);
    throw error; // Rethrow the error if needed
  }
};





ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
