import React, { useEffect, useState } from "react";
// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Box, FormControl, Paper, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Backdrop from "@material-ui/core/Backdrop";
import c9gif from "../../../SampleFile/cloudnine.gif";

const backDropStyle = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const styleSheet = makeStyles((theme) => ({
  paperStyle: { marginTop: "10px" },
  textFieldstyle: {
    marginBottom: "20px",
    marginTop: "20px",
  },
  btnStyle: {
    width: '10%',
    marginLeft: '50%',
    marginRight: '80px',
    marginBottom: '10px',
    marginTop: 'inherit',
  },
  btnStyle1: {
    width: '10%',
    marginLeft: '50%',
    marginRight: '80px',
    marginBottom: '10px',
    marginTop: 'inherit',
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    width: "100%",
  },
}));

export default function AddFirmMapping({
  addDepartment,
  ItemGroups,
  ItemNames,
  doctorList,
  centerList
}) {
  const style = styleSheet();
  const classes = useStyles();
  const initialFormState = {
    PoolId: '',
    siteCode: '',
    itemGroupName: '',
    itemName: '',
    doctorId: [],
  };

  const [department, setDepartment] = useState(initialFormState);
  const [centerValue, setCenterValue] = useState({});
  const [ItemGroupValue, setItemGroupValue] = useState({});
  const [ItemNamesValue, setItemNamesValue] = useState({});
  const [DocValue, setDocValue] = useState([]);
  const [doctorListByCenter, setDoctorListByCenter] = useState({});
  const [ItemGroupByCenter, setItemGroupByCenter] = useState([]);
  const [ItemNamesByItemGroups, setItemNamesByCenter] = useState([]);
  const [tablleView, setTableView] = useState(false);
  const [tablleData, setTableData] = useState([]);
  const [backDropOpen, setBackDropOpen] = useState(false);
  const backClasses = backDropStyle();

  const handleInputChange = (event) => {
    const { name, value } = event.target
// console.log(name, value, "name,value")
    setDepartment({ ...department, [name]: value })
  }
  const addRatio = () => {
    let arr = []
    setTableView(true)
    department.doctorId.map((elem) => {
      arr.push({
        "PoolId": department.PoolId,
        "siteCode": department.siteCode,
        "itemGroupName": department.itemGroupName,
        "itemName": department.itemName,
        "doctorId": elem.id,
        "ratio": elem.ratio
      })
    })
    setTableData(arr)
  }
  const handleChangeRatio = (event, Index) => {
// console.log(event.target.value, Index, "event, Index")
    let tableData = [...tablleData]
    tableData[Index].ratio = parseFloat(event.target.value) / 100
// console.log(tablleData, "event, Index")
    setTableData(tableData)
// console.log(tablleData, "event, Index")
    // department.doctorId.find((el) => el.id == item.id)['ratio'] = event.target.value
    // setDepartment(department)
  }
  const handleReset = () => {
    setTableView(false)
    setDepartment({
      PoolId: '',
      siteCode: '',
      itemGroupName: '',
      itemName: '',
      doctorId: [],
    })
    setDoctorListByCenter({})
    setTableData([])
    setCenterValue({})
    setItemGroupValue({})
    setItemNamesValue({})
    setDocValue([])
  }
  return (
    <>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          addDepartment(tablleData);
        }}
      >
        <Paper className={style.paperStyle}>
          <div className="row mt-2 pt-2">
            <div className="col-4" style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>Select Firm :</div>
            <div className="col-6">
              <FormControl
                variant="outlined"
                style={{ width: "100%", marginTop: "20px" }}
              >
                <TextField id="outlined-basic" label="Enter Pool Name" name='PoolId' variant="outlined" value={department.PoolId} onChange={handleInputChange} sx={{ width: 515 }} />
              </FormControl>
            </div>
          </div>
          <div className="row mt-2 pt-2">
            <div className="col-4" style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>Select Center :</div>
            <div className="col-6">
              <Autocomplete
                id="combo-box-demo"
                disabled={department.PoolId && department.PoolId != '' ? false : true}
                value={centerValue}
                onChange={(event, newValue) => {
                  setBackDropOpen(true);
                  setCenterValue(newValue);
                  setDoctorListByCenter(doctorList.filter((item => item.centerCode == newValue.code)));
                  setItemNamesByCenter(ItemNames.filter((item => item.siteCode == newValue.code)));
                  setDepartment({ ...department, "siteCode": newValue.code })
                  setBackDropOpen(false);
                }}
                options={centerList}
                getOptionLabel={(option) => option.name ? option.name : ''}
                sx={{ width: 515, height: 20 }}
                renderInput={(params) => <TextField {...params} label="Centers" />}
                renderOption={(props, option, state) => {
                  return (
                    <Typography key={option.name} {...props} style={{ color: 'black' }}>{option.name}</Typography>
                  );
                }}
              />
            </div>
          </div>
          <div className="row pt-2" style={{ marginTop: '43px' }}>
            <div className="col-4" style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>Select Item Groups :</div>
            <div className="col-6">
              <Autocomplete
                id="combo-box-demo"
                value={ItemGroupValue}
                disabled={centerValue && Object.keys(centerValue).length > 0 ? false : true}
                onChange={(event, newValue) => {
                  setBackDropOpen(true);
                  setItemGroupValue(newValue);
                  setItemNamesByCenter(ItemNames.filter((item => item.itemGroupId == newValue.itemGroupId)));
                  setDepartment({ ...department, "itemGroupName": newValue.itemGroupName })
                  setBackDropOpen(false);
                }}
                options={ItemGroups}
                getOptionLabel={(option) => option.itemGroupName ? option.itemGroupName : ''}
                sx={{ width: 515, height: 20 }}
                renderInput={(params) => <TextField {...params} label="Item Groups" />}
                renderOption={(props, option, state) => {
                  return (
                    <Typography key={option.itemGroupName} {...props} style={{ color: 'black' }}>{option.itemGroupName}</Typography>
                  );
                }}
              />
            </div>
          </div>
          <div className="row pt-2" style={{ marginTop: '43px' }}>
            <div className="col-4" style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>Select Items :</div>
            <div className="col-6">
              <Autocomplete
                id="combo-box-demo"
                value={ItemNamesValue}
                disabled={ItemGroupValue && Object.keys(ItemGroupValue).length > 0 ? false : true}
                onChange={(event, newValue) => {
                  setItemNamesValue(newValue);
                  setDepartment({ ...department, "itemName": newValue.itemName })
                }}
                options={ItemNamesByItemGroups}
                getOptionLabel={(option) => option.itemName ? option.itemName : ''}
                sx={{ width: 515, height: 20 }}
                renderInput={(params) => <TextField {...params} label="Items" />}
                renderOption={(props, option, state) => {
                  return (
                    <Typography key={option.itemName} {...props} style={{ color: 'black' }}>{option.itemName}</Typography>
                  );
                }}
              />
            </div>
          </div>
          <div className="row pt-2" style={{ marginTop: '43px' }}>
            <div className="col-4" style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>Select Doctors :</div>
            <div className="col-6">
              <Autocomplete
                multiple
                disabled={centerValue && Object.keys(centerValue).length > 0 ? false : true}
                id="checkboxes-tags-demo"
                options={doctorListByCenter}
                onChange={(event, newValue) => {
                  // console.log(newValue,"DocValue------")
                  setDocValue(newValue);
                  let doctorList = []
                  newValue.map(item => doctorList.push({ "id": item.doctorId, "ratio": '' }))
                  setDepartment({ ...department, "doctorId": doctorList })
                }}
                disableCloseOnSelect
                getOptionLabel={(option) => option.doctorName}
                renderOption={(props, option, { selected }) => (
                  <Typography {...props} style={{ color: 'black' }}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.doctorName}
                  </Typography>
                )}
                style={{ width: 515 }}
                renderInput={(params) => (
                  <TextField {...params} label="Doctor List" placeholder="Doctor List" />
                )}
              />
            </div>
          </div>
          {tablleView && tablleData.length > 0 ?
            <div>
              <table style={{ border: 'solid 1px', marginTop: '13px', marginBottom: '13px', marginLeft: '90px' }}>
                <tr>
                  <th className="p-2" style={{ width: '26%' }}>Item Group Name</th>
                  <th className="p-2" style={{ width: '26%' }}>Item Name</th>
                  <th className="p-2" style={{ width: '26%' }}>Doctor Name</th>
                  <th className="p-2" style={{ width: '26%' }}>Ratio</th>
                </tr>
                {tablleData.map((item, Index) =>
                  <tr>
                    <td className="p-2">{item.itemGroupName}</td>
                    <td className="p-2">{item.itemName}</td>
                    <td className="p-2">{DocValue.find((elem) => elem.doctorId === item.doctorId).doctorName}</td>
                    <td className="p-2">
                      <div className="row" style={{ flexWrap: 'nowrap !important' }}>
                        <input className="form-control" value={item.ratio ? parseFloat(item.ratio) * 100 : 0}
                          onChange={(event) => handleChangeRatio(event, Index)} style={{ color: 'black', width: '60px' }} />
                        <input type='text' className="form-control" value={'%'} disabled style={{ color: 'black', width: '39px', fontWeight: 'bold' }} />
                      </div>
                    </td>
                  </tr>
                )}
              </table>
            </div>
            : null}
          {tablleView ?
            <div className="row">
              <div className="col-10">
                <Button color="primary" variant="contained" className={style.btnStyle1} onClick={() => handleReset()}>Reset</Button>
              </div>
              <div className="col-1">
                <Button type="submit" color="primary" variant="contained" disabled={tablleData.find(item => item.ratio) ? false : true} className={style.btnStyle} style={{position:'relative','marginLeft':'-385px', width:'90px'}}>Add</Button>
              </div>
            </div>
            :
            <Button color="primary" variant="contained" className={style.btnStyle1} onClick={addRatio} disabled={doctorListByCenter && doctorListByCenter.length > 0 ? false : true}>Add Ratio</Button>
          }
        </Paper>
      </form>
      <Backdrop className={backClasses.backdrop} open={backDropOpen}>
        <img src={c9gif} alt="loading..." />
      </Backdrop>
    </>
  );
}
