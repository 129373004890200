import React, { useEffect, useState } from "react";
import { Card, Table, Toast } from "react-bootstrap";
import Navbar from "../../Components/Navbar";
import { Modal } from "react-bootstrap";
//import ui components
import MaterialTable from "material-table";
import DoctorSidebar from "../../Components/DoctorSidebar";
import { agreementState, onboardState } from "../../CommomMethods/States";
//import material-ui component
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
//import axios
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import { useDispatch, useSelector } from "react-redux";
import { listusers } from "../../actions/userActions";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import { toast } from "react-toastify";
import FixedfullTime from "../Payout/NEW/FixedfullTime";
import FixedMG from "../Payout/NEW/fixedMG";
import VcChennai from "../Payout/NEW/VcChennai";
import VisitingCon from "../Payout/NEW/VisitingCon";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { APIURL } from "../../CommomMethods/Fetch";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "fixed",
    backgroundColor: "#810e36",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },

  buttonProgress: {
    // backgroundColor: "red",
    color: "white",
    position: "absolute",
    top: "50%",
    left: "20%",
    marginTop: -12,
    marginLeft: -12,
  },
}));
export default function CheckAgreement() {
  const [show, setShow] = useState(false);
  const [rows, setRows] = useState([]);
  const [orow, setOrow] = useState({});
  const [openr, setOpenr] = useState(false);
  const [status, setStatus] = useState(false);
  const [remark, setRemark] = useState("");
  const [viewAgreement, setViewAgreement] = useState(false);
  const [row, setRow] = useState({});
  const [categoty, setCategory] = useState("");
  const [docData, setDocData] = useState([]);
  const classes = useStyles();
  const dispatch = useDispatch();
  const userList = useSelector((state) => state.userList);
  const { users } = userList;

  const [openReject, setOpenRejectmodal] = React.useState(false);
  const [openApprove, setOpenApprovemodal] = React.useState(false);
  const [review, setReview] = useState("");
  const [approve, setapprove] = useState([]);
  const [rej, setRej] = useState([]);

  const userLogin = useSelector((state) => state.userLogin);
  // bringing things from the reducer
  const { loading, error, user } = userLogin;
  let history = useHistory();
  useEffect(() => {
    dispatch(listusers());
  }, [dispatch]);

  useEffect(() => {
    axios
      .get(APIURL() + "onboard/currentAgreementStatus/")
      .then((res) => {
    // console.log(res.data.data,"res.data.data");
        setRows(res.data.data);
      })
      .catch((error) => { });
  }, [status]);

  const formStyle = {
    borderRadius: "5px",
    border: "1px solid black",
  };
  const headerStyle = {
    backgroundColor: "#94248E",
    margins: "0px",
    padding: "10px",
    color: "white",
    borderRadius: "5px",
    borderBottom: "3px black solid",
  };
  const bodyStyle = {
    borderRadius: "5px",
  };
  const handleClose = () => setShow(false);
  const handleShow = (doctorid) => {
    setShow(true);
  };

  const handleApprove = (row) => {
    setStatus(true);
    const obj = {
      doctorId: approve.doctorId,
      name: approve.name,
      currentAction: agreementState.dr_accepted.state,
      currentActionBy: users[0].id,
      nextAction: agreementState.completed,
      remark: review,
      status: 1,
    };
    axios.post(APIURL() + "onboard/agreementAction/", obj).then((res) => {
      setStatus(false);
      toast.success("Success");
      history.push("/Estimation");
      setTimeout(() => {
        // toast.success("Success!");
        window.location.reload();
      }, 2000);
    });
  };
  const handleComments = (e) => {
    setReview(e.target.value)
  };
  const handleApproveDialog = (row) => {
    //setDocid(row.doctorId);
    setOpenApprovemodal(true)
    setapprove(row);
  };

  const handlerClickOpen = (row) => {
    setOrow(row);
    setOpenr(true);
  };
  const Remark = (e) => {
    setRemark(e.target.value);
  };

  const handlerClose = () => {
    setOpenr(false);
  };

  const handleSubmitReject = () => {
    const obj = {
      doctorId: orow.doctorId,
      name: orow.name,
      currentAction: agreementState.dr_accepted.state,
      currentActionBy: users[0].id,
      // nextAction: agreementState.hr_revision.state,
      nextAction: agreementState.dr_decline.state,
      remark: remark,
      status: 1,
    };

    axios.post(APIURL() + "onboard/agreementAction/", obj).then((res) => {
      toast.success("Rejection successfully submitted");
    });

    const obj1 = {
      doctorId: orow.doctorId,
      name: orow.name,
      currentAction: agreementState.dr_decline.state,
      currentActionBy: users[0].id,
      nextAction: onboardState.ch.state,
      remark: remark,
      status: 1,
    };

    axios.post(APIURL() + "onboard/action/", obj1).then((res) => {
      toast.success("Rectification successfully submitted");
    });

    window.location.reload();
  };
  const closeAgreement = () => {
    setViewAgreement(false);
  };

  const viewAgreementDoc = (row) => {
    setRow(row);
    axios
      .get(APIURL() + "onboard/agreementView/" + row.doctorId)
      .then((res) => {
        if (res.data != null) {
          setCategory(res.data.data.vacancyData[0].category);
          setDocData(res.data.data);
      // console.log(res.data, "chk doc ag");
        }
      });
    setViewAgreement(true);
  };

  let data = user.user.id;
  return (
    <>
      <DoctorSidebar />

      <div className="main-content side-content pt-0 mg-b-30">
        <div className="container-fluid">
          <div className="inner-body">
            <Navbar />

            <form>
              <br />
              <Card style={formStyle}>
                <Card.Body style={bodyStyle}>
                  <MaterialTable
                    columns={[
                      { title: "Doctor Name", field: "name" },
                      { title: "Comments", field: "remark" },

                      // {
                      //   title: "Current Action",
                      //   field: "Action",
                      //   filtering: false,
                      //   render: (row) => (
                      //     <>{agreementState.hr_revision.message}</>
                      //   ),
                      // },
                      {
                        title: "View Agreements",
                        field: "Action",
                        filtering: false,
                        render: (row) => (
                          <AssignmentIcon
                            style={{ color: "#810e36" }}
                            color="black"
                            onClick={() => {
                              viewAgreementDoc(row);
                            }}
                          ></AssignmentIcon>
                        ),
                      },
                      {
                        title: "Action",
                        field: "Action",
                        filtering: false,
                        render: (row) =>
                          row.nextAction ===
                            agreementState.dr_accepted.state ? (
                            <div style={{ whiteSpace: "nowrap" }}>
                              {/* <Button
                                size="small"
                                style={{
                                  backgroundColor: "#810e36",
                                  color: "white",
                                }}
                                variant="contained"
                                onClick={() => {
                                  handleApprove(row);
                                }}
                              >
                                Approve
                              </Button> */}
                              <div className={classes.wrapper}>
                                <Button
                                  variant="contained"
                                  size="small"
                                  // color="primary"

                                  style={{
                                    backgroundColor: "#810e36",
                                    color: "white",
                                  }}
                                  // className={buttonClassname}
                                  disabled={status}
                                  onClick={() => {
                                    handleApproveDialog(row);
                                  }}
                                >
                                  Accept
                                </Button>
                                {status && (
                                  <CircularProgress
                                    size={24}
                                    className={classes.buttonProgress}
                                  />
                                )}

                                {/* <Button
                                  style={{ marginLeft: "5px" }}
                                  size="small"
                                  color="secondary"
                                  variant="contained"
                                  onClick={() => {
                                    handlerClickOpen(row);
                                  }}
                                >
                                  Rectification Required
                                </Button> */}
                              </div>
                            </div>
                          ) : (
                              <>{row.nextAction === agreementState.finance_approval.state ? <span className="badge badge-success">Accepted </span> : <span className="badge badge-warning">Sent For Review</span>}</>
                            ),
                            // <div style={{ whiteSpace: "nowrap" }}>
                            //   <Button
                            //     size="small"
                            //     color="primary"
                            //     variant="contained"
                            //     disabled
                            //   >
                            //     No Action Required
                            //   </Button>
                            // </div>
                          // ),
                      },
                    ]}
                    data={
                      rows && rows.filter((z) => z.doctorId == user.user.id)
                    }
                    options={{
                      // filtering: true,
                      sorting: true,
                      exportButton: true,
                      pageSize: 5,
                      pageSizeOptions: [5, 10, 50, 100, 200, 500],
                      search: true,
                    }}
                    title="Agreement Details"
                  />
                </Card.Body>
              </Card>
            </form>
          </div>
        </div>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerClose}
        aria-labelledby="customized-dialog-title"
        open={openr}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClose}
          style={{ textAlign: "center" }}
        >
          Review
        </DialogTitle>
        <DialogContent dividers>
          <label>
            <b>Remark</b>
          </label>
          <textarea
            type="text"
            onChange={Remark}
            style={{ color: "black", border: "1px solid black" }}
            className="form-control"
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleSubmitReject}
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
          <Button
            autoFocus
            onClick={handlerClose}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen
        open={viewAgreement}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="end"
              style={{ marginLeft: "99%" }}
              color="inherit"
              onClick={closeAgreement}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <List>
          {categoty === "Full Time Consultant" || categoty === 'Retainer' ? (
            <FixedfullTime
              history={row}
              recData={docData}
              catagory={categoty}
            />
          ) : categoty === "Minimum Guarentee(MG)" ? (
            <FixedMG history={row} recData={docData} catagory={categoty} />
          ) : categoty === "Visiting Consultant" ? (
            <VisitingCon history={row} recData={docData} catagory={categoty} />
          ) : categoty === "Visiting Consultant Chennai" ? (
            <VcChennai history={row} recData={docData} catagory={categoty} />
          ) : (
            "hi"
          )}
        </List>
      </Dialog>

      {/* APPROVE MODAL */}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={() => {
          setOpenApprovemodal(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={openApprove}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpenApprovemodal(false);
          }}
          style={{ textAlign: "center" }}
        >Accept</DialogTitle>
        <DialogContent dividers>
          <label>
            Comments{" "}
          </label>
          <textarea
            placeholder="Comments"
            type="text"
            onChange={handleComments}
            style={{ color: "black", border: "1px solid black" }}
            className="form-control"
            required
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleApprove}
            color="secondary"
            variant="contained"
            size="small"
          >

            Accept
          </Button>
          <Button
            autoFocus
            onClick={() => {
              setOpenApprovemodal(false);
            }}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>


    </>
  );
}
