import React, { useEffect, useState } from "react";
// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {
  FormControl,
  FormControlLabel,
  Paper,
  RadioGroup,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const styleSheet = makeStyles((theme) => ({
  paperStyle: { marginTop: "10px" },
  textFieldstyle: {
    marginBottom: "20px",
    marginTop: "20px",
  },
  btnStyle: {
    marginTop: "20ox",
    marginRight: "80px",
    marginLeft: "auto",
    display: "block",
    marginBottom: "20px",
  },
}));

export default function AddUserRegionMap({
  addDepartment,
  regionRows,
  userRows,
}) {
  const style = styleSheet();
  const initialFormState = {
    id: null,
    regionid: "",
    centerId: "0",
    roleid: "1",
    userId: "",
  };
  console.log(userRows, "user rows");
  const [department, setDepartment] = useState(initialFormState);
  const [value, setValue] = React.useState("1");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
// console.log(name, value, "name-name");
    setDepartment({ ...department, [name]: value });
  };
  const [regingName, setRegionName] = useState("");
  useEffect(() => {
  return  department.regionid === 1
      ? setRegionName("NORTH")
      : department.regionid == 2
      ? setRegionName("SOUTH")
      : department.regionid === 3
      ? setRegionName("WEST")
      : "";
  }, [department.regionid]);

  return (
    <>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          department.status = value;

          addDepartment(department);
        }}
      >
        <Paper className={style.paperStyle}>
          <Grid container>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <FormControl
                variant="outlined"
                style={{ width: "100%", marginTop: "20px" }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Region
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="regionid"
                  value={department.regionid}
                  onChange={handleInputChange}
                  label="Select Center"
                >
                  {regionRows &&
                    regionRows.map((opt) => (
                      <MenuItem key={opt.id} value={opt.id}>
                        {opt.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControl
                variant="outlined"
                style={{ width: "100%", marginTop: "20px" }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Select User
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="userId"
                  value={department.userId}
                  onChange={handleInputChange}
                  label="Select Center"
                >
                  {userRows &&
                    userRows
                      .filter(
                        (z) =>
                          z.users_role_id == 1 && z.name.includes(regingName)
                      )
                      .map((opt) => (
                        <MenuItem key={opt.id} value={opt.id}>
                          {opt.name}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                className={style.btnStyle}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </>
  );
}
