import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Navbar from "../../Components/Navbar";
import { login } from "../../actions/authActions";
import DoctorSidebar from "../../Components/DoctorSidebar";
import axios from "axios";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Grid } from "@mui/material";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { APIURL } from "../../CommomMethods/Fetch";
const stylesmodal = makeStyles({
  root: {
    marginTop: "18px",
    marginBottom: "15px",
  },
});
export default function AddEmployer() {
  const styleClasses = stylesmodal();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  // bringing things from the reducer
  const { user } = userLogin;
  const [data, setData] = React.useState("");
  const [date, setDate] = React.useState(new Date());
  const [toDate, setToDate] = React.useState(new Date());
  const [error, setError] = useState({});

  const initialForm = {
    designation: "",
    organization: "",
    currentcompany: "",
    workfrom: "",
    workto: "",
    describejob: "",
    noticeperiod: "",
    doctorId: user.user.id,
    hrverify: "No",
    status: 1,
  };
  const [employer, setEmployer] = useState(initialForm);

  const AddEmployer = () => {
    let temp = {};
    temp.designation =
      employer.designation != "" ? "" : "This is required field.";
    temp.describejob =
      employer.describejob != "" ? "" : "This is required field.";
    temp.currentcompany =
      employer.currentcompany != "" ? "" : "This is required field.";
    temp.organization =
      employer.organization != "" ? "" : "This is required field.";
    temp.noticeperiod =
      employer.noticeperiod != "" ? "" : "This is required field.";
    setError({ ...temp });
    if (Object.keys(error).length) {
      const isEmpty = Object.values(error).every((x) => x === "");
  // console.log(isEmpty, "rcc");
      if (isEmpty === true) {
        alert(12);
        axios.post(APIURL() + "employer", employer).then((res) => {
      // console.log(res, "yuyuyu");
        });
      }
    }
  };

  const UpdateEmployer = () => {
    const obj = {
      doctorId: user.user.id,
      data: data,
      hrverify: "No",
      status: 1,
    };
    axios.put(APIURL() + "employer/", obj).then((res) => {
      window.location.reload();
      toast.success("Successfully Submitted");
    });
  };

  var newdata = [];
  const selectedValue = (event) => {
    const { name, value } = event.target;
    if (name === "designation") {
      if (value.length > 0) {
        error.designation = "";
        setError({ ...error });
      } else {
        error.designation = "This is required field.";
        setError({ ...error });
      }
    }
    if (name === "describejob") {
      if (value.length > 0) {
        error.describejob = "";
        setError({ ...error });
      } else {
        error.describejob = "This is required field.";
        setError({ ...error });
      }
    }
    if (name === "currentcompany") {
      if (value.length > 0) {
        error.currentcompany = "";
        setError({ ...error });
      } else {
        error.currentcompany = "This is required field.";
        setError({ ...error });
      }
    }

    if (name === "organization") {
      if (value.length > 0) {
        error.organization = "";
        setError({ ...error });
      } else {
        error.organization = "This is required field.";
        setError({ ...error });
      }
    }

    if (name === "noticeperiod") {
      if (value.length > 0) {
        error.noticeperiod = "";
        setError({ ...error });
      } else {
        error.noticeperiod = "This is required field.";
        setError({ ...error });
      }
    }
    setEmployer({ ...employer, [name]: value });
  };
  const handleChange = (a) => {
    setDate(a);
  };
  const handleChangeworkfrom = (a) => {
    setToDate(a);
  };

  return (
    <>
      <DoctorSidebar />
      <CssBaseline />

      <Container style={{ margin: "10px 0px 7px 235px", width: "80%" }}>
        <Navbar />
        <div
          style={{
            backgroundColor: "#810e36",
            color: "white",
            padding: "5px",
            textAlign: "center",
            margin: "1rem",
          }}
        >
          Add Employer
        </div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            employer.workfrom = date;
            employer.workto = toDate;
            AddEmployer();
          }}
        >
          <Grid container spacing={2} style={{ marginTop: "15px" }}>
            <Grid item xs={12} md={6} lg={6} sm={12} xl={6}>
              <TextField
                style={{ marginBottom: "15px" }}
                name="designation"
                value={employer.designation}
                label="Designation"
                onChange={selectedValue}
                variant="outlined"
                fullWidth
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.designation}
              />
              <TextField
                style={{ marginBottom: "15px" }}
                name="describejob"
                value={employer.describejob}
                label="Describe Job Profile"
                onChange={selectedValue}
                variant="outlined"
                fullWidth
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.describejob}
              />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-dialog"
                  autoOk
                  label="Worked Till"
                  // disablePast
                  id="workto"
                  value={date}
                  className={styleClasses.root}
                  onChange={handleChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  fullWidth
                />
              </MuiPickersUtilsProvider>

              <TextField
                name="currentcompany"
                style={{ marginBottom: "15px" }}
                value={employer.currentcompany}
                label="Experience in Cloudnine"
                onChange={selectedValue}
                variant="outlined"
                fullWidth
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.currentcompany}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6} sm={12} xl={6}>
              <TextField
                style={{ marginBottom: "15px" }}
                label="Organization"
                name="organization"
                value={employer.organization}
                variant="outlined"
                onChange={selectedValue}
                fullWidth
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.organization}
              />
              <TextField
                style={{ marginBottom: "15px" }}
                name="noticeperiod"
                value={employer.noticeperiod}
                label="Notice Period"
                onChange={selectedValue}
                variant="outlined"
                fullWidth
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.noticeperiod}
              />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-dialog"
                  autoOk
                  label="Started working from"
                  // disablePast
                  id="workfrom"
                  value={toDate}
                  onChange={handleChangeworkfrom}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Button
              variant="contained"
              style={{
                float: "Right",
                margin: "2rem",
                backgroundColor: "#810e36",
                color: "white",
              }}
              type="submit"
            >
              Submit
            </Button>
            {/* <Button variant="contained" onClick={UpdateEmployer} style={{ float: "Right", margin: "2rem" }}>Update</Button> */}
          </Grid>
        </form>
      </Container>
      {/* <div className="main-content side-content pt-0">
        <div
          className="container-fluid"
          style={{ margin: "0 auto", backgroundColor: "white" }}
        >
          <div className="inner-body">
            <div
              className="container"
              style={{ width: "100%", height: "200vh" }}
            >
              <h2>Add Employer</h2>
              <hr />
              <br />
              
              <br />
              <hr />
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
