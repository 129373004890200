import React, { useEffect, useState } from "react";
// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  FormControl,
  FormControlLabel,
  Paper,
  RadioGroup,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const styleSheet = makeStyles((theme) => ({
  paperStyle: { marginTop: "10px" },
  textFieldstyle: {
    marginBottom: "20px",
    marginTop: "20px",
  },
  btnStyle: {
    right: "5px",
    marginBottom: "20px",
  },
  cancellBtnStyle: {
    marginBottom: "20px",
  },
}));

export default function EditUser({
  currentDepartment,
  setEditing,
  setPreview,
  updateDepartment,
  rows,
  roleRows
}) {
  const style = styleSheet();
  //   const initialFormState = { id: null, name: "", code: "", status: "" };
  const [department, setDepartment] = useState(currentDepartment);
  const [value, setValue] = React.useState(0);
  const [check, setCheck] = useState(false);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
// console.log(name)
    if(name === "users_role_id"){
      setDepartment({ ...department, [name]: value, designation: roleRows.find((role) => role.id == value).name });
    }
    else{
      setDepartment({ ...department, [name]: value });
    }
  };

  useEffect(() => {
    setValue(department.status);
  }, [currentDepartment]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  console.log(department, "currentDepartment")
  return (
    <>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          department.status = value;
          updateDepartment(department.id, department);
        }}
      >
        <Paper className={style.paperStyle}>
          <Grid container spacing={1} style={{padding:"20px"}}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                className={style.textFieldstyle}
                variant="outlined"
                name="name"
                label="Name"
                value={department.name}
                onChange={handleInputChange}
                required
                fullWidth
              />
              <TextField
                className={style.textFieldstyle}
                variant="outlined"
                name="email"
                label="User Email"
                value={department.email}
                onChange={handleInputChange}
                required
                fullWidth
              />
              <FormControl component="fieldset">
                <FormLabel component="legend">Status</FormLabel>
                <RadioGroup
                  row
                  name="status"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio color="#00963f" />}
                    label="Active"
                    checked={value == 1 ? true : false}
                  />
                  <FormControlLabel
                    value={0}
                    control={<Radio color="#00963f" />}
                    label="Inactive"
                    checked={value == 0 ? true : false}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <FormControl
                component="fieldset"
                className={style.textFieldstyle}
              >
                <FormLabel component="legend">Gender</FormLabel>
                <RadioGroup
                  row
                  aria-label="Position"
                  name="gender"
                  value={department.gender}
                  onChange={handleInputChange}
                >
                  <FormControlLabel
                    value="M"
                    control={<Radio color="#fd7e14" />}
                    label="Male"
                    checked={department.gender == "M" ? true : false}
                  />
                  <FormControlLabel
                    value="F"
                    control={<Radio color="#00963f" />}
                    label="Female"
                    checked={department.gender == "F" ? true : false}
                  />
                </RadioGroup>
              </FormControl>
              <TextField
                // className={style.textFieldstyle}
                style={{marginTop:"2px"}}
                variant="outlined"
                name="mobile"
                label="User Phone"
                value={department.mobile}
                onChange={handleInputChange}
                required
                fullWidth
              />
              <FormControl
                  variant="outlined"
                  style={{ width: "100%", marginTop: "20px" }}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Designation
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    name="users_role_id"
                    value={department.users_role_id}
                    // value={department.designation}
                    onChange={handleInputChange}
                    label="Select Designation"
                  >
                    {roleRows &&
                      roleRows.filter((z) => z.id !== 4).map((opt) => (
                        <MenuItem key={opt.id} value={opt.id}>
                          {opt.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{display:'flex',justifyContent:'flex-end'}}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                className={style.btnStyle}
              >
                Update
              </Button>
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                className={style.cancellBtnStyle}
                onClick={() => {
                  setEditing(false);
                  setPreview(true);
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </>
  );
}
