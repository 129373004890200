import axios from "axios";
import { APIURL } from "../../CommomMethods/Fetch";

function createLocumDoctor(department) {
    
// console.log(department,"11111111111111111");
    const obj = {
        email: "",
        name: department.doctorName,
        mobile: 9988800009,
        users_role_id: 4,
        status: 9

    }
    axios.post(APIURL() + "users/", obj)
        .then((res) => {
           return res.data.data.id;
        });
    // return id
}

export const createLocumDoctors = {
    createLocumDoctor:createLocumDoctor
  };