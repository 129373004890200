/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Button from "@mui/material/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
// @material-ui/icons components
import Email from "@material-ui/icons/Email";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
// core components
// import Button from "components/CustomButtons/Button.js";
// sanckbar and notistack
// import Snackbar from "@material-ui/core/Snackbar";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import ErrorIcon from "@material-ui/icons/Error";
// import styles from "assets/jss/views/commonStyles.js";
import { listusers } from "../../actions/userActions";
import { putRecord, postRecord } from "../../CommomMethods/Save";
import { APIURL } from "../../CommomMethods/Fetch";

import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/authActions";
import { toast } from "react-toastify";
import { stepButtonClasses } from "@mui/material";
// api url constant
const APIUser = APIURL() + "users/Passowrd/";
const APIUserSession = APIURL() + "UserSession/";
const APIChangePwd = APIURL() + "SendChangePasswordMail/";
//export default function RegisterPage(props) {
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(2)",
    color: "red !important",
    fontWeight: "bold",
  },
}))(Tooltip);

const backdropStyle = makeStyles((theme) => ({
  backdrop: {
    zIndex: 999999,
    color: "#fff",
  },
}));
export default function ChangePassword(props) {
  const { history } = props;
  const dispatch = useDispatch();
  const copyDisable = () => {
    alert("copy not possible");
  };
  const bckclasses = backdropStyle();
  const [loading, setLoading] = React.useState(false);

  const bull = <span className={HtmlTooltip.bullet}>•</span>;

  const initialState = {
    User_Email: emailid,
    User_Password: "",
  };
  const initformState = {
    _Old_Password: "",
    _New_Password: "",
    _Confirm_Password: "",
    showNewPassword: false,
    showOldPassword: false,
    showConPassword: false,
  };
  const initialSessionState = {
    User_Id: 0,
    User_Login_TimeStamp: "",
    User_Logout_TimeStamp: "",
    User_Remarks: "",
  };

  const userList = useSelector((state) => state.userList);
  const { users } = userList;
  // * Use State Hooks Declalrations
  const [open, setOpen] = useState(false); // to open close Snackbar
  const [notify, setnotify] = useState(""); // to set Snackbar Content and Design
  const [thisform, setThisForm] = useState(initformState);
  const [thisOldform, setThisOldForm] = useState(initformState);
  const [oldpassword, setOldPassword] = useState("");
  const [thisuser, setThisUser] = useState(initialState);
  const [opendialog, setOpenDialog] = useState(false);
  const [sessionData, setSessiondata] = useState(initialSessionState);
  const [pwd, setPwd] = useState(false);
  const [pwdtooltip, setPwdtooltip] = useState(false);
  const [disable, setDisable] = useState(true);
  const [openchangepwd, setOpenchangepwd] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;
  useEffect(() => {
    dispatch(listusers());
  }, [dispatch]);

  var emailid = users[0] && users[0].email;
  const HandleNotification = (message, status, icon) => {
    setnotify({
      message: message,
      color: status,
      close: true,
      icon: icon,
    });
  };
  const CloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      setOpen(false);
    }
    setOpen(false);
  };
  const handlePasswordChange = (event) => {
    const { name, value } = event.target;
    setThisForm({ ...thisform, [name]: value });
  };
  const handleInputChangeforPassword = (event) => {
    const { name, value } = event.target;
    setThisForm({ ...thisform, [name]: value });
    // if(e)
    var passwordChk =
      /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9](?!.*\s).{7,20})$/;
    if (value.length >= 8 && passwordChk.test(value)) {
      setPwd(true);
      setPwdtooltip(false);
      setDisable(false);
    } else {
      setPwd(false);
      setPwdtooltip(true);
      setDisable(true);
    }
  };
  const handleInputChangeforOldPassword = (event) => {
// console.log(event.target.value, user.user, "EVENT");
    const { name, value } = event.target;
    // axios.get(APIURL()+"users/OldPassowrd/" + user.user.id, event.target.value)
    // .then((res) => {
    //   setLoading(false);
    //   // setOpenchangepwd(true);
    //   // dispatch(logout());
    // });
    // setThisOldForm({ ...thisform, [name]: value });
    // setThisForm({ ...thisform, [name]: value });
    // if(event.targe.value=== user.user.password)
    // var passwordChk =
    //   /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9](?!.*\s).{7,20})$/;
    // if (value.length >= 8 && passwordChk.test(value)) {
    //   setPwd(true);
    //   setPwdtooltip(false);
    //   setDisable(false);
    // } else {
    //   setPwd(false);
    //   setPwdtooltip(true);
    //   setDisable(true);
    // }
  };
  const logoutHandler = () => {
    dispatch(logout());
  };

  const UpdateUserPassword = () => {
    // setLoading(true);
    const obj = {
      user: user,
      emailid: emailid,
      thisuser: thisuser,
      oldpass: oldpassword
    }
    axios.put(APIURL() + "users/Passowrd/" + user.user.id, obj)
      .then((res) => {
        setLoading(false);
        if (res.data.status == 200) {
          setOpenchangepwd(true);
        } else {
          setOpenError(true);
          setErrorMsg(JSON.stringify(res.data.message))
          // toast.error(JSON.stringify(res.data.message));
        }

      });
  };
  const sendMailToUser = (data) => {
    postRecord(APIChangePwd, data).then((res) => { });
  };

  const updateUserSession = (data) => {
    sessionData.User_Logout_TimeStamp = new Date().toLocaleString();
    sessionData.User_Id = data.User_Id;
    sessionData.User_Remarks = "User Logged Out";
    putRecord(APIUserSession, sessionData.User_Id, sessionData).then(
      (res) => { }
    );
  };
  const handleClickShowOldPassword = () => {
    setThisForm({ ...thisform, showOldPassword: !thisform.showOldPassword });
  };
  const handleClickShowNewPassword = () => {
    setThisForm({ ...thisform, showNewPassword: !thisform.showNewPassword });
  };
  const handleClickShowConPassword = () => {
    setThisForm({ ...thisform, showConPassword: !thisform.showConPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleLogin = () => {
    window.location = "/";
    logoutHandler();
  };
  const handleClosePwd = () => {
    setOpenchangepwd(false);
  };
  
  const handleCloseError = () => {
    setOpenError(false);
  };
  return (
    <div>
      <h3 style={{ color: "green" }}>
        <VpnKeyIcon /> Change Password
      </h3>

      <form
        onSubmit={(event) => {
          event.preventDefault();
          if (thisform._New_Password === thisform._Confirm_Password) {
            thisuser.User_Password = thisform._Confirm_Password;
            UpdateUserPassword();
            setThisForm(initformState);
          } else alert("Your New Password And Confirm Password Should Match");
        }}
      >
        <TextField
          style={{
            marginBottom: "20px",
          }}
          label="Old Password"
          name="_Old_Password"
          // value={thisform._Old_Password}
          // onChange={handleInputChangeforPassword}
          value={oldpassword}
          onChange={(e) => setOldPassword(e.target.value)}
          required
          fullWidth
          onCopy={(e) => e.preventDefault()}
          onPaste={(e) => e.preventDefault()}
          type={thisform.showOldPassword ? "text" : "password"}
          InputProps={{
            autoComplete: "off",
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{
                    padding: "0",
                    color: "rgb(73, 80, 87)",
                  }}
                  aria-label="toggle password visibility"
                  onClick={handleClickShowOldPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {thisform.showOldPassword ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <HtmlTooltip
          open={pwdtooltip}
          placement="left-start"
          title={
            <>
              <Typography variant="body2" color="inherit">
                Password Policy
              </Typography>
              <span style={{ color: "orange" }}>
                {bull}
                {" Minimum length is 8 characters"}
                <br />
                {bull}
                {" Atleast one upper case character"}
                <br />
                {bull}
                {" Atleast one special character"}
                <br />
                {bull}
                {" Atleast one number"}
              </span>
            </>
          }
        >

          <TextField
            style={{
              marginBottom: "20px",
            }}
            label="New Password"
            name="_New_Password"
            value={thisform._New_Password}
            onChange={handleInputChangeforPassword}
            required
            fullWidth
            onCopy={(e) => e.preventDefault()}
            onPaste={(e) => e.preventDefault()}
            type={thisform.showNewPassword ? "text" : "password"}
            InputProps={{
              autoComplete: "off",
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    style={{
                      padding: "0",
                      color: "rgb(73, 80, 87)",
                    }}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowNewPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {thisform.showNewPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </HtmlTooltip>
        {!pwd ? (
          <label style={{ color: "red" }}>Please check password policy !</label>
        ) : (
          <label style={{ color: "green" }}>Password policy matched !</label>
        )}
        <TextField
          style={{
            marginBottom: "20px",
          }}
          label="Confirm Password"
          name="_Confirm_Password"
          value={thisform._Confirm_Password}
          onChange={handlePasswordChange}
          required
          fullWidth
          onCopy={(e) => e.preventDefault()}
          onPaste={(e) => e.preventDefault()}
          type={thisform.showConPassword ? "text" : "password"}
          InputProps={{
            autoComplete: "off",
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{
                    padding: "0",
                    color: "rgb(73, 80, 87)",
                  }}
                  aria-label="toggle password visibility"
                  onClick={handleClickShowConPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {thisform.showConPassword ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button type="submit" color="success" disabled={disable}>
          <span>
            <SaveOutlinedIcon />
          </span>
          Change Password
        </Button>
      </form>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={opendialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Your password has been updated."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are logged out from server now. And you can try with your
            updated credentials.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" autoFocus>
            OK
          </Button>
          <Button
            onClick={handleCloseDialog}
            style={{ float: "right" }}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openchangepwd}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        onClose={handleClosePwd}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle style={{ textAlign: "center" }} id="form-dialog-title">
          Password Updated Successfully !!!
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your password updated successfully.Kindly login again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogin} color="primary">
            Login
          </Button>
        </DialogActions>
      </Dialog>


  
      <Dialog
        open={openError}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        onClose={handleCloseError}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle style={{ textAlign: "center" }} id="form-dialog-title">
          {errorMsg}
        </DialogTitle>
        <DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseError} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>


      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={500}
        onClose={CloseNotification}
      >
        <SnackbarContent
          onClose={CloseNotification}
          color={notify.color}
          message={notify.message}
          close={notify.close}
          icon={notify.icon}
        />
      </Snackbar>
      <Backdrop className={bckclasses.backdrop} open={loading}>
        <CircularProgress size={50} style={{ color: "green" }} />
      </Backdrop>
    </div>
  );
}
