import React, { useEffect, useState } from "react";
import axios from "axios";
import { APIURL } from "../../../CommomMethods/Fetch";
import { numberWithCommas, numberToEnglish } from "../../../actions/createPositionActions"
import { useDispatch, useSelector } from "react-redux";
import { MenuProps, useStylesmulti } from "../../CommonFIle/util";
//mui component
import Button from "@material-ui/core/Button";
import Autocomplete from '@mui/material/Autocomplete';
import Popper from "@material-ui/core/Popper";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import List from "@material-ui/core/List";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MaterialTable from "material-table";
import { Paper } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import InputLabel from "@material-ui/core/InputLabel";
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import parse from 'html-react-parser';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "fixed",
    backgroundColor: "#810e36",
  },
  root: {
    flexGrow: 1,

    "& .MuiAutocomplete-listbox": {
      border: "2px solid grey",
      minHeight: 250,
      overflowY: "scroll",
      color: "black",
      fontSize: 15,
      position: "absolute",
      zIndex: 100,
      backgroundColor: theme.palette.background.paper,
      opacity: 1,
      "& li": {
        marginTop: "3px",
        backgroundColor: theme.palette.background.paper,
        // backgroundC:"rgba(252, 252, 252, 1) !important",
        // border:"1px solid grey",
        // backgroundColor: theme.palette.background,
        borderRadius: 4,
        opacity: 1,
        padding: 12,
      }
    }
  },
  formControl: {
    width: "100%",
  },
  paper: {
    height: "100px"

  },
  ListboxProps: {
    sx: {
      minHeight: 200,
      maxHeight: 400,
    }
  },

  //   option: {
  //     default: "#F9F9F9",
  //     paper: "#FFF",
  // }

}));
const myTableStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "20%",
    marginTop: theme.spacing(3),
    "& td": {
      fontFamili: "Roboto !important",
    },
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflow: "auto",
    marginBottom: "50px",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },

  buttonProgress: {
    // backgroundColor: "red",
    color: "white",
    position: "absolute",
    top: "50%",
    left: "20%",
    marginTop: -12,
    marginLeft: -12,
  },
}));
const CustomPopper = function (props) {
  const classes = useStyles();
  return <Popper {...props} className={classes.root} placement="bottom" />;
};
export default function PersonalDetail(props) {
  const classes = useStyles();
  const tables = myTableStyle();
  const initialFormData = {
    emailId: "",
    fullName: "",
    contactNumber: "",
    McrcName: "",
    Center: "",
    noticeperiod: "",
    lockinperiod: "",
  };
  const TableTitle = ({ text }) => (
    <Typography
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: "black",
        textDecoration: "underline",
        fontWeight: "bold",
        fontSize: "15px"
      }}
    >
      {text}
    </Typography>
  );
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;

  console.log(user, "onboad form data");
  console.log(props, "props");
  const [doctorOpCommercial, setDoctorOpCommercial] = useState([]);
  const [doctorIpCommercial, setDoctorIpCommercial] = useState([]);
  const [personalData, setPersonalData] = useState(initialFormData);
  const [regionCenter, setRegionCenter] = useState([]);
  const [options, setOptions] = useState([]);
  const [centerList, setCenterList] = useState([]);
  const [doctorMgDetails, setDoctorMgDetails] = useState([]);
  const [TotalPayOutValue, setTotalPayOutValue] = useState([]);
  const [doctorFixedDetails, setDoctorFixedDetails] = useState([]);
  const [openOp, setOpenOp] = React.useState(false);
  const [selected, setSelected] = useState([]);
  const [getMasterData, setGetMasterData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [primaryCenter, setPrimaryCenter] = useState(null);
  const [allDataCenterName, setAllDataCenterName] = useState([]);
  const [selectedchild, setSelectedChild] = useState([]);
  const [newOptions, setNewOptions] = useState([]);
  const [noticeperiod, setNoticeperiod] = useState([]);
  const [lockinperiod, setLockinperiod] = useState([]);
  const [newFull, setNewFull] = useState('');
  const [valuess, setValues] = useState([]);
  const [check, setCheck] = useState(false);
  const [doctorType, setDoctorType] = useState("");
  const [fullnames, setfullnames] = useState("");
  const [np, setNp] = useState("");
  const [perData, setPerData] = useState({});
  const [lp, setLp] = useState("");
  const [noticePeriodState, setNoticePeriodState] = useState("");
  const [lockinPeriodState, setLockinPeriodState] = useState("");
  const [doctorIpDetail, setDoctorIpDetail] = React.useState([]);
  const [annexuresList, setAnnexuresList] = React.useState([]);
  const [agreementTemplate, setAgreementTemplate] = React.useState(null);
  const [agreementAnnexure, setAgreementAnnexure] = React.useState(props.agreementAnnexure);
  const [agreementTemplateList, setAgreementTemplateList] = React.useState([]);
  const [agreement, setAgreement] = React.useState({});
  const [viewAgreement, setViewAgreement] = useState(false);
  const [viewAnnexure, setViewAnnexure] = useState(false);
  const [show, setShow] = useState(false);
  console.log(props.data, "props.data.centerNames");
  var newArray = [];
  console.log(getMasterData, "LLLLLLLLLLL")

  const handleClose = () => setShow(false);
  const closeAgreement = () => {
    setViewAgreement(false);
  };

  const getTemplates = () => {
    axios
      .get(APIURL() + "template/getAllTemplate")
      .then((res) => res.data)
      .then((rows) => {
    // console.log(rows.data, "rows.data")
        setAgreementTemplateList(rows.data.filter(item => item.status === 1));
      });
  }
  useEffect(() => {
    getTemplates()
  }, []);
  useEffect(() => {
    setRegionCenter(user.user.usermap);
    axios
      .get(APIURL() + "centers/")
      .then((res) => res.data)
      .then((rows) => {
        // setRows(rows);
        setCenterList(rows.filter((center) => center.status))
        setOptions(rows.filter(elem => elem.status && (elem.name !== props.data.centerNames || props.data.doctorId ? props.data.businessunit !== elem.name : '')));
      });
  }, []);
  useEffect(()=>{
    if(props.data && centerList.length > 0){
        setPrimaryCenter(centerList.find((elem) => props.data.doctorId ? props.data.Center ? props.data.Center == elem.name : props.businessunit == elem.name : props.data.centerNames ? props.data.centerNames == elem.name : props.data.businessunit == elem.name));

        props.setPrimaryCenter(centerList.find((elem) => props.data.doctorId ? props.data.Center ? props.data.Center == elem.name : props.businessunit == elem.name : props.data.centerNames ? props.data.centerNames == elem.name : props.data.businessunit == elem.name))
      }
    },[centerList, props.data])
  useEffect(() => {
    if (props.data.doctorId) {
      setPersonalData({
        emailId: props.data.email ? props.data.email : props.data.emailId,
        McrcName: props.data.McrcName,
        fullName: props.data.name ? props.data.name : props.data.fullName,
        contactNumber: props.data.mobile ? props.data.mobile : props.data.contactNumber,
        Center: props.data.Center ? props.data.Center : props.data.businessunit,
        noticeperiod: props.data.noticePeriod,
        lockinperiod: props.data.lockinPeriod,
        doctorId: props.data.doctorId,
      })
  // console.log(props, "update query check");
    }
  }, [props]);
  console.log(options, "options");

  useEffect(() => {
    if (props.doctorData && props.doctorData.agreementTemplate && props.doctorData.agreementTemplate[0]) {
      if (agreementTemplateList.find(item => item.id == props.doctorData.agreementTemplate[0].id)) {
        setAgreementTemplate(props.doctorData.agreementTemplate[0]);
        props.setAgreementTemplate(props.doctorData.agreementTemplate[0]);
        setAgreement(props.doctorData.agreementTemplate[0])
      }
    }
    if (props.doctorData && props.doctorData.user && props.doctorData.user.centers) {
      let centernames = [];
      let centerid = [];
      props.doctorData.user.centers.map((center) => {
        centerid.push(center.id);
        centernames.push(center.name);
      })
      setSelected(centernames);
      setAllData(centerid);
      setAllDataCenterName(centernames);
      props.setCenterData(centernames);
    }
  }, [props.doctorData]);
  useEffect(() => {
    let doctype = "";
// console.log(doctorType, "doctorType res-ponse");
    if (props.data.category == "Minimum Guarentee(MG)") {
      doctype = "MG";
    }
    if (props.data.category == "Full Time Consultant") {
      doctype = "Fixed";
    }

    if (props.data.category == "Visiting Consultant") {
      doctype = "VC";
    }
    axios
      .get(APIURL() + "onboard/np/fetchNoticeperiod")
      .then((res) => {
    // console.log(res, "res-ponse");
        var n = res.data.data.filter((z) => z.doctortype == doctype);
        setNoticeperiod(n);
      })
      .catch((err) => {
    // console.log(err, "err-or");
      });
  }, []);

  useEffect(() => {
    axios
      .get(APIURL() + "onboard/lp/fetchLockinperiod")
      .then((res) => {
    // console.log(res, "res-ponse");
        var l = res.data.data.filter((z) => z.doctortype == doctorType);
        setLockinperiod(l);
      })
      .catch((err) => {
    // console.log(err, "err-or");
      });
  }, [doctorType]);

  const isAllSelected = options.length > 0 && selected.length === options.length;

  const parentItemChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      let centernames = [];
      let centerid = [];

      options.forEach((element) => {
        centerid.push(element.id);
        centernames.push(element.name);
      });
      setSelected(selected.length === options.length ? [] : centernames);
      setAllData(centerid);
      setAllDataCenterName(selected.length === options.length ? [] : centernames);
      props.setCenterData(selected.length === options.length ? [] : centernames);
      return;
    }
    else {
      props.setCenterData(value);
      setSelected(value);
      setAllDataCenterName(value);
      let newArralyList = [];
      newArralyList.push([...value]);
  // console.log(newArralyList, "new array list");
      let indexPosition = -1;
      const centerSet = new Set();
      let arrayList = [];
      let obj = {};
  // console.log(value, "selected value");
      if (value.length > 0) {
        for (let i = 0; i < value.length; i++) {
          options.map((element, index) => {
            if (element.name == value[i]) {
              obj.cid = element.id;
              allData.push({ ...obj });
              setAllDataCenterName(value);
            }
          });
        }
      } else {
        options.map((element, index) => {
          if (element.name == value) {
            obj.cid = element.id;
            allData.push(obj);
            setAllDataCenterName(value);
          }
        });
      }
      const centerids = Array.from(centerSet);
      setAllData(centerids);
    }
  };
  function handleInputChangeLockin(e) {
    const { name, value } = e.target;
    setPerData({ ...perData, [name]: value });
  }

  const handlerCloseOp = () => {
    setOpenOp(false);
  };


  useEffect(() => {
// console.log(
    //   Object.keys(perData).includes("noticeperiod"),
    //   perData,
    //   "check"
    // );
    if (Object.keys(perData).includes("noticeperiod")) {
      personalData.noticeperiod = perData.noticeperiod;
    }
    if (Object.keys(perData).includes("lockinperiod")) {
      personalData.lockinperiod = perData.lockinperiod;
    }
    setPersonalData({ ...personalData });
  }, [perData]);

  console.log(allData, "center id  array");
  useEffect(() => {
// console.log(newFull, "newFull.doctorId");
    if (newFull) {
      axios
        .get(APIURL() + "onboard/previewOne/" + newFull.doctorId)
        .then((res) => {
      // console.log(res, "PPPPPPPPPPPP");
          setDoctorOpCommercial(res.data.doctorOpCommercial);
          setDoctorIpCommercial(res.data.doctorIpCommercial);
          setDoctorMgDetails(res.data.doctorMgDetails);
          setDoctorFixedDetails(res.data.doctorFixedDetails);
          setDoctorIpDetail(res.data.doctorIPParameter);
          let TotalRev = 0
          let TotalPayOutOP = 0
          let TotalPayOutIP = 0
          let TotalMGorFixedValue = 0
          setPersonalData({ ...personalData, 'emailId': res.data.doctorDetails.email, 'contactNumber': res.data.doctorDetails.mobile, });
          props.onchange({
            ...personalData,
            // selectedName: values.docname,
            'emailId': res.data.doctorDetails.email,
            'contactNumber': res.data.doctorDetails.mobile,
          });
          let TotalPayout = 0
          if (res.data.doctorIPParameter && res.data.doctorIPParameter.length > 0) {
            res.data.doctorIPParameter.map((elem) => {
              TotalRev = TotalRev + parseFloat(elem.ipTotalAmount)
              TotalPayOutIP = TotalPayOutIP + parseFloat(elem.payout)
            })
          }
          if (res.data.doctorOPParameter && res.data.doctorOPParameter.length > 0) {
            res.data.doctorOPParameter.map((elem) => {
              TotalRev = TotalRev + parseFloat(elem.oprevenue)
              TotalPayOutOP = TotalPayOutOP + parseFloat(elem.oppayout)
            })
          }
          if (res.data.doctorMgDetails && res.data.doctorMgDetails.length > 0) {
            res.data.doctorMgDetails.map((elem) => {
              TotalMGorFixedValue = TotalMGorFixedValue + parseFloat(elem.minguranteepermonth)
            })
            if (TotalMGorFixedValue > (TotalPayOutIP + TotalPayOutOP)) {
              TotalPayout = TotalMGorFixedValue
            } else {
              TotalPayout = (TotalPayOutIP + TotalPayOutOP)
            }
          }
          if (res.data.doctorFixedDetails && res.data.doctorFixedDetails.length > 0) {
            res.data.doctorFixedDetails.map((elem) => {
              TotalMGorFixedValue = TotalMGorFixedValue + parseFloat(((elem.professionalfeepermonth).toString()).replaceAll(',', '')) + parseFloat(((elem.additionalfeevalue).toString()).replaceAll(',', ''))
            })
            TotalPayout = TotalMGorFixedValue + (TotalPayOutIP + TotalPayOutOP)
          }
          setTotalPayOutValue([{
            "TotalRevenue": TotalRev,
            "TotalIP": TotalPayOutIP,
            "TotalOP": TotalPayOutOP,
            "TotalMGorFixedValue": TotalMGorFixedValue,
            "Payout": TotalPayout
          }])
        })
        .catch((error) => { });
    }
  }, [newFull])

  const handleChangenoticechange = (e) => {
    props.setNoticePeriod(e.target.value);
    if (e.target.value.length > 0) {
      props.error.noticeperiod = "";
      props.setError({ ...props.error });
    } else {
      props.error.noticeperiod = "This is required field.";
      props.setError({ ...props.error });
    }
  }

  const handleChangelockinchange = (e) => {
    props.setLockinPeriod(e.target.value);
    if (e.target.value.length > 0) {
      props.error.lockinperiod = "";
      props.setError({ ...props.error });
    } else {
      props.error.lockinperiod = "This is required field.";
      props.setError({ ...props.error });
    }
  }
  // onSelect={handleSelect} 
  // useEffect(() => {

  //   if (fullnames !== "") {
  //     axios.get(APIURL() + "doctor/suggestion/", { params: { obj: fullnames }, })
  //       .then((res) => {
  //     // console.log(res, "res-ponsessss");
  //         setGetMasterData(res.data.data);
  //       })
  //   }

  // }, [fullnames])

  const handleClear = async () => {
    // alert("hi");
// console.log(valuess, "OOOOOOOOOOOOO");
    setValues([]);
    personalData.fullName = "";
    setNewFull([]);
    setGetMasterData([]);
    setPersonalData({ ...personalData, 'emailId': "", 'contactNumber': "", });
    props.onchange({
      ...personalData,
      'emailId': "",
      'contactNumber': "",
    });
  }

  const handleChange = (event, values) => {
// console.log(event, values, 'valuesvaluesvalues')
    setValues(values);

    const { name, value } = event.target;
    if (name === "emailId") {
      var re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (value.length > 0) {
        if (value.includes('@cloudninecare')) {
          props.error.emailId = "Email should be doctor's personal email.";
          props.setError({ ...props.error });
        } else if (re.test(value)) {
          props.error.emailId = "";
          props.setError({ ...props.error });
        } else {
          props.error.emailId = "Invalid email id.";
          props.setError({ ...props.error });
        }
      } else {
        props.error.emailId = "This is required field.";
        props.setError({ ...props.error });
      }
    }

    if (name === "McrcName") {
      if (value.length > 0) {
        props.error.McrcName = "";
        props.setError({ ...props.error });
      } else {
        props.error.McrcName = "This is required field.";
        props.setError({ ...props.error });
      }
      setPersonalData({ ...personalData, [name]: value });
    }

    if (name === "fullName") {
      setfullnames(value);
      if (value !== "") {
        axios.get(APIURL() + "doctor/suggestion/", { params: { obj: event.target.value }, })
          .then((res) => {
        // console.log(res, "res-ponsessss");
            setGetMasterData(res.data.data);
          })
      }
      if (value.length > 0) {
        props.error.fullName = "";
        props.setError({ ...props.error });
      } else {
        props.error.fullName = "This is required field.";
        props.setError({ ...props.error });
      }

    }

    if (name === "contactNumber") {
      var phoneno = /^[1-9]\d{9}$/;
      if (value.length > 0) {
        if (phoneno.test(value)) {
          props.error.contactNumber = "";
          props.setError({ ...props.error });
        } else {
          props.error.contactNumber = "Invalid phone number.";
          props.setError({ ...props.error });
        }
      } else {
        props.error.contactNumber = "This is required field.";
        props.setError({ ...props.error });
      }
    }

    // if (name === "PanCard") {
    //   console.log(value);
    //   var Pan = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
    //   if (value.length > 0) {
    //     if (Pan.test(value)) {
    //       props.error.PanCard = "";
    //       props.setError({ ...props.error });
    //     } else {
    //       props.error.PanCard = "Invalid PAN Number.";
    //       props.setError({ ...props.error });
    //     }
    //   } else {
    //     props.error.PanCard = "This is required field.";
    //     props.setError({ ...props.error });
    //   }
    // }
    // personalData.Center = allDataCenterName;

    personalData.lockinperiod = perData.lockinperiod;
    personalData.noticeperiod = perData.noticeperiod;
// console.log(personalData, "11-11");

    setPersonalData({ ...personalData, [name]: value });
    props.onchange({
      ...personalData,
      // selectedName: values.docname,
      [name]: event.target.value,
      allData: allData,
      region: user.user.usermap[0].regionid,
    });
  };


  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };

  const handleTermChange = (e) => {
// console.log(e.target.value, "row-data");
    props.setContractTerm(e.target.value);
    props.setTerm(e.target.value);
    if (e.target.value.length > 0) {
      props.error.term = "";
      props.setError({ ...props.error });
    } else {
      props.error.term = "This is required field.";
      props.setError({ ...props.error });
    }
    // props.setNoticePeriod(e.target.value.noticeperiodinmonths);
    // props.setLockinPeriod(e.target.value.lockinperiodinmonths);
  };
  const handleChangeLockin = (e) => {
    alert("1");
// console.log(e.target.value, "lockinn-value");
    props.setLockinPeriod(e.target.value);
  };

  const handleChangeTemplate = async (event, newValue) => {
    await setAgreementTemplate(newValue);
    await props.setAgreementTemplate(newValue);
    await setAgreement(newValue)
    if (newValue) {
      props.error.agreementTemplate = "";
      props.setError({ ...props.error });
    } else {
      props.error.agreementTemplate = "This is required field.";
      props.setError({ ...props.error });
    }
// console.log(agreementTemplateList, "abc", 'template')
  }

  const handleChangePrimaryCenter = async (event, newValue) => {
    await setPrimaryCenter(newValue);
    await props.setPrimaryCenter(newValue);
    setOptions(centerList.filter(elem => elem.name !== newValue.name));
// console.log(newValue)
    // await props.setAgreementTemplate(newValue);
    // await setAgreement(newValue)
    if (newValue) {
      props.error.primaryCenter = "";
      props.setError({ ...props.error });
    } else {
      props.error.primaryCenter = "This is required field.";
      props.setError({ ...props.error });
    }
// console.log(primaryCenter, "abc", 'primarycenter')
  }

  const handleAgreementAnnexure = async (event) => {
    await setAgreementAnnexure(event.target.checked)
    await props.setAgreementAnnexure(event.target.checked);
    var centername = props.data.doctorId ? props.data.Center ? props.data.Center : props.businessunit : props.data.centerNames ? props.data.centerNames : props.data.businessunit
    var departmentLabel = props.data.doctorId ? props.doctorData.vacancyData && props.doctorData.vacancyData[0] && props.doctorData.vacancyData[0].departmentLabel : props.data.departmentLabel
    const centerdata = await centerList.find((elem) => elem.name == centername)
// console.log(centerdata, centerList, centername, event.target.checked, 'annexures')
    if (centerdata && event.target.checked == true) {
      await axios
        .post(APIURL() + "onboard/get/annexure", {
          siteCode: centerdata.code,
          departmentLabel: departmentLabel,
        })
        .then(async (res) => {
          if (res && res.data && res.data.data && res.data.data.length > 0) {
            props.error.annexure = "";
            props.setError({ ...props.error });
            await setAnnexuresList(res.data.data)
        // console.log(res.data.data, annexuresList, "annexures")
          } else {
            props.error.annexure = "Annexure doesn't exist";
            props.setError({ ...props.error });
            await setAnnexuresList([])
        // console.log(res.data.data, annexuresList, "annexures not found")
          }
        })
        .catch((err) => {
      // console.log(err, "err-or");
        });
    } else {
      props.error.annexure = "";
      props.setError({ ...props.error });
      setAnnexuresList([])
    }

  };

  const previewAgreement = () => {
    setViewAgreement(true)
  }
  const previewAnnexures = () => {
    setViewAnnexure(true)
  }
  const handleCloseAnnexure = () => {
    setViewAnnexure(false)
  }

  console.log(props, "prooops--");
  // const PAN = <p>PAN<span style={{ color: "red" }} > *</span></p>;
  const McrcName = <p>Full Name as per Medical Council Certificate<span style={{ color: "red" }} > *</span></p>;
  const EnterEmail = <p>Enter Email (Personal)<span style={{ color: "red" }} > *</span></p>;
  const EnterMobileNumber = <p>Enter Mobile Number<span style={{ color: "red" }} > *</span></p>;
  const FullName = <p>Full Name as per Pan Card<span style={{ color: "red" }} > *</span></p>;
  return (
    <>
      <Typography
        variant="h6"
        style={{ marginBottom: "15px", textAlign: "center" }}
      >
        Personal Details
      </Typography>
      <form>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6} sm={6} xl={6} style={{ position: "relative" }}>
            <Typography
              variant="p"
              style={{ marginBottom: "15px" }}
            >
              Requisition Type : {props.data.doctorId ? props.doctorData.vacancyData && props.doctorData.vacancyData[0] && (props.doctorData.vacancyData[0].request_type + ' for ' + props.doctorData.vacancyData[0].category + ' - ' + props.doctorData.vacancyData[0].departmentLabel) : props.data.requestType}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={6} xl={6} style={{ position: "relative" }}>
            <Typography
              variant="p"
              style={{ marginBottom: "15px" }}
            >
              Requisition No : {props.data.doctorId ? props.doctorData.vacancyData && props.doctorData.vacancyData[0] && ("REQ-" + props.doctorData.vacancyData[0].id) : props.data.id}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={6} sm={6} xl={6} style={{ position: "relative" }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClear}
              style={{ marginLeft: "21rem", fontSize: "10px", marginBottom: "5px", padding: "1px", width: "10rem" }}
            >
              Clear Estimation
            </Button>
            {/* <span  onClick={handleClear} style={{ marginLeft: "23rem",fontSize:"14px",marginBottom:"5px" }} >Clear Estimation<i className="fas fa-times" style={{marginLeft:"5px"}} ></i></span> */}
            <Autocomplete
              options={getMasterData}
              getOptionLabel={(option) => option.label ?? option.docname + "-" + option.departmentlist + "-" + option.designationlist + "-" + option.businessunit}
              id="disable-close-on-select"
              // className="MuiAutocomplete-clearIndicator"
              disableCloseOnSelect
              disabled={props.data.doctorId ? true : false}
              style={{ marginBottom: "20px", width: "100%" }}
              classes={{
                option: classes.option
              }}
              // classes={{ ListboxProps: classes.ListboxProps }}
              // onChange={(event, value) => {
              //   setNewFull(value)
              //   props.onchangefulname({
              //     selectedName: value.docname,
              //   });
              //   console.log(value, "PPPPP")
              // }}
              onChange={(event, value) => {
            // console.log(value, 'vvvvvvvvvv')
                handleChange(event, value);
                setNewFull(value)
              }}
              // value={newFull && newFull.docname ? newFull.docname : ""}
              // onChange={(event,value) => handleChangeFull(event , value)}
              renderInput={(params) => (
                <TextField {...params} label={FullName} fullWidth name="fullName" onChange={handleChange} required className={classes.textfield} variant="outlined" value={newFull && newFull.docname ? newFull.docname : personalData.fullName !== "" ? personalData.fullName : ""} FormHelperTextProps={{
                  style: { color: "red" },
                }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Dr.</InputAdornment>
                    ),
                  }}
                  helperText={props.error.fullName} />
                //   <TextField
                //   {...params}
                //   id="outlined-basic"
                //   label={FullName}
                //   // required
                //   // variant="outlined"
                //   variant="standard"
                //   fullWidth
                //   name="fullName"
                //   value={personalData.fullName}
                //   onChange={handleChange}
                //   style={{ marginBottom: "20px" }}
                //   autoComplete="off"
                //   InputProps={{
                //     startAdornment: (
                //       <InputAdornment position="start">
                //         <PersonIcon />
                //       </InputAdornment>
                //     ),
                //   }}
                //   FormHelperTextProps={{
                //     style: { color: "red" },
                //   }}
                //   helperText={props.error.fullName}
                // />

              )}
              PopperComponent={CustomPopper}
            // renderOption={(option)=>{
            //   return <h4>{(option) => option.docname + "-" + option.departmentlist + "-" + option.designationlist + "-" + option.businessunit}</h4>
            // }}
            />

            {(!newFull) && personalData.fullName !== "" ? <TextField label="Full Name" value={personalData.fullName} style={{ marginBottom: "18px" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Dr.</InputAdornment>
                ),
              }} fullWidth name="fullName" disabled variant="outlined" /> : ""}
            {/* <TextField
              id="outlined-basic"
              label={FullName}
              // required
              variant="outlined"
              fullWidth
              name="fullName"
              value={personalData.fullName}
              onChange={handleChange}
              style={{ marginBottom: "20px" }}
              autoComplete="off"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
              FormHelperTextProps={{
                style: { color: "red" },
              }}
              helperText={props.error.fullName}
            /> */}

            <TextField
              id="outlined-basic"
              label={EnterMobileNumber}
              variant="outlined"
              fullWidth
              required
              name="contactNumber"
              value={personalData.contactNumber}
              inputProps={{ maxLength: 10 }}
              onInput={(e) => onlyNumbers(e)}
              onChange={handleChange}
              autoComplete="off"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon />
                  </InputAdornment>
                ),
              }}
              FormHelperTextProps={{
                style: { color: "red" },
              }}
              helperText={props.error.contactNumber}
            />
            {/* <TextField
              style={{ marginTop: "18px" }}
              id="outlined-basic"
              label="Select Term"
              variant="outlined"
              fullWidth
              name="lockinperiod"
              value={props.term}
              onChange={handleTermChange}
              onInput={(e) => onlyNumbers(e)}
              autoComplete="off"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <span>In Months</span>
                  </InputAdornment>
                ),
              }}
              required
              FormHelperTextProps={{
                style: { color: "red" },
              }}
              helperText={props.error.term}
            /> */}
            <TextField
              style={{ marginTop: "18px" }}
              id="outlined-basic"
              label="Notice Period"
              variant="outlined"
              fullWidth
              name="noticeperiod"
              value={props.noticePeriod}
              onChange={handleChangenoticechange}
              onInput={(e) => onlyNumbers(e)}
              autoComplete="off"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <span>In Months</span>
                  </InputAdornment>
                ),
              }}
              required
              FormHelperTextProps={{
                style: { color: "red" },
              }}
              helperText={props.error.noticeperiod}
            // disabled
            />
            <FormControl className={classes.formControl} style={{ marginTop: "13px" }}>
              <label >Select Additional Center</label>
              <Select
                autoFocus
                labelId="mutiple-select-label"
                multiple
                value={selected}
                onChange={parentItemChange}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps} style={{ marginTop: "0px" }}
              >
                <MenuItem
                  value="all"
                  classes={{
                    root: isAllSelected ? classes.selectedAll : "",
                  }} style={{ marginTop: "0px" }}
                >
                  <ListItemIcon>
                    <Checkbox
                      classes={{ indeterminate: classes.indeterminateColor }}
                      checked={isAllSelected}
                      indeterminate={
                        selected.length > 0 && selected.length < options.length
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.selectAllText }}
                    primary="Select All"
                  />
                </MenuItem>
                {options && options.map((option) => (
                  <MenuItem key={option.id} value={option.name}>
                    <ListItemIcon>
                      <Checkbox
                        checked={selected.indexOf(option.name) > -1}
                      />
                    </ListItemIcon>
                    <ListItemText primary={option.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12} lg={6} sm={6} xl={6}>
            {/* <input type="hidden"></input> */}
            <TextField
              id="outlined-basic"
              label={McrcName}
              // required
              variant="outlined"
              fullWidth
              name="McrcName"
              value={personalData.McrcName}
              onChange={handleChange}
              style={{ marginBottom: "20px", marginTop: "25px" }}
              autoComplete="off"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Dr.</InputAdornment>
                ),
              }}
              required
              FormHelperTextProps={{
                style: { color: "red" },
              }}
              helperText={props.error.McrcName}
            />
            <TextField
              id="outlined-basic"
              label={EnterEmail}
              // required
              variant="outlined"
              fullWidth
              name="emailId"
              value={personalData.emailId}
              onChange={handleChange}
              style={{ marginBottom: "20px" }}
              autoComplete="off"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
              required
              FormHelperTextProps={{
                style: { color: "red" },
              }}
              helperText={props.error.emailId}
            />
            <Autocomplete
              id="combo-box-demo"
              value={primaryCenter}
              onChange={(event, newValue) => handleChangePrimaryCenter(event, newValue)}
              options={centerList}
              getOptionLabel={(option) => option.name ? option.name : ''}
              renderInput={(params) => <TextField {...params} label="Select Primary Center" helperText={props.error.primaryCenter} FormHelperTextProps={{
                style: { color: "red" },
              }} />}
              renderOption={(props, option, state) => {
                return (
                  <Typography key={option.name} {...props} style={{ color: 'black' }}>{option.name}</Typography>
                );
              }}
            />

            {/* <TextField
              id="outlined-basic"
              label={PAN}
              variant="outlined"
              fullWidth
              // required
              name="PanCard"
              // inputProps={{ maxLength: 10 }}
              // onInput={(e) => onlyNumbers(e)}
              value={personalData.PanCard}
              onChange={handleChange}
              // style={{ marginBottom: "20px" }}
              autoComplete="off"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FeaturedPlayListIcon />
                  </InputAdornment>
                ),
              }}
              FormHelperTextProps={{
                style: { color: "red" },
              }}
              helperText={props.error.PanCard}
            /> */}
            <TextField
              style={{ marginTop: "16px" }}
              id="outlined-basic"
              label="Lock in period"
              variant="outlined"
              fullWidth
              name="lockinperiod"
              onChange={handleChangelockinchange}
              onInput={(e) => onlyNumbers(e)}
              value={props.lockinPeriod}
              autoComplete="off"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <span>In Months</span>
                  </InputAdornment>
                ),
              }}
              required
              FormHelperTextProps={{
                style: { color: "red" },
              }}
              helperText={props.error.lockinperiod}
            // disabled
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6} sm={6} xl={6}>
            <Autocomplete
              id="combo-box-demo"
              value={agreementTemplate}
              onChange={(event, newValue) => handleChangeTemplate(event, newValue)}
              options={agreementTemplateList}
              getOptionLabel={(option) => option.templateName ? option.templateName : ''}
              renderInput={(params) => <TextField {...params} label="Select Agreement Template" helperText={props.error.agreementTemplate} FormHelperTextProps={{
                style: { color: "red" },
              }} />}
              renderOption={(props, option, state) => {
                return (
                  <Typography key={option.templateName} {...props} style={{ color: 'black' }}>{option.templateName}</Typography>
                );
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6} sm={6} xl={6}>
            {agreementTemplate && agreement ?
              <Button
                variant="contained"
                color="secondary"
                onClick={() => previewAgreement()}
              >
                Preview
              </Button>
              : ''}
          </Grid>
          <Grid item xs={12} md={12} lg={6} sm={6} xl={6}>
            <FormControlLabel control={
              <Checkbox
                color="primary"
                checked={agreementAnnexure}
                onChange={handleAgreementAnnexure}
              />
            } label="Include Annexure" />
            {annexuresList && annexuresList.length > 0 ?
              <Button
                variant="contained"
                color="secondary"
                onClick={() => previewAnnexures()}
              >
                Preview Annexure
              </Button>
              : ''}
            <div style={{ color: "red", fontSize: "12px" }}>{props.error.annexure}</div>
          </Grid>

        </Grid>
      </form>

      <Dialog
        fullScreen
        open={viewAgreement}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="end"
              style={{ marginLeft: "99%" }}
              color="inherit"
              onClick={closeAgreement}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <List>
          {agreementTemplate && agreement && agreement.templateData ? parse(agreement.templateData) : ''}
        </List>
      </Dialog>
      <Dialog
        open={viewAnnexure}
        onClose={handleCloseAnnexure}
        TransitionComponent={Transition}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleCloseAnnexure}
          style={{ textAlign: "center" }}
        >
          <h5
            style={{
              margin: ".4rem",
              textAlign: "center",
              padding: "7px",
              backgroundColor: "#810e36",
              color: "white",
            }}
          >
            <b>Annexure Summary</b>
          </h5>
        </DialogTitle>
        <DialogContent dividers>
          <MaterialTable
            columns={[
              { title: "Center Name", field: "center", render: (rowop) => (<div>{rowop.center ? rowop.center : "---"}</div>) },
              { title: "Procedure", field: "procedurename", render: (rowop) => (<div>{rowop.procedurename ? rowop.procedurename : "---"}</div>) },
              { title: "Payout", field: "payout", render: (rowop) => (<div>{rowop.payout ? rowop.payout : "---"}</div>) },
            ]}
            data={annexuresList}
            options={{
              // filtering: true,
              sorting: true,
              paging: false,
              search: false,
              rowopStyle: {
                fontSize: 12,
              },
              headerStyle: {
                border: "1px solid rgba(224, 224, 224, 1)",
                padding: "3px 10px",
                fontSize: 12,
              },
              cellStyle: {
                border: "1px solid rgba(224, 224, 224, 1)",
                padding: "3px 10px"
              },
            }}
            title={<TableTitle text="Annexures" />}
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleCloseAnnexure}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {newFull && newFull.docname ?

        <div style={{ marginTop: "20px" }}>
          <MaterialTable
            columns={[
              { title: "Center Name", field: "center", render: (row) => (<div>{row.center ? row.center : "---"}</div>) },
              { title: "Item Group", field: "itemgroup", render: (row) => (<div>{row.itemgroup ? row.itemgroup : "---"}</div>) },
              { title: "Item Name", field: "itemName", render: (row) => (<div>{row.itemName ? row.itemName : "---"}</div>) },
              { title: "Commercial Term", field: "commercialterm", render: (row) => (<div>{row.commercialterm ? row.commercialterm : "---"}</div>) },
            ]}
            data={doctorOpCommercial}
            options={{
              // filtering: true,
              sorting: true,
              paging: false,
              search: false,
              rowStyle: {
                fontSize: 12,
              },
              headerStyle: {
                border: "1px solid rgba(224, 224, 224, 1)",
                padding: "3px 10px",
                fontSize: 12,
              },
              cellStyle: {
                border: "1px solid rgba(224, 224, 224, 1)",
                padding: "3px 10px"
              },
            }}
            title={<TableTitle text="Out-Patient Commercial" />}
          />
          <MaterialTable
            columns={[
              { title: "Center Name", field: "Center", render: (row) => (<div>{row.Center ? row.Center : "---"}</div>) },
              { title: "Item Group", field: "itemGroup", render: (row) => (<div>{row.itemGroup ? row.itemGroup : "---"}</div>) },
              { title: "Item Name", field: "itemName", render: (row) => (<div>{row.itemName ? row.itemName : "---"}</div>) },
              { title: "Room Category", field: "roomCategory", render: (row) => (<div>{row.roomCategory ? row.roomCategory : "---"}</div>) },
              { title: "MOP", field: "ipmodeofpayment", render: (row) => (<div>{row.ipmodeofpayment ? row.ipmodeofpayment : "---"}</div>) },
              { title: "Commercial Term", field: "commercialTerm", render: (row) => (<div>{row.commercialTerm ? row.commercialTerm : "---"}</div>) },
            ]}
            // data={doctorDetail.doctorIPParameter}
            data={doctorIpDetail}
            options={{
              // filtering: true,
              sorting: true,
              paging: false,
              search: false,
              rowStyle: {
                fontSize: 12,
              },
              headerStyle: {
                border: "1px solid rgba(224, 224, 224, 1)",
                padding: "3px 10px",
                fontSize: 12,
              },
              cellStyle: {
                border: "1px solid rgba(224, 224, 224, 1)",
                padding: "3px 10px"
              },
            }}
            title={<TableTitle text="In-Patient Commercials" />}
          />
          {doctorMgDetails && doctorMgDetails.length > 0 ?
            doctorMgDetails.map((item) =>
              <MaterialTable
                columns={[
                  { title: "Minimum Guarantee Payment: The Company agrees to pay the Consultant an amount of Rs. " + numberWithCommas(item.minguranteepermonth) + " (Rs. " + numberToEnglish((item.minguranteepermonth).replaceAll(',', '')) + ") per month (“Minimum Guarantee Amount”) or the “Calculated Earnings” whichever is higher subject to statutory deductions. ", field: "mgCommercial" },
                ]}
                // data={doctorDetail.doctorIPParameter}
                data={
                  [
                    {
                      mgCommercial: 'The Minimum Guarantee Amount will be paid for ' + item.mgperiod + ' months'
                    },
                    {
                      mgCommercial: 'The earnings shall be calculated on ' + item.settlementperiod + ' basis and difference amount (Calculated Earnings minus Minimum Guarantee), if any, shall be paid at the end of ' + item.settlementpaymentperiod + '.'
                    },
                  ]
                }
                options={{
                  // filtering: true,
                  sorting: true,
                  paging: false,
                  search: false,
                  headerStyle: {
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "3px 10px",
                    fontSize: 12,
                    fontWeight: 400
                  },
                  rowStyle: {
                    fontSize: 12,
                  },
                  cellStyle: {
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "3px 10px"
                  },
                }}
                title={<TableTitle text={"Minimum Guarentee (MG) Commercial for " + item.Center} />}
              />
            )
            : ''}
          {doctorFixedDetails && doctorFixedDetails.length > 0 ?
            doctorFixedDetails.map((item) =>
              <MaterialTable
                columns={[
                  { title: "Monthly Fixed Fee: The Company will pay an all-inclusive amount of Rs. " + numberWithCommas((item.professionalfeepermonth).replaceAll(',', '')) + " (Rs. " + numberToEnglish((item.professionalfeepermonth).replaceAll(',', '')) + ") per month. ", field: "fixedCommercial", width: "100%" },
                ]}
                // data={doctorDetail.doctorIPParameter}
                data={
                  [
                    {
                      fixedCommercial: item.additionalfeevalue != '0' ? "Additional Fee for " + item.additionalfeename + ": The Company will pay an additional amount of Rs. " + numberWithCommas(((item.additionalfeevalue).toString()).replaceAll(',', '')) + " (Rs. " + numberToEnglish(((item.additionalfeevalue).toString()).replaceAll(',', '')) + ") per month. " : ""
                    },
                  ]
                }
                options={{
                  // filtering: true,
                  sorting: false,
                  paging: false,
                  search: false,
                  headerStyle: {
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "3px 10px",
                    fontSize: 12,
                    fontWeight: 400
                  },
                  rowStyle: {
                    fontSize: 12,
                  },
                  cellStyle: {
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "3px 10px"
                  },
                }}
                title={<TableTitle text={"Full Time Consultant Commercial for " + item.Center} />}
              />
            )
            : ''}
        </div>
        : ""}

    </>
  );
}
