import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import logo2 from "../images/Logos-04.png";
import { useLocation } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

function DrmAppSidebar({ userType }) {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);

  // bringing things from the reducer
  const { loading, error, user } = userLogin;
  // console.log(user, "user data");

  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const checkModuleAccess = (moduleName, userObj) => {
    // console.log(userObj.user.roles, "roleId");
    if (userObj.user.roles === 7) {
      return true;
    }
  };
  const checkModuleResourceAccess = (
    moduleName,
    resourceName,
    actionn,
    userObj
  ) => {
    if (userObj.user.roles === 7) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div class="sidebar main-sidebar main-sidebar-sticky side-menu ps ps--active-y">
      <div className="main-sidebar-header header-img">
        <img src={logo2} className="img-responsive" alt="logo" />
      </div>
      <div className="main-sidebar-body">
        <ul className="nav">
          <li>
            <div>
              <Link
                to="/UploadJoining"
                id="nav-link"
                className="new-nav-link"
                style={{
                  backgroundColor: "transparent",
                  height: "2.6rem",
                  border: "none",
                  marginTop: "1px",
                  color: "white",
                }}
              >
                <i className="fas fa-file-contract sidemenu-icon mr-2"></i>

                <span className="sidemenu-label" style={{ fontSize: "15px" }}>
                  Upload Joining
                </span>
              </Link>
            </div>
          </li>
        </ul>

        <div
          style={{
            display: "block",
            width: "100%",
            marginTop: "-.5rem",
            marginLeft: "13px",
          }}
        ></div>
      </div>
    </div>
    // <div class="sidebar main-sidebar main-sidebar-sticky side-menu ps ps--active-y">
    //   <div className="main-sidebar-header header-img">
    //     <img src={logo2} className="img-responsive" alt="logo" />
    //   </div>
    //   <div className="main-sidebar-body">
    //     {checkModuleAccess("ResourceModule", user) ? (
    //       <Accordion
    //         defaultActiveKey="1"
    //         style={{ backgroundColor: "transparent" }}
    //       >
    //         <Card style={{ backgroundColor: "transparent" }}>
    //           <Card.Header style={{ backgroundColor: "transparent" }}>
    //             <Accordion.Toggle
    //               as={Button}
    //               variant="link"
    //               eventKey="0"
    //               style={{ color: "white" }}
    //             >
    //               <i className="fas fa-angle-down sidemenu-icon mr-4"></i>
    //               Masters
    //             </Accordion.Toggle>
    //           </Card.Header>

    //           {checkModuleResourceAccess(
    //             "ResourceModule",
    //             "Department",
    //             "canAccess",
    //             user
    //           ) ? (
    //             <Accordion.Collapse eventKey="0">
    //               <Card.Body
    //                 style={{
    //                   backgroundColor: "transparent",
    //                   height: "1.2rem",
    //                   border: "none",
    //                 }}
    //               >
    //                 <Link
    //                   to="/manage-agreement-annexure"
    //                   id="nav-link"
    //                   className="nav-link"
    //                   style={{ marginTop: "-1rem" }}
    //                 >
    //                   <span className="shape1"></span>
    //                   <span className="shape2"></span>
    //                   <i className="ti-folder sidemenu-icon mr-2"></i>
    //                   Agrrement Annexure
    //                 </Link>
    //               </Card.Body>
    //             </Accordion.Collapse>
    //           ) : null}

    //           {checkModuleResourceAccess(
    //             "ResourceModule",
    //             "Department",
    //             "canAccess",
    //             user
    //           ) ? (
    //             <Accordion.Collapse eventKey="0">
    //               <Card.Body
    //                 style={{
    //                   backgroundColor: "transparent",
    //                   height: "1.2rem",
    //                   border: "none",
    //                 }}
    //               >
    //                 <Link
    //                   to="/manage-department"
    //                   id="nav-link"
    //                   className="nav-link"
    //                   style={{ marginTop: "-1rem" }}
    //                 >
    //                   <span className="shape1"></span>
    //                   <span className="shape2"></span>
    //                   <i className="ti-folder sidemenu-icon mr-2"></i>
    //                   Department
    //                 </Link>
    //               </Card.Body>
    //             </Accordion.Collapse>
    //           ) : null}

    //           {checkModuleResourceAccess(
    //             "ResourceModule",
    //             "Position",
    //             "canAccess",
    //             user
    //           ) ? (
    //             <Accordion.Collapse eventKey="0">
    //               <Card.Body
    //                 style={{
    //                   backgroundColor: "transparent",
    //                   height: "1.2rem",
    //                   border: "none",
    //                 }}
    //               >
    //                 <Link
    //                   to="/manage-position"
    //                   id="nav-link"
    //                   className="nav-link"
    //                   style={{ marginTop: "-1rem" }}
    //                 >
    //                   <span className="shape1"></span>
    //                   <span className="shape2"></span>
    //                   <i className="ti-folder sidemenu-icon mr-2"></i>
    //                   Position
    //                 </Link>
    //               </Card.Body>
    //             </Accordion.Collapse>
    //           ) : null}
    //           {checkModuleResourceAccess(
    //             "ResourceModule",
    //             "Region",
    //             "canAccess",
    //             user
    //           ) ? (
    //             <Accordion.Collapse eventKey="0">
    //               <Card.Body
    //                 style={{
    //                   backgroundColor: "transparent",
    //                   height: "1.2rem",
    //                   border: "none",
    //                 }}
    //               >
    //                 <Link
    //                   to="/manage-region"
    //                   id="nav-link"
    //                   className="nav-link"
    //                   style={{ marginTop: "-1rem" }}
    //                 >
    //                   <span className="shape1"></span>
    //                   <span className="shape2"></span>
    //                   <i className="ti-folder sidemenu-icon mr-2"></i>
    //                   Region
    //                 </Link>
    //               </Card.Body>
    //             </Accordion.Collapse>
    //           ) : null}

    //         </Card>
    //       </Accordion>
    //     ) : null}

    //     {/* {checkModuleAccess("AccessMap", user) ? (
    //       <Accordion
    //         defaultActiveKey="1"
    //         style={{ backgroundColor: "transparent" }}
    //       >
    //         <Card style={{ backgroundColor: "transparent" }}>
    //           <Card.Header style={{ backgroundColor: "transparent" }}>
    //             <Accordion.Toggle
    //               as={Button}
    //               variant="link"
    //               eventKey="0"
    //               style={{ color: "white" }}
    //             >
    //               <i className="fas fa-angle-down sidemenu-icon mr-4"></i>
    //               AccessMap
    //             </Accordion.Toggle>
    //           </Card.Header>

    //           {checkModuleResourceAccess(
    //             "AccessMap",
    //             "Permissions",
    //             "canAccess",
    //             user
    //           ) ? (
    //             <Accordion.Collapse eventKey="0">
    //               <Card.Body
    //                 style={{
    //                   backgroundColor: "transparent",
    //                   height: "1.2rem",
    //                   border: "none",
    //                 }}
    //               >
    //                 <Link
    //                   to="/BHProfile"
    //                   id="nav-link"
    //                   className="nav-link"
    //                   style={{ marginTop: "-1rem" }}
    //                 >
    //                   <span className="shape1"></span>
    //                   <span className="shape2"></span>
    //                   <i className="ti-folder sidemenu-icon mr-2"></i>
    //                   Permissions
    //                 </Link>
    //               </Card.Body>
    //             </Accordion.Collapse>
    //           ) : null}

    //           {checkModuleResourceAccess(
    //             "AccessMap",
    //             "AccessAllocation",
    //             "canAccess",
    //             user
    //           ) ? (
    //             <Accordion.Collapse eventKey="0">
    //               <Card.Body
    //                 style={{
    //                   backgroundColor: "transparent",
    //                   height: "1.2rem",
    //                   border: "none",
    //                 }}
    //               >
    //                 <Link
    //                   to="/BHProfile"
    //                   id="nav-link"
    //                   className="nav-link"
    //                   style={{ marginTop: "-1rem" }}
    //                 >
    //                   <span className="shape1"></span>
    //                   <span className="shape2"></span>
    //                   <i className="ti-folder sidemenu-icon mr-2"></i>
    //                   Designation Reports
    //                 </Link>
    //               </Card.Body>
    //             </Accordion.Collapse>
    //           ) : null}
    //         </Card>
    //       </Accordion>
    //     ) : null} */}

    //     {/* {checkModuleAccess("Users", user) ? (
    //       <Accordion
    //         defaultActiveKey="1"
    //         style={{ backgroundColor: "transparent" }}
    //       >
    //         <Card style={{ backgroundColor: "transparent" }}>
    //           <Card.Header style={{ backgroundColor: "transparent" }}>
    //             <Accordion.Toggle
    //               as={Button}
    //               variant="link"
    //               eventKey="0"
    //               style={{ color: "white" }}
    //             >
    //               <i className="fas fa-angle-down sidemenu-icon mr-4"></i>
    //               User Management
    //             </Accordion.Toggle>
    //           </Card.Header>

    //           {checkModuleResourceAccess(
    //             "Users",
    //             "Users",
    //             "canAccess",
    //             user
    //           ) ? (
    //             <Accordion.Collapse eventKey="0">
    //               <Card.Body
    //                 style={{
    //                   backgroundColor: "transparent",
    //                   height: "1.2rem",
    //                   border: "none",
    //                 }}
    //               >
    //                 <Link
    //                   to="/BHProfile"
    //                   id="nav-link"
    //                   className="nav-link"
    //                   style={{ marginTop: "-1rem" }}
    //                 >
    //                   <span className="shape1"></span>
    //                   <span className="shape2"></span>
    //                   <i className="ti-folder sidemenu-icon mr-2"></i>
    //                   Users
    //                 </Link>
    //               </Card.Body>
    //             </Accordion.Collapse>
    //           ) : null}

    //           {checkModuleResourceAccess(
    //             "Users",
    //             "Mapping",
    //             "canAccess",
    //             user
    //           ) ? (
    //             <Accordion.Collapse eventKey="0">
    //               <Card.Body
    //                 style={{
    //                   backgroundColor: "transparent",
    //                   height: "1.2rem",
    //                   border: "none",
    //                 }}
    //               >
    //                 <Link
    //                   to="/BHProfile"
    //                   id="nav-link"
    //                   className="nav-link"
    //                   style={{ marginTop: "-1rem" }}
    //                 >
    //                   <span className="shape1"></span>
    //                   <span className="shape2"></span>
    //                   <i className="ti-folder sidemenu-icon mr-2"></i>
    //                   Designation Reports
    //                 </Link>
    //               </Card.Body>
    //             </Accordion.Collapse>
    //           ) : null}
    //         </Card>
    //       </Accordion>
    //     ) : null} */}
    //   </div>
    // </div>
  );
}

export default DrmAppSidebar;
