import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Table, Toast } from "react-bootstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Navbar from "../../Components/Navbar";
import Iframe from "react-iframe";
import ClearAllIcon from '@mui/icons-material/ClearAll';
import HrSidebar from "../../Components/hrSidebar";
import ConfirmDialog from "../../Components/ConfirmDialog";
import axios from "axios";
import Backdrop from "@material-ui/core/Backdrop";
import TextField from "@mui/material/TextField";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CircularProgress from "@material-ui/core/CircularProgress";
import { listusers } from "../../actions/userActions";
import { documentlist } from "../../actions/documentActions";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import SubjectIcon from "@material-ui/icons/Subject";
import DateRangeIcon from "@material-ui/icons/DateRange";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import MaterialTable from "material-table";
import { Paper } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { onboardState } from "../../CommomMethods/States";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import { APIURL } from "../../CommomMethods/Fetch";
import {
  dateFormatter,
  numberWithCommasINR,
} from "../../actions/createPositionActions";
import AppSidebar from "../../Components/AppSidebar";
import Sidebar from "../../Components/Sidebar";
import DoctorSidebar from "../../Components/DoctorSidebar";
import Agreement from "../Payout/NEW/Agreement";
import c9gif from '../../SampleFile/cloudnine.gif';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const backDropStyle = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
const myTableStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "20%",
    marginTop: theme.spacing(3),
    "& td": {
      fontFamili: "Roboto !important",
    },
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflow: "auto",
    marginBottom: "50px",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },

  buttonProgress: {
    // backgroundColor: "red",
    color: "white",
    position: "absolute",
    top: "50%",
    left: "20%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "fixed",
    backgroundColor: "#810e36",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));
const stylesmodal = makeStyles({
  root: {
    marginTop: "18px",
  },
});

function UploadedDocHR(props) {
  const styleClasses = stylesmodal();
  const [openDialog, setOpenDialog] = useState(false);
  const tables = myTableStyle();
  const classes = useStyles();
  const dispatch = useDispatch();
  const backClasses = backDropStyle();
  const [backDropOpen, setBackDropOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [remark, setRemark] = useState("");
  const [doctorid, setdoctorId] = useState(0);
  const [step, setStep] = useState(1);
  const [rows, setRows] = useState([]);
  const [profileDetails, setProfileDetails] = useState({});
  const [profileDetailspos, setProfileDetailspos] = useState({});
  const [stampdate, setStampdate] = React.useState(new Date());
  const [id, setId] = useState("");
  const [detailsid, setDetailsId] = useState("");
  const [rid, setrId] = useState("");
  const [orow, setOrow] = useState({});
  const [open, setOpen] = React.useState(false);
  const [openDetails, setOpenDetails] = React.useState(false);
  const [openr, setOpenr] = React.useState(false);
  const [lists, setList] = useState([]);
  const [uploadData, setUploadData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [stat, setStat] = React.useState(false);
  const [openRemark, setOpenRemark] = React.useState(false);
  const [viewRemark, setViewRemark] = useState("");
  const [rowRecord, setRowRecord] = useState({});
  const [status, setStatus] = useState(false);
  const [loader, setLoader] = useState(false);
  const [openD, setOpenD] = useState(false);
  const [openstamp, setopenstamp] = useState(false);
  const [muiTableKey, setMuiTableKey] = React.useState(0);

  const [openReject, setOpenRejectmodal] = React.useState(false);
  const [openApprove, setOpenApprovemodal] = React.useState(false);
  const [review, setReview] = useState("");
  const [reviewType, setReviewType] = useState("");
  const [eStampTag, setEStampTag] = useState("stamptag");
  const [error, setError] = useState({});
  const [disableSubmitExecution, setDisableSubmitExecution] = useState(true);
  const [approve, setapprove] = useState([]);
  const [rej, setRej] = useState([]);
  const [docdetail, setdetails] = useState([])
  const [viewAgreement, setViewAgreement] = useState(false);
  const [categoty, setCategory] = useState("");
  const [docData, setDocData] = useState([]);
  const [agreementrow, setAgreementRow] = useState({});

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });
  const handlemClickOpen = (id) => {
    dispatch(documentlist(id));
    setId(id);
    setOpen(true);
    // setRowRecord(row);
    axios.get(APIURL() + "onboard/" + id)
      .then((response) => {
    // console.log(response, "response");
        setdetails(response.data.doctorCommercial)
      })
  };

  const handleStamp = (id) => {
    setId(id);
    setopenstamp(true);
    // setRowRecord(row);
  };

  const handlemClickOpenDetails = (row) => {
// console.log(row, "rowsData");
    axios
      .get(APIURL() + "ProfileDetails/" + row.doctorId)
      .then((res) => {
        if (res.data.details[0]) {
          setProfileDetails(res.data.details[0]);
          setOpenDetails(true);
        } else {
          setConfirmDialog({
            isOpen: true,
            title: "Alert",
            subtitle: "Doctor's information is pending.",
          });
          // return alert("Doctor's information is pending.");
        }
      })
      .catch((error) => {
    // console.log(error);
      });
    setDetailsId(row.id);
  };
  const handlemCloseDetails = () => {
    setOpenDetails(false);
  };
  const handlemClose = () => {
    setOpen(false);
  };

  const handleStampClose = () => {
    setopenstamp(false);
  };
  const handlerClickOpen = (row) => {
    setrId(row.id);
    setOrow(row);
    setOpenr(true);
  };

  const handleChangeStamp = async (a) => {
    await setStampdate(a);
    if (eStampTag !== '' && stampdate !== '') {
      await setDisableSubmitExecution(false)
    } else {
      await setDisableSubmitExecution(true)
    }
  };


  const handleChangeEStampTag = async (e) => {
    await setEStampTag(e.target.value)
// console.log(e.target.value, eStampTag, stampdate, 'e.target.value')
    if (e.target.value !== '' && stampdate !== '') {
      await setDisableSubmitExecution(false)
    } else {
      await setDisableSubmitExecution(true)
    }
  }
  const handleStampSubmit = () => {
    // console.log(stampdate,id,"@@@@@@");
    if (eStampTag == '' || stampdate == '') {
      toast.error("Kindly enter stamp date and stamp tag");
      return false;
    }
    setBackDropOpen(true);
    const obj = {
      stampdate: stampdate,
      e_stamp_tag: eStampTag,
    };
    axios
      .put(APIURL() + "ProfileDetails/updateStamp/" + id, obj)
      .then((res) => {
        setopenstamp(false);
        setBackDropOpen(false);
        toast.success(res.data.message);
        window.location.reload();
      });
  };

  const handleSubmitReject = () => {
    setStatus(false);
    const obj = {
      doctorId: orow.doctorId,
      name: orow.name,
      currentAction: onboardState.hr.state,
      currentActionBy: user.user.id,
      role: user.user.roles,
      requested_id: orow.requestId,
      nextAction: onboardState.upload.state,
      remark: remark,
      status: 1,
    };

    axios.post(APIURL() + "onboard/action/", obj).then((res) => {
      setOpenr(true);
      setStatus(true);
      toast.success("Rejection successfully submitted");
    });
  };
  const Remark = (e) => {
    setRemark(e.target.value);
  };
  const back = useRef(null);
  const next = useRef(null);
  const reviewAndSubmit = useRef(null);
  const handleClose = () => setShow(false);
  const handleShow = (doctorid) => {
    setShow(true);

    dispatch(documentlist(doctorid));
  };
  const listDoc = useSelector((state) => state.listDoc);
  const { list } = listDoc;

  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;

  const userList = useSelector((state) => state.userList);
  const { users } = userList;

  function clickNext() {
    if (step == 1) {
      back.current.className = "standardButton rejectButton";
    } else if (step == 3) {
      next.current.className += " hide";
      back.current.className += " hide";
      reviewAndSubmit.current.className = "standardButton acceptButton";
    }
    setStep((s) => s + 1);
  }

  function clickBack() {
    if (step == 2) {
      back.current.className += " hide";
    }
    setStep((s) => s - 1);
  }
  function clickReviewAndSubmit() { }

  const formStyle = {
    borderRadius: "5px",
    border: "1px solid black",
  };
  const headerStyle = {
    backgroundColor: "#94248E",
    margins: "0px",
    padding: "10px",
    color: "white",
    borderRadius: "5px",
    borderBottom: "3px black solid",
  };
  const bodyStyle = {
    borderRadius: "5px",
  };

  const handleDownload = (url, filename) => {
    fetch(APIURL() + "documents/download/" + url, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
        // let a = document.createElement("a");
        // a.href = url;
        // a.download = filename;
        // a.click();
      });

      //window.location.href = response.url;
    });
  };
  function getalldata() {
    axios
      .get(APIURL() + "onboard/currentstatus/")
      .then((res) => res.data)
      .then((rows) => {
        // let k = rows.data.filter((z) => z.doctorcommercial != null);

        setRows(rows.datas);
      });
  }
  // useEffect(() => {
  //   // getalldata();
  // }, [status]);
  useEffect(() => {
    axios
      .get(APIURL() + "documents/list/" + user.user.id)
      .then((res) => res.data)
      .then((rows) => {
        setList(rows.data);
        var k = rows.data;
        var l = [];
        k.forEach((element) => {
          l.push(element.docTitle);
        });
        setUploadData(l);
      });
  }, [status]);

  // useEffect(() => {
  //   axios
  //     .get(APIURL()+"ProfileDetails/"+ user.user.id)
  //     .then((res) => {
  //       setProfileDetails(res);
  //     })
  //     .catch((error) => {
  //   // console.log(error);
  //     });
  // }, []);
  console.log(profileDetails && profileDetails.departmentlist, "PPPPPPPPPPPPPPPPPP")
  const handleApprove = (row) => {
    setOpenApprovemodal(false);
    if (approve.stampdate == null || approve.stampdate == '0000-00-00') {
      setBackDropOpen(false);
      setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Please Add Execution Date and Try Again",
      });
      return;
    }
    setStatus(false);
// console.log(approve, stampdate, "row----------------->")
    axios.get(APIURL() + "onboard/checkDoc/" + approve.doctorId).then((res) => {
      var k = res.data.data.filter((z) => z.verificationRemark === "2");
  // console.log(k.length, "k.length ");
      if (k.length >= 9) {
        setBackDropOpen(true);
        const updateData = {
          doctorId: approve.doctorId,
          name: approve.name,
          currentAction: onboardState.hr.state,
          currentActionBy: user.user.id,
          role: user.user.roles,
          requested_id: approve.requestId,
          remark: review,
          // position:profileDetailspos && profileDetailspos.departmentlist,
          // nextAction: onboardState.ch.state,
          nextAction: approve.manualAgreement === 1 && approve.isConfirmed == 1 ? onboardState.complete.state : onboardState.rd.state,
          status: 1,
        };
        axios.post(APIURL() + "onboard/action/", updateData).then((res) => {
          setBackDropOpen(false);
          getalldata();
          setStatus(true);
        });
        window.location.reload();
      } else {
        setBackDropOpen(false);
        setConfirmDialog({
          isOpen: true,
          title: "Alert",
          subtitle: "Please view documents before approving",
        });
      }
    });
  };
  const handleReject = (row) => {
// console.log('I am here to reject')
    setOpenRejectmodal(false);
    setStatus(false);
    axios.get(APIURL() + "onboard/checkDoc/" + rej.doctorId).then((res) => {
      var k = res.data.data.filter((z) => z.verificationRemark === "2");
  // console.log(k.length, "k.length ");
      // if (k.length >= 9) {
      setBackDropOpen(true);
      const updateData = {
        doctorId: rej.doctorId,
        name: rej.name,
        currentAction: reviewType === 'Document' ? onboardState.hr_rej.state : onboardState.dr_info_revise.state,
        currentActionBy: user.user.id,
        role: user.user.roles,
        requested_id: rej.requestId,
        remark: review,
        // position:profileDetailspos && profileDetailspos.departmentlist,
        // nextAction: onboardState.ch.state,
        nextAction: reviewType === 'Document' ? onboardState.upload.state : onboardState.dr_info.state,
        status: 1,
      };
      axios.post(APIURL() + "onboard/action/", updateData).then((res) => {
        setBackDropOpen(false);
        setOpenRejectmodal(false);
        getalldata();
        setStatus(true);
      });
      window.location.reload();
      // } else {
      //   setBackDropOpen(false);
      //   setConfirmDialog({
      //     isOpen: true,
      //     title: "Alert",
      //     subtitle: "Please view documents before approving",
      //   });
      // }
    });
  };

  function viewDocuments(id) {
    setdoctorId(id);
  }
  console.log(rows.data, "rows.data");
  const handleApproveDoc = (row) => {
    setStatus(false);

    const updateData = {
      isVerified: "APPROVED",
      verificationRemark: "2",
      verifiedBy: users[0] && users[0].users_role_id,
    };
    axios
      .put(APIURL() + "documents/documents/" + row.id, updateData)
      .then((res) => {
        dispatch(documentlist(id));
        setStatus(true);
      });
    // window.location.reload();
  };

  const handleRemarkOpen = (remark) => {
    setViewRemark(remark);
    setOpenRemark(true);
  };
  const handleRemarkOpenDoc = (remark) => {
    setViewRemark(remark);
    setOpenRemark(true);
  };
  const handleCloseRemark = () => {
    setOpenRemark(false);
  };
  useEffect(() => {
    dispatch(listusers());
  }, [dispatch]);

  const handlerClickOpenDoc = (row) => {
    setrId(row.id);
    setOpenD(true);
    setRowRecord(row);
  };

  const handlerCloseD = () => {
    setOpenD(false);
  };

  const handleSubmitRejectD = () => {
    setStatus(false);
    const updateData = {
      isVerified: "REJECTED",
      verificationRemark: remark,
      verifiedBy: users[0] && users[0].users_role_id,
    };

    axios
      .put(APIURL() + "documents/documents/" + rowRecord.id, updateData)
      .then((res) => {
        setOpenD(false);
        dispatch(documentlist(id));
        setStatus(true);
        setOpenr(false);
      });
  };

  const handlerClose = () => {
    setOpenr(false);
  };

  const [urlData, setUrlData] = useState("");
  const handleDownloadFile = (url, filename) => {
    fetch(APIURL() + "documents/download/" + url, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      });

      //window.location.href = response.url;
    });
  };
  const handleComments = (e) => {
    setReview(e.target.value)
  };
  const handleRejectDialog = (row, review) => {
    //setDocid(row.doctorId);
    setOpenRejectmodal(true);
    setRej(row);
    setReviewType(review);
  };
  const handleApproveDialog = (row) => {
    //setDocid(row.doctorId);
    setOpenApprovemodal(true)
    setapprove(row);
    setStat(true);
  };


  const viewDocFile = (url, filename) => {
    setOpenDialog(true);

    fetch(APIURL() + "documents/download/" + url, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        setUrlData(url);
      });
    });
  };

  const viewAgreementDoc = (row) => {
// console.log(row, "view-agreement-ch");
    setAgreementRow(row);
    axios
      .get(APIURL() + "onboard/agreementView/" + row.doctorId)
      .then((res) => {
        if (res.data != null) {
      // console.log(res.data.data, "category-view");
          setCategory(res.data.data.vacancyData[0].category);
          setDocData(res.data.data);
        }
      });
    setViewAgreement(true);
  };

  const viewSignedAgreementDoc = async (row) => {
// console.log(row, "view-agreement-ch");
    // setAgreementRow(row);
    await axios.get(APIURL() + "onboard/download-agreement/" + row.digio_doc_id, {
      responseType: 'blob',
      headers: {
        Accept: 'application/pdf',
      },
    }).then((res) => {
  // console.log(res)
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.target = "_blank";
      link.download = row.name + "_Agreement.pdf"
      link.href = url;
      // link.setAttribute('download', 'Doctor Agreement.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
  };

  const closeAgreement = () => {
    setViewAgreement(false);
  };

  // function Doclist(props) {
  //   const list = 
  //   const listItems = numbers.map((number) =>
  //     <li>{number}</li>
  //   );
  //   return (
  //     <Doclist></Doclist>
  //   );
  // }
  console.log(docdetail, list.data, "jKJKJJJJJJJJJ")
  return (
    <>
      {
        user.user.roles == 4 ? <DoctorSidebar />
          :
          user.user.roles !== 0 ? <Sidebar />
            : <AppSidebar />
      }

      <div className="main-content side-content pt-0 mg-b-30">
        <div className="container-fluid">
          <div className="inner-body">
            <Navbar />
            {user.user.activityAssigments.filter((elem) => elem.activityId === 6 || elem.activityId === 8).find(item => item.RoleId === user.user.roles) ?
              <form>
                <br />
                <Card style={formStyle}>
                  <Card.Body style={bodyStyle}>
                    <MaterialTable
                      columns={[
                        { title: "Doctor Name", field: "name" },

                        {
                          title: "Current Action",
                          field: "Action",
                          filtering: false,
                          render: (row) => <>{Object.values(onboardState).find((ele) => ele.state == row.nextAction) ? Object.values(onboardState).find((ele) => ele.state == row.nextAction).message : "---"}</>,
                        },
                        {
                          title: "View Remark", field: "remark",
                          filtering: false,
                        },
                        {
                          title: "View Details",
                          field: "Action",
                          filtering: false,
                          render: (row) => (
                            <SubjectIcon
                              style={{
                                color: "#810e36",
                              }}
                              onClick={() => {
                                handlemClickOpenDetails(row);
                              }}
                            ></SubjectIcon>
                          ),
                        },
                        {
                          title: "View Documents",
                          field: "Action",
                          filtering: false,
                          render: (row) => (
                            <InsertDriveFileIcon
                              style={{
                                color: "#810e36",
                              }}
                              onClick={() => {
                                handlemClickOpen(row.doctorId);
                              }}
                            ></InsertDriveFileIcon>
                          ),
                        },
                        {
                          title: "View Agreement",
                          field: "Action",
                          filtering: false,
                          render: (row) => (
                            row.nextAction === onboardState.completed ?
                              <IconButton
                                style={{
                                  color: "#810e36",
                                  cursor: "pointer",
                                }}
                                aria-label="close"
                                onClick={() => {
                                  viewSignedAgreementDoc(row);
                                }}
                              >
                                <VisibilityIcon />
                              </IconButton>
                              :
                              <IconButton
                                style={{
                                  color: "#810e36",
                                  cursor: "pointer",
                                }}
                                aria-label="close"
                                onClick={() => {
                                  viewAgreementDoc(row);
                                }}
                              >
                                <VisibilityIcon />
                              </IconButton>

                          ),
                        },
                        {
                          title: "Execution Date",
                          field: "Action",
                          filtering: false,
                          render: (row) =>
                            row.stampdate ?
                              (<><span>{moment(row.stampdate.substr(0, 10)).format("DD-MM-YYYY")}</span>
                                {row.nextAction === onboardState.hr.state ?
                                  <span>{"  "}
                                    <i className="fas fa-edit sidemenu-icon mr-2" style={{ color: "#810e36" }} onClick={() => {
                                      handleStamp(row.doctorId);
                                    }}></i></span> :
                                  <span>{"  "}
                                    <i className="fas fa-edit sidemenu-icon mr-2" style={{ color: "grey" }} ></i>
                                  </span>}
                              </>)

                              :
                              (row.currentAction == onboardState.hr ||
                                row.isConfirmed == 1 ||
                                row.currentAction == onboardState.completed) &&
                                (row.stampdate != null &&
                                  row.stampdate != "0000-00-00") ? (
                                <div style={{ whiteSpace: "nowrap" }}>
                                  <span>{dateFormatter(row.stampdate)}</span>
                                </div>

                              ) : (
                                <DateRangeIcon
                                  style={{
                                    color: "#810e36",
                                  }}
                                  onClick={() => {
                                    handleStamp(row.doctorId);
                                  }}
                                ></DateRangeIcon>
                              ),
                        },
                        {
                          title: "Approve/Reject",
                          field: "Action",
                          filtering: false,
                          render: (row) =>
                            row.nextAction === onboardState.hr.state &&
                              stampdate ? (
                              <div style={{ whiteSpace: "nowrap" }}>
                                <Button
                                  size="small"
                                  // style={{
                                  //   backgroundColor: "#810e36",
                                  //   color: "white",
                                  // }}
                                  color="primary"
                                  style={{
                                    backgroundColor: "#810e36",
                                    color: "#fff",
                                  }}
                                  variant="contained"
                                  onClick={() => {
                                    handleApproveDialog(row);
                                  }}
                                >
                                  Approve
                                </Button>

                                <Button
                                  size="small"
                                  style={{ marginLeft: "10px", color: "#fff" }}
                                  color="primary"
                                  variant="contained"
                                  onClick={() => {
                                    handleRejectDialog(row, 'Document');
                                  }}
                                >
                                  Review Document
                                </Button>
                                <Button
                                  size="small"
                                  style={{ marginLeft: "10px", color: "#fff" }}
                                  color="primary"
                                  variant="contained"
                                  onClick={() => {
                                    handleRejectDialog(row, 'Personal Information');
                                  }}
                                >
                                  Review Personal Information
                                </Button>

                                {loader && (
                                  <CircularProgress
                                    size={24}
                                    className={tables.buttonProgress}
                                  />
                                )}
                                {/* <Button
                              style={{ marginLeft: "5px" }}
                              size="small"
                              color="secondary"
                              variant="contained"
                              onClick={() => {
                                handlerClickOpen(row);
                              }}
                            >
                              Rectification Required
                            </Button> */}
                              </div>
                            )
                              : row.currentAction == onboardState.hr_rej.state && row.nextAction === onboardState.upload.state ? (
                                <span className="badge badge-danger">Sent for revision</span>
                              )
                                : row.currentAction == onboardState.dr_info_revise.state && row.nextAction === onboardState.dr_info.state ? (
                                  <span className="badge badge-danger">Sent for revision</span>
                                )
                                  : (
                                    <div style={{ whiteSpace: "nowrap" }}>
                                      <span className="badge badge-success">
                                        Approved
                                      </span>
                                    </div>
                                  ),
                        },
                      ]}
                      // data={rows.data}
                      data={query =>
                        new Promise((resolve, reject) => {
                          let url = APIURL() + "onboard/currentstatus?"
                          url += `per_page=${query.pageSize}`
                          url += `&page=${(query.page + 1)}`
                          url += `&search=${query.search}`
                          if (query.filters.length) {
                            query.filters.forEach(item => {
                              url += `&${item.column.field}=${item.value}`
                            })
                          }
                          if (query.orderBy) {
                            url += `&orderByField=${query.orderBy.field}`
                            url += `&orderBySort=${query.orderDirection}`
                          }
                          // console.log(query, tableRef, "activitylogs")
                          axios.get(url)
                            .then(response => response.data)
                            .then(result => {
                              resolve({
                                data: result.data,
                                page: result.page - 1,
                                totalCount: result.total,
                              })
                            })
                        })
                      }
                      options={{
                        filtering: true,
                        sorting: true,
                        exportButton: true,
                        pageSize: 5,
                        pageSizeOptions: [5, 10, 50, 100, 200, 500],
                        search: true,
                        exportAllData: true,
                        searchText: props && props.location && props.location.state && props.location.state.data && props.location.state.data.doctorName ? props.location.state.data.doctorName : ''
                      }}
                      actions={[
                        {
                          icon: () => <ClearAllIcon />,
                          tooltip: "Clear All Filters",
                          isFreeAction: true,
                          onClick: (event) => {
                            setMuiTableKey(muiTableKey + 1); // set new key causing remount
                          }
                        }
                      ]}
                      icons={{
                        Filter: "filter",
                      }}
                      title="Details"
                    />
                  </Card.Body>
                </Card>
              </form>
              : <div>
                <h2 style={{ marginTop: "200px", display: 'flex', justifyContent: 'center', color: "#d11d53" }}>You Are Not Authorized To See This Page</h2>
              </div>
            }

          </div>
        </div>
        <Modal
          size="lg"
          scrollable
          dialogClassName="modalStandard"
          centered
          aria-labelledby="example-modal-sizes-title-lg"
          show={show}
          onHide={handleClose}
        >
          <Modal.Header className="modalHeader" closeButton>
            <Modal.Title className="modalTitle">Requested Id</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* {addpos.data && addpos.data.map((ele,index) =>( */}
            <Table style={{ width: "100%" }} borderless>
              <thead>
                <tr
                  style={{
                    textAlign: "center",
                    borderBottom: "1px solid rgb(200, 200, 200)",
                  }}
                >
                  <th className="col-2">Document Name</th>

                  <th> Documents</th>
                </tr>
              </thead>
              <tbody>
                {list.data &&
                  list.data.map((ele) => (
                    <tr
                      onClick={() => handleShow(ele.id)}
                      id="color"
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid rgb(200, 200, 200)",
                      }}
                    >
                      {/* <td>{ele.id}.</td> */}
                      <td>{ele.docTitle}</td>
                      <td>
                        <i
                          className="fas fa-file"
                          onClick={() => handleDownload(ele.url, ele.docTitle)}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center"></Modal.Footer>
        </Modal>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlemClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlemClose}
          style={{ textAlign: "center" }}
        >
          Uploaded Documents
        </DialogTitle>
        <DialogContent dividers>
          <Card style={formStyle}>
            <Card.Header style={headerStyle}></Card.Header>
            <Card.Body style={bodyStyle}>
              <Table style={{ width: "100%" }} borderless>
                <thead>
                  <tr
                    style={{
                      textAlign: "center",
                      backgroundColor: "hsla(340, 97%, 26%, 0.55)",
                      color: "white",
                      borderBottom: "1px solid rgb(200, 200, 200)",
                    }}
                  >
                    <th className="col-2">Document Title</th>
                    <th className="col-2"> Download</th>
                    <th className="col-2"> View</th>
                    <th className="col-2"> Details </th>
                    <th className="col-2"> Actions </th>
                  </tr>
                  {/* )} */}
                </thead>
                <tbody>
                  {list &&
                    list.data &&
                    list.data.map((ele) => (
                      <tr>
                        <td style={{ textAlign: "center" }}>{ele.docTitle}</td>
                        <td style={{ cursor: "pointer", textAlign: "center" }}>
                          {" "}
                          <i
                            className="fas fa-download"
                            onClick={() =>
                              handleDownloadFile(ele.url, ele.docTitle)
                            }
                            title={"Download"}
                            style={{ color: "#810e36", fontSize: "22px" }}
                          />
                        </td>

                        <td style={{ cursor: "pointer", textAlign: "center" }}>
                          {" "}
                          <InsertDriveFileIcon
                            className="fas fa-eye"
                            onClick={() => viewDocFile(ele.url, ele.docTitle)}
                            title={"View"}
                            style={{ color: "#810e36", fontSize: "22px" }}
                          />
                        </td>

                        <td style={{ textAlign: "center" }}>
                          <p style={{ color: "#810e36", fontSize: "10px" }} >{
                            ele.docTitle == 'Medical Council Registration Certificate No' ?
                              <>
                                <p style={{ color: "#810e36", fontSize: "10px" }}>{docdetail && docdetail.length > 0 && docdetail[0].mcrcval ? docdetail[0].mcrcval : '---'}</p>
                                <p style={{ color: "#810e36", fontSize: "10px" }}>{docdetail && docdetail.length > 0 && docdetail[0].mcrcval && ele.certExpiryDate ? "Expiry: " + ele.certExpiryDate : ''}</p>
                              </>
                              : ele.docTitle == 'PAN Card' ?
                                docdetail && docdetail.length > 0 && docdetail[0].panno ? docdetail[0].panno : '---'
                                : ele.docTitle == 'Aadhaar Card' ?
                                  docdetail && docdetail.length > 0 && docdetail[0].adhaarno ? docdetail[0].adhaarno : '---'
                                  : ele.docTitle == 'Indemnity Insurance Copy' ?
                                    <p style={{ color: "#810e36", fontSize: "10px" }}>{docdetail && docdetail.length > 0 && docdetail[0].indemnityexpiry ? "Expiry: " + dateFormatter(docdetail[0].indemnityexpiry) : ''}</p>
                                    : ele.docTitle == 'Cancelled Cheque (Bank Details)' ?
                                      <>
                                        <p style={{ color: "#810e36", fontSize: "10px" }}>{docdetail && docdetail.length > 0 && docdetail[0].bankname ? docdetail[0].bankname : ''}</p>
                                        <p style={{ color: "#810e36", fontSize: "10px" }}>{docdetail && docdetail.length > 0 && docdetail[0].bankaccno ? "A/C: " + docdetail[0].bankaccno : ''}</p>
                                      </>
                                      : ele.docTitle == 'Address Proof' ? docdetail && docdetail.length > 0 && docdetail[0].State && docdetail[0].PinCode && docdetail[0].City && docdetail[0].Address ? docdetail[0].City + ', ' + docdetail[0].State : '---'
                                        : '---'
                          }</p>
                        </td>


                        <td style={{ cursor: "pointer", textAlign: "center" }}>
                          {ele.isVerified === "APPROVED" ? (
                            <span className="badge badge-success">
                              APPROVED
                            </span>
                          ) : ele.isVerified === "REJECTED" ? (
                            <span className="badge badge-danger">REJECTED</span>
                          ) : (
                            <div style={{ whiteSpace: "nowrap" }}>
                              <Button
                                size="small"
                                style={{
                                  backgroundColor: "#810e36",
                                  color: "white",
                                }}
                                variant="contained"
                                onClick={() => {
                                  handleApproveDoc(ele);
                                }}
                              >
                                Approve
                              </Button>

                              <Button
                                style={{ marginLeft: "5px" }}
                                size="small"
                                color="secondary"
                                variant="contained"
                                onClick={() => {
                                  handlerClickOpenDoc(ele);
                                }}
                              >
                                Review
                              </Button>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handlemClose}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlemCloseDetails}
        aria-labelledby="customized-dialog-title"
        open={openDetails}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlemCloseDetails}
          style={{ textAlign: "center" }}
        >
          <h5
            style={{
              margin: ".4rem",
              textAlign: "center",
              padding: "7px",
              backgroundColor: "#810e36",
              color: "white",
            }}
          >
            <b>Doctor Details</b>
          </h5>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2} lg={2} sm={12} xl={2}></Grid>

            <Grid item xs={12} md={3} lg={3} sm={12} xl={3}>
              <Typography gutterBottom>
                <b>Name </b>
              </Typography>
              <Typography gutterBottom>
                <b> Email-Id </b>
              </Typography>
              <Typography gutterBottom>
                <b> Mobile </b>
              </Typography>
              <Typography gutterBottom>
                <b> Father Name</b>
              </Typography>
              <Typography gutterBottom>
                <b> Spouse Name </b>
              </Typography>
              <Typography gutterBottom>
                <b> Gender </b>
              </Typography>
              <Typography gutterBottom>
                <b> DOB </b>
              </Typography>
              <Typography gutterBottom>
                <b> Designation </b>
              </Typography>
              <Typography gutterBottom>
                <b> Department </b>
              </Typography>
              <Typography gutterBottom>
                <b> Business Unit </b>
              </Typography>
              <Typography gutterBottom>
                <b> Address </b>
              </Typography>
              <Typography gutterBottom>
                <b> Permanent Address </b>
              </Typography>
              <Typography gutterBottom>
                <b> DOJ </b>
              </Typography>
              <Typography gutterBottom>
                <b> Contract length </b>
              </Typography>
              <Typography gutterBottom>
                <b> PG Degree </b>
              </Typography>
              <Typography gutterBottom>
                <b> PG Total Experience </b>
              </Typography>
              <Typography gutterBottom>
                <b> PG Relevant Experience </b>
              </Typography>
              <Typography gutterBottom>
                <b>Notice Period</b>
              </Typography>
              <Typography gutterBottom>
                <b> Lock in Period </b>
              </Typography>
              <Typography gutterBottom>
                <b> Indemnity Value </b>
              </Typography>
              <Typography gutterBottom>
                <b> Indemnity Expiry </b>
              </Typography>
              <Typography gutterBottom>
                <b> About Doctor</b>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={6} sm={12} xl={6}>
              <Typography gutterBottom>
                <b>: </b>
                {profileDetails.fullname}
              </Typography>
              <Typography gutterBottom>
                <b>: </b>
                {profileDetails.email}
              </Typography>
              <Typography gutterBottom>
                <b>: </b>
                {profileDetails.mobile}
              </Typography>
              <Typography gutterBottom>
                <b>: </b> {profileDetails.fathername}
              </Typography>
              <Typography gutterBottom>
                <b>: </b> {profileDetails.husbandname}
              </Typography>
              <Typography gutterBottom>
                <b>: </b> {profileDetails.Gender}
              </Typography>
              <Typography gutterBottom>
                <b>: </b>{" "}
                {profileDetails.birthday && profileDetails.birthday != null
                  ? dateFormatter(profileDetails.birthday)
                  : ""}
              </Typography>
              <Typography gutterBottom>
                <b>: </b> {profileDetails.designationlist}
              </Typography>
              <Typography gutterBottom>
                <b>: </b> {profileDetails.departmentlist}
              </Typography>
              <Typography gutterBottom>
                <b>: </b> {profileDetails.businessunit}
              </Typography>
              <Typography gutterBottom>
                <b>: </b> {profileDetails.Address}, {profileDetails.State},
                {profileDetails.City} ,{profileDetails.PinCode}
              </Typography>
              <Typography gutterBottom>
                <b>: </b> {profileDetails.PermanentAddress},{" "}
                {profileDetails.PermanentState},{profileDetails.PermanentCity} ,
                {profileDetails.PermanentPinCode}
              </Typography>
              <Typography gutterBottom>
                <b>: </b>{" "}
                {profileDetails.doj && profileDetails.doj != null
                  ? dateFormatter(profileDetails.doj)
                  : ""}
              </Typography>
              <Typography gutterBottom>
                <b>: </b> {profileDetails.contractlength} Months
              </Typography>
              <Typography gutterBottom>
                <b>: </b> {profileDetails.pgdegree}
              </Typography>
              <Typography gutterBottom>
                <b>: </b> {profileDetails.pgtotalexp} Months
              </Typography>
              <Typography gutterBottom>
                <b>: </b> {profileDetails.pgrelevantexp} Months
              </Typography>
              <Typography gutterBottom>
                <b>: </b> {profileDetails.noticeperiod} Months
              </Typography>
              <Typography gutterBottom>
                <b>: </b> {profileDetails.lockinperiod} Months
              </Typography>
              <Typography gutterBottom>
                <b>: </b>{" "}
                {profileDetails.indemnityvalue
                  ? <><span> <CurrencyRupeeIcon fontSize="small" /> </span>{numberWithCommasINR(profileDetails.indemnityvalue)}</>
                  : ""}
              </Typography>
              <Typography gutterBottom>
                <b>: </b> {profileDetails.indemnityexpiry ? dateFormatter(profileDetails.indemnityexpiry) : ""}
              </Typography>
              <Typography gutterBottom>
                <b>: </b> {profileDetails.aboutdoctor}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2} lg={2} sm={12} xl={2}></Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handlemCloseDetails}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen
        open={viewAgreement}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="end"
              style={{ marginLeft: "99%" }}
              color="inherit"
              onClick={closeAgreement}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <List tabIndex={0}>
            {docData && docData.agreementTemplate ?
              <Agreement
                history={agreementrow}
                recData={docData}
                catagory={categoty}
              />
              :
              ""
            }
          </List>
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerClose}
        aria-labelledby="customized-dialog-title"
        open={openr}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClose}
          style={{ textAlign: "center" }}
        >
          Review
        </DialogTitle>
        <DialogContent dividers>
          <label>
            <b>Remark</b>
          </label>
          <textarea
            type="text"
            onChange={Remark}
            style={{ color: "black", border: "1px solid black" }}
            className="form-control"
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleSubmitReject}
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
          <Button
            autoFocus
            onClick={handlerClose}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handleCloseRemark}
        aria-labelledby="customized-dialog-title"
        open={openRemark}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClose}
          style={{ textAlign: "center" }}
        >
          Remark
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>{viewRemark}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleCloseRemark}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerCloseD}
        aria-labelledby="customized-dialog-title"
        open={openD}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerCloseD}
          style={{ textAlign: "center" }}
        >
          Review Document
        </DialogTitle>
        <DialogContent dividers>
          <label>
            <b>Remark</b>
          </label>
          <textarea
            type="text"
            onChange={Remark}
            style={{ color: "black", border: "1px solid black" }}
            className="form-control"
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleSubmitRejectD}
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
          <Button
            autoFocus
            onClick={handlerCloseD}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={() => {
          setOpenDialog(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        disableBackdropClick
      >
        <DialogTitle
          id="customized-dialog-title"
          // onClose={handlerClickcloseCommercials}
          style={{ textAlign: "center" }}
        ></DialogTitle>
        <DialogContent dividers></DialogContent>
        <Iframe
          url={urlData}
          width="900px"
          height="800px"
          id="myId"
          className="myClassname"
          display="initial"
          position="relative"
        />

        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
            }}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handleStampClose}
        aria-labelledby="customized-dialog-title"
        open={openstamp}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleStampClose}
          style={{ textAlign: "center" }}
        >
          Execution Date
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6} sm={12} xl={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  autoOk
                  label="Execution Date*"
                  disablePast
                  id="stampdate"

                  value={stampdate}
                  onChange={handleChangeStamp}
                  className={styleClasses.root}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            </Grid>
            {/* <Grid item xs={12} md={6} lg={6} sm={12} xl={6} className="pt-4">
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                // required
                name="eStampTag"
                label="E-stamp Tag*"
                value={eStampTag}
                onChange={(e) => handleChangeEStampTag(e)}
                autoComplete="off"
                FormHelperTextProps={{
                  style: { color: "red" },
                }}
                helperText={error.eStampTag}
              />
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleStampSubmit}
            color="primary"
            variant="contained"
            disabled={disableSubmitExecution}
          >
            Submit
          </Button>
          <Button
            autoFocus
            onClick={handleStampClose}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* APPROVE MODAL */}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={() => {
          setOpenApprovemodal(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={openApprove}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpenApprovemodal(false);
          }}
          style={{ textAlign: "center" }}
        >Approve</DialogTitle>
        <DialogContent dividers>
          <label>
            Comments{" "}
          </label>
          <textarea
            placeholder="Comments"
            type="text"
            onChange={handleComments}
            style={{ color: "black", border: "1px solid black" }}
            className="form-control"
            required
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleApprove}
            color="secondary"
            variant="contained"
            size="small"
          >

            Approve
          </Button>
          <Button
            autoFocus
            onClick={() => {
              setOpenApprovemodal(false);
            }}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* REJECT MODAL */}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={() => {
          setOpenRejectmodal(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={openReject}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpenRejectmodal(false);
          }}
          style={{ textAlign: "center" }}
        >Review</DialogTitle>
        <DialogContent dividers>
          <label>
            Comments{" "}
          </label>
          <textarea
            placeholder="Comments"
            type="text"
            onChange={handleComments}
            style={{ color: "black", border: "1px solid black" }}
            className="form-control"
            required
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleReject}
            color="secondary"
            variant="contained"
            size="small"
          >
            Review
          </Button>
          <Button
            autoFocus
            onClick={() => {
              setOpenRejectmodal(false);
            }}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* confirm Dialog */}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Backdrop
        className={backClasses.backdrop}
        open={backDropOpen}
      >
        <img src={c9gif} alt="loading..." />
      </Backdrop>
    </>
  );
}

export default UploadedDocHR;
