import React, { useEffect, useState } from "react";
// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import {
  FormControl,
  FormControlLabel,
  Paper,
  Typography,
  RadioGroup,
} from "@material-ui/core";
import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import ConfirmDialog from "../../../Components/ConfirmDialog";

const styleSheet = makeStyles((theme) => ({
  paperStyle: { marginTop: "10px" },
  textFieldstyle: {
    marginBottom: "20px",
    marginTop: "20px",
  },
  btnStyle: {
    right: "5px",
    marginBottom: "20px",
  },
  cancellBtnStyle: {
    marginBottom: "20px",
  },
}));

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "fixed",
    backgroundColor: "#810e36",
  },
  root: {
    flexGrow: 1,

    "& .MuiAutocomplete-listbox": {
      border: "2px solid grey",
      minHeight: 250,
      overflowY: "scroll",
      color: "black",
      fontSize: 15,
      position: "absolute",
      zIndex: 100,
      backgroundColor: theme.palette.background.paper,
      opacity: 1,
      "& li": {
        marginTop: "3px",
        backgroundColor: theme.palette.background.paper,
        // backgroundC:"rgba(252, 252, 252, 1) !important",
        // border:"1px solid grey",
        // backgroundColor: theme.palette.background,
        borderRadius: 4,
        opacity: 1,
        padding: 12,
      }
    }
  },
  formControl: {
    width: "100%",
  },
  paper: {
    height: "100px"

  },
  ListboxProps: {
    sx: {
      minHeight: 200,
      maxHeight: 400,
    }
  },

  //   option: {
  //     default: "#F9F9F9",
  //     paper: "#FFF",
  // }

}));

export default function EditDepartment({
  rows,
  user,
  currentDepartment,
  setEditing,
  setPreview,
  updateAgreementAnnexure,
  centerRows,
  designationRows,
  procedures,
}) {
  const style = styleSheet();
  const classes = useStyles();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });
  //   const initialFormState = { id: null, name: "", code: "", status: "" };
  const [department, setDepartment] = useState(currentDepartment);
  const [value, setValue] = React.useState(0);
  const [check, setCheck] = useState(false);
  const [center, setCenter] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [error, setError] = useState({});
  const [procedurename, setProcedureName] = useState('');
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDepartment({ ...department, [name]: value });
  };

  useEffect(() => {
    setEditAnnexure()
  }, [currentDepartment]);

  const setEditAnnexure = async () => {
    await setValue(department.status);
    await setCenter(department.center ? (department.center).split(',') : [])
    await setDesignation(department.designationlist ? (department.designationlist).split(',') : [])
    await setProcedureName(procedures.find((item) => item.itemName == department.procedurename))
// console.log(currentDepartment, (department.center).split(','), designation, 'currentDepartment')
  }

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const [selectedDate, setSelectedDate] = React.useState(department.fromdate);
  const [selectedtoDate, setSelectedtoDate] = React.useState(department.todate);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleDateChangeto = (date) => {
    setSelectedtoDate(date);
  };

  function arePermutations(arr1, arr2) {
    if (arr1.length != arr2.length)
      return false;
    let hM = new Map();
    for (let i = 0; i < arr1.length; i++) {
      let x = arr1[i];
      if (!hM.has(x))
        hM.set(x, 1);
      else {
        let k = hM[x];
        hM.set(x, k + 1);
      }
    }
    for (let i = 0; i < arr2.length; i++) {
      let x = arr2[i];
      if (!hM.has(x) || hM[x] == 0)
        return false;

      let k = hM[x];
      hM.set(x, k - 1);
    }
    return true;
  }

  const handleInputChangeProcedure = (event, value) => {
    const itemName = value.itemName;
    setDepartment({ ...department, ['procedurename']: itemName });
    setProcedureName(value)
    for (let i = 0; i <= rows.length; i++) {
      if (rows[i].procedurename !== null && rows[i].procedurename !== "") {
        if (rows[i].procedurename == itemName) {
          if (rows[i].center !== null && rows[i].center !== "") {
            if (arePermutations(rows[i].center.split(', '), center)) {
              if (rows[i].designationlist !== null && rows[i].designationlist !== "") {
                if (arePermutations(rows[i].designationlist.split(', '), designation)) {
                  error.procedurename = "Invalid Combination";
                  setError({ ...error });
                }
                else {
                  error.procedurename = "";
                  setError({ ...error });
                }
              }
            }
            else {
              error.procedurename = "";
              setError({ ...error });
            }
          }
        }

      }
    }

  };

  const handleDateChangeCenter = (data) => {
    const { name, value } = data.target
    if (value[value.length - 1] === "all") {
      let centerCode = [];

      centerRows.forEach((element) => {
        centerCode.push(element.code);
      });
      setCenter(center.length === centerRows.length ? [] : centerCode);
      return;
    } else {
      setCenter(value);
    }
  };

  const handleDateChangeDesignation = (data) => {
    const { name, value } = data.target
    if (value[value.length - 1] === "all") {
      let designationnames = [];

      designationRows.forEach((element) => {
        designationnames.push(element.name);
      });
      setDesignation(designation.length === designationRows.length ? [] : designationnames);
      return;
    } else {
      setDesignation(value);
    }
    setDesignation(value);
  };

  const isAllSelected = centerRows.length > 0 && center.length === centerRows.length;
  const isAllDesignationSelected = designationRows.length > 0 && designation.length === designationRows.length;
  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // if (center.length === 0 || designation.length === 0) {
    if (center.length === 0) {
      setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Please fill all the required details first",
      });
      return;
    }
    if (error.procedurename == null || error.procedurename == "") {
      department.fromdate = selectedDate;
      department.createdBy = user.user.id;
      department.todate = selectedtoDate;
      department.center = center.join(', ');
      department.designationlist = designation.join(', ');
      updateAgreementAnnexure(department.id, department);
    }
    else {
      setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Incorrect combinations",
      });
    }

  }
  return (
    <>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit(event);
        }}
      >
        <Paper className={style.paperStyle}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <FormControl
                variant="outlined"
                style={{ width: "100%", marginTop: "20px", marginBottom: "10px" }}
              >
                <InputLabel id="demo-mutiple-checkbox-label3">
                  Select Center
                </InputLabel>
                <Select
                  labelId="demo-mutiple-checkbox-label3"
                  id="demo-mutiple-checkbox3"
                  name="centerid"
                  value={center}
                  onChange={handleDateChangeCenter}
                  renderValue={(center) => center.join(", ")}
                  label="Select Center"
                  multiple
                >
                  <MenuItem
                    value="all"
                    classes={{
                      root: isAllSelected ? classes.selectedAll : "",
                    }} style={{ marginTop: "0px" }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        classes={{ indeterminate: classes.indeterminateColor }}
                        checked={isAllSelected}
                        indeterminate={
                          center.length > 0 && center.length < centerRows.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.selectAllText }}
                      primary="Select All"
                    />
                  </MenuItem>
                  {centerRows &&
                    centerRows.map((opt) => (
                      <MenuItem key={opt.id} value={opt.code}>
                        <ListItemIcon>
                          <Checkbox
                            checked={center.indexOf(opt.code) > -1}
                          />
                        </ListItemIcon>
                        <ListItemText primary={opt.name} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Autocomplete
                id="combo-box-demo"
                value={procedurename}
                onChange={(event, newValue) => handleInputChangeProcedure(event, newValue)}
                options={procedures}
                getOptionLabel={(option) => option.itemName ? option.itemName : ''}
                renderInput={(params) => <TextField {...params} label="Select Procedure *" helperText={''} FormHelperTextProps={{
                  style: { color: "red" },
                }} />}
                renderOption={(props, option, state) => {
                  return (
                    <Typography key={option} {...props} style={{ color: 'black' }}>{option.itemName}</Typography>
                  );
                }}
              />
              {/* <FormControl
                variant="outlined"
                style={{ width: "100%", marginTop: "20px", marginBottom: "10px" }}
              >
                <InputLabel id="demo-mutiple-checkbox-label3">
                  Select Procedure *
                </InputLabel>
                <Select
                  labelId="demo-mutiple-checkbox-label3"
                  id="demo-mutiple-checkbox3"
                  name="procedurename"
                  value={department.procedurename}
                  onChange={handleInputChangeProcedure}
                  // renderValue={(center) => center.join(", ")}
                  label="Select Procedure *"
                  // multiple
                >
                  {procedures &&
                    procedures.map((opt) => (
                      <MenuItem key={opt.id} value={opt.itemName}>
                        <ListItemText primary={opt.itemName} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl> */}
              {/* <TextField
                className={style.textFieldstyle}
                variant="outlined"
                name="procedurename"
                label="Procedure"
                value={department.procedurename}
                onChange={handleInputChangeProcedure}
                required
                fullWidth
              /> */}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {/* <FormControl
                variant="outlined"
                style={{ width: "100%", marginTop: "20px", marginBottom: "10px" }}
              >
                <InputLabel id="demo-mutiple-checkbox-label3">
                  Select Specialization
                </InputLabel>
                <Select
                  labelId="demo-mutiple-checkbox-label3"
                  id="demo-mutiple-checkbox3"
                  name="designationid"
                  value={designation}
                  onChange={handleDateChangeDesignation}
                  renderValue={(designation) => designation.join(", ")}
                  label="Select Center"
                  multiple
                >
                  <MenuItem
                    value="all"
                    classes={{
                      root: isAllDesignationSelected ? classes.selectedAll : "",
                    }} style={{ marginTop: "0px" }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        classes={{ indeterminate: classes.indeterminateColor }}
                        checked={isAllDesignationSelected}
                        indeterminate={
                          designation.length > 0 && designation.length < designationRows.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.selectAllText }}
                      primary="Select All"
                    />
                  </MenuItem>
                  {designationRows &&
                    designationRows.map((opt) => (
                      <MenuItem key={opt.id} value={opt.name}>
                        <ListItemIcon>
                          <Checkbox
                            checked={designation.indexOf(opt.name) > -1}
                          />
                        </ListItemIcon>
                        <ListItemText primary={opt.name} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl> */}
              <TextField
                className={style.textFieldstyle}
                variant="outlined"
                name="payout"
                label="Payout"
                value={department.payout}
                onChange={handleInputChange}
                inputProps={{ maxLength: 10 }}
                onInput={(e) => onlyNumbers(e)}
                required
                fullWidth
              />
              {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Date picker inline"
                    value={selectedDate}
                    // fullWidth
                    autoFocus="true"
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    style={{ float: "right" }}
                    // fullWidth
                    id="date-picker-inline"
                    label="Date picker inline"
                    value={selectedtoDate}
                    onChange={handleDateChangeto}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid> */}
              <Button
                type="Update"
                color="primary"
                variant="contained"
                style={{ float: "right" }}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </form>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
