import { Box, Grid, Typography } from '@material-ui/core';
import React, { PureComponent, useState } from 'react';
import { PieChart, Pie, Sector, Legend, Tooltip, Line, ResponsiveContainer, Cell } from 'recharts';

const data = [
  {
    name: 'North',
    pv: 115,
    IP: 70,
    OP: 45,
    fill: '#93428a',
  },
  {
    name: 'South',
    pv: 170,
    IP: 105,
    OP: 65,
    fill: '#e4308a',
  },
  {
    name: 'West',
    pv: 40,
    IP: 30,
    OP: 10,
    fill: '#c987b0',
  }
];
const numbersManager = (number) => {
  let nf = new Intl.NumberFormat('en-IN');
  if (!isNaN(number)) {
    return nf.format(Math.abs(number).toFixed(0))
  }
  else{
    return nf.format(number.toFixed(0))
  }
}
const fill = "#8884d8"
const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';
  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill} fontSize={12} fontWeight='bold'>
        {payload.name}
      </text>
      <text x={cx} y={cy + 20} textAnchor="middle" fill={fill} fontSize={12} fontWeight='bold'>{`${numbersManager(percent*100)}%`}</text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius + 6}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={payload.ColorCode ? payload.ColorCode : fill}
      />
    </g>
  );
};
const DoctorsByLocation = (props) => {
  // console.log(props.graphData, "LegendList")
  const [activeIndex, setActiveIndex] = useState(null)
  const onPieEnter = (_, index) => {
    setActiveIndex(index)
  };
  const onPieExit = (_, index) => {
    setActiveIndex(null)
  };
  const graphOnClick = (e) => {
    // this.props.emitGraphClick(e)
// console.log(e, "KLLSKKSKSKSKSKSSKSK")
  }
  return (
    <div>
      <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <PieChart width={250} height={250}>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            // data={data}
            data={props.graphData}
            cx="50%"
            cy="50%"
            innerRadius={75}
            outerRadius={95}
            fill="#8884d8"
            dataKey="pv"
            onMouseEnter={onPieEnter}
            onMouseLeave={onPieExit}
            colorRendering
          >
            {props.graphData && props.graphData.length && props.graphData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.fill ? entry.fill : fill}>
              </Cell>
            ))}
          </Pie>
        </PieChart>
        <Box className={props.graphData.length > 4 ? 'label-view' : ''} style={{ maxHeight: '210px', width: '56%', paddingLeft: '5px', marginTop:'9px' }}>
          {props.graphData && props.graphData.length && props.graphData.length > 0 && props.graphData && props.graphData.length && props.graphData.map((item, index) =>
            <Box key={index} style={{ marginTop: '15px', padding: '0 10px', display: 'grid' }}>
              <Typography style={{ fontSize: '12px', paddingBottom: '4px', paddingLeft: '3px' }}>
                <span style={{ fontSize: '14px', fontWeight: '800', paddingRight: '10px', float: 'left', color: '#93428a' }}>
                  {item.name}</span>
                <span style={{ fontSize: '14px', fontWeight: '800', paddingRight: '10px', float: 'left', color: '#93428a' }}>IP</span>
                <span style={{ float: 'left', marginTop: '2px', color: '#93428a' }}>{`( ${item.IP ? numbersManager(item.IP) : "0"})`}</span>

                <span style={{ fontSize: '14px', fontWeight: '800', float: 'center', paddingRight: '10px', color: '#c987b0' }}>BOTH</span>
                <span style={{ float: 'center', marginTop: '2px', color: '#c987b0' }}>{`( ${item.both ? numbersManager(item.both) : "0"})`}</span>
                
                <span style={{ float: 'right', marginTop: '2px', color: '#e4308a' }}>{`( ${item.OP ? numbersManager(item.OP) : "0"})`}</span>
                <span style={{ fontSize: '14px', fontWeight: '800', float: 'right', paddingRight: '10px', color: '#e4308a' }}>OP</span>
              </Typography>
              <Box style={{ width: '100%', height: '15px', backgroundColor: '#e4308a', borderRadius: '15px', cursor: 'pointer' }} onClick={() => graphOnClick(item.name)}>
                <Box style={{ width: item.both ? '' + Math.round((item.both * 100) / (item.IP+item.both+item.OP)) + '%' : '0%', height: '15px', backgroundColor: '#c987b0', borderRadius: '15px' }}>
                  <Box style={{ width: item.IP ? '' + Math.round((item.IP * 100) / (item.IP+item.both+item.OP)) + '%' : '0%', height: '15px', backgroundColor: '#93428a', borderRadius: '15px' }} />
                  <Box style={{ position: 'relative', bottom: '20px', width: '7px', height: '24px', backgroundColor: '#999', left: item.IP ? ''+(((item.IP * 100) / (item.IP+item.both+item.OP))-1) +'%' : '0' }}></Box>
                </Box>
                <Box style={{ position: 'relative', bottom: '20px', width: '7px', height: '24px', backgroundColor: '#999', left: item.both ? ''+(((item.both * 100) / (item.IP+item.both+item.OP))-1) +'%' : '0' }}></Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </div>
  )
}
export default DoctorsByLocation