import React, { PureComponent } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const data = [
  {
    "name": "Minimum Guarentee(MG)",
    "uv": 70,
    "#": 70,
    "amt": 70,
    "fill": "#93428a"
  },
  {
    "name": "Visiting Consultant (VC)",
    "uv": 105,
    "#": 105,
    "amt": 105,
    "fill": "#e4308a"
  },
  {
    "name": "Retainer",
    "uv": 85,
    "#": 85,
    "amt": 85,
    "fill": "#c987b0"
  },
  {
    "name": "Fixed Consultant",
    "uv": 65,
    "#": 65,
    "amt": 65,
    "fill": "#ec64a4"
  }
]

const numbersManager = (number) => {
  let nf = new Intl.NumberFormat('en-IN');
  if (!isNaN(number)) {
    return nf.format(Math.abs(number).toFixed(0))
  }
  else{
    return nf.format(number.toFixed(0))
  }
}
const Total = 325
export default class BarchartDoctorsByLocation extends PureComponent {
  static demoUrl = 'https://codesandbox.io/s/vertical-composed-chart-w6fni';
  render() {
// console.log(this.props,"JSJSJSJSJSJSJJS")
    const graphOnClick = (e) => {
      // this.props.emitGraphClick(e)
  // console.log(e, "KLLSKKSKSKSKSKSSKSK")
    }
    
    return (
      this.props.graphData && this.props.graphData.length > 0 && this.props.graphData.map((item, index) =>
        <Box key={index} style={{ marginTop: '15px', padding: '0 10px', display: 'grid' }}>
          <Typography style={{ color: item.fill, fontSize: '12px', paddingBottom: '4px', paddingLeft: '3px' }}><span style={{ fontSize: '14px', fontWeight: '800', paddingRight: '10px', float: 'left' }}>{item.uv? numbersManager(item.uv) : "0"}</span><span style={{ float: 'left', marginTop: '3px' }}>{item.name}</span><span style={{ fontSize: '14px', fontWeight: '800', float: 'right' }}>{item.uv ? Math.round(parseFloat(item.uv * 100) / parseFloat(this.props.Total)) : '0'}%</span></Typography>
          <Box style={{ width: '100%', height: '15px', backgroundColor: '#dedede', borderRadius: '15px', cursor: 'pointer' }} onClick={() => graphOnClick(item.name)}>
            <Box style={{ width: item.uv ? '' + Math.round(parseFloat(item.uv * 100) / parseFloat(this.props.Total)) + '%' : '0%', height: '15px', backgroundColor: item.fill, borderRadius: '15px' }} />
          </Box>
        </Box>)
    );
  }
}
