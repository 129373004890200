import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../../Components/Sidebar";
import Navbar from "../../Components/Navbar";
import { APIURL } from "../../CommomMethods/Fetch";
import { Backdrop, Button, CircularProgress, CssBaseline, FormControl, Grid, MenuItem, Paper, Select } from "@material-ui/core";
import { Container } from "react-bootstrap";
import Skeleton from "@material-ui/lab/Skeleton";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import moment from 'moment';
import MaterialTable from "material-table";
import { toast } from "react-toastify";
import { payoutState } from "../../CommomMethods/States";
import { makeStyles } from "@material-ui/core/styles";
import XLSX from 'xlsx';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
const backDropStyle = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

// const month_list = ["JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"];

export default function LOSPayoutProcess() {
    // const [confirmDialog, setConfirmDialog] = useState({
    //     isOpen: false,
    //     title: "",
    //     subtitle: "",
    // });
    // const location = useLocation();
    // let history = useHistory();
    const backClasses = backDropStyle();
    const [loading, setLoading] = useState(true);
    const [centerName, setCenterName] = useState([]);
    const [center, setCenter] = useState("");
    const [month, setMonth] = useState("");
    // const [days, setDays] = useState(0);
    const [year, setYear] = useState((new Date()).getFullYear());
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [openUploadRetainerDataModal, setOpenUploadRetainerDataModal] = useState("");
    const [file, setFile] = useState("");
    const [fileName, setFilename] = useState("");
    const [doctorList, setDoctorList] = useState([]);
    const [payoutDataStatus, setPayoutDataStatus] = useState([]);
    const [circularLoader, setCirculatLoader] = React.useState(false);
    const [remark, setRemark] = useState("");
    const [docData, setDocData] = useState(null);
    const [OpenHoldModal, setOpenHoldModal] = useState(false);
    const [payoutCycle, setPayoutCycle] = React.useState(false);

    // const currentMonth = new Date().getMonth();
    // const currentDate = new Date().getDate();

    // const payoutCycles = [...Array(currentDate > 20 ? currentMonth : currentMonth - 1)].map((d, m) => ({
    //     label: `${new Date().getFullYear()}-${(m + 1) > 9 ? (m + 1) : '0' + (m + 1)}-21/${new Date().getFullYear()}-${(m + 2) > 9 ? (m + 2) : '0' + (m + 2)}-20`,
    //     value: `${new Date().getFullYear()}-${(m + 1) > 9 ? (m + 1) : '0' + (m + 1)}-21/${new Date().getFullYear()}-${(m + 2) > 9 ? (m + 2) : '0' + (m + 2)}-20`
    // }));

    const generateBillCyclesFromLastYear = (year = (new Date).getFullYear -1) => {
        const cycles = [];
        // Start date is 21st of January of the previous year
        const currentDate = new Date();
        const lastYear = year;
        let cycleStartDate = new Date(lastYear, 0, 21); // January 21 of the previous year
        if(currentDate.getDate() <= 20){
            currentDate.setMonth(currentDate.getMonth() - 1);
        }
        if (currentDate.getDate() > 20) {
            currentDate.setDate(20);
        }
        // Loop until the current date
        while (cycleStartDate <= currentDate) {
            // Set the start date of the cycle (21st of the current month)
            const startDate = new Date(cycleStartDate);   
            // Set the end date of the cycle (20th of the next month)
            const endDate = new Date(cycleStartDate);
            endDate.setMonth(cycleStartDate.getMonth() + 1);
            endDate.setDate(20);
            // Add the cycle to the array (as a string in yyyy-mm-dd format)
            cycles.push({
                label: `${startDate.toLocaleDateString('en-CA').split('T')[0]}/${endDate.toLocaleDateString('en-CA').split('T')[0]}`,
                value: `${startDate.toLocaleDateString('en-CA').split('T')[0]}/${endDate.toLocaleDateString('en-CA').split('T')[0]}`,
            });
            // Move to the next cycle (next month 21st)
            cycleStartDate.setMonth(cycleStartDate.getMonth() + 1);
            cycleStartDate.setDate(21);
        }
        return cycles;
    }
    const payoutCycles = generateBillCyclesFromLastYear(2024);

    const userLogin = useSelector((state) => state.userLogin);
    const { user } = userLogin;

    const Remark = (e) => {
        setRemark(e.target.value);
    };

    useEffect(() => {
        axios.get(APIURL() + "usermapcenters/centermaps/" + user.user.id)
            .then((res) => res.data)
            .then((rows) => {
            // console.log(rows, "2323");
                // setCenterName(rows.data.filter(ele => ele.code == 'PPS' || ele.code == 'FPR' || ele.code == 'HTC'));
                setCenterName(rows.data.filter(ele => ele.status == 1));
            });
    }, []);

    const selectedCenter = (e) => {
        const { name, value } = e.target;
        setCenter(value);
    }
    // var currentdate = new Date().toJSON().slice(0, 10);
    // const handleChangeDate = (e) => {
    //     const { name, value } = e.target;
    //     if (name == "StartDate") {
    //         setStartDate(value)
    //         setEndDate(value);
    //     }
    //     if (name == "EndDate") {
    //         setEndDate(value)
    //     }
    // }

    const LoadData = async () => {
        if (center == "") {
            toast.info("Please select center");
            return;
        }
        if (payoutCycle == "" || payoutCycle == null) {
            toast.info("Please select Payout Cycle");
            return;
        }
        var data = {
            Center: center,
            // Year: year,
            // Month: month,
            user_id: user.user.id,
            startDate: moment(new Date(startDate)).format('YYYY-MM-DD'),
            endDate: moment(new Date(endDate)).format('YYYY-MM-DD'),
        };
        await axios
            .post(
                APIURL() + "billings/get-retainer-doctors",
                data
            )
            .then((res) => res.data)
            .then((rows) => {
                // setDays(new Date(year, month, 0).getDate())
                setDoctorList(rows.data)
                var siteCodes = []
                var flags = []
                for (let i = 0; i < rows.data.length; i++) {
                    if (flags[rows.data[i].siteCode]) continue;
                    flags[rows.data[i].siteCode] = true;
                    siteCodes.push(rows.data[i].siteCode);
                }
                // for (let index = 0; index < siteCodes.length; index++) {
                //     const element = siteCodes[index];
                // }
                getPayoutStatus("Retainer", center)
            // console.log(rows, "@!!45");
            });
    }
    const updateItemData = async (newData, oldData) => {
    // console.log(newData, oldData, 'function calls')
        // var doctorFee = parseFloat(newData.fixedAmount)
        // if (newData.LOS) {
        //     doctorFee = (parseFloat(newData.fixedAmount) / newData.totalDays) * (newData.totalDays - newData.LOS)
        // }
        // if (newData.otherDeduction) {
        //     doctorFee = (parseFloat(doctorFee)) - (parseFloat(newData.otherDeduction))
        // }
        // if (newData.otherEarning) {
        //     doctorFee = (parseFloat(doctorFee)) + (parseFloat(newData.otherEarning))
        // }
        var item = newData
        var doctorFee = parseFloat(item.current_fee).toFixed(2)
        if (item.previous_agreement_date == item.current_agreement_date) {
            if (item.current_agreement_date > startDate) {
                var date1 = new Date(item.current_agreement_date);
                var date2 = new Date(endDate);
                var timeDiff = Math.abs(date2.getTime() - date1.getTime());
                var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
                doctorFee = parseFloat((parseFloat((parseFloat(item.current_fee) / item.totalDays) * (diffDays + 1))).toFixed())
            }
        } else {
            var last_pay = 0
            var current_pay = 0
            if (item.current_agreement_date > startDate) {
                var cdate1 = new Date(item.current_agreement_date);
                var cdate2 = new Date(endDate);
                var ctimeDiff = Math.abs(cdate2.getTime() - cdate1.getTime());
                var cdiffDays = Math.ceil(ctimeDiff / (1000 * 3600 * 24));
                current_pay = parseFloat((parseFloat((parseFloat(item.current_fee) / item.totalDays) * (cdiffDays + 1))).toFixed())
                var ldate1 = new Date(item.current_agreement_date);
                var ldate2 = new Date(startDate);
                var ltimeDiff = Math.abs(ldate1.getTime() - ldate2.getTime());
                var ldiffDays = Math.ceil(ltimeDiff / (1000 * 3600 * 24));
                last_pay = parseFloat((parseFloat((parseFloat(item.previous_fee) / item.totalDays) * (ldiffDays + 1))).toFixed())
                doctorFee = parseFloat(parseFloat(current_pay)) + (parseFloat(last_pay)).toFixed(2)
            }
        }

        if (item.LOS > 0) {
            doctorFee = parseFloat(parseFloat(doctorFee)) - parseFloat((parseFloat(item.current_fee) / item.totalDays) * parseFloat(item.LOS))
        }
        if (item.OT > 0) {
            doctorFee = parseFloat(parseFloat(doctorFee)) + parseFloat((parseFloat(item.current_fee) / item.totalDays) * parseFloat(item.OT))
        }
        if (item.otherDeduction) {
            doctorFee = parseFloat(parseFloat(doctorFee)) - (parseFloat(item.otherDeduction)).toFixed(2)
        }
        if (item.otherEarning) {
            doctorFee = parseFloat(parseFloat(doctorFee) + parseFloat(item.otherEarning)).toFixed(2)
        }
        var data = {
            payout_detail_id: oldData.payout_detail_id,
            // center: center,
            doctorFee: doctorFee,
            LOS: newData.LOS ? newData.LOS : 0,
            OT: newData.OT ? newData.OT : 0,
            LOPRemarks: newData.LOPRemarks ? newData.LOPRemarks : "",
            OTRemarks: newData.OTRemarks ? newData.OTRemarks : "",
            otherDeduction: newData.otherDeduction ? newData.otherDeduction : 0,
            otherDeductionRemarks: newData.otherDeductionRemarks ? newData.otherDeductionRemarks : "",
            otherEarning: newData.otherEarning ? newData.otherEarning : 0,
            otherEarningRemarks: newData.otherEarningRemarks ? newData.otherEarningRemarks : "",
            NetPay : parseFloat(parseFloat(newData.doctorFee) * (parseInt(100 - parseInt(newData.TDS)) / 100)).toFixed(2),
            // month: month,
            // year: year,
            // startDate: moment(new Date(startDate)).format('YYYY-MM-DD'),
            // endDate: moment(new Date(endDate)).format('YYYY-MM-DD'),
        }
        await axios.post(APIURL() + 'billings/update-single-lop-item', data).catch((error) => {
        // console.log(error)
        }).then((res) => {
        // console.log(res, 'Updated bill item')
            LoadData()
            toast.success("item updated successfully");
        })
    }
    const getPayoutStatus = async (patientType = "Retainer", siteCode = center) => {
        var data = {
            center: siteCode,
            startDate: startDate,
            endDate: endDate,
            month: month,
            year: year,
            patientType: patientType,
        }
        await axios.post(APIURL() + 'billings/payout-status', data)
            .then(async (result) => {
                setPayoutDataStatus(result.data.data);
            // console.log(payoutDataStatus, result, 'payoutDataStatus')
            })
    }
    const submitLOP = async (currentAction = payoutState.hr_approved.state, nextAction = payoutState.co_hr_approval.state, patientType = "Retainer") => {
        setCirculatLoader(true);
        var siteCodes = []
        var flags = []
        for (let i = 0; i < doctorList.length; i++) {
            if (flags[doctorList[i].siteCode]) continue;
            flags[doctorList[i].siteCode] = true;
            siteCodes.push(doctorList[i].siteCode);
        }
    // console.log(siteCodes);
        for (let j = 0; j < siteCodes.length; j++) {
            const element = siteCodes[j];
            var data = {
                center: element,
                startDate: startDate,
                endDate: endDate,
                year: year,
                month: month,
                currentAction: currentAction,
                currentActionBy: user.user.id,
                role: user.user.roles,
                request_id: payoutDataStatus ? payoutDataStatus.request_id : "",
                nextAction: nextAction,
                status: 1,
                patientType: patientType,
            }
            await axios.post(APIURL() + 'billings/send-for-approval', data)
                .then(async () => {
                    toast.success(Object.values(payoutState).find((ele) => ele.state == nextAction) ? Object.values(payoutState).find((ele) => ele.state == nextAction).message : "Sent for Approval");
                    await setCirculatLoader(false);
                    await getPayoutStatus(patientType, element)
                })
        }
    }

    // const selectedMonth = async (e) => {
    //     const { name, value } = e.target;
    //     setMonth(value);
    // }

    // const selectedYear = async (e) => {
    //     const { name, value } = e.target;
    //     if (value >= (new Date).getFullYear()) {
    //         if (month >= (new Date).getMonth()) {
    //             setMonth("");
    //         }
    //     }
    //     setYear(value);
    // }

    const totalFeesAccordingToCurrAndPrevFee = (item) =>{
        let doctorFee = item.current_fee;
        if (item.previous_agreement_date == item.current_agreement_date) {
            if (item.current_agreement_date > startDate) {
                var date1 = new Date(item.current_agreement_date);
                var date2 = new Date(endDate);
                var timeDiff = Math.abs(date2.getTime() - date1.getTime());
                var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
                doctorFee = parseFloat((parseFloat((parseFloat(item.current_fee) / item.totalDays) * (diffDays + 1))).toFixed())
            }
        } else {
            var last_pay = 0
            var current_pay = 0
            if (item.current_agreement_date > startDate) {
                var cdate1 = new Date(item.current_agreement_date);
                var cdate2 = new Date(endDate);
                var ctimeDiff = Math.abs(cdate2.getTime() - cdate1.getTime());
                var cdiffDays = Math.ceil(ctimeDiff / (1000 * 3600 * 24));
                current_pay = parseFloat((parseFloat((parseFloat(item.current_fee) / item.totalDays) * (cdiffDays + 1))).toFixed())
                var ldate1 = new Date(item.current_agreement_date);
                var ldate2 = new Date(startDate);
                var ltimeDiff = Math.abs(ldate1.getTime() - ldate2.getTime());
                var ldiffDays = Math.ceil(ltimeDiff / (1000 * 3600 * 24));
                last_pay = parseFloat((parseFloat((parseFloat(item.previous_fee) / item.totalDays) * (ldiffDays + 1))).toFixed())
                doctorFee = parseFloat(parseFloat(current_pay)) + (parseFloat(last_pay)).toFixed(2)
            }
        }
        return doctorFee;
    }

    const downloadExcel = () => {
        var payoutData = [...doctorList]
        var count = 1
        const newData = payoutData.map(row => {
            // delete row.tableData
            // let doc_fee = totalFeesAccordingToCurrAndPrevFee(row);
            let obj = {
                "SlNo": count,
                "Doctor Name": row.name,
                "PAN": row.panno,
                "Date of Joining": row.doj,
                "Doctor Email": row.email,
                "Center": row.Center,
                // "Month": month,
                // "Year": year,
                "Payout Cycle": row.startDate + "/" + row.endDate,
                "Nature": row.doctorType,
                "Previous Fee": row.previous_fee ? parseFloat(row.previous_fee).toFixed() : 0,
                "Current Fee": row.current_fee ? parseFloat(row.current_fee).toFixed() : 0,
                "Total Days": row.totalDays,
                "LOP": row.LOS ? row.LOS : 0,
                "LOP Remarks": row.LOPRemarks ? row.LOPRemarks : "",
                "OT": row.OT ? row.OT : 0,
                "OT Remarks": row.OTRemarks ? row.OTRemarks : "",
                "Other Deduction": row.otherDeduction ? parseFloat(row.otherDeduction).toFixed() : "",
                "Other Deduction Remarks": row.otherDeductionRemarks ? row.otherDeductionRemarks : "",
                "Other Earning": row.otherEarning ? parseFloat(row.otherEarning).toFixed() : "",
                "Other Earning Remarks": row.otherEarningRemarks ? row.otherEarningRemarks : "",
                "TDS(%)": 10,
                "Net Pay": row.NetPay ? parseFloat(row.NetPay).toFixed() : "",
            }
            count++
            return obj
        })
        const workSheet = XLSX.utils.json_to_sheet(newData)
        const workBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook, workSheet, "doctors")
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" })
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
        //Download
        XLSX.writeFile(workBook, "Ratianer-Payout_" + startDate + "_" + endDate + ".xlsx")
    }

    const uploadRetainerPayouts = async () => {
        setOpenUploadRetainerDataModal(true)
    }

    const onChangeFIle = (element) => {
        setFile(element.target.files[0]);
        setFilename(element.target.files[0].name);
    };

    const handleUploadRetainerData = async () => {
        const reader = new FileReader();
        reader.onload = async (evt) => { // evt = on_file_select event
            var payoutData = [...doctorList]
            var newData = []
            var lopData = []
            var otData = []
            var docData = []
            var tdsData = []
            var otherDeductionData = []
            var otherEarningData = []
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws);
            /* Update state */
        // console.log("Data>>>", data);
            if (payoutData.length != data.length) {
                toast.error("Data mismatch with loaded data");
                return;
            }
            await payoutData.map(async (item) => {
                var obj = { ...item }
                var element = data.find((el) => (el["Doctor Name"]).trim() == (item.name).trim() && (el["Doctor Email"]).trim() == (item.email).trim())
                if (!element) {
                // console.log(element, item, "doc check")
                    docData.push(element)
                }
                if (!element || (element && element.LOP && element["LOP Remarks"] == "")) {
                    // console.log(isNaN(element.LOP), 'element LOP Data')
                    if (parseFloat(element.LOP) > 0) {
                        lopData.push(element)
                    }
                }
                if (!element || (element && element.OT && element["OT Remarks"] == "")) {
                    // console.log(isNaN(element.LOP), 'element LOP Data')
                    if (parseFloat(element.OT) > 0) {
                        otData.push(element)
                    }
                }
                if (!element || (element && element["TDS(%)"])) {
                    // console.log(isNaN(element.LOP), 'element LOP Data')
                    if (parseFloat(element["TDS(%)"]) === 10 || parseFloat(element["TDS(%)"]) === 20) {
                    } else {
                        tdsData.push(element)
                    }
                }
                if (!element || (element && element["Other Deduction"] && element["Other Deduction Remarks"] == "")) {
                    otherDeductionData.push(element)
                }
                if (!element || (element && element["Other Earning"] && element["Other Earning Remarks"] == "")) {
                    otherEarningData.push(element)
                }
                if (element && element["Other Earning"] && isNaN(element["Other Earning"])) {
                    otherEarningData.push(element)
                }
                if (element && element["Other Deduction"] && isNaN(element["Other Deduction"])) {
                    otherDeductionData.push(element)
                }
                if (docData.length > 0) {
                // console.log(docData, 'docData')
                    toast.error("Doctor Data mismatch with the exported data");
                    return;
                }
                if (lopData.length > 0) {
                // console.log(lopData, 'lopData')
                    toast.error("LOP remarks should not be empty for the uploaded records");
                    return;
                }
                if (otData.length > 0) {
                // console.log(otData, 'otData')
                    toast.error("OT remarks should not be empty for the uploaded records");
                    return;
                }
                if (tdsData.length > 0) {
                // console.log(tdsData, 'tdsData')
                    toast.error("TDS should eaither 10 or 20 for the uploaded records");
                    return;
                }
                if (otherDeductionData.length > 0) {
                // console.log(otherDeductionData, 'otherDeductionData')
                    toast.error("Other Deduction remarks should not be empty for the uploaded records");
                    return;
                }
                if (otherEarningData.length > 0) {
                // console.log(otherEarningData, 'otherEarningData')
                    toast.error("Other Earning remarks should not be empty for the uploaded records");
                    return;
                }
                var doc_fee_for_month = await totalFeesAccordingToCurrAndPrevFee(item)
                var doctorFee = doc_fee_for_month;
                // var doctorFee = parseFloat(obj.current_fee).toFixed(2)
                // if (obj.previous_agreement_date == obj.current_agreement_date) {
                //     if (item.current_agreement_date > startDate) {
                //         var date1 = new Date(obj.current_agreement_date);
                //         var date2 = new Date(endDate);
                //         var timeDiff = Math.abs(date2.getTime() - date1.getTime());
                //         var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
                //         doctorFee = parseFloat((parseFloat((parseFloat(item.current_fee) / obj.totalDays) * (diffDays + 1))).toFixed())
                //     }
                // } else {
                //     var last_pay = 0
                //     var current_pay = 0
                //     if (item.current_agreement_date > startDate) {
                //         var cdate1 = new Date(obj.current_agreement_date);
                //         var cdate2 = new Date(endDate);
                //         var ctimeDiff = Math.abs(cdate2.getTime() - cdate1.getTime());
                //         var cdiffDays = Math.ceil(ctimeDiff / (1000 * 3600 * 24));
                //         current_pay = parseFloat((parseFloat((parseFloat(item.current_fee) / obj.totalDays) * (cdiffDays + 1))).toFixed())
                //         var ldate1 = new Date(obj.current_agreement_date);
                //         var ldate2 = new Date(startDate);
                //         var ltimeDiff = Math.abs(ldate1.getTime() - ldate2.getTime());
                //         var ldiffDays = Math.ceil(ltimeDiff / (1000 * 3600 * 24));
                //         last_pay = parseFloat((parseFloat((parseFloat(item.previous_fee) / obj.totalDays) * (ldiffDays + 1))).toFixed())
                //         doctorFee = parseFloat(parseFloat(current_pay)) + (parseFloat(last_pay)).toFixed(2)
                //     }
                // }
                if (element.LOP > 0) {
                    doctorFee = parseFloat(parseFloat(doctorFee)) - parseFloat((parseFloat(doc_fee_for_month) / obj.totalDays) * parseFloat(element.LOP))
                }
                if (element.OT > 0) {
                    doctorFee = parseFloat(parseFloat(doctorFee)) + parseFloat((parseFloat(doc_fee_for_month) / obj.totalDays) * parseFloat(element.OT))
                }
                if (element["Other Deduction"]) {
                    doctorFee = parseFloat(parseFloat(doctorFee)) - (parseFloat(element["Other Deduction"])).toFixed(2)
                }
                if (element["Other Earning"]) {
                    doctorFee = parseFloat(parseFloat(doctorFee) + parseFloat(element["Other Earning"])).toFixed(2)
                }
            // console.log(doctorFee, obj.name, 'Retiner payout')
                try{
                obj.LOS = element.LOP ? element.LOP : 0
                obj.totalDays = obj.totalDays
                obj.LOPRemarks = element["LOP Remarks"] ? element["LOP Remarks"] : ""
                obj.otherDeduction = element["Other Deduction"] ? element["Other Deduction"] : 0
                obj.otherDeductionRemarks = element["Other Deduction Remarks"] ? element["Other Deduction Remarks"] : ""
                obj.otherEarning = element["Other Earning"] ? element["Other Earning"] : 0
                obj.otherEarningRemarks = element["Other Earning Remarks"] ? element["Other Earning Remarks"] : ""
                obj.Location = obj.siteCode
                obj.startDate = startDate
                obj.ConductedBy = obj.name
                obj.CONDUCTED_BY_LOGIN_ID = obj.email
                obj.OPIP = obj.doctorType
                // obj.PaymentMonth = month
                obj.endDate = endDate
                obj.doctorFee = doctorFee
                obj.patientType = obj.doctorType
                obj.PaymentMonth = month
                obj.OT = element.OT ? element.OT : 0
                obj.TDS = element["TDS(%)"] ? element["TDS(%)"] : 10
                obj.OTRemarks = element["OT Remarks"] ? element["OT Remarks"] : ""
                obj.NetPay = parseFloat(parseFloat(obj.doctorFee) * (parseInt(100 - parseInt(obj.TDS)) / 100)).toFixed(2)
                newData.push(obj)
                }
                catch(err){console.log(err)}
            })

        // console.log(newData.length,payoutData.length)
            if (newData.length == payoutData.length) {
            // console.log(newData)
                axios.post(APIURL() + 'billings/update-lop-item', newData).catch((error) => {
                // console.log(error)
                }).then((res) => {
                // console.log(res, 'Updated bill item')
                    LoadData()
                    toast.success("item updated successfully");
                })
            }else{
            // console.log("Something Went Wrong!")
            }
        };
        reader.readAsBinaryString(file);
    }

    const toggleHoldModal = (row) => {
        setDocData(row)
        setOpenHoldModal(true)

    }

    const changePayoutStatus = () => {
        axios.post(APIURL() + 'billings/update-lop-item-status', { id: docData.payout_detail_id, user_id: user.user.id, on_hold: !docData.onHold }).catch((error) => {
        // console.log(error)
        }).then((res) => {
        // console.log(res, 'Updated bill item status')
            LoadData()
            setRemark("")
            setOpenHoldModal(false)
            toast.success("item updated successfully");
        })
    }

    const selecPayoutCycle = (e) => {
        var val = e.target.value
        if (val) {
            var splitPayoutCycle = val.split("/")
        // console.log(splitPayoutCycle, 'selecPayoutCycle')
            setStartDate(splitPayoutCycle[0])
            setEndDate(splitPayoutCycle[1])
            setPayoutCycle(val)
        }
    }

    return (
        <>
            <Sidebar />
            <CssBaseline />
            <Container style={{ margin: "10px 0px 7px 235px", width: "80%" }}>
                <Navbar />
                {!loading ? (
                    <Skeleton animation="wave" variant="rect" width={950} height={400} />
                ) : (
                    <div style={{ whiteSpace: "nowrap" }}>
                        <Paper
                            style={{ marginBottom: "10px", border: "1px solid #810e36" }}
                        >
                            <div style={{ padding: "8px" }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} xl={3} lg={3} md={3}>
                                        <FormControl variant="outlined" style={{ width: "100%" }}>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={center}
                                                onChange={(e) => selectedCenter(e)}
                                                label=" Select Center"
                                                placeholder="Select Center"
                                                fullWidth
                                            >
                                                <MenuItem value="Select the Center" disabled selected>
                                                    Select Center
                                                </MenuItem>
                                                {centerName.length > 0 && centerName.map((ele) => {
                                                    return (<MenuItem value={ele.code}>{ele.name}</MenuItem>)
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} xl={3} lg={3} md={3}>
                                        <FormControl variant="outlined" style={{ width: "100%" }}>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={payoutCycle}
                                                onChange={(e) => selecPayoutCycle(e)}
                                                label=" Select Payout Cycle"
                                                fullWidth
                                            >
                                                <MenuItem disabled value="Select the Center">
                                                    Select Payout Cycle
                                                </MenuItem>
                                                {payoutCycles.length > 0 && payoutCycles.map((ele) => {
                                                    return (<MenuItem value={ele.label}>{ele.value}</MenuItem>)
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {/* <Grid item xs={12} sm={12} xl={3} lg={3} md={3}>
                                        <FormControl variant="outlined" style={{ width: "100%" }}>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={center}
                                                onChange={(e) => selectedCenter(e)}
                                                label=" Filter By Center"
                                                fullWidth
                                            >
                                                <MenuItem value="Select the Center">
                                                    Filter By Center
                                                </MenuItem>
                                                {centerName.length > 0 && centerName.map((ele) => {
                                                    return (<MenuItem value={ele.code}>{ele.name}</MenuItem>)
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} xl={3} lg={3} md={3}>
                                        <FormControl variant="outlined" style={{ width: "100%" }}>
                                            <input
                                                type="date"
                                                name="StartDate"
                                                value={startDate}
                                                max={currentdate}
                                                onChange={(e) => handleChangeDate(e)}
                                                style={{ color: "#000000", height: "55.98px", fontWeight: "500", fontSize: "15px" }}
                                                className="form-control"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} xl={3} lg={3} md={3}>
                                        <FormControl variant="outlined" style={{ width: "100%" }}>
                                            <input
                                                type="date"
                                                label="From"
                                                name="EndDate"
                                                min={startDate}
                                                max={currentdate}
                                                value={endDate}
                                                onChange={(e) => handleChangeDate(e)}
                                                style={{ color: "#000000", height: "55.98px", fontWeight: "500", fontSize: "15px" }}
                                                className="form-control"
                                            />
                                        </FormControl>
                                    </Grid> */}
                                    <Grid item xs={12} sm={12} xl={3} lg={3} md={3}>
                                        {" "}
                                        <Button
                                            variant="contained"
                                            onClick={() => LoadData()}
                                            style={{
                                                height: "100%",
                                                backgroundColor: "#810e36",
                                                color: "white",
                                            }}
                                            fullWidth
                                        >
                                            Load Data{" "}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={12} xl={3} lg={3} md={3}>
                                        {payoutDataStatus == null && user.user.roles == 3 ?
                                            <Button
                                                variant="contained"
                                                onClick={() => uploadRetainerPayouts()}
                                                style={{
                                                    height: "100%",
                                                    backgroundColor: "#810e36",
                                                    color: "white",
                                                }}
                                                fullWidth
                                            >
                                                Upload Retainer Data{" "}
                                            </Button>
                                            : ""}
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                        {doctorList && doctorList.length > 0 ?
                            <>
                                <MaterialTable
                                    columns={[
                                        { title: "D365 ID", field: "d365_id", editable: 'never', render: (row) => row.d365_id ? row.d365_id : '-' },
                                        { title: "Doctor name", field: "name", editable: 'never' },
                                        { title: "PAN", field: "panno", editable: 'never' },
                                        { title: "Date of Joining", field: "doj", editable: 'never' },
                                        { title: "Previous Agreement Date", field: "previous_agreement_date", editable: 'never' },
                                        { title: "Current Agreement Date", field: "current_agreement_date", editable: 'never' },
                                        { title: "Center", field: "Center", editable: 'never' },
                                        // { title: "Date Of Joining", field: "doj" },
                                        { title: "Previous Fee", field: "previous_fee", editable: 'never' },
                                        { title: "Current Fee", field: "current_fee", editable: 'never' },
                                        { title: "Total Days", field: "totalDays", editable: 'never' },
                                        { title: "LOP", field: "LOS", type: 'numeric', cellStyle: { textAlign:'left' }, validate: rowData => parseFloat(rowData.LOS) < 0 ? "LOP cannot be less than 0" : "", render: (row) => row.LOS ? parseFloat(row.LOS) : 0 },
                                        { title: "OT", field: "OT", type: 'numeric', cellStyle: { textAlign:'left' }, validate: rowData => parseFloat(rowData.OT) < 0 ? "OT cannot be less than 0" : "", render: (row) => row.OT ? parseFloat(row.OT) : 0 },
                                        { title: "Attendance", field: "Attendance", editable: 'never', render: (row) => (row.totalDays - parseFloat(row.LOS ? row.LOS : 0) + parseFloat(row.OT ? row.OT : 0)) },
                                        { title: "LOP Remarks", field: "LOPRemarks", validate: rowData => parseFloat(rowData.LOS) > 0 && rowData.LOPRemarks == "" ? "LOP Remarks can't be empty" : "", },
                                        { title: "OT Remarks", field: "OTRemarks", validate: rowData => parseFloat(rowData.OT) > 0 && rowData.OTPRemarks == "" ? "OT Remarks can't be empty" : "", },
                                        { title: "Other Deduction", field: "otherDeduction", type: 'numeric', cellStyle: { textAlign:'left' }, validate: rowData => parseFloat(rowData.otherDeduction) < 0 ? "Other Deduction cannot be less than 0" : "", },
                                        { title: "Other Deduction Remarks", field: "otherDeductionRemarks", validate: rowData => parseFloat(rowData.otherDeduction) > 0 && rowData.otherDeductionRemarks == "" ? "Other Deduction Remarks cannot be empty" : "", },
                                        { title: "Other Earning", field: "otherEarning", type: 'numeric', cellStyle: { textAlign:'left' }, validate: rowData => parseFloat(rowData.otherEarning) < 0 ? "Other Earning cannot be less than 0" : "", },
                                        { title: "Other Earning Remarks", field: "otherEarningRemarks", validate: rowData => parseFloat(rowData.otherEarning) > 0 && rowData.otherEarningRemarks == "" ? "Other Earning Remarks cannot be empty" : "", },
                                        { title: "Gross Earning", field: "doctorFee", editable: 'never' },
                                        { title: "TDS(%)", field: "TDS", editable: 'never', render: (row) => row.TDS ? row.TDS : 10 },
                                        { title: "Net Pay", field: "NetPay", editable: 'never' },
                                        { title: "Current Status", field: "onHold", editable: 'never', render: (row) => row.onHold ? 'On-Hold' : 'Under Process' },
                                        {
                                            title: "Status", field: "onHold", editable: 'never',
                                            hidden: payoutDataStatus && user.user.roles == 3,
                                            render: (row) =>
                                                user.user.roles == 3 ? row.onHold ? "" : <Button onClick={() => { toggleHoldModal(row) }} disabled={!row.payout_detail_id} className={`btn text-white btn-${row.onHold ? 'primary' : 'primary'}`}>{row.onHold ? 'Procss' : 'Hold'}</Button> :
                                                    payoutDataStatus && (payoutDataStatus.nextAction == payoutState.co_hr_approval.state) && user.user.roles == 10 ?
                                                        <Button onClick={() => { toggleHoldModal(row) }} disabled={!row.payout_detail_id} className={`btn text-white btn-${row.onHold ? 'primary' : 'primary'}`}>{row.onHold ? 'Procss' : 'Hold'}</Button> : null
                                        },
                                    ]}
                                    options={{
                                        sorting: true,
                                        // exportButton: true,
                                        exportAllData: true,
                                        pageSize: 5,
                                        filtering: true,
                                        pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                        search: true,
                                    }}
                                    data={doctorList}
                                    actions={[
                                        {
                                            icon: () => <Button size="small" color="secondary" variant="contained" startIcon={<FileDownloadOutlinedIcon fontSize="x-large" />}> Export{" "}</Button>,// you can pass icon too
                                            tooltip: "Export to Excel",
                                            onClick: () => downloadExcel(),
                                            isFreeAction: true
                                        }
                                    ]}
                                    editable={payoutDataStatus && payoutDataStatus.nextAction == payoutState.co_hr_approval.state && user.user.roles == 10 ? {
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise((resolve, reject) => {
                                                // setTimeout(() => {
                                                // const dataUpdate = [...doctorList];
                                                // const index = oldData.tableData.id;
                                                // dataUpdate[index] = newData;

                                                updateItemData(newData, oldData)
                                                // setDoctorList([...dataUpdate]);
                                                resolve();

                                                // }, 1000)
                                            }),
                                    } : {}}
                                    title="Doctor Details"
                                />
                                {
                                    payoutDataStatus == null && user.user.roles == 3 ?
                                        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                                            <Button
                                                style={{ marginLeft: "10px" }}
                                                size="small"
                                                color="secondary"
                                                variant="contained"
                                                onClick={() => submitLOP(payoutState.hr_approved.state, payoutState.co_hr_approval.state)}
                                            >
                                                Send To Corporate HR
                                            </Button>
                                        </div>
                                        : payoutDataStatus && payoutDataStatus.nextAction == payoutState.co_hr_approval.state && user.user.roles == 10 ?
                                            <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                                                <Button
                                                    style={{ marginLeft: "10px" }}
                                                    size="small"
                                                    color="secondary"
                                                    variant="contained"
                                                    onClick={() => submitLOP(payoutState.co_hr_approved.state, payoutState.dp_team_approval.state)}
                                                >
                                                    Send To DP Team
                                                </Button>
                                            </div>
                                            : ""
                                }
                            </>
                            : ""}
                    </div>
                )}

            </Container>
            {/* APPROVE MODAL */}
            <Dialog
                fullWidth={true}
                maxWidth="md"
                onClose={() => {
                    setOpenUploadRetainerDataModal(false);
                }}
                aria-labelledby="customized-dialog-title"
                open={openUploadRetainerDataModal}
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={() => {
                        setOpenUploadRetainerDataModal(false);
                    }}
                    style={{ textAlign: "center" }}
                >
                    Retainer Data
                </DialogTitle>
                <DialogContent dividers>
                    <input
                        accept=".xlsx,.csv,.docx,.xls,.doc"
                        id="fileUpload"
                        type="file"
                        onChange={onChangeFIle}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        onClick={() => handleUploadRetainerData()}
                        color="secondary"
                        variant="contained"
                        size="small"
                    >
                        Upload
                    </Button>
                    <Button
                        autoFocus
                        onClick={() => {
                            setOpenUploadRetainerDataModal(false);
                        }}
                        color="secondary"
                        variant="contained"
                        size="small"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth="md"
                onClose={() => {
                    setOpenHoldModal(false);
                }}
                aria-labelledby="customized-dialog-title"
                open={OpenHoldModal}
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={() => {
                        setOpenHoldModal(false);
                    }}
                    style={{ textAlign: "center" }}
                >
                    {docData && docData.onHold ? "Process" : "Hold"} Payment
                </DialogTitle>
                <DialogContent dividers>
                    <label>
                        <b>Remark</b>
                    </label>
                    <textarea
                        type="text"
                        onChange={Remark}
                        style={{ color: "black", border: "1px solid black" }}
                        className="form-control"
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        onClick={() => changePayoutStatus()}
                        color="secondary"
                        variant="contained"
                        size="small"
                    >
                        {docData && docData.onHold ? "Process" : "Hold"}
                    </Button>
                    <Button
                        autoFocus
                        onClick={() => {
                            setOpenHoldModal(false);
                        }}
                        color="secondary"
                        variant="contained"
                        size="small"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop className={backClasses.backdrop} open={circularLoader}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}