import React, { useEffect, useState } from "react";

//import ui
import { Container, CssBaseline } from "@material-ui/core";
import MaterialTable from "material-table";
import { Button, Typography, FormControl } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
//ui components
import PTSidebar from "../../Components/PTSidebar";
import Navbar from "../../Components/Navbar";
//axios
import axios from "axios";
import { APIURL } from "../../CommomMethods/Fetch";
import TextField from "@mui/material/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function IpLos() {
  const [rows, setRows] = useState([]);
  const [openr, setOpenr] = React.useState(false);
  const [id, setId] = useState([]);
  const [reload, setReload] = useState(false);

  const [rowDetail, setRowDetail] = useState({
    Group: "",
    Subgroup: "",
    LOS: "",
    // applicable: valueDdl,
  });
  useEffect(() => {
    axios.get(APIURL() + "billings/getLosData").then((res) => {
  // console.log(res, "los data");
      setRows(res.data.data);
    });
  }, []);
// console.log(rows.id,"%%%%%%%%%%%%5");
  // const handleInputChangeApplicaale = (event) => {
  //   setValueDdl(event.target.value);
  // };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setRowDetail({ ...rowDetail, [name]: value });
  };
  const handlerClose = () => {
    setOpenr(false);
  };
  const handleParameterEdit = (rows) => {
    setReload(true);
    // console.log(rows.id, "update data");
    axios
      .put(APIURL() + "ipparameters/updatelos/" + id, rowDetail)
      .then((res) => {
        setReload(false);
        setOpenr(false);
      });
  };


  return (
    <>
      <PTSidebar />
      <CssBaseline />
      <Container style={{ margin: "10px 0px 7px 235px", width: "80%" }}>
        <Navbar />
        <MaterialTable
          columns={[
            { title: "Admit Purpose", field: "AdmitPurpose" },
            { title: "Group", field: "Group" },

            { title: "Sub Group", field: "Subgroup" },
            { title: "LOS", field: "LOS" },
            // { title: "Center", field: "center" },
          ]}

          data={rows}
          options={{
            // filtering: true,
            sorting: true,
            exportButton: true,
            exportAllData: true,
            pageSize: 5,
            pageSizeOptions: [5, 10, 50, 100, 200, 500],
            search: true,
          }}

          actions={[
            {
              tooltip: "Edit data",
              icon: "edit",
              iconProps: { style: { color: "#810e36" } },
              onClick: (evt, data) =>{
                 setOpenr(true); 
                 setId(data.id);},
            },

          ]}

          title="Ip Los"
        />
      </Container>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerClose}
        aria-labelledby="customized-dialog-title"
        open={openr}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClose}
          style={{ textAlign: "center" }}
        >
          Edit LOS Details
        </DialogTitle>
        <DialogContent dividers>
          <label>
            <b>Group</b>
          </label>
          <TextField
            id="Payout"
            label="Group"
            variant="outlined"
            fullWidth
            name="Group"
            value={rowDetail.Group}
            onChange={handleInputChange}
            //   onChange={(e) => setPayout(e.target.value)}

            style={{ marginBottom: "20px" }}
            autoComplete="off"
          />

          <label>
            <b>SubGroup</b>
          </label>
          <TextField
            id="Payout"
            label="Subgroup"
            variant="outlined"
            fullWidth
            name="Subgroup"
            value={rowDetail.Subgroup}
            onChange={handleInputChange}
            //   onChange={(e) => setPayout(e.target.value)}

            style={{ marginBottom: "20px" }}
            autoComplete="off"
          />

          <label>
            <b>LOS</b>
          </label>
          <TextField
            id="Payout"
            label="LOS"
            variant="outlined"
            fullWidth
            name="LOS"
            value={rowDetail.LOS}
            onChange={handleInputChange}
            //   onChange={(e) => setPayout(e.target.value)}

            style={{ marginBottom: "20px" }}
            autoComplete="off"
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => handleParameterEdit(rows)}
            color="primary"
            variant="contained"
          >
            Update
          </Button>
          <Button
            autoFocus
            onClick={handlerClose}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
