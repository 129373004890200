import React, { useRef, useState } from "react";
// @material-ui/core components
import "date-fns";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import JoditEditor from "jodit-react";
import {
    FormControl,
    FormControlLabel,
    Paper,
    Radio,
    RadioGroup,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import { FormLabel } from "react-bootstrap";

const styleSheet = makeStyles((theme) => ({
    paperStyle: { marginTop: "10px", height: "50%" },
    textFieldstyle: {
        marginBottom: "20px",
        marginTop: "20px",
    },
    btnStyle: {
        marginRight: "80px",
        marginLeft: "auto",
        display: "block",
        marginBottom: "20px",
    },
}));

export default function AddDepartment({ addAgreementTemplate, defaultBody }) {
    const style = styleSheet();
    const initialFormState = {
        templateData: defaultBody,
        templateName: '',
        status: 1
    };
    const [department, setDepartment] = useState(initialFormState);
    const editor = useRef()
    const config = {
        readonly: false, // all options from https://xdsoft.net/jodit/doc/
        // height: '500px',
        askBeforePasteFromWord: false,
        askBeforePasteHTML : false
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDepartment({ ...department, [name]: value });
    };

    const handleInputTemplate = (value) => {
        setDepartment({ ...department, "templateData": value });
    }
    const handleChangeStatus = (event) => {
    // console.log(event.target.value,"event.target.value")
        setDepartment({ ...department, "status": parseInt(event.target.value) });
    }

    return (
        <>
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    addAgreementTemplate(department);
                }}
            >
                <Paper className={style.paperStyle}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextField
                                className={style.textFieldstyle}
                                variant="outlined"
                                name="templateName"
                                label="Template Name"
                                value={department.templateName}
                                onChange={handleInputChange}
                                required
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={6} style={{ padding: '15px' }}>
                            <JoditEditor ref={editor} value={department.templateData} config={config} tabIndex={1} // tabIndex of textarea
                                onBlur={newContent => handleInputTemplate(newContent)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={6} style={{ padding: '15px' }}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Status</FormLabel>
                                <RadioGroup
                                    row
                                    name="status"
                                    value={department.status}
                                    onChange={handleChangeStatus}
                                >
                                    <FormControlLabel
                                        value={1}
                                        control={<Radio color="#00963f" />}
                                        label="Active"
                                    />
                                    <FormControlLabel
                                        value={0}
                                        control={<Radio color="#00963f" />}
                                        label="Inactive"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginLeft: "58px" }}>
                        <Button type="submit" color="primary" variant="contained" className={style.btnStyle}>Add</Button>
                    </Grid>
                </Paper>
            </form>
        </>
    );
}
