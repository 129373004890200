import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import { Modal, Button, Container, Row, Col, Card } from "react-bootstrap";
import CountUp from "react-countup";
import axios from "axios";
import BarChartStatusCountHR from "../../Components/BarChartStatusCountHR";
import { Link } from "react-router-dom";
import Navmain from "../../Components/Navbar";
import HrSidebar from "../../Components/hrSidebar";
import Footer from "../../Components/Footer";
import RequestTable from "../../Components/RequestTable";
import RequestCard from "../../Components/RequestCard";
import ChartComponent1 from "../../Components/ChartComponent";
import AreaChart from "../../Components/areaChart";
import ActivitiesCard from "../../Components/ActivitiesCard";
import StatsCard from "../../Components/statsCard";
import { toast } from "react-toastify";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { listusers } from "../../actions/userActions";
import "react-circular-progressbar/dist/styles.css";
import doctorLogo from "../../images/doctor.png";
import renewalLogo from "../../images/migrate.png";
import positionLogo from "../../images/shop.png";
import { APIURL } from "../../CommomMethods/Fetch";
function Dashboard({ reqTableData, history }) {
  const [newVis, setNewVis] = useState("hiddenDiv");
  const [ongVis, setOngVis] = useState("hiddenDiv");
  const [compVis, setCompVis] = useState("hiddenDiv");
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [count, setCount] = useState([]);
  const [dashBoardCount, setDashBoardCount] = useState([]);
  const [centerwiseData, setCenterwiseData] = useState([]);
  const [statusCount, setStatusCount] = useState([]);
  const [centerposition, setCenterposition] = useState([]);
  useEffect(() => {
    axios.get(APIURL() + "onboard/onboardStatus")
      .then((res) => {
        setStatusCount(res.data.data);
        setCount(res.data.data);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, []);
  const cardStyle = {
    background: "transparent",
    border: "0px",
  };
  const infoStyle = {
    border: "2px solid #858585",
  };
  const subTitle = {
    fontSize: "13px",
  };

  const userList = useSelector((state) => state.userList);
  const { users } = userList;


  const info = count && count.data && count.data.onboardedInprogressCount && count.data.onboardedCount[0] && count.data.onboardedCount[0].onboaredCount;
  const info2 = count && count.data && count.data.onboardedInprogressCount && count.data.onboardedInprogressCount[0] && count.data.onboardedInprogressCount[0].onboaredprogressCount;
  const userloginid = users && users[0] && users[0].id;
  // console.log(userloginid, "user  login   id");
  const getposition = () => {
    axios
      .get(
        APIURL() + `centerpositionmaps/findAllCenterPositionByID/${userloginid}`
      )
      .then((res) => {
    // console.log(res.data.data, "center map data");
        const fetchdata = res.data.data
        // const newFilter =fetchdata.filter(z =>{
        //   z.openposition > 0
        // })
        // console.log(newFilter,"fetchdata");
        setCenterposition(fetchdata);
    // console.log()
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
  useEffect(() => {
    dispatch(listusers());
  }, [dispatch]);

  useEffect(() => {
    axios
      .get(APIURL() + "vacancies/centerwise")
      .then((res) => {
        setCenterwiseData(res.data);
      })
      .catch((err) => {
        toast.error(err.message);
      });
    axios
      .get(APIURL() + "vacancies/allvacancy")
      .then((res) => {
        setDashBoardCount(res.data);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, []);

  function tabChange(stat) {
    if (stat == "new") {
      if (newVis == "hiddenDiv") {
        setNewVis("visibleDiv");
        setOngVis("hiddenDiv");
        setCompVis("hiddenDiv");
      } else {
        setNewVis("hiddenDiv");
      }
    }
  }
  return (
    <>
      <HrSidebar />

      <div className="main-contenthr side-content pt-0">
        <div className="container-fluid">
          <div className="inner-body ">
            <div className="navstyle"> <Navmain /></div>
           

            <div className="page-header">
              <div>
                {/* <h2 className="main-content-title tx-24 mg-b-5">
                  Welcome {users && users[0] && users[0].name}
                </h2>
                <br /> */}
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="dashboard">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Dashboard
                  </li>
                </ol>
              </div>
            </div>
            {/* new request */}
            {/* <div className="justify-content-center"> */}
            <div className="row wrapstyle">
              <div className="col-md-4 requestCol linebreak colbreakreq">
                <a className="requestCard" onClick={() => tabChange("new")}>
                  <RequestCard
                    onClick={() => tabChange("new")}
                    reqType="new"
                    num={5}
                    datalist={statusCount}
                  />
                </a>
              </div>
              <div className="col-md-8 col-sm-12 col-xs-12 requestCol linebreak colbreakrstat">
                <StatsCard />
              </div>
            </div>
            {/* </div> */}

            {/* hidden new requests table */}
            <div className={`${newVis}`} id="newReqTable">
              <RequestTable reqTableData={reqTableData} reqType="new" history={history} />
            </div>

            <div className={`${ongVis}`} id="ongReqTable">
              <RequestTable reqTableData={reqTableData} reqType="ongoing" />
            </div>

            <div className={`${compVis}`} id="compReqTable">
              <RequestTable reqTableData={reqTableData} reqType="completed" />
            </div>

            <Card style={cardStyle}>
              <Card.Body>


                {/* <Card className="p-12" style={infoStyle}>
                            <Card.Header
                              style={{
                                paddingTop: "5px",
                                paddingLeft: "5px",
                                paddingBottom: "5px",
                                borderBottom: "1px solid #858585",
                              }}
                            >
                              <h4>Onboarded Doctors</h4>
                            </Card.Header>
                            <Card.Body style={{ margin: "0px" }}>
                              <CircularProgressbarWithChildren
                                value={count && count.data && count.data.onboardedInprogressCount && count.data.onboardedCount[0] && count.data.onboardedCount[0].onboaredCount}
                                strokeWidth={6}
                                styles={buildStyles({
                                  pathColor: "rgb(98, 89, 202, 0.8)",
                                  trailColor: "transparent",
                                })}
                              >
                                <div style={{ width: "88.5%" }}>
                                  <CircularProgressbar
                                    value={20}
                                    text={info}
                                    styles={buildStyles({
                                      pathColor: "rgba(186, 8, 66, 0.8)",
                                      trailColor: "transparent",
                                      textSize: "10px",
                                    })}
                                  />
                                </div>
                              </CircularProgressbarWithChildren>
                            </Card.Body>
                          </Card> */}
                {/* <Card className="p-12" style={infoStyle}>
                            <Card.Header
                              style={{
                                paddingTop: "5px",
                                paddingLeft: "5px",
                                paddingBottom: "5px",
                                borderBottom: "1px solid #858585",
                              }}
                            >
                              <h4>Onboarding Progress</h4>
                            </Card.Header>
                            <Card.Body style={{ margin: "0px" }}>
                              <CircularProgressbarWithChildren
                                value={count && count.data && count.data.onboardedInprogressCount && count.data.onboardedInprogressCount[0] && count.data.onboardedInprogressCount[0].onboaredprogressCount}
                                strokeWidth={6}
                                styles={buildStyles({
                                  // pathColor: "rgb(98, 89, 202, 0.8)",
                                  trailColor: "transparent",
                                })}
                              >
       <div style={{ width: "88.5%" }}>
                                  <CircularProgressbar
                                    value={20}
                                    text={info2}
                                    styles={buildStyles({
                                      pathColor: "rgba(186, 8, 66, 0.8)",
                                      trailColor: "transparent",
                                      textSize: "10px",
                                    })}
                                  />
                                </div>
                              </CircularProgressbarWithChildren>
                            </Card.Body>
                          </Card> */}
                <div className="row rowtocol">
                  <div className="col-xl-4 col-md-4">
                    <BarChartStatusCountHR datalist={count} />
                  </div>

                  <div className="col-xl-4 col-md-4 colbreak" style={{ height: "280px" }}>
                    <ChartComponent1 datalist={dashBoardCount} />
                  </div>
                  <div className="col-xl-4 col-md-4 colbreak">
                  </div>
                </div>



                <br></br>
                {/* <div className="col-xl-8 col-md-8">
                    <div className="card ht-xs-90p" style={infoStyle}>
                      <ChartComponent1 />
                    </div>
                  </div> */}

                <div>

                  <div className="col-xl-12 col-md-12 wrapstylebody">
                    <div className="card ht-xs-90p" style={infoStyle}>
                      {/* <ChartComponent1 datalist={doctorCount && doctorCount.rows && doctorCount.rows.data2 && doctorCount.rows.data2.length} /> */}
                      <h4 style={{ textAlign: "center" }}>Vacancy</h4>
                      <table
                        style={{
                          fontFamily: " arial, sans-serif",
                          borderCollapse: "collapse",
                          width: " 100%",
                        }}
                      >
                        <tr style={{ backgroundColor: "#7f0c348c" }}>
                          <td
                            style={{
                              border: "1px solid #dddddd",
                              textAlign: "left",
                              padding: "8px",
                            }}
                          >
                            Doctor Type
                          </td>
                          <td
                            style={{
                              border: "1px solid #dddddd",
                              textAlign: "left",
                              padding: "8px",
                            }}
                          >
                            Department
                          </td>
                          <td
                            style={{
                              border: "1px solid #dddddd",
                              textAlign: "left",
                              padding: "8px",
                            }}
                          >
                            Open Position
                          </td>

                          <td
                            style={{
                              border: "1px solid #dddddd",
                              textAlign: "left",
                              padding: "8px",
                            }}
                          >
                            Professional Fees (Minimum)
                          </td>
                          <td
                            style={{
                              border: "1px solid #dddddd",
                              textAlign: "left",
                              padding: "8px",
                            }}
                          >
                            Professional Fees (Maximum)
                          </td>
                        </tr>

                        {centerposition.length > 0 ? (
                          centerposition.map((item) => (
                            <tr>
                              {/* {
                                item.allocated - item.occupiedposition === 0 || item.allocated - item.occupiedposition < 0 ? (
                                  <>
                                    <div className="col-12">
                                      <span style={{
                                        color: "orangered",
                                        textAlign: "left",
                                        padding: "8px",
                                      }}>
                                      </span>
                                    </div>
                                  </>) :
                                  (<> */}
                              <td
                                style={{
                                  border: "1px solid #dddddd",
                                  textAlign: "left",
                                  padding: "8px",
                                }}
                              >
                                {item.category}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #dddddd",
                                  textAlign: "left",
                                  padding: "8px",
                                }}
                              >
                                {item.departmentname}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #dddddd",
                                  textAlign: "left",
                                  padding: "8px",
                                }}
                              >
                                {item.openposition}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #dddddd",
                                  textAlign: "left",
                                  padding: "8px",
                                }}
                              >
                                {item.fg_from}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #dddddd",
                                  textAlign: "left",
                                  padding: "8px",
                                }}
                              >
                                {item.fg_to}
                              </td>
                              {/* </>
                                  )} */}
                            </tr>
                          ))
                        ) : (
                          <tr><td colspan={5} style={{ textAlign: "center", margin: "20px" }}>No Records</td></tr>
                        )}
                      </table>
                    </div>
                  </div>

                </div>
                <br></br>
                {/* <div className="row" style={{ marginTop: "-2rem" }}>
                  <div className="col-xl-6 col-md-6">
                    <div className="card ht-xs-100p" style={infoStyle}>
                      <AreaChart />
                    </div>
                  </div>
                </div> */}
              </Card.Body>
            </Card>
            <br />
            <Card style={cardStyle}>

            </Card>
            <br />
          </div>
        </div>
        <Footer style={{}} />
      </div>
      <Modal
        size="lg"
        scrollable
        dialogClassName="modalStandard"
        centered
        aria-labelledby="example-modal-sizes-title-lg"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="modalHeader" closeButton>
          <Modal.Title className="modalTitle">Create Position</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-6">
              <label className="tx-inverse" style={{ textDecoration: "underline" }}>Select a Designation</label>
              <select className="form-control" style={{ color: "black" }}>
                <option value="Associate Director">Associate Director</option>
                <option value="Associates">Associates</option>
                <option value="Consultant">Consultant</option>
                <option value="Physician Assistant">Physician Assistant</option>
              </select>
              <br />
            </div>
            <div className="col-6 ">
              <label className="tx-inverse" style={{ textDecoration: "underline" }}>Select a Position Type</label>
              <select className="form-control" style={{ color: "black" }}>
                <option value="MG">Minimum Guarantee (MG)</option>
                <option value="saab">Full Time Consultant</option>
                <option value="audi">Visiting Consultant</option>
              </select>
            </div>
            <div className="col-6 ">
              <label className="tx-inverse" style={{ textDecoration: "underline" }}>Select a Specialization</label>
              <select className="form-control" style={{ color: "black" }}>
                <option value="Cardiology">Cardiology</option>
                <option value="saab">Obstetrics and Gynaecology</option>
                <option value="fiat">Paediatrics</option>
                <option value="audi">Radiology</option>
              </select>
            </div>
            <div className="col-6">
              <label className="tx-inverse" style={{ textDecoration: "underline" }}>Select the Branch</label>
              <select className="form-control" style={{ color: "black" }}>
                <option value="Jayanagar">Jayanagar</option>
                <option value="Malleshwaram">Malleshwaram</option>
                <option value="Bellandur">Bellandur</option>
              </select>
              <br />
            </div>
            <div className="col-12">
              <label className="tx-inverse" style={{ textDecoration: "underline" }}>Reason for Position Creation</label>
              <textarea
                style={{ color: "black" }}
                class="form-control"
                placeholder="Textarea"
                rows="3"
              ></textarea>
            </div>
          </div>
          <hr style={{ backgroundColor: "black", height: "1px", opacity: "0.4" }} />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <button className="btn btn-primary" style={{ width: "100px" }} onClick={handleClose}>
            Confirm
          </button>
          <button className="btn btn-dark" style={{ width: "100px" }} onClick={handleClose}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Dashboard;
