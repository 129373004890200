import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Navbar from "../../Components/Navbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
//ui component
import Sidebar from "../../Components/Sidebar";
import { APIURL } from "../../CommomMethods/Fetch";
const myTableStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "20%",
    marginTop: theme.spacing(3),
    "& td": {
      fontFamili: "Roboto !important",
    },
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflow: "auto",
    marginBottom: "50px",
  },
  customizedButton: {
    position: "absolute",
    left: "95%",
    top: "-9%",
    backgroundColor: "lightgray",
    color: "gray",
  },
}));

export default function OverallPayout() {
  const tables = myTableStyle();
//   const location = useLocation();
//   console.log(location.state.data, "location");
  const [rows, setRows] = useState([]);
  const [reload, setReload] = useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;

  console.log(user, "****(")
  useEffect(() => {
    // axios
    //   .get(APIURL() + "billings/findAllBillingDet/"+location.state.data)
    //   .then((res) => res.data)
    //   .then((rows) => {
    // // console.log(rows, "AllExcelData");
    //     setRows(rows);
    //   });
  }, [reload]);
//   console.log(rows,"123");
  return (
    <>
      <Sidebar />
      <CssBaseline />
      <Container style={{ margin: "10px 0px 7px 235px", width: "80%" }}>
      <Navbar />
        <Typography variant="subtitle1" style={{
                    margin: ".4rem", textAlign: "center", padding: "7px", backgroundColor: "#810e36",
                    color: "white"
                }}>Doctor Wise PayoutDetails</Typography>
        <Grid container spacing={1} style={{ marginBottom: "40px" }}>
          <Paper className={tables.root}>
            <MaterialTable
              columns={[
                { title: "SlNo", field: "SlNo" },
                { title: "MPI", field: "MPI" },
                { title: "Item Group Name", field: "ItemGroupName" },
                { title: "Item Name", field: "ItemName" },
                { title: "Specialisation", field: "Specialisation" },
                { title: "Location", field: "Location" },
                { title: "Conducted By", field: "ConductedBy" },
                { title: "Doctor Payout", field: "DoctorPayout" },
             
               
              ]}
            //   data={rows}
              options={{
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
                exportAllData: true,
              }}
              title="Overall Payout"
            />
          </Paper>
        </Grid>
      </Container>
    </>
  );
}