import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//ui components
import AppSidebar from "../../Components/PTSidebar";
import Navbar from "../../Components/Navbar";
import ConfirmDialog from "../../Components/ConfirmDialog";
import ipCommercialSample from "../../SampleFile/IPCommercialSample.xlsx";
import opCommercialSampleFIle from "../../SampleFile/opCommercialSampleFIle.xlsx";
import c9gif from "../../SampleFile/cloudnine.gif";
//materia components
import Backdrop from "@material-ui/core/Backdrop";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { Button, Typography } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import WorkIcon from "@material-ui/icons/Work";
import Divider from "@material-ui/core/Divider";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { Skeleton } from "@mui/material";

//
import axios from "axios";
import { APIURL } from "../../CommomMethods/Fetch";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    width: "100%",
    marginBottom: "10px",
  },
  root2: {
    height: "235px",
    overflowY: "auto",
    // marginTop: "10px",
    width: "100%",
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  iconStyle: {
    color: "#820e37",
    cursor: "pointer",
    marginRight: "20px",
    height: "20px",
    width: "20px",
  },
}));
const stylesmodal = makeStyles({
  root: {
    marginTop: "18px",
  },
});

const backDropStyle = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function UploadCommercials() {
  const styleClasses = stylesmodal();
  const classes = useStyles();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });
  const initialFormState = {
    Year: "",
    Month: "",
    Center: "",
    region: "",
  };
  const [backDropOpen, setBackDropOpen] = useState(false);
  const backClasses = backDropStyle();
  const [value, setValue] = React.useState("1");
  const [payoutDtls, setPayoutDtls] = useState(initialFormState);
  const [file, setFile] = useState();
  const [filename, setFilename] = useState();
  const [name, setname] = useState("true");
  const [rows, setRows] = useState([]);
  const [oprows, setOpRows] = useState([]);
  const [iprows, setIpRows] = useState([]);
  const [opPayoutDiscount, setOpPayoutDiscount] = useState([]);
  const [ipPayoutDiscount, setIpPayoutDiscount] = useState([]);
  const [loadingPayout, setLoadingpayout] = useState(true);
  const [loading, setLoading] = useState(false);
  
  const [centerName, setCenterName] = useState([]);
  // const [to, setToDate] = React.useState(new Date());
  // const [From, setFromDate] = React.useState(new Date());
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;

  // const handleChangeBirthday = (a) => {
  //   setFromDate(a);
  //   payoutDtls.from = a;
  // };
  // const handleChangeBirthdays = (a) => {
  //   setToDate(a);
  //   payoutDtls.to = a;
  // };
  const [circularLoader, setCirculatLoader] = useState(false);
  useEffect(() => {
    axios.get(APIURL() + "usermapcenters/centermaps/" + user.user.id)
      .then((res) => res.data)
      .then((rows) => {
    // console.log(rows, "2323");
        setCenterName(rows.data);
      });
  }, []);

  console.log(centerName,"CENTERNAME");
  const handleChangetab = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    setLoadingpayout(true);
    axios.get(APIURL() + "billings/getopcom").then((res) => {
      var filterData = [];
      if (user.user.id == 401) {
        filterData = res.data.data.filter((z) => z.region == "NORTH");
      }
      if (user.user.id == 400) {
        filterData = res.data.data.filter((z) => z.region == "SOUTH");
      }
      if (user.user.id == 402) {
        filterData=res.data.data.filter((z)=>z.region == "WEST");
      }
      setOpRows(filterData);
      setLoadingpayout(false);
    });
  }, [loading]);

useEffect(() => {
  setLoadingpayout(true);    
axios.get(APIURL() + "billings/getallIvf").then((res) => {
      var filterData = [];
      if (user.user.id == 356) {
        filterData = res.data.data.filter((z) => z.Center == "NORTH");
      }
      if (user.user.id == 357) {
        filterData = res.data.data.filter((z) => z.Center == "SOUTH");
      }
      if (user.user.id == 358) {
        filterData = res.data.data.filter((z) => z.Center == "WEST");
      }
      setRows(filterData);
      setLoadingpayout(false);
    });
  }, [loading]);

  useEffect(() => {
    setLoadingpayout(true);
    axios.get(APIURL() + "billings/ipbayreceiving").then((res) => {
      var filterData = [];
      if (user.user.id == 356) {
        filterData = res.data.data.filter((z) => z.Center == "NORTH");
      }
      if (user.user.id == 357) {
        filterData = res.data.data.filter((z) => z.Center == "SOUTH");
      }
      if (user.user.id == 358) {
        filterData = res.data.data.filter((z) => z.Center == "WEST");
      }
      setIpRows(filterData);
      setLoadingpayout(false);
    });
  }, [circularLoader]);
useEffect(() => {
  setLoadingpayout(true);    
axios.get(APIURL() + "billings/ipcommercialFile").then((res) => {
      var filterData = [];
      if (user.user.id == 401) {
        filterData = res.data.data.filter((z) => z.Center == "NORTH");
      }
      if (user.user.id == 400) {
        filterData = res.data.data.filter((z) => z.Center == "SOUTH");
      }
      if (user.user.id == 402) {
        filterData = res.data.data.filter((z) => z.Center == "WEST");
      }
      setIpRows(filterData);
      setLoadingpayout(false);
    });
  }, [circularLoader]);

  const deleteFileData = (rowData) => {
    setLoadingpayout(true);
    setLoading(false);
// console.log(rowData, "rowData");
    //deleteOpPayoutBillProcess
    // axios.delete(APIURL() + "billings/deleteopcom/" + rowData.id)
    axios
      .post(APIURL() + "billings/deleteipitemdata", rowData)
      .then(function (response) {
        setLoadingpayout(false);
        setLoading(true);
        if (response.data) {
      // console.log(response);
          alert("Total record deleted" + response.data);
        }
      });
  };

  const deleteOpFileData = (rowData) => {
    setLoadingpayout(true);
    setLoading(false);
// console.log(rowData, "rowData");
    //deleteOpPayoutBillProcess
    axios.delete(APIURL() + "billings/deleteopcom/" + rowData.id)
      .then(function (response) {
        setLoadingpayout(false);
        setLoading(true);
        if (response.data) {
      // console.log(response);
          // alert("Total record deleted" + response.data);
        }
      });
  };
  const deleteIpComeercialFile = (rowData) => {
    setCirculatLoader(true);
    setLoadingpayout(true);
    setLoading(false);
// console.log(rowData, "rowData");
    //deleteOpPayoutBillProcess
    axios
      .post(APIURL() + "billings/deleteIpComeercialFileData", rowData)
      .then(function (response) {
        setLoadingpayout(false);
        setCirculatLoader(false);
        setLoading(true);
        if (response.data) {
      // console.log(response);
          // alert("Total record deleted" + response.data);
        }
      });
  };
  const handleDownloadFile = (url) => {
    fetch(APIURL() + "billings/download/" + url, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "1";
        a.click();
      });

      //window.location.href = response.url;
    });
  };

  const uplodComFIle = () => {
    setLoading(false);
    setLoadingpayout(true);
    var fileupld = document.getElementById("fileUpload").innerHTML;
    const formData = new FormData();
    formData.append("name", filename);
    formData.append("docFile", file);
    formData.append("uploadedBy", user.user.username);
    formData.append("payoutDtls", JSON.stringify(payoutDtls));
    const obj = { formData: formData, payoutDtls: payoutDtls };

    
    axios.post(APIURL() + "billings/createOpCom", formData)
    .then(function (response) {
      if (response.data.message != "ok") {
        setConfirmDialog({
          isOpen: true,
          title: "Error",
          subtitle: response.data.message,
        });
        setLoading(true);
        setPayoutDtls(initialFormState);
        setLoadingpayout(false);
        return;
      }
      if (response.data.msgdata) {
        setLoading(true);
        setPayoutDtls(initialFormState);
        setLoadingpayout(false);
        setConfirmDialog({
          isOpen: true,
          title: "Alert",
          subtitle: "Total " + response.data.msgdata + " record dumped.",
        });
        return;
      }
      setLoading(true);
      setPayoutDtls(initialFormState);
      setLoadingpayout(false);
    });
    setname("false");
};
  const uplodFIle = () => {
    setLoading(false);

    setLoadingpayout(true);
    var fileupld = document.getElementById("fileUpload").innerHTML;
    const formData = new FormData();
    formData.append("name", filename);
    formData.append("docFile", file);
    formData.append("uploadedBy", user.user.username);
    formData.append("payoutDtls", JSON.stringify(payoutDtls));
    const obj = { formData: formData, payoutDtls: payoutDtls };
    axios
      .post(APIURL() + "billings/createIpItem", formData)
      .then(function (response) {
        if (response.data.message != "ok") {
          setConfirmDialog({
            isOpen: true,
            title: "Alert",
            subtitle: response.data.message,
          });
          setLoading(true);
          setPayoutDtls(initialFormState);
          setLoadingpayout(false);
          return;
        }
        if (response.data.msgdata) {
          setLoading(true);
          setPayoutDtls(initialFormState);
          setLoadingpayout(false);
          setConfirmDialog({
            isOpen: true,
            title: "Alert",
            subtitle: "Total " + response.data.msgdata + " record dumped.",
          });
          return;
        }
        setLoading(true);
        setPayoutDtls(initialFormState);
        setLoadingpayout(false);
      });
  };

  const uploadIpCommercial = () => {
    setCirculatLoader(true);
    setLoading(false);

    setLoadingpayout(true);
    var fileupld = document.getElementById("fileUpload").innerHTML;
    const formData = new FormData();
    formData.append("name", filename);
    formData.append("docFile", file);
    formData.append("uploadedBy", user.user.username);
    formData.append("payoutDtls", JSON.stringify(payoutDtls));
    const obj = { formData: formData, payoutDtls: payoutDtls };
    axios
      .post(APIURL() + "billings/uploadIpCommercial", formData)
      .then(function (response) {
        setCirculatLoader(false);
        if (response.data.message != "ok") {
          setConfirmDialog({
            isOpen: true,
            title: "Alert",
            subtitle: response.data.message,
          });
          setLoading(true);
          setPayoutDtls(initialFormState);
          setLoadingpayout(false);
          setFilename();
          return;
        }
        if (response.data.msgdata) {
          setLoading(true);
          setPayoutDtls(initialFormState);
          setLoadingpayout(false);
          setConfirmDialog({
            isOpen: true,
            title: "Alert",
            subtitle: "Total " + response.data.msgdata + " record dumped.",
          });
          return;
        }
        setFilename();
        setLoading(true);
        setPayoutDtls(initialFormState);
        setLoadingpayout(false);
      });
  };
  const onChangeFIle = (element) => {
    setFile(element.target.files[0]);
    setFilename(element.target.files[0].name);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setPayoutDtls({ ...payoutDtls, [name]: value });
  };

  function payoutSkeleton() {
    return (
      <Card style={{ marginBottom: "8px" }}>
        <div style={{ textAlign: "center" }}>
          <Skeleton variant="text" />
        </div>
        <CardHeader
          avatar={
            loadingPayout ? (
              <Skeleton
                animation="wave"
                variant="circle"
                width={40}
                height={40}
              />
            ) : (
              ""
            )
          }
          title={
            loadingPayout ? (
              <>
                <Skeleton
                  animation="wave"
                  height={10}
                  width="60%"
                  style={{ marginBottom: 6 }}
                />
                <Skeleton
                  animation="wave"
                  height={10}
                  width="60%"
                  style={{ marginBottom: 6 }}
                />
              </>
            ) : (
              "Ted"
            )
          }
        />
      </Card>
    );
  }
  return (
    <>
      <AppSidebar />
      <CssBaseline />
      <Container style={{ margin: "10px 0px 7px 235px", width: "80%" }}>
        <Navbar />
        <TabContext value={value}>
          <AppBar
            position="static"
            style={{ marginTop: "10px", backgroundColor: "#810e36" }}
          >
            <TabList
              onChange={handleChangetab}
              aria-label="simple tabs example"
            >
              <Tab label="Op Commercial" value="1" />
              <Tab label="Ip Commercial" value="2" />
            </TabList>
          </AppBar>
          <TabPanel value="1">
            {" "}
            {/* <Typography variant="subtitle1"> Op Commercal</Typography> */}
            <Grid container spacing={2} style={{ marginTop: "30px" }}>
              <Grid item xs={12} sm={12} xl={6} md={6} lg={6}>
                {" "}
                <Typography variant="subtitle1">Op Commercal</Typography>
              </Grid>
              <Grid item xs={12} sm={12} xl={6} md={6} lg={6}>
                {" "}
                <Tooltip title="DOWNOAD SAMPLE FILE" aria-label="add">
                  <Button
                    color="secondary"
                    size="large"
                    variant="text"
                    style={{ float: "right" }}
                  >
                    <a href={opCommercialSampleFIle} download="IP COMMERCIAL FILE">
                      Sample File
                    </a>
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: "30px" }}>
              <Grid item xs={12} sm={12} xl={6} md={6} lg={6}>
                {" "}
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Year
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={payoutDtls.Year}
                    name="Year"
                    onChange={handleChange}
                    label="Select Year"
                  >
                    <MenuItem value="2021">2021</MenuItem>
                    <MenuItem value="2022">2022</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Month
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={payoutDtls.Month}
                    name="Month"
                    onChange={handleChange}
                    label="Select Year"
                  >
                    <MenuItem value="JANUARY">JANUARY</MenuItem>
                    <MenuItem value="FEBRUARY">FEBRUARY</MenuItem>
                    <MenuItem value="MARCH">MARCH</MenuItem>
                    <MenuItem value="APRIL">APRIL</MenuItem>
                    <MenuItem value="MAY">MAY</MenuItem>
                    <MenuItem value="JUNE">JUNE</MenuItem>
                    <MenuItem value="JULY">JULY</MenuItem>
                    <MenuItem value="AUGUST">AUGUST</MenuItem>
                    <MenuItem value="SEPTEMBER">SEPTEMBER</MenuItem>
                    <MenuItem value="OCTOBER">OCTOBER</MenuItem>
                    <MenuItem value="NOVEMBER">NOVEMBER</MenuItem>
                    <MenuItem value="DECEMBER">DECEMBER</MenuItem>
                  </Select>
                </FormControl>
                
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Center
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    // value={payoutDtls.Center}
                    value={payoutDtls.Center}
                    // value={c}
                    name="Center"
                    onChange={handleChange}
                    label="Select Center"
                  >
                    {centerName.length > 0 &&
                      centerName.map((ele) => {
                        return <MenuItem value={ele.code}>{ele.name}</MenuItem>;
                      })}
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Region
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={payoutDtls.region}
                    name="region"
                    onChange={handleChange}
                    label="Select Region"
                  >
                    {user.user.id == 401 ? (
                      <MenuItem value="NORTH">NORTH</MenuItem>
                    ) : user.user.id == 400 ? (
                      <MenuItem value="SOUTH">SOUTH</MenuItem>
                    ) : user.user.id == 402 ? (
                      <MenuItem value="WEST">WEST</MenuItem>
                    ) : (
                      ""
                    )}
                  </Select>
                </FormControl>
                {/* <FormControl variant="outlined" className={classes.formControl}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="date-picker-dialog"
                      autoOk
                      label="From"
                      // disablePast
                      id="fromdate"
                      value={From}
                      className={styleClasses.root}
                      onChange={handleChangeBirthday}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      fullWidth
                    />
                  </MuiPickersUtilsProvider>
                </FormControl> */}
                {/* <FormControl variant="outlined" className={classes.formControl}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="date-picker-dialog"
                      autoOk
                      label="To"
                      // disablePast
                      id="todate"
                      value={to}
                      className={styleClasses.root}
                      onChange={handleChangeBirthdays}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      fullWidth
                    />
                  </MuiPickersUtilsProvider>
                </FormControl> */}
                <label htmlFor="fileUpload">
                  <Button
                    style={{ backgroundColor: "#820e37" }}
                    variant="contained"
                    color="primary"
                    component="span"
                    endIcon={<CloudUploadIcon />}
                    fullWidth
                  >
                    Upload FIle
                  </Button>
                </label>
                <input
                  accept=".xlsx,.csv,.docx,.xls,.doc"
                  id="fileUpload"
                  type="file"
                  style={{ display: "none", margin:"2px"}}
                  onChange={onChangeFIle}
                />
                {name == "true" ? <>{filename}</>: ""}

                {payoutDtls.Center != "" &&
                  payoutDtls.Month != "" &&
                  payoutDtls.Year != "" &&
                  file ? (
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    fullWidth
                    onClick={uplodComFIle}
                    style={{ backgroundColor: "#820e37" }}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    disabled={true}
                    variant="contained"
                    color="primary"
                    component="span"
                    fullWidth
                    onClick={uplodComFIle}
                    style={{ backgroundColor: "gray" }}
                  >
                    Submit
                  </Button>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                xl={6}
                md={6}
                lg={6}
              // style={{ marginTop: "10px" }}
              >
                {" "}
                <List className={classes.root2}>
                  {loadingPayout ? (
                    <>
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}{" "}
                    </>
                  ) : (
                    oprows &&
                    oprows.map((ele) => (
                      <>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <WorkIcon />
                            </Avatar>
                          </ListItemAvatar>

                          <ListItemText
                            primary={ele.Year}
                            secondary={ele.Month}
                          />
                          <div style={{ marginBottom: "15px" }}>
                            {/* <Tooltip
                              title="Proceed for Next Action"
                              aria-label="add"
                            >
                              <Link
                                to={{
                                  // pathname: "/oppayout-process",
                                  // state: { data: ele },
                                }}
                              >
                                <SkipNextIcon className={classes.iconStyle} />
                              </Link>
                            </Tooltip> */}
                            <Tooltip title="Download" aria-label="add">
                              <GetAppIcon
                                className={classes.iconStyle}
                                onClick={() => {
                                  handleDownloadFile(ele.File);
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Remove" aria-label="add">
                              <DeleteForeverIcon
                                className={classes.iconStyle}
                                onClick={() => {
                                  setConfirmDialog({
                                    isOpen: true,
                                    title:
                                      "Discarding Op-File may result in some features unresponsive ?",
                                    subtitle:
                                      "Are you sure you want to Discard this record ?",
                                    onConfirm: () => {
                                      deleteOpFileData(ele);
                                      setConfirmDialog({ isOpen: false });
                                    },
                                  });
                                }}
                              />
                            </Tooltip>
                          </div>
                        </ListItem>
                        <Divider style={{ marginBottom: "18px" }} />
                      </>
                    ))
                  )}
                </List>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            {" "}
            <Grid container spacing={2} style={{ marginTop: "30px" }}>
              <Grid item xs={12} sm={12} xl={6} md={6} lg={6}>
                {" "}
                <Typography variant="subtitle1"> Ip Commercial</Typography>
              </Grid>
              <Grid item xs={12} sm={12} xl={6} md={6} lg={6}>
                {" "}
                <Tooltip title="DOWNOAD SAMPLE FILE" aria-label="add">
                  <Button
                    color="secondary"
                    size="large"
                    variant="text"
                    style={{ float: "right" }}
                  >
                    <a href={ipCommercialSample} download="IP COMMERCIAL FILE">
                      Sample File
                    </a>
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: "30px" }}>
              <Grid item xs={12} sm={12} xl={6} md={6} lg={6}>
                {" "}
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Year
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={payoutDtls.Year}
                    name="Year"
                    onChange={handleChange}
                    label="Select Year"
                  >
                    <MenuItem value="2021">2021</MenuItem>
                    <MenuItem value="2022">2022</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Month
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={payoutDtls.Month}
                    name="Month"
                    onChange={handleChange}
                    label="Select Year"
                  >
                    <MenuItem value="JANUARY">JANUARY</MenuItem>
                    <MenuItem value="FEBRUARY">FEBRUARY</MenuItem>
                    <MenuItem value="MARCH">MARCH</MenuItem>
                    <MenuItem value="APRIL">APRIL</MenuItem>
                    <MenuItem value="MAY">MAY</MenuItem>
                    <MenuItem value="JUNE">JUNE</MenuItem>
                    <MenuItem value="JULY">JULY</MenuItem>
                    <MenuItem value="AUGUST">AUGUST</MenuItem>
                    <MenuItem value="SEPTEMBER">SEPTEMBER</MenuItem>
                    <MenuItem value="OCTOBER">OCTOBER</MenuItem>
                    <MenuItem value="NOVEMBER">NOVEMBER</MenuItem>
                    <MenuItem value="DECEMBER">DECEMBER</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Center
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    // value={payoutDtls.Center}
                    value={payoutDtls.Center}
                    // value={c}
                    name="Center"
                    onChange={handleChange}
                    label="Select Center"
                  >
                    {centerName.length > 0 &&
                      centerName.map((ele) => {
                        return <MenuItem value={ele.code}>{ele.name}</MenuItem>;
                      })}
                      {user.user.id == 401 ? (
                      <MenuItem value="NORTH">NORTH</MenuItem>
                    ) : user.user.id == 400 ? (
                      <MenuItem value="SOUTH">SOUTH</MenuItem>
                    ) : user.user.id == 402 ? (
                      <MenuItem value="WEST">WEST</MenuItem>
                    ) : (
                      ""
                    )}
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Region
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={payoutDtls.region}
                    name="region"
                    onChange={handleChange}
                    label="Select Region"
                  >
                    {user.user.id == 401 ? (
                      <MenuItem value="NORTH">NORTH</MenuItem>
                    ) : user.user.id == 400 ? (
                      <MenuItem value="SOUTH">SOUTH</MenuItem>
                    ) : user.user.id == 402 ? (
                      <MenuItem value="WEST">WEST</MenuItem>
                    ) : (
                      ""
                    )}
                  </Select>
                </FormControl>
                <label htmlFor="fileUpload">
                  <Button
                    style={{ backgroundColor: "#820e37" }}
                    variant="contained"
                    color="primary"
                    component="span"
                    endIcon={<CloudUploadIcon />}
                    fullWidth
                  >
                    Upload FIle
                  </Button>
                </label>
                <input
                  accept=".xlsx,.csv,.docx,.xls,.doc"
                  id="fileUpload"
                  type="file"
                  style={{ display: "none" }}
                  onChange={onChangeFIle}
                />
                <span style={{ marginLeft: "18px", color: "#7e0b33" }}>
                  {filename}
                </span>
                {payoutDtls.Center != "" &&
                payoutDtls.Month != "" &&
                payoutDtls.Year != "" &&
                payoutDtls.region != "" &&
                file ? (
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    fullWidth
                    onClick={uploadIpCommercial}
                    style={{ backgroundColor: "#820e37" }}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    disabled={true}
                    variant="contained"
                    color="primary"
                    component="span"
                    fullWidth
                    onClick={uploadIpCommercial}
                    style={{ backgroundColor: "gray" }}
                  >
                    Submit
                  </Button>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                xl={6}
                md={6}
                lg={6}
                // style={{ marginTop: "10px" }}
              >
                {" "}
                <List className={classes.root2}>
                  {loadingPayout ? (
                    <>
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}
                      {payoutSkeleton()}{" "}
                    </>
                  ) : (
                    iprows &&
                    iprows.map((ele) => (
                      <>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <WorkIcon />
                            </Avatar>
                          </ListItemAvatar>

                          <ListItemText
                            primary={ele.Year}
                            secondary={ele.Month}
                          />
                          <div style={{ marginBottom: "15px" }}>
                            {/* <Tooltip
                              title="Proceed for Next Action"
                              aria-label="add"
                            >
                              <Link
                                to={{
                                  pathname: "/oppayout-process",
                                  state: { data: ele },
                                }}
                              >
                                <SkipNextIcon className={classes.iconStyle} />
                              </Link>
                            </Tooltip> */}
                            <Tooltip title="Download" aria-label="add">
                              <GetAppIcon
                                className={classes.iconStyle}
                                onClick={() => {
                                  handleDownloadFile(ele.File);
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Remove" aria-label="add">
                              <DeleteForeverIcon
                                className={classes.iconStyle}
                                onClick={() => {
                                  setConfirmDialog({
                                    isOpen: true,
                                    title:
                                      "Discarding Op-File may result in some features unresponsive ?",
                                    subtitle:
                                      "Are you sure you want to Discard this record ?",
                                    onConfirm: () => {
                                      deleteIpComeercialFile(ele);
                                      setConfirmDialog({ isOpen: false });
                                    },
                                  });
                                }}
                              />
                            </Tooltip>
                          </div>
                        </ListItem>
                        <Divider style={{ marginBottom: "18px" }} />
                      </>
                    ))
                  )}
                </List>
              </Grid>
            </Grid>
          </TabPanel>
        </TabContext>
      </Container>

      <Backdrop className={backClasses.backdrop} open={circularLoader}>
        {/* <CircularProgress color="inherit" /> */}

        <img src={c9gif} alt="loading..." />
      </Backdrop>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
