import React, { PureComponent, useState } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine, ResponsiveContainer, LabelList} from 'recharts';

const data = [
  {name: 'Center-1', "2021": -4000, "2022": 2400},
  {name: 'Center-2', "2021": -3000, "2022": 1398},
  {name: 'Center-3', "2021": -2000, "2022": 6800},
  {name: 'Center-4', "2021": -2780, "2022": 3908},
  {name: 'Center-5', "2021": -1890, "2022": 4800},
  {name: 'Center-6', "2021": -2390, "2022": 3800},
  {name: 'Center-7', "2021": -3490, "2022": 4300},
  {name: 'Center-8', "2021": -4000, "2022": 2400},
  {name: 'Center-9', "2021": -3000, "2022": 1398},
  {name: 'Center-10', "2021": -2000, "2022": 6800},
  {name: 'Center-11', "2021": -2780, "2022": 3908},
  {name: 'Center-12', "2021": -1890, "2022": 4800},
  {name: 'Center-13', "2021": -2390, "2022": 3800},
  {name: 'Center-14', "2021": -3490, "2022": 4300},
  {name: 'Center-15', "2021": -4000, "2022": 2400},
  {name: 'Center-16', "2021": -3000, "2022": 1398},
  {name: 'Center-17', "2021": -2000, "2022": 6800},
  {name: 'Center-18', "2021": -2780, "2022": 3908},
  {name: 'Center-19', "2021": -1890, "2022": 4800},
  {name: 'Center-20', "2021": -2390, "2022": 3800},
  {name: 'Center-21', "2021": -3490, "2022": 4300},
  {name: 'Center-22', "2021": -2780, "2022": 3908},
  {name: 'Center-23', "2021": -1890, "2022": 4800},
  {name: 'Center-24', "2021": -2390, "2022": 3800},
  {name: 'Center-25', "2021": -3490, "2022": 4300},
];

const legends = [
  {legendName: "2021"},
  {legendName: "2022"},
];




const StackedDualBarGraph = (props) => {
  const [focusBar, setFocusBar] = useState(null);
  const numbersManager = (number) => {
    let nf = new Intl.NumberFormat('en-IN');
    if (!isNaN(number)) {
      return nf.format(Math.abs(number / 100000).toFixed(0))
    }
    else{
      return nf.format(number.toFixed(0))
    }
  }
  const renderCustomizedLabel = (props) => {
    const {
      x, y, width, height, value,
    } = props;
  
    let Reswidth = 2;
  const fireOffset = value > 0;
  let fill = "white"
  if(Math.abs(width) > 80){
    Reswidth = (width/2);
  }
  else{
    Reswidth = width;
    fill = "#000"
  }
  const offset = fireOffset ? -40 : 5;
  console.log(fireOffset, offset, value, `${value}`.length, width.toFixed(0), Reswidth, "asdfghjklpuywqzxcvbnmkjhgfdsa")
    return (
        <text x={x + Reswidth -offset} y={y + height - 8} fill={fill} textAnchor="end" fontSize="12px">
          { value ? numbersManager(value) : 0}
        </text>
    );
  };
  
  const CustomTooltip = ({ active, payload, label }) => {
    // console.log(payload,"arr--------")
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p  style={{color:'Black', fontSize: "12px", fontWeight: "bolder"}}>{`${label}`}</p>
          {payload.map((entry, index) => (
            <p className="label" style={{display:'flex', allignItems:'left', fontSize: "10px"}}>{`${entry.name} : ${numbersManager(entry.value)}`}</p>
            ))}
        </div>
      );
    }
    return null;
  };
  
  const COLORS = ['#94438b', '#e4308b'];
  // console.log(props.RevenueyearData)
    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          layout="vertical"
          width={400}
          height={100}
          data={props.RevenueyearData}
          stackOffset="sign"
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          onMouseMove={(state) => {
            if (state.isTooltipActive) {
              setFocusBar(state.activeTooltipIndex);
            } else {
              setFocusBar(null);
            }
         }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis type="number" tickFormatter={(label) => `${(Math.abs(label/100000)).toLocaleString('en-IN')}`} />
          <YAxis dataKey="name" type="category" scale="band" fontSize="11px"/>
          <Tooltip cursor={{fill: 'transparent'}} wrapperStyle={{zIndex: 10003}} content={<CustomTooltip />} />
          <Legend wrapperStyle={{fontSize: "11px"}} iconSize={8} iconType='circle' />
          <ReferenceLine x={0} stroke="#000" />
          {props.Legendyear.map((entry, index) => (
          <Bar dataKey={entry.legendName} fill={COLORS[index % COLORS.length]} stackId="stack" barCategoryGap={5} barSize={20}>
            {data.map((entry, index) => (
              <Cell
                fillOpacity={focusBar === index ? ".5": "1"}
              />
            ))}
            <LabelList dataKey={entry.legendName} content={renderCustomizedLabel} position="insideRight" style={{ fill: "white" }} />
          </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
    );
  }

export default StackedDualBarGraph;
