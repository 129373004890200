import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/core components
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { IconButton } from "@material-ui/core";
import ListIcon from "@material-ui/icons/List";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
// import { SnackbarContent } from "@material-ui/core";
import SnackbarContent from "@material-ui/core/SnackbarContent";
// axios
import axios from "axios";
//bootstrap
import { Container } from "react-bootstrap";
import { APIURL } from "../../../CommomMethods/Fetch";
//ui components
import AddIpPayableNonPayable from "./forms/AddIpPayableNonPayable";
import EditIpPayableNonPayable from "./forms/EditIpPayableNonPayable";
import ListIpPayableNonPayable from "./tables/ListIpPayableNonPayable";
import Navbar from "../../../Components/Navbar";
import AppSidebar from "../../../Components/PTSidebar";

const styleSheet = makeStyles((theme) => ({
  iconButtonStyle: {
    float: "right",
  },
  marginforMaster: {
    marginTop: "0px",
  },
}));

export default function ManageIpPayableNonPayable({ user }) {
  const classes = styleSheet();
  const dispatch = useDispatch();

  //useStae for Snackbar
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    color: "",
    icon: "",
  });
  //variables for snack bar
  const { vertical, horizontal, open } = state;
  //function for snackbar
  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const initialFormState = {
    id: null,
    ItemGroupname: "",
    Itemname: "",
    Pnp: "",
    regionName: "",
    centerCode: "",
    centerId: "",
  };
  const [department, setDepartment] = useState(initialFormState);
  const [preview, setPreview] = useState(true);
  const [editing, setEditing] = useState(false);
  const [rows, setRows] = useState([]);
  const [regionRows, setRegionRows] = useState([]);
  const [data, setData] = useState(false);
  const [itemDetail, setItemDetail] = useState([]);
  console.log(user, "dataa from propss");
  // get all records from doctor
  useEffect(() => {
    axios
      .get(APIURL() + "ippayablelineitem/findPayableLineItem")
      .then((res) =>  {
        setItemDetail(res.data.itemDetail)
      })
      .then((res) => res.data)
      .then((rows) => {
        // var filterData = [];
        // if (user.user.id == 401) {
        //   filterData = rows.filter((z) => z.regionName == "North");
        // }
        // if (user.user.id == 400) {
        //   filterData = rows.filter((z) => z.regionName == "South");
        // }
        // if (user.user.id == 402) {
        //   filterData = rows.filter((z) => z.regionName == "West");
        // }
    // console.log(rows, "managedoctor");
        setRows(rows);
      });
  }, [data]);

  //populate single record in the Edit component
  const editRow = (department) => {
    setPreview(false);
    setEditing(true);
    setDepartment({
      id: department.id,
      ItemGroupname: department.ItemGroupname,
      Itemname: department.Itemname,
      Pnp: department.Pnp,
      regionName: department.regionName,
      centerCode: department.centerCode,
      centerId: department.centerId,
    });
  };

  //update department
  const updateDepartment = (id, department) => {
    setData(false);
// console.log(id, department, "doctor row data");
    axios
      .put(
        APIURL() + "ippayablelineitem/updatePayableLineItem/" + id,
        department
      )
      .then((res) => {
        if (res.status === 200) {
          setEditing(false);
          setPreview(true);
          setData(true);

          setState({
            open: true,
            vertical: "top",
            horizontal: "center",
            message: res.data.message,
            color: "success",
          });
        }
      });
  };

  //add designation
  const addDepartment = (department) => {
    setData(false);
    axios
      .post(APIURL() + "ippayablelineitem/createLineItem", department)
      .then((res) => {
        setPreview(true);
        setData(true);
    // console.log(res, "res from addition of P/N");
        if (res.status === 200) {
          setState({
            open: true,
            vertical: "top",
            horizontal: "center",
            message: "Ip Payable Item added successfully.",
            color: "success",
          });
        }
      });
  };

  //deletion of designation
  const deleteDepartment = (row) => {
    setData(false);
    var k = window.confirm("Are you sure want to delete this record ?");
    if (k) {
      axios.delete(APIURL() + "centers/" + row.id).then((res) => {
        setData(true);
        if (res.status === 200) {
          setState({
            open: true,
            vertical: "top",
            horizontal: "center",
            message: res.data.message,
            color: "danger",
          });
        }
      });
    }
  };

  return (
    <>
      {/* <AppSidebar />
      <div className="main-content side-content pt-0 mg-b-30">
        <div className="container-fluid">
          <div className="inner-body">
            <Navbar /> */}
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={8}
          xl={8}
          className={classes.marginforMaster}
        >
          <Typography variant="h6">Manage Ip-Payable-NonPayable</Typography>
          {preview ? (
            <Typography variant="body2">
              Masters / Manage Ip-Payable-NonPayable / Ip-Payable-NonPayable
              List
            </Typography>
          ) : editing ? (
            <Typography variant="body2">
              Masters / Manage Ip-Payable-NonPayable / Edit
              Ip-Payable-NonPayable
            </Typography>
          ) : (
            <Typography variant="body2">
              Masters / Manage Ip-Payable-NonPayable / Add Ip-Payable-NonPayable
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          className={classes.marginforMaster}
        >
          <div className={classes.iconButtonStyle}>
            <IconButton
              color="primary"
              onClick={() => {
                setPreview(true);
                setEditing(false);
              }}
            >
              <ListIcon />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() => {
                setPreview(false);
              }}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {preview ? (
            <ListIpPayableNonPayable
              rows={itemDetail}
              editRow={editRow}
              deleteDepartment={deleteDepartment}
            />
          ) : editing ? (
            <EditIpPayableNonPayable
              editing={editing}
              setEditing={setEditing}
              setPreview={setPreview}
              currentDepartment={department}
              updateDepartment={updateDepartment}
            />
          ) : (
            <AddIpPayableNonPayable
              addDepartment={addDepartment}
              regionRows={regionRows}
              currentDepartment={department}
            />
          )}
        </Grid>
      </Grid>
      {/* </div>
        </div>
      </div> */}
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={5000}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <SnackbarContent color={state.color} message={state.message} />
      </Snackbar>
    </>
  );
}
