import React, { useEffect, useState } from "react";
import { Card, Table, Toast } from "react-bootstrap";
import Navbar from "../../Components/Navbar";
import { Modal } from "react-bootstrap";
//import ui components
import DoctorSidebar from "../../Components/DoctorSidebar";
import { agreementState } from "../../CommomMethods/States";
//import material-ui component
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import MaterialTable from "material-table";
import { Paper } from "@material-ui/core";
//import axios
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";

import List from "@material-ui/core/List";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import { listusers } from "../../actions/userActions";
import SidebarRD from "../../Components/Rd-sidebar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { toast } from "react-toastify";
import FixedfullTime from "../Payout/NEW/FixedfullTime";
import FixedMG from "../Payout/NEW/fixedMG";
import VcChennai from "../Payout/NEW/VcChennai";
import AssignmentIcon from "@material-ui/icons/Assignment";
import VisitingCon from "../Payout/NEW/VisitingCon";
import { APIURL } from "../../CommomMethods/Fetch";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "fixed",
    backgroundColor: "#810e36",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));
export default function CheckAgreement() {
  const [show, setShow] = useState(false);
  const [rows, setRows] = useState([]);
  const [orow, setOrow] = useState({});
  const [openr, setOpenr] = useState(false);
  const [remark, setRemark] = useState("");
  const [viewAgreement, setViewAgreement] = useState(false);
  const [row, setRow] = useState({});
  const [categoty, setCategory] = useState("");
  const [docData, setDocData] = useState([]);
  const classes = useStyles();
  const dispatch = useDispatch();
  const userList = useSelector((state) => state.userList);
  const { users } = userList;

  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;
  let userid = users[0] && users[0].id;
  useEffect(() => {
    dispatch(listusers());
  }, [dispatch]);

  // useEffect(() => {
  //   axios
  //     .get(APIURL() + "onboard/getAgreementbyRd/" + user.user.id)
  //     .then((res) => {
  //       setRows(res.data.data);
  //     })
  //     .catch((error) => {});
  //   // axios
  //   //   .get(APIURL() + "onboard/currentAgreementStatus/")
  //   //   .then((res) => {
  //   //     setRows(res.data.data);
  //   //   })
  //   //   .catch((error) => {
  //   //   });
  // }, [user]);

  useEffect(() => {
    axios
      .get(APIURL() + "onboard/currentstatus/")
      .then((res) => res.data)
      .then((rows) => {
    // console.log(rows, userid, "chk point for ch");
        setRows(rows.datas);
        // setRows(rows.data.filter((z) => z.currentActionBy == userid));
      });
  }, []);

  const formStyle = {
    borderRadius: "5px",
    border: "1px solid black",
  };
  const headerStyle = {
    backgroundColor: "#94248E",
    margins: "0px",
    padding: "10px",
    color: "white",
    borderRadius: "5px",
    borderBottom: "3px black solid",
  };
  const bodyStyle = {
    borderRadius: "5px",
  };
  const handleClose = () => setShow(false);
  const handleShow = (doctorid) => {
    setShow(true);
  };

  const handleApprove = (row) => {
    const obj = {
      doctorId: row.doctorId,
      name: row.name,
      currentAction: agreementState.rd_approved.state,
      currentActionBy: users[0].id,
      nextAction: "COMPLETE",
      status: 1,
    };
    axios.post(APIURL() + "onboard/agreementAction/", obj).then((res) => {
      window.location.reload();
      // toast.success("Successfully Confirmed");
    });
  };

  const handlerClickOpen = (row) => {
    setOrow(row);
    setOpenr(true);
  };
  const Remark = (e) => {
    setRemark(e.target.value);
  };

  const handlerClose = () => {
    setOpenr(false);
  };

  const handleSubmitReject = () => {
    const obj = {
      doctorId: orow.doctorId,
      name: orow.name,
      currentAction: agreementState.rd_approved.state,
      currentActionBy: users[0].id,
      nextAction: agreementState.ch_approved.state,
      remark: remark,
      status: 1,
    };

    axios.post(APIURL() + "onboard/agreementAction/", obj).then((res) => {
      toast.success("Rejection successfully submitted");
    });
    window.location.reload();
  };
  const closeAgreement = () => {
    setViewAgreement(false);
  };

  const viewAgreementDoc = (row) => {
    setRow(row);
    axios
      .get(APIURL() + "onboard/agreementView/" + row.doctorId)
      .then((res) => {
        if (res.data != null) {
          setCategory(res.data.data.vacancyData[0].category);
          setDocData(res.data.data);
        }
      });
    setViewAgreement(true);
  };

  return (
    <>
      <SidebarRD />

      <div className="main-content side-content pt-0 mg-b-30">
        <div className="container-fluid">
          <div className="inner-body">
            <Navbar />

            <form>
              <br />
              <Card style={formStyle}>
                <Card.Body style={bodyStyle}>
                  <MaterialTable
                    columns={[
                      { title: "Doctor Name", field: "name" },

                      // {
                      //   title: "Current Action",
                      //   field: "Action",
                      //   filtering: false,
                      //   render: (row) => (
                      //     <div style={{ width: "140px" }}>
                      //       <>{agreementState.hr_accepted.message}</>
                      //     </div>
                      //   ),
                      // },
                      // {
                      //   title: "Current Action",
                      //   field: "currentAction",
                      //   filtering: false,
                      //   render: (row) => (
                      //     <div style={{ width: "140px" }}>
                      //       {row.currentAction === agreementState.init.state ? (
                      //         <span style={{ color: "red" }}>
                      //           {" "}
                      //           {agreementState.init.message}
                      //         </span>
                      //       ) : row.currentAction ===
                      //         agreementState.rd_revision.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.rd_revision.message}
                      //         </span>
                      //       ) : row.currentAction ===
                      //         agreementState.hr_revision.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.hr_revision.message}
                      //         </span>
                      //       ) : row.currentAction ===
                      //         agreementState.dr_accepted.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.dr_accepted.message}
                      //         </span>
                      //       ) : row.currentAction ===
                      //         agreementState.hr_accepted.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.hr_accepted.message}
                      //         </span>
                      //       ) : row.currentAction ===
                      //         agreementState.ch_approved.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.ch_approved.message}
                      //         </span>
                      //       ) : row.currentAction ===
                      //         agreementState.ceo_approved.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.ceo_approved.message}
                      //         </span>
                      //       ) : row.currentAction ===
                      //         agreementState.rd_approved.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.rd_approved.message}
                      //         </span>
                      //       ) : row.currentAction ===
                      //         agreementState.dr_decline.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.dr_decline.message}
                      //         </span>
                      //       ) : row.currentAction ===
                      //         agreementState.ch_decline.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.ch_decline.message}
                      //         </span>
                      //       ) : row.currentAction ===
                      //         agreementState.rd_decline.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.rd_decline.message}
                      //         </span>
                      //       ) : row.currentAction ===
                      //         agreementState.hr_decline.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.hr_decline.message}
                      //         </span>
                      //       ) : row.currentAction ===
                      //         agreementState.completed ? (
                      //         <span style={{ color: "blue" }}>"COMPLETED"</span>
                      //       ) : (
                      //         ""
                      //       )}
                      //     </div>
                      //   ),
                      // },
                      // {
                      //   title: "Next Action",
                      //   field: "nextAction",
                      //   filtering: false,
                      //   render: (row) => (
                      //     <div style={{ width: "140px" }}>
                      //       {row.nextAction === agreementState.init.state ? (
                      //         <span style={{ color: "red" }}>
                      //           {" "}
                      //           {agreementState.init.message}
                      //         </span>
                      //       ) : row.nextAction ===
                      //         agreementState.rd_revision.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.rd_revision.message}
                      //         </span>
                      //       ) : row.nextAction ===
                      //         agreementState.hr_revision.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.hr_revision.message}
                      //         </span>
                      //       ) : row.nextAction ===
                      //         agreementState.dr_accepted.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.dr_accepted.message}
                      //         </span>
                      //       ) : row.nextAction ===
                      //         agreementState.hr_accepted.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.hr_accepted.message}
                      //         </span>
                      //       ) : row.nextAction ===
                      //         agreementState.ch_approved.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.ch_approved.message}
                      //         </span>
                      //       ) : row.nextAction ===
                      //         agreementState.ceo_approved.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.ceo_approved.message}
                      //         </span>
                      //       ) : row.nextAction ===
                      //         agreementState.rd_approved.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.rd_approved.message}
                      //         </span>
                      //       ) : row.nextAction ===
                      //         agreementState.dr_decline.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.dr_decline.message}
                      //         </span>
                      //       ) : row.nextAction ===
                      //         agreementState.ch_decline.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.ch_decline.message}
                      //         </span>
                      //       ) : row.nextAction ===
                      //         agreementState.rd_decline.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.rd_decline.message}
                      //         </span>
                      //       ) : row.nextAction ===
                      //         agreementState.hr_decline.state ? (
                      //         <span style={{ color: "blue" }}>
                      //           {agreementState.hr_decline.message}
                      //         </span>
                      //       ) : row.nextAction === agreementState.completed ? (
                      //         <span style={{ color: "blue" }}>"COMPLETED"</span>
                      //       ) : (
                      //         ""
                      //       )}
                      //     </div>
                      //   ),
                      // },

                      // {
                      //   title: "View Agreement",
                      //   field: "Action",
                      //   filtering: false,
                      //   render: (rows) => (
                      //     <div style={{ width: "140px" }}>
                      //       {rows && rows.data1 && rows.data1.agreementcurrentAction === "DR_ACCEPTED" ? (
                      //         <InsertDriveFileIcon
                      //           style={{ color: "#810e36", cursor: "pointer" }}
                      //           aria-label="close"
                      //           onClick={() => {
                      //             viewAgreementDoc(row);
                      //           }}
                      //         >
                      //           <VisibilityIcon />
                      //         </InsertDriveFileIcon>
                      //       ) : (
                      //         <InsertDriveFileIcon
                      //           style={{ color: "gray" }}
                      //           aria-label="close"
                      //         >
                      //           <VisibilityIcon />
                      //         </InsertDriveFileIcon>
                      //       )}
                      //     </div>
                      //   ),
                      // },
                      {
                        title: "View Agreement",
                        field: "Action",
                        filtering: false,
                        render: (row) =>
                          row.agreementcurrentAction == "DR_ACCEPTED" ? (
                            <div style={{ width: "140px" }}>
                              <AssignmentIcon
                                style={{ color: "#810e36" }}
                                aria-label="close"
                                onClick={() => {
                                  viewAgreementDoc(row);
                                }}
                              >
                                <VisibilityIcon />
                              </AssignmentIcon>
                            </div>
                          ) : (
                            <div style={{ width: "140px" }}>
                              <AssignmentIcon
                                style={{ color: "gray" }}
                                aria-label="close"
                              >
                                <VisibilityIcon />
                              </AssignmentIcon>
                            </div>
                          ),
                      },
                      // {
                      //   title: "Approve/Reject",
                      //   field: "Action",
                      //   filtering: false,
                      //   render: (row) =>
                      //     row.nextAction ===
                      //     agreementState.ch_approved.state ? (
                      //       <div style={{ whiteSpace: "nowrap" }}>
                      //         <Button
                      //           size="small"
                      //           color="primary"
                      //           variant="contained"
                      //           onClick={() => {
                      //             handleApprove(row);
                      //           }}
                      //         >
                      //           Approve
                      //         </Button>

                      //         <Button
                      //           style={{ marginLeft: "5px" }}
                      //           size="small"
                      //           color="secondary"
                      //           variant="contained"
                      //           onClick={() => {
                      //             handlerClickOpen(row);
                      //           }}
                      //         >
                      //           Rectification Required
                      //         </Button>
                      //       </div>
                      //     ) : (
                      //       <Button
                      //         style={{ marginLeft: "5px" }}
                      //         size="small"
                      //         color="secondary"
                      //         variant="contained"
                      //         disabled
                      //       >
                      //         No Action Required
                      //       </Button>
                      //     ),
                      // },
                    ]}
                    data={rows.data1}
                    // data={
                    //   rows && rows.filter((z) => z.nextAction === "CH_APPROVED")
                    // }
                    options={{
                      // filtering: true,
                      sorting: true,
                      exportButton: true,
                      pageSize: 5,
                      pageSizeOptions: [5, 10, 50, 100, 200, 500],
                      search: true,
                    }}
                    title="Agreement Details"
                  />
                  {/* <MaterialTable
                    columns={[
                      { title: "Doctor Name", field: "name" },
                      {
                        title: "Current Action",
                        field: agreementState.rd_approved.message,
                      },

                      {
                        title: "View Documents",
                        field: "Action",
                        filtering: false,
                        render: (row) => (
                          <div style={{ width: "140px" }}>
                            <AssignmentIcon
                              style={{ color: "#810e36" }}
                              className="fas fa-eye"
                              onClick={() => {
                                viewAgreementDoc(row);
                              }}
                            ></AssignmentIcon>
                          </div>
                        ),
                      },
                      {
                        title: "Action",
                        field: "Action",
                        filtering: false,
                        render: (row) =>
                          row.nextAction ===
                          agreementState.rd_approved.state ? (
                            <div style={{ whiteSpace: "nowrap" }}>
                              <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                  handleApprove(row);
                                }}
                              >
                                Approve
                              </Button>

                              <Button
                                style={{ marginLeft: "5px" }}
                                size="small"
                                color="secondary"
                                variant="contained"
                                onClick={() => {
                                  handlerClickOpen(row);
                                }}
                              >
                                Rectification Required
                              </Button>
                            </div>
                          ) : (
                            " "
                          ),
                      },
                    ]}
                    data={
                      rows &&
                      rows.filter(
                        (z) => z.nextAction === agreementState.rd_approved.state
                      )
                    }
                    options={{
                      // filtering: true,
                      sorting: true,
                      exportButton: true,
                      pageSize: 5,
                      pageSizeOptions: [5, 10, 50, 100, 200, 500],
                      search: true,
                    }}
                    title="View Agreement"
                  /> */}

                  {/* <Table style={{ width: "99%" }} borderless>
                    <thead>
                      <tr
                        style={{
                          textAlign: "center",
                          borderBottom: "1px solid rgb(200, 200, 200)",
                          backgroundColor: "hsla(340, 97%, 26%, 0.55)",
                          color: "white",
                        }}
                      >
                        <th style={{ width: "40%" }}>Doctor Name</th>
                        <th style={{ width: "40%" }}>Current Action</th>
                        <th style={{ width: "40%" }}>View Documents</th>
                        <th style={{ width: "40%" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rows &&
                        rows
                          .filter(
                            (z) =>
                              z.nextAction === agreementState.rd_approved.state
                          )
                          .map((ele) => (
                            <tr
                              id="color"
                              style={{
                                textAlign: "center",
                                borderBottom: "1px solid rgb(200, 200, 200)",
                              }}
                            >
                              <td>{ele.name}</td>
                              <td>{agreementState.rd_approved.message}</td>

                              <td>
                                <i
                                  className="fas fa-eye"
                                  color="black"
                                  onClick={() => {
                                    viewAgreementDoc(ele);
                                  }}
                                ></i>
                              </td>
                              <td>
                                {ele.nextAction ===
                                agreementState.rd_approved.state ? (
                                  <div style={{ whiteSpace: "nowrap" }}>
                                    <Button
                                      size="small"
                                      color="primary"
                                      variant="contained"
                                      onClick={() => {
                                        handleApprove(ele);
                                      }}
                                    >
                                      Approve
                                    </Button>

                                    <Button
                                      style={{ marginLeft: "5px" }}
                                      size="small"
                                      color="secondary"
                                      variant="contained"
                                      onClick={() => {
                                        handlerClickOpen(ele);
                                      }}
                                    >
                                      Rectification Required
                                    </Button>
                                  </div>
                                ) : (
                                  " "
                                )}
                              </td>
                            </tr>
                          ))}
                    </tbody>

                    {rows &&
                    rows.filter(
                      (z) => z.nextAction === agreementState.rd_approved.state
                    ).length === 0 ? (
                      <tbody>
                        <tr style={{ textAlign: "center",width:"100%" }}>
                         <td colSpan="3"> No Record Found</td>
                         
                        </tr>
                      </tbody>
                    ) : (
                      ""
                    )}
                  </Table> */}
                </Card.Body>
              </Card>
            </form>
          </div>
        </div>
      </div>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerClose}
        aria-labelledby="customized-dialog-title"
        open={openr}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClose}
          style={{ textAlign: "center" }}
        >
          Review
        </DialogTitle>
        <DialogContent dividers>
          <label>
            <b>Remark</b>
          </label>
          <textarea
            type="text"
            onChange={Remark}
            style={{ color: "black", border: "1px solid black" }}
            className="form-control"
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleSubmitReject}
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
          <Button
            autoFocus
            onClick={handlerClose}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen
        open={viewAgreement}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="end"
              style={{ marginLeft: "99%" }}
              color="inherit"
              onClick={closeAgreement}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <List>
          {categoty === "Full Time Consultant" || categoty === 'Retainer' ? (
            <FixedfullTime
              history={row}
              recData={docData}
              catagory={categoty}
            />
          ) : categoty === "Minimum Guarentee(MG)" ? (
            <FixedMG history={row} recData={docData} catagory={categoty} />
          ) : categoty === "Visiting Consultant" ? (
            <VisitingCon history={row} recData={docData} catagory={categoty} />
          ) : categoty === "Visiting Consultant Chennai" ? (
            <VcChennai history={row} recData={docData} catagory={categoty} />
          ) : (
            "hi"
          )}
        </List>
      </Dialog>
    </>
  );
}
