import React, { useState, useEffect } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import {
  FormControl,
  Typography,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import { Paper } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import UpgradeIcon from '@mui/icons-material/Upgrade';
import CancelIcon from '@mui/icons-material/Cancel';
import RefreshIcon from "@material-ui/icons/Refresh";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@mui/material/InputAdornment";
import CountertopsIcon from "@mui/icons-material/Countertops";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import MoneyIcon from "@mui/icons-material/Money";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import CalculateIcon from "@mui/icons-material/Calculate";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Divider from "@material-ui/core/Divider";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
//ui components
import { toast } from "react-toastify";
import ConfirmDialog from "../../../Components/ConfirmDialog";
import { APIURL } from "../../../CommomMethods/Fetch";
import { numberWithCommas } from "../../../actions/createPositionActions";
import { useSelector } from "react-redux";
import { Autocomplete, createFilterOptions } from "@mui/material";
import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
import { CheckBox as CheckBoxIcon } from "@mui/icons-material";

const applicableOnOptions = [
  {
    label: 'Gross Percentage',
    value: 'Gross'
  },
  {
    label: 'Net Percentage',
    value: 'Net'
  },
  {
    label: 'Fixed Amount',
    value: 'Fixed'
  },
]

const myTableStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    whiteSpace: "nowrap",
    marginTop: theme.spacing(3),
    "& td": {
      fontFamili: "Roboto !important",
      whiteSpace: "nowrap",
    },
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflow: "auto",
    marginBottom: "50px",
  },
  root1: {
    "& label + .MuiInput-formControl": {
      marginTop: "1vw",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    width: "100%",
  },
}));
export default function OpDetail({ sendDataToParent, sendCalculatedData, data, doctorData, centerData, centerList, primaryCenter }) {

  const initialFormtState = {
    consultation: "",
    applicableOn: "",
    totalRevenue: "",
    opfixedpay: "",
    oppercpay: "",
    opadhoc: "",
    opcount: "",
    opmonth: "",
    fixedamount: "",
    fixedpercentage: "",
    opperunitcharge: "",
    netamount: "",
    opamount: "",
    oprevenue: "",
    opgross: "",
    opnet: "",
    oppayout: "",
    opdiscounttype: "",
    opdiscountvalue: "",
  };
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });
  const tables = myTableStyle();
  const classes = useStyles();
  const [parentItem, setParentItem] = useState('');
  const [childItem, setChildItem] = useState([]);
  const [options, setOptions] = useState([]);
  const [centerOptions, setCenterOptions] = useState([]);
  const [childoptions, setChildOptions] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState('');
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;
  const [opservice, setOpservice] = useState({
    initialFormtState,
  });
  const [totalOpRevenue, setTotalOpRevenue] = useState(0);
  const [totalOpPayout, setTotalOpPayout] = useState(0);
  const [table, setTable] = useState([]);
  const [totalnetAmount, setTotalnetAmount] = useState([]);
  const [totalpayout, setTotalpayout] = useState([]);
  const [commercialOpTable, setCommercialOpTable] = useState([]);
  const [Discount, setDiscount] = useState("No");
  const [opmonth, setOpMonth] = useState(1);
  const [opNetAmount, setOpNetAmount] = useState(0);
  const [totaldeduction, setTotaldeduction] = useState(0);
  const [opRevenue, setopRevenue] = useState(0);
  const [opNetPayout, setOpNetPayout] = useState(0);
  const [gross, setGross] = useState("");
  const [adhoc, setAdhoc] = useState("");
  const [selected, setSelected] = useState([]);
  const [selectedchild, setSelectedChild] = useState([]);
  const [applicableOn, setApplicableOn] = useState('');
  const [applicableOnOption, setApplicableOnOption] = useState(null);
  const [selectedRows, setSelectedRows] = useState([])
  const [opCommercialItem, setOpCommercialItem] = useState([])

  useEffect(() => {
    let centerDataValue = [...centerData]
    if (primaryCenter && primaryCenter !== undefined && primaryCenter.name) {
      if (!centerDataValue.find(ele => ele == primaryCenter.name)) {
        centerDataValue.push(primaryCenter.name)
      }
    } else {
      if (data.centerNames && data.centerNames !== undefined) {
        if (!centerDataValue.find(ele => ele == data.centerNames)) {
          centerDataValue.push(data.centerNames)
        }
      }
      if (data.doctorId && data.businessunit && data.businessunit !== undefined) {
        if (!centerDataValue.find(ele => ele == data.businessunit)) {
          centerDataValue.push(data.businessunit)
        }
      }
    }
// console.log(centerDataValue, centerDataValue.length, data.centerNames, "centerDataValue", primaryCenter)
    let centervalue = data.centerNames;
    setCenterOptions(centerDataValue);
    // setSelectedCenter(centervalue);
    for (let i = 0; i < centerList.length; i++) {
      const element = centerList[i];
      if (centervalue == element.name) {
        getOpItemGroups(element.code)
    // console.log(element.code, "centerDataValue")
      }
    }
  }, [data, centerData, primaryCenter]);

  useEffect(() => {
    if (doctorData && doctorData.doctorOPParameter) {
      updateOpCommercials(doctorData.doctorOPParameter)
    }
  }, [doctorData]);

  const getOpItemGroups = async (siteCode, itemGroupName = '', itemName = '') => {
    await axios
      .get(APIURL() + "onboard/fetchopItemData/" + siteCode)
      .then((res) => res.data)
      .then(async (rows) => {
    // console.log(rows.data, "fetchopItemData");
        await rows.data.forEach(async (element) => {
          if (itemGroupName == element.itemGroupName) {
            await setSelected(itemGroupName);
            await setParentItem(element)
            await getOpSubItems(siteCode, element.itemGroupId, itemName)
          }
          element.selected = false;
        });
        await setOptions(rows.data);
        // setParentItem(rows.data);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }
  const getOpSubItems = async (siteCode, itemGroupId, itemName = '') => {
    var childItem = []
    var selectedItemName = []
    await axios
      .get(APIURL() + "onboard/fetchopSubItemData/" + siteCode + "/" + itemGroupId)
      .then((res) => res.data)
      .then(async (rows) => {
        await rows.data.forEach((element) => {
          if (element.itemName === itemName) {
            childItem.push(element)
            selectedItemName.push(itemName)
          }
          element.selected = false;
        });
        await setChildOptions(rows.data);
        if (itemName === 'Select all') {
          await setChildItem(rows.data);
          await setSelectedChild([]);
        } else {
          await setChildItem(childItem);
          await setSelectedChild(selectedItemName);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  const isAllChildSelected =
    childoptions.length > 0 && childItem.length === childoptions.length;

  const parentItemChange = async (event, value) => {
// console.log(event, value, 'Itemgroup')
    var itemGroupName = ''
    var siteCode = ''
    for (let i = 0; i < centerList.length; i++) {
      const element = centerList[i];
      if (selectedCenter == element.name) {
        siteCode = element.code
      }
    }
    if (value) {
      itemGroupName = value.itemGroupName
      getOpSubItems(siteCode, value.itemGroupId)
      setChildItem([]);
    }
    setSelected(itemGroupName);
    setParentItem(value)
  };

  const handleSelectAll = isSelected => {
    if (isSelected) {
      let Itemname = []
      childoptions.forEach((element) => {
        Itemname.push(element.itemName);
      });
      setChildItem(childoptions);
      setSelectedChild(Itemname.length === childoptions.length ? [] : Itemname);
    } else {
      handleClearOptions();
    }
  };

  const handleClearOptions = () => {
    setSelectedChild([])
    setChildItem([])
  };
  const handleToggleOption = selectedOptions => {
    let Itemname = []
    selectedOptions.forEach((element) => {
      Itemname.push(element.itemName);
    });
    setSelectedChild(Itemname);
    setChildItem(selectedOptions);
  }

  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!isAllChildSelected);
  };

  console.log(selected, "selectedselected");
  console.log(selectedchild, "selectedchildselectedchild");
  const childItemChange = async (event, selectedOptions, reason) => {
// console.log(reason, selectedOptions, 'childItemChange')
    if (reason === "selectOption" || reason === "removeOption") {
      if (selectedOptions.find(option => option.itemName === "Select all")) {
        handleToggleSelectAll();
      } else {
        handleToggleOption && handleToggleOption(selectedOptions);
      }
    } else if (reason === "clear") {
      handleClearOptions && handleClearOptions();
    }
  };

  const validationOp = async () => {
    if (!selectedCenter) {
      setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Please select center.",
      });
      return false;
    }
    if (selected.length <= 0) {
      setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Please select item group.",
      });
      return false;
    }
    if (selectedchild.length <= 0 && !isAllChildSelected) {
      setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Please select Procedure.",
      });
      return false;
    }
    if (!applicableOn) {
      setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Please select Applicable On.",
      });
      return false;
    }
    return true;
  }

  const addTableData = () => {
    var valid = validationOp()
    if (!valid) {
      return;
    }
    opservice.opmonth = opmonth;
    opservice.consultation = '';
    opservice.opdiscounttype = Discount;
    opservice.applicableOn = applicableOn;
    opservice.oprevenue = opRevenue;
    opservice.oppayout = opNetPayout;
    opservice.netamount = opNetAmount;
    opservice.opgross = gross;
    opservice.opnet = opNetAmount;
    opservice.opadhoc = adhoc ? adhoc : 0;
    opservice.opdiscountvalue = opservice.opdiscountvalue
      ? opservice.opdiscountvalue
      : 0;
    opservice.totalRevenue = opservice.totalRevenue
      ? opservice.totalRevenue
      : 0;
    let opserviceDataArray = [];
    if (selectedchild.length > 0 && selected) {
      for (let k = 0; k < selectedchild.length; k++) {
        for (let m = 0; m < table.length; m++) {
          if (selected + "," + selectedchild[k] + "," + selectedCenter === table[m].itemGroup + "," + table[m].itemName + "," + table[m].center) {
            setConfirmDialog({
              isOpen: true,
              title: "Alert",
              subtitle: table[m].itemGroup + "," + table[m].itemName + " for center" + table[m].center + " already exist.",
            });
            setSelectedChild([]);
            return;
          }
        }
        let opserviceData = {
          ...opservice,
          itemGroup: selected,
          itemName: selectedchild[k],
          center: selectedCenter,
        };
        opserviceDataArray.push(opserviceData);
        // setTotalnetAmount((j) => [...(j + opserviceData.oprevenue)]);
      }
    } else {
      for (let m = 0; m < table.length; m++) {
        if (selected + "," + selectedCenter + "," === table[m].itemGroup + "," + table[m].center + "," + (table[m].itemName ? table[m].itemName : '')) {
          setConfirmDialog({
            isOpen: true,
            title: "Alert",
            subtitle: table[m].itemGroup + " for center" + table[m].center + " already exist.",
          });
          return;
        }
      }
      let opserviceData = { ...opservice, itemGroup: selected, center: selectedCenter };
      opserviceDataArray.push(opserviceData);
      // setTotalnetAmount((j) => [...(j + opserviceData.oprevenue)]);
    }
// console.log(opserviceDataArray, "opserviceArray");
    setTotalpayout((j) => [...(j + opservice.oppayout)]);
    setTable((prevItems) => [...prevItems, ...opserviceDataArray]);
    createCommercialOp(opserviceDataArray);
    refreshopRecord();
  };

  console.log(table, "tabletable");
  const calculatePR = (array) => {
// console.log(array.length);
    let payout = 0;
    let revenue = 0;
    array.forEach((element) => {
      payout += new Number(element.oppayout);
      revenue += new Number(element.oprevenue);

  // console.log(payout, revenue);
    });

    setTotalOpPayout(payout);
    setTotalOpRevenue(revenue);
  };

  useEffect(() => {
    calculatePR(table);
  }, [table]);

  useEffect(() => {
    sendDataToParent(table, commercialOpTable);
  }, [table, commercialOpTable]);

  useEffect(() => {
    sendCalculatedData(totalOpRevenue);
  }, [totalOpRevenue]);

  const createCommercialOp = async (datalist) => {
    var commercialOpTableData = [...commercialOpTable]
    await datalist.map(async (ele) => {
      let ob = {};
      let itemgroup = ele.itemGroup;
      let itemName = ele.itemName;
      var commercialterm = "";
      commercialterm = ele.applicableOn == 'Fixed' ? "Fixed Amount of Rs. " + ele.fixedamount + " /- Per case" : ele.applicableOn == 'Gross' ? ele.fixedpercentage + " % of the Gross Amount" : ele.applicableOn == 'Net' ? ele.fixedpercentage + " % of the Net Amount" : ''

      if (commercialterm != "NA") {
        ob = { ...ob, itemgroup, itemName, commercialterm, center: ele.center };
        await commercialOpTableData.push(ob);
      }
    });
    await setCommercialOpTable(commercialOpTableData)
  };
  console.log(table, commercialOpTable, "tabletabletabletable1");
  //OP CALCULATION FORMULAE
  const opCalculation = () => {
    //deduction calculation
    let deduct = parseInt(opservice.opadhoc);
    if (isNaN(deduct)) {
      deduct = 0;
    } else {
    }

    setAdhoc(deduct);

    let unit = parseInt(opservice.opperunitcharge);
    let count = parseInt(opservice.opcount);
    //unit value checking
    if (isNaN(unit)) {
      unit = 0;
    } else {
    }
    //count value checking
    if (isNaN(count)) {
      count = 0;
    } else {
    }
    let gs = unit * count;
    let discountval = parseInt(opservice.opdiscountvalue) * count;
    //discount value checking
    if (isNaN(discountval) || opservice.opdiscountvalue.length === 0) {
      discountval = 0;
    } else {
    }

    let netVal = gs - discountval;
    let deduction = parseInt(opservice.opadhoc) * count;
    let totalRevenue = parseInt(opservice.totalRevenue);

    if (isNaN(deduction)) {
      deduction = 0;
    } else {
    }

    if (isNaN(totalRevenue)) {
      totalRevenue = 0;
    } else {
    }
    //net value checking
    deduction = totalRevenue ? deduct : deduction
    discountval = totalRevenue ? parseInt(opservice.opdiscountvalue) : discountval
    gs = totalRevenue ? totalRevenue : gs
    netVal = totalRevenue ? totalRevenue - deduction : netVal

    setTotaldeduction(deduction);
    if (isNaN(deduction)) {
      deduction = 0;
    } else {
    }
    let opRevenueval = netVal - deduction;
    let percnt = parseInt(opservice.fixedpercentage);
    let fixedamt = parseInt(opservice.fixedamount);

    //payout value checking

    if (isNaN(percnt)) {
      percnt = 0;
    } else {
    }
    if (isNaN(fixedamt)) {
      fixedamt = 0;
    } else {
    }

    let fixedDeduction = totalRevenue
      ? totalRevenue * (percnt / 100)
      : fixedamt
        ? fixedamt * (count > 0 ? count : 1)
        : opRevenueval * (percnt / 100);
    fixedDeduction = fixedDeduction.toFixed(2);
    //set value
    setGross(gs);
    setOpNetAmount(gs - deduction);

    if (opservice.totalRevenue) {
      setopRevenue(opservice.totalRevenue);
    } else {
      setopRevenue(netVal - deduction);
    }
// console.log(opRevenueval, 'opRevenuevalopRevenueval')

    setOpNetPayout(fixedDeduction);
  };

  const fixedPercentageOrAmount = (e) => {
    if (e.target.name === "fixedpercentage") {
      setOpservice({ ...opservice, fixedamount: 0 });
    }
    if (e.target.name === "fixedamount") {
      setOpservice({ ...opservice, fixedpercentage: 0 });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setOpservice({ ...opservice, [name]: value });
  };
  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };
  const deleteRow = (row) => {
// console.log(row, "rowsop");
// console.log(table, "tableop");
    var data = [...table]
    var data1 = [...commercialOpTable]
    var ids = []
    row.map((elem) => {
      ids.push(elem.tableData.id)
      // const index = totalnetAmount.indexOf(elem.netamount);
      // if (index > -1) {
      //   totalnetAmount.splice(index, 1);
      // }
      const index1 = totalpayout.indexOf(elem.oppayout);
      if (index1 > -1) {
        totalpayout.splice(index1, 1);
      }
    })
    data = data.filter((z, i) => {
      if (!ids.includes(i)) {
        return z
      }
    })
    data1 = data1.filter((z, i) => {
      if (!ids.includes(i)) {
        return z
      }
    })
// console.log(totalpayout, "DHHDHDHDHDD")
    calculatePR(data);
    setTable(data);
    setCommercialOpTable(data1);
  };
  const getDiscount = (e) => {
    setDiscount(e.target.value);
  };

  const handleChangeOpmonth = (e) => {
    setOpMonth(e.target.value);
  };

  const refreshopRecord = () => {
    setSelected([]);
    setSelectedChild([]);
    setParentItem([])
    setChildItem([])
    setChildOptions([]);
    setOpservice(initialFormtState);
    setAdhoc("");
    setOpNetAmount("");
    setOpNetPayout("");
    setopRevenue("");
    setGross("");
    setDiscount("No");
    setTotaldeduction("");
    setSelectedCenter('')
    setApplicableOn("");
    setApplicableOnOption(null);
  };
  const TableTitle = ({ text }) => (
    <Typography
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: "black",
        textDecoration: "underline",
        fontWeight: "bold",
        fontSize: "15px"
      }}
    >
      {text}
    </Typography>
  );

  const nop = <p>No. of Procedures<span style={{ color: "red" }} > *</span></p>;

  const parentItemChangeCenter = async (event, value) => {
    for (let i = 0; i < centerList.length; i++) {
      const element = centerList[i];
      if (value == element.name) {
        getOpItemGroups(element.code)
      }
    }
    setSelectedCenter(value);
    setParentItem('');
    setSelectedChild([])
    setChildItem([])
  };

  const getOptionLabel = option => `${option.itemName}`;

  const getOptionSelected = (option, anotherOption) => {
    return option.itemName === anotherOption.itemName;
  }

  const applicableOnChange = async (event, value) => {
// console.log(value, 'applicableon')
    if (value) {
      setApplicableOnOption(value)
      setApplicableOn(value.value)
      if (value.value == 'Fixed') {
        setOpservice({ ...opservice, fixedpercentage: 0 });
      } else {
        setOpservice({ ...opservice, fixedamount: 0 });
      }
    } else {
      setApplicableOnOption(value)
      setApplicableOn('')
      setOpservice({ ...opservice, fixedamount: 0 });
      setOpservice({ ...opservice, fixedpercentage: 0 });
    }
  }

  const handleSelectionRows = async (rows) => {
    setSelectedRows(rows)
  }

  const updateOpCommercials = async (commercials) => {
    commercials.forEach(item => {
      item.applicableOn = item.applicable
    })
    await setTable((prevItems) => [...prevItems, ...commercials])
    await createCommercialOp(commercials);
  }

  const editCommercial = async (row) => {
    var item = row[0]
// console.log(row, 'Opcommercials Data')
    var siteCode = ''
    var itemGroupName
    for (let i = 0; i < centerList.length; i++) {
      const element = centerList[i];
      if (item.center == element.name) {
        siteCode = element.code
        await setSelectedCenter(item.center);
        itemGroupName = item.itemGroup
        await getOpItemGroups(element.code, itemGroupName, item.itemName ? item.itemName : "Select all")
        // await setSelected(itemGroupName);
    // console.log(options, 'Opcommercials Data options')
      }
    }
    await setApplicableOn(item.applicableOn);
    await setApplicableOnOption(applicableOnOptions.find((elem) => elem.value == item.applicableOn));
    opservice.opcount = item.opcount
    opservice.opperunitcharge = item.opperunitcharge
    opservice.opadhoc = item.opadhoc
    opservice.fixedpercentage = item.fixedpercentage
    opservice.fixedamount = item.fixedamount
    await setOpservice({ ...opservice });
    await setGross(item.opgross);
    await setOpNetAmount(item.opnet);
    await setopRevenue(item.oprevenue);
    await setOpNetPayout(item.oppayout);
    await setOpCommercialItem(row)
  }

  const UpdateTableData = async () => {
    var valid = validationOp()
    if (!valid) {
      return;
    }
    opservice.opmonth = opmonth;
    opservice.consultation = '';
    opservice.opdiscounttype = Discount;
    opservice.applicableOn = applicableOn;
    opservice.oprevenue = opRevenue;
    opservice.oppayout = opNetPayout;
    opservice.netamount = opNetAmount;
    opservice.opgross = gross;
    opservice.opnet = opNetAmount;
    opservice.opadhoc = adhoc ? adhoc : 0;
    opservice.opdiscountvalue = opservice.opdiscountvalue
      ? opservice.opdiscountvalue
      : 0;
    opservice.totalRevenue = opservice.totalRevenue
      ? opservice.totalRevenue
      : 0;
    let opserviceDataArray = table;
    let tableIndex = table.findIndex((row) => opCommercialItem && opCommercialItem[0] && opCommercialItem[0].tableData.id === row.tableData.id)
    if (selectedchild.length > 0 && selected) {
      for (let k = 0; k < selectedchild.length; k++) {
        for (let m = 0; m < table.length; m++) {
          if (opCommercialItem && opCommercialItem[0] && opCommercialItem[0].tableData.id !== table[m].tableData.id) {
            if (selected + "," + selectedchild[k] + "," + selectedCenter === table[m].itemGroup + "," + table[m].itemName + "," + table[m].center) {
              setConfirmDialog({
                isOpen: true,
                title: "Alert",
                subtitle: table[m].itemGroup + "," + table[m].itemName + " for center" + table[m].center + " already exist.",
              });
              setSelectedChild([]);
              return;
            }
          }
        }
        let opserviceData = {
          ...opservice,
          itemGroup: selected,
          itemName: selectedchild[k],
          center: selectedCenter,
        };
        if (k === 0) {
          opserviceDataArray[tableIndex].opmonth = opservice.opmonth;
          opserviceDataArray[tableIndex].opperunitcharge = opservice.opperunitcharge;
          opserviceDataArray[tableIndex].opcount = opservice.opcount;
          opserviceDataArray[tableIndex].fixedamount = opservice.fixedamount;
          opserviceDataArray[tableIndex].fixedpercentage = opservice.fixedpercentage;
          opserviceDataArray[tableIndex].opdiscounttype = opservice.opdiscounttype;
          opserviceDataArray[tableIndex].applicableOn = opservice.applicableOn;
          opserviceDataArray[tableIndex].oprevenue = opservice.oprevenue;
          opserviceDataArray[tableIndex].oppayout = opservice.oppayout;
          opserviceDataArray[tableIndex].netamount = opservice.netamount;
          opserviceDataArray[tableIndex].opgross = opservice.opgross;
          opserviceDataArray[tableIndex].opnet = opservice.opnet;
          opserviceDataArray[tableIndex].opadhoc = opservice.opadhoc;
          opserviceDataArray[tableIndex].opdiscountvalue = opservice.opdiscountvalue;
          opserviceDataArray[tableIndex].totalRevenue = opservice.totalRevenue;
          opserviceDataArray[tableIndex].itemGroup = selected;
          opserviceDataArray[tableIndex].itemName = selectedchild[k];
          opserviceDataArray[tableIndex].center = selectedCenter;
          opserviceDataArray[tableIndex].tableData.checked = false
      // console.log(opserviceDataArray, tableIndex, 'Opcommercials Data Updted data before')
        } else {
          opserviceDataArray.push(opserviceData);
        }
        // setTotalnetAmount((j) => [...(j + opserviceData.oprevenue)]);
      }
    } else {
      for (let m = 0; m < table.length; m++) {
        if (opCommercialItem && opCommercialItem[0] && opCommercialItem[0].tableData.id !== table[m].tableData.id) {
          if (selected + "," + selectedCenter + "," === table[m].itemGroup + "," + table[m].center + "," + (table[m].itemName ? table[m].itemName : '')) {
            setConfirmDialog({
              isOpen: true,
              title: "Alert",
              subtitle: table[m].itemGroup + " for center" + table[m].center + " already exist.",
            });
            return;
          }
        }
      }
      let opserviceData = { ...opservice, itemGroup: selected, center: selectedCenter };
      // opserviceDataArray.push(opserviceData);
      opserviceDataArray[tableIndex].opmonth = opservice.opmonth;
      opserviceDataArray[tableIndex].opperunitcharge = opservice.opperunitcharge;
      opserviceDataArray[tableIndex].opcount = opservice.opcount;
      opserviceDataArray[tableIndex].fixedamount = opservice.fixedamount;
      opserviceDataArray[tableIndex].fixedpercentage = opservice.fixedpercentage;
      opserviceDataArray[tableIndex].opdiscounttype = opservice.opdiscounttype;
      opserviceDataArray[tableIndex].applicableOn = opservice.applicableOn;
      opserviceDataArray[tableIndex].oprevenue = opservice.oprevenue;
      opserviceDataArray[tableIndex].oppayout = opservice.oppayout;
      opserviceDataArray[tableIndex].netamount = opservice.netamount;
      opserviceDataArray[tableIndex].opgross = opservice.opgross;
      opserviceDataArray[tableIndex].opnet = opservice.opnet;
      opserviceDataArray[tableIndex].opadhoc = opservice.opadhoc;
      opserviceDataArray[tableIndex].opdiscountvalue = opservice.opdiscountvalue;
      opserviceDataArray[tableIndex].totalRevenue = opservice.totalRevenue;
      opserviceDataArray[tableIndex].itemGroup = selected;
      opserviceDataArray[tableIndex].center = selectedCenter;
      opserviceDataArray[tableIndex].tableData.checked = false
      // setTotalnetAmount((j) => [...(j + opserviceData.oprevenue)]);
    }
// console.log(opserviceDataArray, opservice, 'Opcommercials Data Updted data')
    // setTotalpayout((j) => [...(j + opservice.oppayout)]);
    await setTable(opserviceDataArray);
    await cancelOpUpdation();
  }

  const cancelOpUpdation = async () => {
    await setOpCommercialItem([])
    await setSelectedRows([])
    await refreshopRecord()
  }

  console.log(opservice, "opservice")
  const filter = createFilterOptions()
  return (
    <>
      <CssBaseline />
      <Container>
        <Grid container spacing={2} style={{ marginBottom: "18px" }}>
          <Grid item xs={12} sm={12} xl={6} lg={6} md={6}>
            <Autocomplete
              id="combo-box-demo"
              value={selectedCenter}
              onChange={(event, newValue) => parentItemChangeCenter(event, newValue)}
              options={centerOptions}
              getOptionLabel={(option) => option ? option : ''}
              renderInput={(params) => <TextField {...params} label="Select Center" helperText={''} FormHelperTextProps={{
                style: { color: "red" },
              }} />}
              renderOption={(props, option, state) => {
                return (
                  <Typography key={option} {...props} style={{ color: 'black' }}>{option}</Typography>
                );
              }}
            />
            {
              selectedCenter ?
                <Autocomplete
                  id="combo-box-demo"
                  value={parentItem}
                  onChange={(event, newValue) => parentItemChange(event, newValue)}
                  options={options}
                  getOptionLabel={(option) => option.itemGroupName ? option.itemGroupName : ''}
                  renderInput={(params) => <TextField {...params} label="Select Item Group" helperText={''} FormHelperTextProps={{
                    style: { color: "red" },
                  }} />}
                  renderOption={(props, option, state) => {
                    return (
                      <Typography key={option.itemGroupId} {...props} style={{ color: 'black' }}>{option.itemGroupName}</Typography>
                    );
                  }}
                />
                : ''
            }
          </Grid>

          <Grid item xs={12} sm={12} xl={6} lg={6} md={6}>
            {selectedCenter && parentItem && childoptions.length > 0 ? (
              <>
                <Autocomplete
                  multiple
                  id="combo-box-demo"
                  // disableClearable={true}
                  // disableCloseOnSelect={true}
                  value={childItem}
                  onChange={(event, newValue, reason) => childItemChange(event, newValue, reason)}
                  options={childoptions}
                  getOptionLabel={(option) => option.itemName}
                  getOptionSelected={getOptionSelected}
                  renderInput={(params) => <TextField {...params} label="Select Procedures *" helperText={''} FormHelperTextProps={{
                    style: { color: "red" },
                  }} />}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    return [{ itemName: "Select all" }, ...filtered];
                  }}
                  renderOption={(props, option, { selected }) => {
                    const selectAllProps =
                      option.itemName === "Select all" // To control the state of 'select-all' checkbox
                        ? { checked: isAllChildSelected }
                        : {};
                    return (
                      <>
                        <Typography key={option.itemGroupId} {...props} style={{ color: 'black' }}>
                          <Checkbox
                            color="primary"
                            icon={<CheckBoxOutlineBlank fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                            {...selectAllProps}
                          />
                          {option.itemName}
                        </Typography>

                      </>
                    );
                  }}
                />
                {selectedCenter && parentItem && childItem.length > 0 ?
                  < Autocomplete
                    id="combo-box-demo"
                    value={applicableOnOption}
                    onChange={(event, newValue) => applicableOnChange(event, newValue)}
                    options={applicableOnOptions}
                    getOptionLabel={(option) => option ? option.label : ''}
                    renderInput={(params) => <TextField {...params} label="Select Applicable On" helperText={''} FormHelperTextProps={{
                      style: { color: "red" },
                    }} />}
                    renderOption={(props, option, state) => {
                      return (
                        <Typography key={option} {...props} style={{ color: 'black' }}>{option.label}</Typography>
                      );
                    }}
                  />
                  : ""
                }
              </>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        {applicableOn ?
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                name="opcount"
                label={nop}
                value={opservice.opcount}
                onChange={handleInputChange}
                onBlur={opCalculation}
                onInput={(e) => onlyNumbers(e)}
                size="medium"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CountertopsIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  No. of Months
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={opmonth}
                  onChange={handleChangeOpmonth}
                  label="No. of Months"
                >
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                  <MenuItem value="3">3</MenuItem>
                  <MenuItem value="4">4</MenuItem>
                  <MenuItem value="5">5</MenuItem>
                  <MenuItem value="6">6</MenuItem>
                  <MenuItem value="7">7</MenuItem>
                  <MenuItem value="8">8</MenuItem>
                  <MenuItem value="9">9</MenuItem>
                  <MenuItem value="10">10</MenuItem>
                  <MenuItem value="11">11</MenuItem>
                  <MenuItem value="12">12</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                name="opperunitcharge"
                label="Per Unit Charge"
                value={
                  opservice.opperunitcharge
                    ? numberWithCommas(opservice.opperunitcharge)
                    : ""
                }
                onChange={handleInputChange}
                onBlur={opCalculation}
                onInput={(e) => onlyNumbers(e)}
                fullWidth
                size="medium"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CountertopsIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Is Discount Applicable
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="Discount"
                  onChange={getDiscount}
                  value={Discount}
                  // onChange={handleChange}
                  label="Is Discount Applicable"
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                name="opadhoc"
                label="Deduction / Unit"
                value={
                  opservice.opadhoc ? numberWithCommas(opservice.opadhoc) : ""
                }
                onInput={(e) => onlyNumbers(e)}
                onChange={handleInputChange}
                onBlur={opCalculation}
                fullWidth
                size="medium"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IndeterminateCheckBoxIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {applicableOn && applicableOn != 'Fixed' ?
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  name="fixedpercentage"
                  label="Fixed Percentage"
                  value={opservice.fixedpercentage ? opservice.fixedpercentage : ''}
                  onChange={handleInputChange}
                  onBlur={opCalculation}
                  onKeyUp={fixedPercentageOrAmount}
                  onInput={(e) => onlyNumbers(e)}
                  fullWidth
                  size="medium"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              :
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  name="fixedamount"
                  label="Fixed Amount"
                  value={
                    opservice.fixedamount
                      ? numberWithCommas(opservice.fixedamount)
                      : ""
                  }
                  onKeyUp={fixedPercentageOrAmount}
                  onChange={handleInputChange}
                  onBlur={opCalculation}
                  onInput={(e) => onlyNumbers(e)}
                  fullWidth
                  size="medium"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MoneyIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            }
            {/* <Grid item xs={4}>
              <TextField
                variant="outlined"
                name="totalRevenue"
                label="Total Revenue"
                value={
                  opservice.totalRevenue
                    ? numberWithCommas(opservice.totalRevenue)
                    : ""
                }
                onKeyUp={fixedPercentageOrAmount}
                onChange={handleInputChange}
                onBlur={opCalculation}
                onInput={(e) => onlyNumbers(e)}
                fullWidth
                size="medium"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalculateIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid> */}
            <Grid item xs={4}>
              <TextField
                style={{ backgroundColor: "#80808030" }}
                variant="outlined"
                name="opamount"
                label="Gross"
                value={gross ? numberWithCommas(gross) : ""}
                size="medium"
                onChange={handleInputChange}
                fullWidth
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalculateIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                style={{ backgroundColor: "#80808030" }}
                variant="outlined"
                name="netamount"
                label="Net"
                size="medium"
                value={
                  opNetAmount <= 0
                    ? 0
                    : opNetAmount
                      ? numberWithCommas(opNetAmount)
                      : ""
                }
                onChange={handleInputChange}
                fullWidth
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalculateIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                name="opadhoc"
                style={{ backgroundColor: "#80808030" }}
                label="Total Deduction"
                value={totaldeduction ? numberWithCommas(totaldeduction) : ""}
                size="medium"
                onInput={(e) => onlyNumbers(e)}
                fullWidth
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalculateIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                style={{ backgroundColor: "#80808030" }}
                variant="outlined"
                name="netamount"
                label="Estimated Revenue"
                value={opRevenue ? numberWithCommas(opRevenue) : ""}
                onChange={handleInputChange}
                fullWidth
                size="medium"
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CurrencyRupeeIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                style={{ backgroundColor: "#80808030" }}
                variant="outlined"
                name="oppayout"
                label="Payout"
                size="medium"
                value={opNetPayout ? numberWithCommas(opNetPayout) : ""}
                onChange={handleInputChange}
                fullWidth
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CurrencyRupeeIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              {" "}
              <Button
                variant="contained"
                color="primary"
                onClick={opCalculation}
                style={{ height: "100%", width: "100%" }}
              >
                Calculate
              </Button>
            </Grid>
            <Grid item xs={12}>
              {
                opCommercialItem && opCommercialItem.length ?
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ height: "100%" }}
                      startIcon={<UpgradeIcon />}
                      // fullWidth
                      onClick={() => UpdateTableData()}
                    >
                      Update
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ height: "100%", marginLeft: "18px" }}
                      startIcon={<CancelIcon />}
                      // fullWidth
                      onClick={() => cancelOpUpdation()}
                    >
                      Cancel
                    </Button>
                  </>
                  :
                  opservice.opcount && (opservice.totalRevenue || opservice.opperunitcharge) && (opservice.fixedpercentage || opservice.fixedamount) && (opNetPayout > 0) && (opRevenue > 0) ? (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ height: "100%" }}
                        startIcon={<AddIcon />}
                        // fullWidth
                        onClick={addTableData}
                      >
                        ADD
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        style={{ height: "100%", marginLeft: "18px" }}
                        startIcon={<RefreshIcon />}
                        // fullWidth
                        onClick={refreshopRecord}
                      >
                        REFRESH
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled
                        style={{ height: "100%" }}
                        onMouseEnter={"please enter all required details"}
                        startIcon={<AddIcon />}
                        // fullWidth
                        onClick={addTableData}
                      >
                        ADD
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        style={{ height: "100%", marginLeft: "18px" }}
                        startIcon={<RefreshIcon />}
                        // fullWidth
                        onClick={refreshopRecord}
                      >
                        REFRESH
                      </Button>
                    </>
                  )}
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
          : ''}
        <Grid container spacing={1}>

          <Grid item xs={12}>
            {table.length > 0 ? (
              <Paper className={tables.root}>
                <MaterialTable
                  columns={[
                    {
                      title: "Center Name",
                      field: "center",
                    },
                    {
                      title: "Item Group Name",
                      field: "itemGroup",
                    },
                    {
                      title: "Item  Name",
                      field: "itemName",
                      render: (row) => (
                        <div>{row.itemName ? row.itemName : "---"}</div>
                      ),
                    },
                    {
                      title: "Discount Applicable",
                      field: "opdiscounttype",
                      render: (row) => (
                        <div>
                          {row.opdiscounttype ? row.opdiscounttype : "---"}
                        </div>
                      ),
                    },
                    {
                      title: "Deduction Value",
                      field: "opadhoc",
                      render: (row) => (
                        <div>
                          {row.opadhoc
                            ? "Rs. " + numberWithCommas(row.opadhoc)
                            : "---"}
                        </div>
                      ),
                    },
                    {
                      title: "Fixed Amount",
                      field: "fixedamount",
                      render: (row) => (
                        <div>
                          {row.fixedamount
                            ? "Rs. " + numberWithCommas(row.fixedamount)
                            : "---"}
                        </div>
                      ),
                    },
                    {
                      title: "Fixed Percentage",
                      field: "fixedpercentage",
                      render: (row) => (
                        <div>
                          {row.fixedpercentage && row.fixedpercentage > 0 ? row.fixedpercentage + "%" : "---"}
                        </div>
                      ),
                    },
                    {
                      title: "Total Revenue",
                      field: "totalRevenue",
                      render: (row) => (
                        <div>
                          {row.totalRevenue
                            ? "Rs. " + numberWithCommas(row.totalRevenue)
                            : "---"}
                        </div>
                      ),
                    },
                    {
                      title: "No. of Procedures",
                      field: "opcount",
                      render: (row) => (
                        <div>{row.opcount ? row.opcount : "---"}</div>
                      ),
                    },
                    {
                      title: "No. of Months",
                      field: "opmonth",
                      render: (row) => (
                        <div>{row.opmonth ? row.opmonth : "---"}</div>
                      ),
                    },
                    {
                      title: "Per Unit Charge",
                      field: "opperunitcharge",
                      render: (row) => (
                        <div>
                          {row.opperunitcharge
                            ? "Rs. " + numberWithCommas(row.opperunitcharge)
                            : "---"}
                        </div>
                      ),
                    },
                    {
                      title: "Gross",
                      field: "opgross",
                      render: (row) => (
                        <div>
                          {row.opgross
                            ? "Rs. " + numberWithCommas(row.opgross)
                            : "---"}
                        </div>
                      ),
                    },
                    {
                      title: "Net",
                      field: "opnet",
                      render: (row) => (
                        <div>
                          {row.opnet
                            ? "Rs. " + numberWithCommas(row.opnet)
                            : "---"}
                        </div>
                      ),
                    },
                    {
                      title: "Revenue",
                      field: "oprevenue",
                      render: (row) => (
                        <div>
                          {row.oprevenue
                            ? "Rs. " + numberWithCommas(row.oprevenue)
                            : "---"}
                        </div>
                      ),
                    },
                    {
                      title: "Payout",
                      field: "oppayout",
                      render: (row) => (
                        <div>
                          {row.oppayout
                            ? "Rs. " +
                            numberWithCommas(
                              parseFloat(row.oppayout).toFixed()
                            )
                            : "---"}
                        </div>
                      ),
                    },
                  ]}
                  actions={[
                    {
                      icon: "delete",
                      tooltip: "Delete",
                      iconProps: {
                        style: {
                          fontSize: "24px",
                          color: "#f55151",
                        },
                      },
                      onClick: (event, row) => {
                        setConfirmDialog({
                          isOpen: true,
                          title:
                            "Deleting Op-Detail may result in some features unresponsive ?",
                          subtitle:
                            "Are you sure you want to delete this record ?",
                          onConfirm: () => {
                            deleteRow(row);
                            setConfirmDialog({ isOpen: false });
                          },
                        });
                      },
                    },
                    {

                      icon: "edit",
                      tooltip: "Edit",
                      iconProps: {
                        style: {
                          fontSize: "24px",
                          color: "#f55151",
                        },
                      },
                      onClick: (event, row) => {
                        if (row.length === 1) {
                          editCommercial(row)
                        }
                    // console.log("event in row", row)
                      },
                      hidden: (selectedRows.length !== 1),
                    },
                  ]}
                  data={table}
                  options={{
                    sorting: true,
                    paging: false,
                    search: false,
                    selection: true,
                    headerStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px",
                      fontSize: 12,
                    },
                    cellStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px",
                    },
                    rowStyle: {
                      fontSize: 12,
                    },
                  }}
                  onSelectionChange={handleSelectionRows}
                  title={<TableTitle text="OP Service" />}
                />
              </Paper>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "2px", marginBottom: "20px" }}>
          {table && table.length > 0 ? (
            <Paper>
              <MaterialTable
                columns={[
                  {
                    title: "Center Name",
                    field: "center"
                  },
                  {
                    title: "Item Group/Procedures",
                    field: "itemGroup"
                  },

                  {
                    title: "Item Name",
                    field: "itemName",
                  },

                  {
                    title: "Commercial Term",
                    field: "commercialterm",
                    filtering: false,
                    render: (row) =>
                      row.applicableOn == 'Fixed' ? row.fixedamount + " /- Per case" : row.applicableOn == 'Gross' ? row.fixedpercentage + " % of the Gross Amount" : row.applicableOn == 'Net' ? row.fixedpercentage + " % of the Net Amount" : '',
                  },
                ]}
                data={table}
                options={{
                  // filtering: true,
                  sorting: true,
                  paging: false,
                  search: false,
                  headerStyle: {
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "3px 10px",
                    fontSize: 12,
                  },
                  cellStyle: {
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "3px 10px"
                  },
                  rowStyle: {
                    fontSize: 12,
                  },
                }}
                title={<TableTitle text="OP Commercial" />}
              />
            </Paper>
          ) : (
            ""
          )}
        </Grid>
        <div>
          <Typography style={{ marginBottom: "20px", float: "right" }}>
            {table.length > 0 ? (
              <Typography>
                {" "}
                <span
                  style={{ width: "100%", display: "block", margin: "15px" }}
                >
                  {" "}
                  <b>Total Estimated Revenue Generated:</b> Rs.{" "}
                  {totalOpRevenue
                    ? numberWithCommas(totalOpRevenue.toFixed(2)) + "/-"
                    : ""}
                </span>
                <span
                  style={{ width: "100%", display: "block", margin: "15px" }}
                >
                  {" "}
                  <b>Total Payout:</b> Rs.{" "}
                  {totalOpPayout
                    ? numberWithCommas(totalOpPayout.toFixed(2)) + "/-"
                    : ""}
                </span>
              </Typography>
            ) : (
              ""
            )}
          </Typography>
        </div>
      </Container>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
