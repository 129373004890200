import { fontSize } from "@mui/system";
import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import icon from "../images/new.png";
import axios from "axios";
import { APIURL } from "../CommomMethods/Fetch";
import { agreementState, onboardState } from "../CommomMethods/States";
import { centerlist } from "../actions/centerActions";

function RequestCard({ reqType, num, datalist, clickReturn }) {
  const [OnboardData, setOnboardData] = useState([]);
  const [allData, setAllData] = useState([]);
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;
  useEffect(()=>{
    axios
    .get(APIURL() + "vacancies/getAllRecordsForRDDashboard/" + user.user.id)
    .then((res) => {
      setOnboardData(res.data.data);
    })
    .catch((error) => { });
    axios.get(APIURL() + "onboard/HrStatusById/" + user.user.id).then((res) => {
      setAllData(res.data.data);
    });
  }, [])
  // console.log( user.user.roles,allData, OnboardData, "datalist")
  let PendingCount = 0
  if(user && user.user && user.user.roles == "1"){
    PendingCount = OnboardData.filter(elem => elem.OnextAction == onboardState.rd_grid_approval.state && elem.rdApproval != 'OK' || (elem.OnextAction == null && elem.financialGrid == 'No' && elem.rdApproval != 'OK') || elem.OnextAction == onboardState.rd.state).length
    // PendingCount = datalist && datalist.length > 0 && datalist.find((elem) => elem.status == "To be verified by RD") &&  datalist.find((elem) => elem.status == "To be verified by RD").onboardcount ? datalist.find((elem) => elem.status == "To be verified by RD").onboardcount :     PendingCount = datalist && datalist.length > 0 && datalist.find((elem) => elem.status == "Verified by RD")&&  datalist.find((elem) => elem.status == "Verified by RD").onboardcount ? datalist.find((elem) => elem.status == "Verified by RD").onboardcount :0
  }else if(user && user.user && user.user.roles == "2"){
    PendingCount = OnboardData.filter(elem => (elem.financialGrid == 'Yes' && elem.OnextAction == onboardState.init.state) || (elem.OnextAction == onboardState.rd_grid_approval.state && elem.rdApproval == 'OK' && (elem.doctorId == null || elem.doctorId == "")) || (elem.OnextAction == onboardState.init.state && elem.rdApproval == 'OK' && (elem.doctorId == null || elem.doctorId == "")) || (elem.OnextAction == null && elem.financialGrid == 'Yes' && (elem.doctorId == null || elem.doctorId == "")) || (elem.OnextAction == null && elem.financialGrid == 'No' && elem.rdApproval == 'OK' && (elem.doctorId == null || elem.doctorId == ""))).length
    // PendingCount = datalist && datalist.length > 0 && datalist.find((elem) => elem.status == "PENDING") &&  datalist.find((elem) => elem.status == "PENDING").onboardcount ? datalist.find((elem) => elem.status == "PENDING").onboardcount : 0
  }else if(user && user.user && user.user.roles == "3"){
    PendingCount = allData.filter(elem => (elem.OnextAction == onboardState.hr.state) || ((elem.OnextAction == onboardState.completed && elem.AnextAction == agreementState.completed && elem.isConfirmed == 0))).length
    // PendingCount = datalist && datalist.length > 0 && datalist.find((elem) => elem.status == "To be verified by HR") && datalist.find((elem) => elem.status == "To be verified by HR").onboardcount ? datalist.find((elem) => elem.status == "To be verified by HR").onboardcount : 0
  }

  return (
    <div className="card requestInfo" onClick={() => clickReturn() }>
      <div style={{
        paddingTop: "5px",
        paddingLeft: "5px",
        paddingBottom: "5px",
        borderBottom: "1px solid #858585",
      }}>
        <h3 style={{textAlign: 'center'}}>Requests</h3>
      </div>
      <div className="card-footer align-items-center" style={{ padding: '28px', alignContent: 'center', height: '108px' }}>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {/* <div style={{ textAlign: 'center', fontSize: "50px", fontWeight: "normal" }}> */}
            <i class="ti-alarm-clock text-light bg-primary" style={{ borderRadius: '50%', fontSize: '32px', padding: '8px' }}></i>
          {/* </div> */}

          <div className="text-center ml-3">
            <h3>{PendingCount}</h3>
            <h6>Onboarding Request</h6>
          </div>

        </div>
      </div>
    </div>
  );
}

export default RequestCard;
