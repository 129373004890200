import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Container, Row, Col, Card } from "react-bootstrap";
//import image
import profileImg from "./../../images/profile.jpg";
import Navbar from "../../Components/Navbar";
import { documentlist } from "../../actions/documentActions";
import { APIURL } from "../../CommomMethods/Fetch";
import { agreementviewById } from "../../actions/agreementActions";
import { agreementState, onboardState } from "../../CommomMethods/States";
// import Navbar from "../../Components/Navbar";
import AppSidebar from "../../Components/AppSidebar";
import Sidebar from "../../Components/Sidebar";
import DialogContent from "@material-ui/core/DialogContent";
import { toast } from "react-toastify";
//import from mui
import Dialog from "@material-ui/core/Dialog";
import Iframe from "react-iframe";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import { onboardById } from "../../actions/onboardActions";
import { useLocation } from "react-router-dom";
import { dateFormatter, numberWithCommas, numberToEnglish } from "../../actions/createPositionActions";
import axios from "axios";
import DoctorSidebar from "../../Components/DoctorSidebar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

//material table
function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;
  return [year, month, day].join('-');
}
const myTableStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "20%",
    marginTop: theme.spacing(3),
    "& td": {
      fontFamili: "Roboto !important",
    },
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflow: "auto",
    marginBottom: "50px",
  },
}));

export default function UserProfile() {
  const tables = myTableStyle();
  const [openDialog, setOpenDialog] = useState(false);
  const [urlData, setUrlData] = useState("");
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, user } = userLogin;
  const location = useLocation();
  const [doctorDetail, setDoctorDetail] = React.useState(null);
  const [doctorCommercial, setDoctorCommercial] = React.useState([]);
  const [doctorOpDetail, setDoctorOpDetail] = React.useState([]);
  const [doctorIpDetail, setDoctorIpDetail] = React.useState([]);
  const [doctorOpCommercial, setDoctorOpCommercial] = React.useState([]);
  const [doctorIpCommercial, setDoctorIpCommercial] = React.useState([]);
  const [doctorMgDetails, setDoctorMgDetails] = React.useState([]);
  const [doctorDocuments, setDoctorDocuments] = React.useState([]);
  const [agreemnetDocData, setAgreemnetDocData] = React.useState(null);
  const [doctorFixedDetails, setDoctorFixedDetails] = React.useState([]);
  const [CenterArray, setCenterArray] = React.useState([]);
  const [rowData, setRowData] = useState({});
  const listDoc = useSelector((state) => state.listDoc);
  const { list } = listDoc;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    dispatch(onboardById(location && location.state && location.state.id));
  }, [dispatch]);

  useEffect(() => {
    dispatch(documentlist(location && location.state && location.state.id));
  }, [dispatch]);
  useEffect(() => {
    axios
      .get(APIURL() + `users/checkdocid/${location.state.id}`)
      .then((res) => {
    // console.log(res.data.data, "res-ponse");
        setRowData(res.data.data);
      })
      .catch((err) => {
        // toast.error("Please try after sometime!");
      });
    axios
      .get(APIURL() + "onboard/previewOne/" + location.state.id)
      .then((res) => {
        setDoctorDetail(res.data.doctorDetails ? res.data.doctorDetails : null);
        setDoctorCommercial(res.data.doctorCommercial);
        setDoctorIpDetail(res.data.doctorIPParameter);
        setDoctorOpDetail(res.data.doctorOPParameter);
        setDoctorOpCommercial(res.data.doctorOpCommercial);
        setDoctorIpCommercial(res.data.doctorIpCommercial);
        setDoctorMgDetails(res.data.doctorMgDetails);
        setDoctorFixedDetails(res.data.doctorFixedDetails);
        setDoctorDocuments(res.data.doctorDocuments);
        var agreemnetDocData = res.data.doctorDocuments.find((elem) => elem.docTitle == 'Agreement')
        if (agreemnetDocData) {
          setAgreemnetDocData(agreemnetDocData)
        }
    // console.log(agreemnetDocData, 'agreemnetDocData')
        let CenterArray = []
        if (res.data.doctorMgDetails && res.data.doctorMgDetails.length > 0) {
          res.data.doctorMgDetails.map(item => {
            CenterArray.push(item.Center)
          })
        } else if (res.data.doctorFixedDetails && res.data.doctorFixedDetails.length > 0) {
          res.data.doctorFixedDetails.map(item => {
            CenterArray.push(item.Center)
          })
        } else if (res.data.doctorIPParameter && res.data.doctorIPParameter.length > 0) {
          res.data.doctorIPParameter.map(item => {
            CenterArray.push(item.Center)
          })
        } else if (res.data.doctorOPParameter && res.data.doctorOPParameter.length > 0) {
          res.data.doctorOPParameter.map(item => {
            CenterArray.push(item.Center)
          })
        }
        if (CenterArray && CenterArray.length == 0) {
          if (res.data.doctorCommercial && res.data.doctorCommercial[0] && res.data.doctorCommercial[0].businessunit) {
            CenterArray.push(res.data.doctorCommercial[0].businessunit)
          }
        }
        setCenterArray(CenterArray.filter((value, index, array) => array.indexOf(value) === index && value != (oboard.doctorCommercial && oboard.doctorCommercial[0] && oboard.doctorCommercial[0].businessunit)))
      })
      .catch((error) => { });
  }, []);
  const onboardId = useSelector((state) => state.onboardId);
  const { oboard } = onboardId;
  // console.log(CenterArray, "docprofile")
  var name = oboard && oboard.doctorCommercial && oboard.doctorCommercial[0] && oboard.doctorCommercial[0].fullname && oboard.doctorCommercial[0].fullname.substr(0, 2);
  var dobb = oboard && oboard.doctorCommercial && oboard.doctorCommercial[0] && oboard.doctorCommercial[0].birthday && oboard.doctorCommercial[0].birthday.substr(0, 4);
  var con = name + dobb;

  const viewDocFile = (url, filename) => {
// console.log(url, filename, "url-file");
    let pass = prompt("Please Enter the Password", "");
    if (pass == con) {
      setOpenDialog(true);

      fetch(APIURL() + "billings/download/" + url, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
        },
      }).then((response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          setUrlData(url);
        });
      })
    } else {
      alert("Incorrect Password!");
    }
  };


  // const viewDocFile = (url, filename) => {
  //   console.log(url, filename, "url-file");
  //   setOpenDialog(true);

  //   fetch(APIURL() + "billings/download/" + url, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/pdf",
  //     },
  //   }).then((response) => {
  //     response.blob().then((blob) => {
  //       let url = window.URL.createObjectURL(blob);
  //       setUrlData(url);
  //     });
  //   });
  // };
  const viewSignedAgreementDoc = async (id, name, doctorAgreement = false) => {
    if (doctorAgreement) {
      await fetch(APIURL() + "documents/download/" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
        },
      }).then((response) => {
    // console.log(response.status);
        if (response.status === 500) {
          toast.error("Something went wrong. Please try again!");
        }
        else {
          response.blob().then((blob) => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = name + "_Agreement.pdf";
            a.click();
          });
        }
      });
    } else {
      // setAgreementRow(row);
      await axios.get(APIURL() + "onboard/download-agreement/" + id, {
        responseType: 'blob',
        headers: {
          Accept: 'application/pdf',
        },
      }).then((res) => {
    // console.log(res)
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.target = "_blank";
        link.download = name + "_Agreement.pdf"
        link.href = url;
        // link.setAttribute('download', 'Doctor Agreement.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
    }
  };

  const handleDownloadFile = (id, url, filename) => {
    fetch(APIURL() + "documents/download/agreement/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      });
      //window.location.href = response.url;
    });
  };
  const TableTitle = ({ text }) => (
    <Typography
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: "black",
        textDecoration: "underline",
        fontWeight: "bold",
        fontSize: "15px"
      }}
    >
      {text}
    </Typography>
  );
  return (
    <>
      {
        user.user.roles == 4 ? <DoctorSidebar />
          :
          user.user.roles !== 0 ? <Sidebar />
            : <AppSidebar />
      }
      <div className="main-content side-content pt-0">
        <div
          className="container-fluid"
          style={{ margin: "0 auto", backgroundColor: "white" }}
        >
          <div className="inner-body">
            <Navbar />
            {
              user.user.roles !== 3 && user.user.roles !== 8
                ?
                oboard && oboard.onBoardData && oboard.onBoardData.length && oboard.onBoardData[oboard.onBoardData.length - 1] && oboard.onBoardData[oboard.onBoardData.length - 1].nextAction && oboard.onBoardData[oboard.onBoardData.length - 1].nextAction === onboardState.completed ?
                  // < Button className="pt-2 pl-2 float-right" style={{ cursor: "pointer", marginLeft: "15px", marginTop: "20px" }} onClick={rowData.digio_doc_id ? () => viewSignedAgreementDoc() : agreemnetDocData ? () => handleDownloadFile(agreemnetDocData.id, agreemnetDocData.url, agreemnetDocData.name) : () => { console.log('No Agreement available') }}>Download Signed Agreement</Button>
                  ""
                  : ""
                : ""
            }

            {
              user.user.roles !== 3 && user.user.roles !== 8 ?
                oboard && oboard.activityData && oboard.activityData.length > 0 ?
                  oboard.activityData.length > 1 ?
                    <div style={{ float: 'right', marginTop: '1%' }}>
                      <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={(event) => { setAnchorEl(event.currentTarget); }}
                      >
                        {"View Agreements"}
                      </Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={() => setAnchorEl(null)}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        {
                          oboard.activityData.map((d, k) => (
                            <MenuItem key={k} onClick={() => viewSignedAgreementDoc(d.digio_doc_id, d.digio_doc_file_name, false)}>{new Date(d.digio_doc_date).toLocaleString()}</MenuItem>
                          ))
                        }
                      </Menu>
                    </div>
                    :
                    < Button className="pt-2 pl-2 float-right" style={{ cursor: "pointer", marginLeft: "15px", marginTop: "20px" }} onClick={() => viewSignedAgreementDoc(oboard.activityData[0].digio_doc_id, oboard.activityData[0].digio_doc_file_name, false)}>Download Agreement</Button>
                  : ""
                : ""
            }


            <Grid container spacing={2} style={{ marginTop: "5px" }}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Paper elevation={24} style={{ padding: "20px", border: "1px solid", height: "528px" }}>
                  {list.data &&
                    list.data
                      .filter((z) => z.docTitle === "Passport Size Photograph")
                      .map((ele) => (
                        <img
                          className="img-responsive"
                          src={APIURL() + "documents/download/" + ele.url}
                          style={{
                            borderRadius: "50%",
                            marginRight: "1rem",
                            width: "500px",
                            height: "58%"
                          }}
                        />
                      ))}
                  <div style={{ height: '178px', maxHeight: '178px', overflow: 'scroll', marginTop: '5%' }}>
                    <Typography variant="h6" style={{ textAlign: "center", marginTop: "8%" }}>
                      Dr.{" "}
                      {oboard.doctorCommercial &&
                        oboard.doctorCommercial[0] && oboard.doctorCommercial[0].fullname}

                    </Typography>
                    <Typography variant="subtitle2" style={{ textAlign: "center", marginTop: "4px" }}>
                      Name as per Medical Council Certificate : {doctorCommercial &&
                        doctorCommercial[0] && doctorCommercial[0].mcrcname}
                    </Typography>
                    <Typography variant="subtitle2" style={{ textAlign: "center", marginTop: "4px" }}>
                      Designation: {oboard.doctorCommercial &&
                        oboard.doctorCommercial[0] && oboard.doctorCommercial[0].designationlist}
                    </Typography>
                    <Typography variant="subtitle2" style={{ textAlign: "center", marginTop: "4px" }}>
                      Department: {oboard.doctorCommercial &&
                        oboard.doctorCommercial[0] && oboard.doctorCommercial[0].departmentlist}
                    </Typography>
                    <Typography variant="subtitle2" style={{ textAlign: "center", marginTop: "4px" }}>
                      Branch: {oboard.doctorCommercial && oboard.doctorCommercial[0] && oboard.doctorCommercial[0].businessunit}
                      {/* Branch: {CenterArray && CenterArray.length > 0 ? CenterArray.filter((c, index) => { return CenterArray.indexOf(c) === index; }).join(', ') : '---'} */}
                    </Typography>
                    <Typography variant="subtitle2" style={{ textAlign: "center", marginTop: "4px" }}>
                      Term: {oboard.doctorCommercial &&
                        oboard.doctorCommercial[0] && oboard.doctorCommercial[0].contractlength} Months
                    </Typography>
                  </div>

                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper
                      elevation={24}
                      style={{ height: "260px", padding: "20px", border: "1px solid", maxHeight: "260px", overflow: "scroll" }}
                    >
                      <h5 className="pb-2" style={{ textAlign: "center", borderBottom: "1px solid" }}>
                        CONTACT
                      </h5>
                      <Typography
                        variant="Body 1"
                        display="block"
                        style={{ textAlign: "left", lineHeight: "35px" }}
                      >
                        <b>Email :</b>{" "}
                        {oboard.doctorDetails && oboard.doctorDetails.email}
                        {oboard.doctorDetails && oboard.doctorDetails.lt_email_id ? <b> (LT Email Id)</b> : ""}
                      </Typography>
                      {oboard.doctorDetails && oboard.doctorDetails.lt_email_id ?
                        <Typography
                          variant="Body 1"
                          display="block"
                          style={{ textAlign: "left", lineHeight: "35px" }}
                        >
                          <b>LT Email :</b>{" "}
                          {oboard.doctorDetails.lt_email_id}
                        </Typography>
                        : ""}
                      <Typography
                        variant="Body 1"
                        display="block"
                        style={{ textAlign: "left", lineHeight: "35px" }}
                      >
                        <b>Phone :</b>{" "}
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0] && oboard.doctorCommercial[0].mobile}
                      </Typography>{" "}
                      <Typography
                        variant="Body 1"
                        display="block"
                        style={{ textAlign: "left", lineHeight: "35px" }}
                      >
                        <b>Permanent Address :</b>{" "}
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0] && oboard.doctorCommercial[0].PermanentAddress}
                      </Typography>
                      <Typography
                        variant="Body 1"
                        display="block"
                        style={{ textAlign: "left", lineHeight: "35px" }}
                      >
                        <b>Current Address :</b>{" "}
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0] && oboard.doctorCommercial[0].Address}
                      </Typography>
                      <Typography
                        variant="Body 1"
                        display="block"
                        style={{ textAlign: "left", lineHeight: "35px" }}
                      >
                        <b>Pin Code :</b>{" "}
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0] && oboard.doctorCommercial[0].PinCode}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper
                      elevation={24}
                      style={{ height: "260px", padding: "20px", border: "1px solid" }}
                    >
                      <h5 className="pb-2" style={{ textAlign: "center", borderBottom: "1px solid" }}>
                        PERSONAL DETAIL
                      </h5>
                      <Typography
                        variant="Body 1"
                        display="block"
                        style={{ textAlign: "left", lineHeight: "35px" }}
                      >
                        <b>Aadhaar :</b>{" "}
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0] && oboard.doctorCommercial[0].adhaarno}
                      </Typography>
                      <Typography
                        variant="Body 1"
                        display="block"
                        style={{ textAlign: "left", lineHeight: "35px" }}
                      >
                        <b>PAN :</b>{" "}
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0] && oboard.doctorCommercial[0].panno}
                      </Typography>
                      <Typography
                        variant="Body 1"
                        display="block"
                        style={{ textAlign: "left", lineHeight: "35px" }}
                      >
                        <b>DOB :</b>{" "}
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0] ? oboard.doctorCommercial[0].dob ? dateFormatter(oboard.doctorCommercial[0].dob) : oboard.doctorCommercial[0].birthday ? dateFormatter(oboard.doctorCommercial[0].birthday) : "To Be Entered." : "To Be Entered."}
                      </Typography>
                      <Typography
                        variant="Body 1"
                        display="block"
                        style={{ textAlign: "left", lineHeight: "35px" }}
                      >
                        <b>DOJ :</b>{" "}
                        {oboard && oboard.doctorCommercial && oboard.doctorCommercial[0] && oboard.doctorCommercial[0].doj ? dateFormatter(oboard.doctorCommercial[0].doj) : "---"}
                      </Typography>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper
                      elevation={24}
                      style={{ height: "260px", padding: "20px", border: "1px solid", maxHeight: "260px", overflow: "scroll" }}
                    >
                      <h5 className="pb-2" style={{ textAlign: "center", borderBottom: "1px solid" }}>
                        BANK DETAIL
                      </h5>
                      <Typography
                        variant="subtitle2"
                        style={{ textAlign: "left" }}
                      ></Typography>
                      <Typography
                        variant="Body 1"
                        display="block"
                        style={{ textAlign: "left", lineHeight: "35px" }}
                      >
                        <b>Bank Name :</b>
                        {oboard && oboard.doctorCommercial &&
                          oboard.doctorCommercial[0] && oboard.doctorCommercial[0].bankname}
                      </Typography>
                      <Typography
                        variant="Body 1"
                        display="block"
                        style={{ textAlign: "left", lineHeight: "35px" }}
                      >
                        <b>IFSC :</b>
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0] && oboard.doctorCommercial[0].bankifsc}
                      </Typography>
                      <Typography
                        variant="Body 1"
                        display="block"
                        style={{ textAlign: "left", lineHeight: "35px" }}
                      >
                        <b>A/C No :</b>
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0] && oboard.doctorCommercial[0].bankaccno}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper
                      elevation={24}
                      style={{ height: "260px", padding: "20px", border: "1px solid", maxHeight: "260px", overflow: "scroll" }}
                    >
                      <h5 className="pb-2" style={{ textAlign: "center", borderBottom: "1px solid" }}>
                        OTHER DETAILS
                      </h5>
                      <Typography variant="Body 1" display="block" style={{ textAlign: "left", lineHeight: "35px" }}><b>About :</b> {oboard.doctorCommercial && oboard.doctorCommercial[0] && oboard.doctorCommercial[0].aboutdoctor}</Typography>
                      <Typography variant="Body 1" display="block" style={{ textAlign: "left", lineHeight: "35px" }}
                      >
                        <b>UG Degree:</b> {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0] && oboard.doctorCommercial[0].medicaldegree}
                      </Typography>
                      <Typography variant="Body 1" display="block" style={{ textAlign: "left", lineHeight: "35px" }}
                      >
                        <b>PG Degree:</b> {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0] && oboard.doctorCommercial[0].pgdegree}
                      </Typography>
                      <Typography
                        variant="Body 1"
                        display="block"
                        style={{ textAlign: "left", lineHeight: "35px" }}
                      >
                        <b>Indemnity Expiry :</b>
                        {oboard && oboard.doctorCommercial &&
                          oboard.doctorCommercial[0] && oboard.doctorCommercial[0].indemnityexpiry ? dateFormatter(oboard.doctorCommercial[0].indemnityexpiry) : "---"}
                      </Typography>
                      <Typography
                        variant="Body 1"
                        display="block"
                        style={{ textAlign: "left", lineHeight: "35px" }}
                      >
                        <b>Additional Branch:</b>
                        {CenterArray && CenterArray.length > 0 ? CenterArray.join(', ') : '---'}
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {user.user.roles !== 3 && user.user.roles !== 8 ?
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography
                      variant="h4"
                      gutterBottom
                      align="center"
                      style={{
                        color: "#810e36",
                        paddingTop: "20px",
                        borderBottom: "1px dashed rgb(0 150 63 / 52%)",
                        paddingBottom: "20px",
                      }}
                    >
                      {"Commercials Summary"}
                    </Typography>
                  </Grid>
                </Grid>
                <DialogContent>
                  <Paper className={tables.root} style={{ border: "1px solid" }}>
                    {/* <h5 className="p-2" style={{ fontWeight: "bold", borderBottom: "1px solid" }}>Commercials Summary</h5> */}

                    <MaterialTable
                      columns={[
                        { title: "Center Name", field: "center", render: (row) => (<div>{row.center ? row.center : "---"}</div>) },
                        { title: "Item Group", field: "itemgroup", render: (row) => (<div>{row.itemgroup ? row.itemgroup : "---"}</div>) },
                        { title: "Item Name", field: "itemName", render: (row) => (<div>{row.itemName ? row.itemName : "---"}</div>) },
                        { title: "Commercial Term", field: "commercialterm", render: (row) => (<div>{row.commercialterm ? row.commercialterm : "---"}</div>) },
                      ]}
                      data={doctorOpCommercial}
                      options={{
                        // filtering: true,
                        sorting: true,
                        paging: false,
                        search: false,
                        headerStyle: {
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px 10px",
                        },
                        cellStyle: {
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px 10px"
                        },
                      }}
                      title={<TableTitle text="Out-Patient Commercial" />}
                    />
                    <MaterialTable
                      columns={[
                        { title: "Center Name", field: "Center", render: (row) => (<div>{row.Center ? row.Center : "---"}</div>) },
                        { title: "Group", field: "group", render: (row) => (<div>{row.group ? row.group : "---"}</div>) },
                        { title: "Item Group", field: "itemGroup", render: (row) => (<div>{row.itemGroup ? row.itemGroup : "---"}</div>) },
                        { title: "Item Name", field: "itemName", render: (row) => (<div>{row.itemName ? row.itemName : "---"}</div>) },
                        { title: "Room Category", field: "roomCategory", render: (row) => (<div>{row.roomCategory ? row.roomCategory : "---"}</div>) },
                        { title: "MOP", field: "ipmodeofpayment", render: (row) => (<div>{row.ipmodeofpayment ? row.ipmodeofpayment : "---"}</div>) },
                        { title: "Commercial Term", field: "commercialTerm", render: (row) => (<div>{row.commercialTerm ? row.commercialTerm : "---"}</div>) },
                      ]}
                      // data={doctorDetail.doctorIPParameter}
                      data={doctorIpDetail}
                      options={{
                        // filtering: true,
                        sorting: true,
                        paging: false,
                        search: false,
                        headerStyle: {
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px 10px",
                        },
                        cellStyle: {
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px 10px"
                        },
                      }}
                      title={<TableTitle text="In-Patient Commercials" />}
                    />
                    {doctorMgDetails && doctorMgDetails.length > 0 ?
                      doctorMgDetails.map((item) =>
                        <MaterialTable
                          columns={[
                            { title: "Minimum Guarantee Payment: The Company agrees to pay the Consultant an amount of Rs. " + numberWithCommas(item.minguranteepermonth) + " (Rs. " + numberToEnglish((item.minguranteepermonth).replaceAll(',', '')) + ") per month (“Minimum Guarantee Amount”) or the “Calculated Earnings” whichever is higher subject to statutory deductions. ", field: "mgCommercial" },
                          ]}
                          // data={doctorDetail.doctorIPParameter}
                          data={
                            [
                              {
                                mgCommercial: 'The Minimum Guarantee Amount will be paid for ' + item.mgperiod + ' months'
                              },
                              {
                                mgCommercial: 'The earnings shall be calculated on ' + item.settlementperiod + ' basis and difference amount (Calculated Earnings minus Minimum Guarantee), if any, shall be paid at the end of ' + item.settlementpaymentperiod + '.'
                              },
                            ]
                          }
                          options={{
                            // filtering: true,
                            sorting: true,
                            paging: false,
                            search: false,
                            headerStyle: {
                              border: "1px solid rgba(224, 224, 224, 1)",
                              padding: "3px 10px",
                              fontWeight: 400
                            },
                            cellStyle: {
                              border: "1px solid rgba(224, 224, 224, 1)",
                              padding: "3px 10px"
                            },
                          }}
                          title={<TableTitle text={"Minimum Guarentee (MG) Commercial for " + item.Center} />}
                        />
                      )
                      : ''}
                    {doctorFixedDetails && doctorFixedDetails.length > 0 ?
                      doctorFixedDetails.map((item) =>
                        <MaterialTable
                          columns={[
                            { title: "Monthly Fixed Fee: The Company will pay an all-inclusive amount of Rs. " + numberWithCommas((item.professionalfeepermonth).replaceAll(',', '')) + " (Rs. " + numberToEnglish((item.professionalfeepermonth).replaceAll(',', '')) + ") per month. ", field: "fixedCommercial", width: "100%" },
                          ]}
                          // data={doctorDetail.doctorIPParameter}
                          data={
                            [
                              {
                                fixedCommercial: item.additionalfeevalue != '0' ? "Additional Fee for " + item.additionalfeename + ": The Company will pay an additional amount of Rs. " + numberWithCommas(((item.additionalfeevalue).toString()).replaceAll(',', '')) + " (Rs. " + numberToEnglish(((item.additionalfeevalue).toString()).replaceAll(',', '')) + ") per month. " : ""
                              },
                            ]
                          }
                          options={{
                            // filtering: true,
                            sorting: false,
                            paging: false,
                            search: false,
                            headerStyle: {
                              border: "1px solid rgba(224, 224, 224, 1)",
                              padding: "3px 10px",
                              fontWeight: 400
                            },
                            cellStyle: {
                              border: "1px solid rgba(224, 224, 224, 1)",
                              padding: "3px 10px"
                            },
                          }}
                          title={<TableTitle text={"Full Time Consultant Commercial for " + item.Center} />}
                        />
                      )
                      : ''}
                  </Paper>
                  <br />
                </DialogContent>
              </>
              : ""
            }
            {/* <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography
                  variant="h4"
                  gutterBottom
                  align="center"
                  style={{
                    color: "#00963f",
                    paddingTop: "20px",
                    borderBottom: "1px dashed rgb(0 150 63 / 52%)",
                    paddingBottom: "20px",
                  }}
                >
                  {"Onboard Information"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: "5px" }}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Paper
                  elevation={24}
                  style={{ height: "520px", padding: "10px" }}
                >
                  {/* <Typography variant="h5" style={{ textAlign: "center" }}>
                DOCUMENT APPROVAL STATUS
              </Typography> 

                  <MaterialTable
                    columns={[
                      {
                        title: "Action",
                        field: "currentAction",
                        filtering: false,
                        render: (row) => (
                          <div style={{ width: "140px" }}>
                            {row.currentAction === onboardState.init.state ? (
                              <span style={{ color: "red" }}>
                                {" "}
                                {onboardState.init.message}
                              </span>
                            ) : row.currentAction ===
                              onboardState.upload.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.upload.message}
                              </span>
                            ) : row.currentAction === onboardState.hr.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.hr.message}
                              </span>
                            ) : row.currentAction === onboardState.ch.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.ch.message}
                              </span>
                            ) : row.currentAction === onboardState.rd.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.rd.message}
                              </span>
                            ) : row.currentAction ===
                              onboardState.done.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.done.message}
                              </span>
                            ) : row.currentAction ===
                              onboardState.ch_doc.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.ch_doc.message}
                              </span>
                            ) : row.currentAction === onboardState.completed ? (
                              <span style={{ color: "blue" }}>"COMPLETED"</span>
                            ) : row.currentAction ===
                              onboardState.ch_renew.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.ch_renew.message}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        ),
                      },
                      { title: "Date", field: "createdAt", render: (row) => (<div>{dateFormatter(row.createdAt)}</div>) },
                      {
                        title: "Next Action",
                        field: "nextAction",
                        filtering: false,
                        render: (row) => (
                          <div style={{ width: "140px" }}>
                            {row.nextAction === onboardState.init.state ? (
                              <span style={{ color: "red" }}>
                                {" "}
                                {onboardState.init.message}
                              </span>
                            ) : row.nextAction === onboardState.upload.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.upload.message}
                              </span>
                            ) : row.nextAction === onboardState.hr.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.hr.message}
                              </span>
                            ) : row.nextAction === onboardState.ch.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.ch.message}
                              </span>
                            ) : row.nextAction === onboardState.rd.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.rd.message}
                              </span>
                            ) : row.nextAction === onboardState.done.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.done.message}
                              </span>
                            ) : row.nextAction === onboardState.ch_doc.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.ch_doc.message}
                              </span>
                            ) : row.nextAction === onboardState.completed ? (
                              <span style={{ color: "blue" }}>"COMPLETED"</span>
                            ) : row.nextAction ===
                              onboardState.ch_renew.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.ch_renew.message}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        ),
                      },
                    ]}
                    data={oboard.onBoardData}
                    options={{
                      sorting: true,
                      exportButton: false,
                      pageSize: 5,
                      pageSizeOptions: [5, 10, 50, 100, 200, 500],
                      search: false,
                    }}
                    title="DOCUMENT APPROVAL STATUS"
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Paper
                  elevation={24}
                  style={{ height: "520px", padding: "10px" }}
                >
                  {/* <Typography variant="h5" style={{ textAlign: "center" }}>
                AGREEMENT APPROVAL STATUS
              </Typography> 
                  <MaterialTable
                    columns={[
                      {
                        title: "Action",
                        field: "currentAction",
                        filtering: false,
                        render: (row) => (
                          <div style={{ width: "140px" }}>
                            {row.currentAction === agreementState.init.state ? (
                              <span style={{ color: "red" }}>
                                {" "}
                                {agreementState.init.message}
                              </span>
                            ) : row.currentAction ===
                              agreementState.rd_revision.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.rd_revision.message}
                              </span>
                            ) : row.currentAction ===
                              agreementState.hr_revision.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.hr_revision.message}
                              </span>
                            ) : row.currentAction ===
                              agreementState.dr_accepted.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.dr_accepted.message}
                              </span>
                            ) : row.currentAction ===
                              agreementState.hr_accepted.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.hr_accepted.message}
                              </span>
                            ) : row.currentAction ===
                              agreementState.ch_approved.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.ch_approved.message}
                              </span>
                            ) : row.currentAction ===
                              agreementState.ceo_approved.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.ceo_approved.message}
                              </span>
                            ) : row.currentAction ===
                              agreementState.rd_approved.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.rd_approved.message}
                              </span>
                            ) : row.currentAction ===
                              agreementState.dr_decline.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.dr_decline.message}
                              </span>
                            ) : row.currentAction ===
                              agreementState.ch_decline.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.ch_decline.message}
                              </span>
                            ) : row.currentAction ===
                              agreementState.rd_decline.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.rd_decline.message}
                              </span>
                            ) : row.currentAction ===
                              agreementState.hr_decline.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.hr_decline.message}
                              </span>
                            ) : row.currentAction ===
                              agreementState.completed ? (
                              <span style={{ color: "blue" }}>"COMPLETED"</span>
                            ) : (
                              ""
                            )}
                          </div>
                        ),
                      },

                      { title: "Date", field: "createdAt", render: (row) => (<div>{dateFormatter(row.createdAt)}</div>) },
                      {
                        title: "Next Action",
                        field: "nextAction",
                        filtering: false,
                        render: (row) => (
                          <div style={{ width: "140px" }}>
                            {row.nextAction === agreementState.init.state ? (
                              <span style={{ color: "red" }}>
                                {" "}
                                {agreementState.init.message}
                              </span>
                            ) : row.nextAction ===
                              agreementState.rd_revision.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.rd_revision.message}
                              </span>
                            ) : row.nextAction ===
                              agreementState.hr_revision.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.hr_revision.message}
                              </span>
                            ) : row.nextAction ===
                              agreementState.dr_accepted.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.dr_accepted.message}
                              </span>
                            ) : row.nextAction ===
                              agreementState.hr_accepted.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.hr_accepted.message}
                              </span>
                            ) : row.nextAction ===
                              agreementState.ch_approved.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.ch_approved.message}
                              </span>
                            ) : row.nextAction ===
                              agreementState.ceo_approved.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.ceo_approved.message}
                              </span>
                            ) : row.nextAction ===
                              agreementState.rd_approved.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.rd_approved.message}
                              </span>
                            ) : row.nextAction ===
                              agreementState.dr_decline.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.dr_decline.message}
                              </span>
                            ) : row.nextAction ===
                              agreementState.ch_decline.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.ch_decline.message}
                              </span>
                            ) : row.nextAction ===
                              agreementState.rd_decline.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.rd_decline.message}
                              </span>
                            ) : row.nextAction ===
                              agreementState.hr_decline.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.hr_decline.message}
                              </span>
                            ) : row.nextAction === agreementState.completed ? (
                              <span style={{ color: "blue" }}>"COMPLETED"</span>
                            ) : (
                              ""
                            )}
                          </div>
                        ),
                      },
                    ]}
                    data={oboard.doctorAgreement}
                    options={{
                      sorting: true,
                      exportButton: false,
                      pageSize: 5,
                      pageSizeOptions: [5, 10, 50, 100, 200, 500],
                      search: false,
                    }}
                    title=" AGREEMENT APPROVAL STATUS"
                  />
                </Paper>
              </Grid>
            </Grid> */}
          </div>
        </div>
      </div >
      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={() => {
          setOpenDialog(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        disableBackdropClick
      >
        <DialogTitle
          id="customized-dialog-title"
          // onClose={handlerClickcloseCommercials}
          style={{ textAlign: "center" }}
        ></DialogTitle>
        <DialogContent dividers></DialogContent>
        <Iframe
          url={urlData}
          width="900px"
          height="800px"
          id="myId"
          className="myClassname"
          display="initial"
          position="relative"
        />

        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
            }}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
