import React, { useEffect, useState } from "react";
//ui components
import AppSidebar from "../../Components/PTSidebar";
import Navbar from "../../Components/Navbar";
//mui components
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { Button, Typography, FormControl } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import MaterialTable from "material-table";
import Skeleton from "@material-ui/lab/Skeleton";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@mui/material/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
////
import axios from "axios";
import { APIURL } from "../../CommomMethods/Fetch";
import { numberWithCommas } from "../../actions/createPositionActions";

export default function DoctorOpParameter() {
  const [rows, setRows] = useState([]);
  const [ipRows, setIpRows] = useState([]);
  const [adminCharges, setAdminCharges] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openr, setOpenr] = React.useState(false);
  const [openAdminCharges, setOpenAdminCharges] = React.useState(false);
  const [rowSelection, setRowSelection] = useState(false);
  const [splitDialog, setSplitDialog] = useState(false);
  const [changeRowValue, setChangeRowValue] = useState([]);
  const [changeRowValueAC, setChangeRowValueAC] = useState([]);
  const [reload, setReload] = useState(false);
  const [valueDdl, setValueDdl] = useState("");

  const handleInputChangeApplicaale = (event) => {
    setValueDdl(event.target.value);
  };

  // const [rowDetail, setRowDetail] = useState({
  //   amount: "",
  //   percentage: "",
  //   // applicable: valueDdl,
  // });

  const [rowDetail, setRowDetail] = useState({
    ipmodeofpayment: "",
    revenue: "",
    // applicable: valueDdl,
  });
  const [rowDetailAC, setRowDetailAC] = useState({
    consulation: "",
    followup: "",
  });
  const [value, setValue] = React.useState("1");
  const handleChangetab = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    setLoading(false);
    axios
      .get(APIURL() + "opparameters/findAllDocParameter")
      .then((res) => res.data)
      .then((rows) => {
        setRows(rows.data);
        setLoading(true);
      });

    axios
      .get(APIURL() + "ipparameters/IpCommercialfindAll")
      .then((res) => res.data)
      .then((rows) => {
    // console.log(rows, "ip parameter");
        setIpRows(rows);
        setLoading(true);
      });

    axios
      .get(APIURL() + "opparameters/findAllAdminCharges")
      .then((res) => res.data)
      .then((rows) => {
        setAdminCharges(rows);
        setLoading(true);
      });
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setRowDetail({ ...rowDetail, [name]: value });
  };

  const handleInputChangeAC = (event) => {
    const { name, value } = event.target;
    setRowDetailAC({ ...rowDetailAC, [name]: value });
  };

  const handlerClose = () => {
    setOpenr(false);
  };

  const handlerCloseAdminCharges = () => {
    setOpenAdminCharges(false);
  };

  const handleParameterEdit = () => {
    setReload(true);
    rowDetail.ipdiscountType = valueDdl;
    const obj = { rowDetail: rowDetail, changeRowValue: changeRowValue };
// console.log(obj, "update data");
    axios
      .put(APIURL() + "ipparameters/updateipparam/" +rowDetail, obj)
      .then((res) => {
        setReload(false);
        setOpenr(false);
      });
  };

  const handleACEdit = () => {
    setReload(true);
    const obj = {
      rowDetailAC: rowDetailAC,
      changeRowValueAC: changeRowValueAC,
    };
    axios
      .put(APIURL() + "opparameters/UpdateAdminCharges/" + rowDetailAC, obj)
      .then((res) => {
        setReload(false);
        setOpenAdminCharges(false);
      });
  };
  return (
    <>
      {" "}
      <AppSidebar />
      <CssBaseline />
      <Container style={{ margin: "10px 0px 7px 235px", width: "80%" }}>
        <Navbar />
        <TabContext value={value}>
          <AppBar
            position="static"
            style={{ marginTop: "10px", backgroundColor: "#810e36" }}
          >
            <TabList
              onChange={handleChangetab}
              aria-label="simple tabs example"
            >
              <Tab label="Op Commercial List" value="1" style={{display:"none"}}/>
              <Tab label="Ip Commercial List" value="2" />
              <Tab label="Admin Charges" value="3" />
            </TabList>
          </AppBar>
          <TabPanel value="1" style={{display:"none"}}>
            {/* <Typography variant="subtitle1">Doctro Op-Parameter</Typography> */}

            {!loading ? (
              <Skeleton
                animation="wave"
                variant="rect"
                width={950}
                height={400}
              />
            ) : (
              <div style={{ whiteSpace: "nowrap" }}>
                {/* {rowSelection ? (
                  <IconButton aria-label="close" tooltip="Edit Selected Row">
                    <EditIcon
                      style={{ color: "#810e36" }}
                      onClick={() => {
                        setOpenr(true);
                      }}
                    />
                  </IconButton>
                ) : (
                  ""
                )} */}

                <MaterialTable
                  columns={[
                    { title: "Doctor Name", field: "doctorName" },
                    { title: "Doctor Type", field: "type" },
                    { title: "Center", field: "centerCode" },
                    { title: "Item Group", field: "itemGroup" },
                    { title: "Fixed Amount", field: "fixedamount" },
                    { title: "Fixed %", field: "fixedpercentage" },
                    { title: "Applicable On", field: "applicable" },

                    // {
                    //   title: "Discount",
                    //   field: "opdiscounttype",
                    //   lookup: {
                    //     1: "Yes",
                    //     0: "No",
                    //     true: "Yes",
                    //     false: "No",
                    //   },
                    // },
                  ]}
                  data={rows}
                  options={{
                    // filtering: true,
                    selection: true,
                    sorting: true,
                    exportButton: true,
                    exportAllData: true,
                    pageSize: 5,
                    pageSizeOptions: [5, 10, 50, 100, 200, 500],
                    search: true,
                  }}
                  actions={[
                    {
                      tooltip: "Edit data",
                      icon: "edit",
                      iconProps: { style: { color: "#810e36" } },
                      onClick: (evt, data) => setOpenr(true),
                    },
                  ]}
                  onSelectionChange={(rows) => {
                    if (rows.length > 0) {
                      setRowSelection(true);
                      setChangeRowValue(rows);
                    } else {
                      setRowSelection(false);
                    }
                  }}
                  title="Op-Commerail List"
                />
              </div>
            )}
          </TabPanel>
          <TabPanel value="2">
            {/* <Typography variant="subtitle1">Doctro Op-Parameter</Typography> */}

            {!loading ? (
              <Skeleton
                animation="wave"
                variant="rect"
                width={950}
                height={400}
              />
            ) : (
              <div style={{ whiteSpace: "nowrap" }}>
                <MaterialTable
                  columns={[
                    
                  
                    { title: "Doctor ID", field: "doctorId" },
                    { title: "Item Group", field: "itemGroup" },
                    { title: "IP Room", field: "iproom" },
                    { title: "Payment Mode", field: "ipmodeofpayment" },
                    { title: "Revenue", field: "revenue",render: (row)=> row.revenue ? "Rs. "+numberWithCommas(row.revenue) : "" },
                    { title: "Total Amount", field: "ipTotalAmount",render: (row)=> row.ipTotalAmount ? "Rs. "+numberWithCommas(row.ipTotalAmount) : ""  },
                    // { title: "Total Amount", field: "ipTotalAmount" },
                    {
                      title: "Discount",
                      field: "ipdiscountType",
                    },
                  ]}
                  data={ipRows}
                  options={{
                    // filtering: true,
                    selection: true,
                    sorting: true,
                    exportButton: true,
                    exportAllData: true,
                    pageSize: 5,
                    pageSizeOptions: [5, 10, 50, 100, 200, 500],
                    search: true,
                  }}
                  actions={[
                    {
                      tooltip: "Edit data",
                      icon: "edit",
                      iconProps: { style: { color: "#810e36" } },
                      onClick: (evt, data) => setOpenr(true),
                    },
                  ]}
                  onSelectionChange={(rows) => {
                    setRowSelection(true);
                    setChangeRowValue(rows);
                  }}
                  title="IP Commercial List"
                />
              </div>
            )}
          </TabPanel>
          <TabPanel value="3">
            <MaterialTable
              columns={[
                // { title: "Doctor Id", field: "doctorId" },
                { title: "Doctor Name", field: "doctorName" },
                { title: "Consulation", field: "consulation" },
                { title: "Followup", field: "followup" },
                { title: "Center", field: "Center" },
                { title: "Region", field: "region" },
              ]}
              data={adminCharges}
              options={{
                // filtering: true,
                selection: true,
                sorting: true,
                exportButton: true,
                exportAllData: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
              }}
              actions={[
                {
                  tooltip: "Edit data",
                  icon: "edit",
                  iconProps: { style: { color: "#810e36" } },
                  onClick: (evt, data) => setOpenAdminCharges(true),
                },
              ]}
              onSelectionChange={(rows) => {
                // setRowSelectionAdminCharges(true);
                // setChangeRowValueAdminCharges(rows);
                if (rows.length > 0) {
                  setRowSelection(true);
                  setChangeRowValueAC(rows);
                } else {
                  setRowSelection(false);
                }
              }}
              title="Admin Charges List"
            />
          </TabPanel>
        </TabContext>
      </Container>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerClose}
        aria-labelledby="customized-dialog-title"
        open={openr}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClose}
          style={{ textAlign: "center" }}
        >
          Edit Parameter Details
        </DialogTitle>
        <DialogContent dividers>
          <label>
            <b>Payment Mode</b>
          </label>
          <TextField
            id="Payout"
            label="ipmodeofpayment"
            variant="outlined"
            fullWidth
            name="ipmodeofpayment"
            value={rowDetail.ipmodeofpayment}
            onChange={handleInputChange}
            //   onChange={(e) => setPayout(e.target.value)}

            style={{ marginBottom: "20px" }}
            autoComplete="off"
          />
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-outlined-label">
               Discount Applicable
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={valueDdl}
              onChange={handleInputChangeApplicaale}
              label="Select Discount Applicable"
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>
          <label>
            <b>Revenue</b>
          </label>
          <TextField
            id="Payout"
            label="Revenue"
            variant="outlined"
            fullWidth
            name="revenue"
            value={rowDetail.revenue}
            onChange={handleInputChange}
            //   onChange={(e) => setPayout(e.target.value)}

            style={{ marginBottom: "20px" }}
            autoComplete="off"
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => handleParameterEdit()}
            color="primary"
            variant="contained"
          >
            Update
          </Button>
          <Button
            autoFocus
            onClick={handlerClose}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerClose}
        aria-labelledby="customized-dialog-title"
        open={openAdminCharges}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClose}
          style={{ textAlign: "center" }}
        >
          Edit Admin Charges
        </DialogTitle>
        <DialogContent dividers>
          <label>
            <b>Followup</b>
          </label>
          <TextField
            label="Followup"
            variant="outlined"
            fullWidth
            name="followup"
            required
            value={rowDetailAC.followup}
            onChange={handleInputChangeAC}
            //   onChange={(e) => setPayout(e.target.value)}

            style={{ marginBottom: "20px" }}
            autoComplete="off"
          />

          <label>
            <b>Consulatation</b>
          </label>
          <TextField
            label="Consultation"
            variant="outlined"
            fullWidth
            required
            name="consulation"
            value={rowDetailAC.consulation}
            onChange={handleInputChangeAC}
            //   onChange={(e) => setPayout(e.target.value)}

            style={{ marginBottom: "20px" }}
            autoComplete="off"
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => handleACEdit()}
            color="primary"
            variant="contained"
          >
            Update
          </Button>
          <Button
            autoFocus
            onClick={handlerCloseAdminCharges}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
